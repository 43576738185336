import {
  type ClinicianAppointmentsTable_clinician$data,
  type ClinicianAppointmentsTable_clinician$key
} from '@app/__generated__/ClinicianAppointmentsTable_clinician.graphql'
import { type ClinicianAppointmentsTablePaginationQuery } from '@app/__generated__/ClinicianAppointmentsTablePaginationQuery.graphql'
import { PaginationTable } from '@app/components'
import { type AppointmentFilter, AppointmentsOrderBy, humanize, longDate } from '@app/lib'
import { Anchor, Stack } from '@mantine/core'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo, type ReactNode } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

type ClinicianAppointmentsAppointmentEdge = ElementOf<
  ClinicianAppointmentsTable_clinician$data['appointments']['edges']
>

const paginationFragment = graphql`
  fragment ClinicianAppointmentsTable_clinician on Clinician
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "AppointmentFilter" }
    first: { type: "Int" }
    orderBy: { type: "[AppointmentsOrderBy!]", defaultValue: [STARTS_AT_DESC] }
  )
  @refetchable(queryName: "ClinicianAppointmentsTablePaginationQuery") {
    appointments(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "ClinicianAppointmentsTable_clinician_appointments", filters: ["filter", "orderBy"]) {
      edges {
        node {
          id
          patient {
            user {
              firstName
              lastName
              slug
            }
          }
          service {
            duration {
              days
              hours
              minutes
              months
              seconds
              years
            }
            name
            slug
          }
          startsAt
          status
          userChild {
            birthdateAt
            dueAt
            name
          }
        }
      }
      totalCount
    }
  }
`

export interface ClinicianAppointmentsTableProps {
  clinician: ClinicianAppointmentsTable_clinician$key
}

export const ClinicianAppointmentsTable: FC<ClinicianAppointmentsTableProps> = ({ clinician }) => {
  const { t } = useTranslation('admin')
  const {
    data: clinicianData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<ClinicianAppointmentsTablePaginationQuery, ClinicianAppointmentsTable_clinician$key>(
    paginationFragment,
    clinician
  )

  return (
    <PaginationTable<
      ClinicianAppointmentsAppointmentEdge,
      AppointmentsOrderBy,
      ClinicianAppointmentsTablePaginationQuery,
      AppointmentFilter
    >
      columns={useMemo(
        () => [
          {
            accessorKey: 'node.startsAt',
            id: 'startsAt',
            enableSorting: true,
            header: t('Date/Time'),
            cell: (props) => {
              const node = props.row.original.node

              return longDate(node.startsAt)
            }
          },
          {
            accessorKey: 'node.patientId',
            id: 'patientId',
            enableSorting: false,
            header: t('Patient'),
            cell: (props) => {
              const node = props.row.original.node
              let child: ReactNode

              if (node.userChild) {
                const now = dayjs()
                let age: number

                if (node.userChild.birthdateAt) {
                  const birthdate = dayjs(node.userChild.birthdateAt)
                  const ageInYears = now.diff(birthdate, 'year')

                  age =
                    ageInYears >= 1
                      ? t('admin:x_years', { x: ageInYears })
                      : t('admin:x_months', { x: now.diff(birthdate, 'month') })
                } else {
                  age = t('admin:due_in_x_months', {
                    x: Math.round(dayjs(node.userChild?.dueAt).diff(now, 'month', true))
                  })
                }

                child = (
                  <Anchor
                    component={Link}
                    href={`/admin/users/${node.patient.user.slug}/children`}
                  >
                    {node.userChild.name} ({age})
                  </Anchor>
                )
              }

              return (
                <Stack spacing='xs'>
                  <Anchor
                    component={Link}
                    href={`/admin/users/${node.patient.user.slug}/patient`}
                  >
                    {node.patient.user.firstName} {node.patient.user.lastName}
                  </Anchor>
                  {child}
                </Stack>
              )
            }
          },
          {
            accessorKey: 'node.serviceId',
            id: 'serviceId',
            enableSorting: false,
            header: t('Service'),
            cell: (props) => {
              const node = props.row.original.node

              return (
                <Anchor
                  component={Link}
                  href={`/admin/services/${node.service.slug}`}
                >
                  {node.service.name} ({dayjs.duration(node.service.duration).asMinutes()}min)
                </Anchor>
              )
            }
          },
          {
            id: 'status',
            header: t('Status'),
            cell: (props) => {
              const node = props.row.original.node

              return humanize(node.status)
            }
          }
        ],
        [t]
      )}
      data={clinicianData?.appointments?.edges}
      isFilterable
      getFilterFromSearch={useCallback(
        (search) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    patient: {
                      user: {
                        firstName: {
                          includesInsensitive: search
                        }
                      }
                    }
                  },
                  {
                    patient: {
                      user: {
                        lastName: {
                          includesInsensitive: search
                        }
                      }
                    }
                  },
                  {
                    service: {
                      name: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    userChild: {
                      name: {
                        includesInsensitive: search
                      }
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={useMemo(
        () => [
          {
            id: 'startsAt',
            desc: true
          }
        ],
        []
      )}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={useMemo(
        () => ({
          startsAt: {
            asc: AppointmentsOrderBy.StartsAtAsc,
            desc: AppointmentsOrderBy.StartsAtDesc
          }
        }),
        []
      )}
      totalCount={clinicianData?.appointments?.totalCount}
    />
  )
}
