import {
  LoadingOverlay,
  type LoadingOverlayProps,
  type MantineColor,
  RingProgress,
  Text,
  type TextProps
} from '@mantine/core'
import { type FC } from 'react'

export interface UploadProgressOverlayProps extends LoadingOverlayProps {
  color?: MantineColor
  labelProps?: TextProps
  progress: number
}

export const UploadProgressOverlay: FC<UploadProgressOverlayProps> = ({
  color,
  labelProps,
  progress = 0,
  ...loadingOverlayProps
}) => {
  return (
    <LoadingOverlay
      {...loadingOverlayProps}
      loader={
        <RingProgress
          sections={[
            {
              value: progress,
              color
            }
          ]}
          label={
            <Text
              weight={700}
              align='center'
              size='xl'
              {...labelProps}
              color={color}
            >
              {progress.toFixed()}%
            </Text>
          }
        />
      }
    />
  )
}
