import { TextInput } from '@app/components'
import {
  ActionIcon,
  Box,
  CloseButton,
  createStyles,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  SegmentedControl,
  Stack,
  Table as MantineTable,
  type TableProps as MantineTableProps,
  Text
} from '@mantine/core'
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks'
import {
  IconArrowsSort,
  IconChevronsLeft,
  IconChevronsRight,
  IconSearch,
  IconSortAscending,
  IconSortDescending,
  IconZoomExclamation
} from '@tabler/icons-react'
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  type PaginationState,
  type SortingState,
  type TableOptions,
  useReactTable
} from '@tanstack/react-table'
import { isArray, isEmpty, map, reduce, toString } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useMemo, useState } from 'react'
import { type LoadMoreFn, type RefetchOptions, type Variables } from 'react-relay'
import { type OperationType } from 'relay-runtime'

const useStyles = createStyles({
  root: {
    position: 'relative'
  }
})

export interface PaginationTableProps<TEdge, TOrderBy, TQuery extends OperationType, TFilter>
  extends MantineTableProps {
  columns: ColumnDef<TEdge>[]
  data: TEdge[] | ReadonlyArray<TEdge>
  getFilterFromSearch?: (search: string) => TFilter
  hasNext: boolean
  initialPagination?: PaginationState
  initialSorting: SortingState
  isFilterable?: boolean
  isLoadingNext: boolean
  loadNext: LoadMoreFn<TQuery>
  pageSizeOptions?: number[]
  refetch: (variables: object, options?: RefetchOptions) => void
  sortOptions: Record<
    string,
    {
      asc: TOrderBy
      desc: TOrderBy
    }
  >
  tableOptions?: TableOptions<TEdge>
  totalCount: number
}

export const PaginationTable = <TEdge, TOrderBy, TQuery extends OperationType, TFilter>({
  className,
  columns,
  data,
  getFilterFromSearch,
  hasNext,
  initialPagination = {
    pageIndex: 0,
    pageSize: 10
  },
  initialSorting,
  isFilterable,
  isLoadingNext,
  loadNext,
  pageSizeOptions = [10, 20, 50, 100],
  refetch,
  sortOptions,
  tableOptions,
  totalCount = 0,
  ...mantineTableProps
}: PaginationTableProps<TEdge, TOrderBy, TQuery, TFilter>) => {
  const [loading, setLoading] = useState(false)
  const [rawSearch, setRawSearch] = useState(null)
  const [sorting, setSorting] = useState(initialSorting)
  const [{ pageIndex, pageSize }, setPagination] = useState(initialPagination)
  const [search] = useDebouncedValue(rawSearch, 300)
  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize])
  const table = useReactTable<TEdge>({
    ...tableOptions,
    columns,
    data: useMemo(() => {
      if (isArray(data)) {
        // since relay appends new pages to the connection in the store, we need to slice just the edges that we want for
        // this page. we'll determine the starting and (non-inclusive) ending index, and return a shallow copy of overall
        // data for this page.
        const pageIndexStart = pageIndex * pageSize

        return data.slice(pageIndexStart, pageIndexStart + pageSize)
      }

      return []
    }, [data, pageIndex, pageSize]),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    pageCount: useMemo(() => (totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize)), [pageSize, totalCount]),
    sortDescFirst: false,
    state: {
      pagination,
      sorting
    }
  })
  const { t } = useTranslation('admin')
  const { classes, cx, theme } = useStyles()
  // this gives the parent table a chance to re-render before dispatching another re-render call (which react complains about)
  const onSearchChange = useCallback((e) => setRawSearch(e.currentTarget.value), [])
  const onSearchClear = useCallback(() => setRawSearch(''), [])

  useDidUpdate(() => {
    setLoading(true)

    const orderBy = reduce(sorting, (acc, { id, desc }) => [...acc, sortOptions[id][desc ? 'desc' : 'asc']], [])
    const variables = {
      after: null,
      first: pageSize,
      orderBy: !isEmpty(orderBy) ? orderBy : null
    } as Variables

    if (isFilterable && getFilterFromSearch) {
      variables.filter = getFilterFromSearch(search)
    }

    refetch(variables, {
      onComplete: () =>
        requestAnimationFrame(() => {
          table.setPageIndex(0)
          setLoading(false)
        })
    })
  }, [search, pageSize, sorting])

  return (
    <Box className={cx(classes.root, className)}>
      <LoadingOverlay visible={loading} />
      {useMemo(
        () =>
          isFilterable ? (
            <TextInput
              icon={<IconSearch size={18} />}
              mb='xs'
              onChange={onSearchChange}
              placeholder={t('Search')}
              rightSection={
                search && (
                  <CloseButton
                    onClick={onSearchClear}
                    variant='transparent'
                  />
                )
              }
              value={rawSearch ?? ''}
            />
          ) : null,
        [isFilterable, onSearchChange, onSearchClear, rawSearch, search, t]
      )}
      <Paper
        shadow='sm'
        radius='sm'
        withBorder
      >
        <MantineTable {...mantineTableProps}>
          <thead>
            {map(
              table.getHeaderGroups(),
              useCallback(
                (headerGroup) => (
                  <tr key={headerGroup.id}>
                    {map(headerGroup.headers, (header) => {
                      const isSorted = header.column.getIsSorted()
                      const label = !header.isPlaceholder
                        ? flexRender(header.column.columnDef.header, header.getContext())
                        : null

                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {header.column.getCanSort() ? (
                            <Group
                              position='left'
                              spacing={0}
                              noWrap
                            >
                              {label}
                              <ActionIcon
                                onClick={() => header.column.toggleSorting(undefined, header.column.getCanMultiSort())}
                                variant='transparent'
                              >
                                {isSorted === 'asc' ? (
                                  <IconSortAscending size={14} />
                                ) : isSorted === 'desc' ? (
                                  <IconSortDescending size={14} />
                                ) : (
                                  <IconArrowsSort size={14} />
                                )}
                              </ActionIcon>
                            </Group>
                          ) : (
                            label
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ),
                []
              )
            )}
          </thead>
          <tbody>
            {map(
              table.getRowModel().rows,
              useCallback(
                (row) => (
                  <tr key={row.id}>
                    {map(row.getVisibleCells(), (cell) => (
                      <td
                        key={cell.id}
                        width={cell.column.getSize()}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ),
                []
              )
            )}
          </tbody>
        </MantineTable>
        {useMemo(
          () =>
            data?.length === 0 ? (
              <Stack
                align='center'
                justify='center'
                spacing='xs'
                p='xl'
                sx={{ minHeight: '10vh' }}
              >
                <IconZoomExclamation
                  color={theme.colors.gray[4]}
                  size={64}
                />
                <Text
                  size='xl'
                  color='dimmed'
                >
                  {search ? t('admin:no_results_found_for_search', { search }) : t('No results found')}
                </Text>
              </Stack>
            ) : null,
          [data?.length, search, t, theme.colors.gray]
        )}
        <Divider color={theme.colors.gray[3]} />
        <Group
          align='center'
          p='xs'
          position='apart'
        >
          <ActionIcon
            color='blue'
            disabled={!table.getCanPreviousPage()}
            onClick={useCallback(() => table.previousPage(), [table])}
            variant='transparent'
          >
            <IconChevronsLeft size={18} />
          </ActionIcon>
          <Group spacing='sm'>
            <Text size='xs'>
              {t('admin:page_x_of_y', {
                x: pageIndex + 1,
                y: table.getPageCount()
              })}
            </Text>
            <Text size='xs'>&middot;</Text>
            <SegmentedControl
              color='blue'
              data={useMemo(() => map(pageSizeOptions, toString), [pageSizeOptions])}
              onChange={useCallback((nextPageSize) => table.setPageSize(Number(nextPageSize)), [table])}
              size='xs'
              value={toString(pageSize)}
            />
            <Text size='xs'>{t('Per Page')}</Text>
            <Text size='xs'>&middot;</Text>
            <Text size='xs'>{t('admin:results_count', { count: totalCount })}</Text>
          </Group>
          <ActionIcon
            color='blue'
            disabled={!(hasNext || table.getCanNextPage())}
            onClick={useCallback(() => {
              if (data[(pageIndex + 1) * pageSize]) {
                // the data is already fetched, so just change page index
                table.nextPage()
              } else if (hasNext) {
                // we don't have the data already, and relay reports that there's a next page, so we need to load it
                setLoading(true)

                loadNext(pageSize, {
                  onComplete: () =>
                    requestAnimationFrame(() => {
                      // now that the data is loaded, we can let the table change to that page index
                      table.nextPage()

                      setLoading(false)
                    })
                })
              }
            }, [data, hasNext, loadNext, pageIndex, pageSize, table])}
            variant='transparent'
          >
            <IconChevronsRight size={18} />
          </ActionIcon>
        </Group>
      </Paper>
    </Box>
  )
}
