/**
 * @generated SignedSource<<8d7af560f556f67255465aec4ac19289>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClinicianAvailabilityForm_query$data = {
  readonly clinicians: {
    readonly nodes: ReadonlyArray<{
      readonly rowId: any;
      readonly user: {
        readonly firstName: string;
        readonly lastName: string;
      } | null;
    }>;
  } | null;
  readonly " $fragmentType": "ClinicianAvailabilityForm_query";
};
export type ClinicianAvailabilityForm_query$key = {
  readonly " $data"?: ClinicianAvailabilityForm_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClinicianAvailabilityForm_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianAvailabilityForm_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "USER_BY_USER_ID__FIRST_NAME_ASC",
            "USER_BY_USER_ID__LAST_NAME_ASC"
          ]
        }
      ],
      "concreteType": "CliniciansConnection",
      "kind": "LinkedField",
      "name": "clinicians",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Clinician",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "clinicians(orderBy:[\"USER_BY_USER_ID__FIRST_NAME_ASC\",\"USER_BY_USER_ID__LAST_NAME_ASC\"])"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a6f522bc13b0045ec449b4208f7ebc32";

export default node;
