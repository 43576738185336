import { type UpdateClinicianAvailabilityModal_clinicianAvailability$key } from '@app/__generated__/UpdateClinicianAvailabilityModal_clinicianAvailability.graphql'
import { type UpdateClinicianAvailabilityModal_query$key } from '@app/__generated__/UpdateClinicianAvailabilityModal_query.graphql'
import { type UpdateClinicianAvailabilityModalUpdateClinicianAvailabilityMutation } from '@app/__generated__/UpdateClinicianAvailabilityModalUpdateClinicianAvailabilityMutation.graphql'
import { ClinicianAvailabilityForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type ClinicianAvailabilityPatch } from '@app/lib'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const clinicianAvailabilityFragment = graphql`
  fragment UpdateClinicianAvailabilityModal_clinicianAvailability on ClinicianAvailability {
    id
    ...ClinicianAvailabilityForm_clinicianAvailability
  }
`
const updateClinicianAvailabilityMutation = graphql`
  mutation UpdateClinicianAvailabilityModalUpdateClinicianAvailabilityMutation(
    $input: UpdateClinicianAvailabilityByIdInput!
  ) {
    updateClinicianAvailabilityById(input: $input) {
      clinicianAvailability {
        clinician {
          user {
            firstName
            lastName
          }
        }
        clinicianId
        startsAt
        endsAt
        isExclusive
        ...ClinicianAvailabilityForm_clinicianAvailability
      }
    }
  }
`
const queryFragment = graphql`
  fragment UpdateClinicianAvailabilityModal_query on Query {
    ...ClinicianAvailabilityForm_query
  }
`

export interface UpdateClinicianAvailabilityModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  clinicianAvailability: UpdateClinicianAvailabilityModal_clinicianAvailability$key
  query: UpdateClinicianAvailabilityModal_query$key
}

export const UpdateClinicianAvailabilityModal: FC<UpdateClinicianAvailabilityModalProps> = ({
  onClose,
  clinicianAvailability,
  query,
  ...modalProps
}) => {
  const clinicianAvailabilityData = useFragment(clinicianAvailabilityFragment, clinicianAvailability)
  const queryData = useFragment(queryFragment, query)
  const [updateClinicianAvailability, isCreatingClinicianAvailability] =
    useMutation<UpdateClinicianAvailabilityModalUpdateClinicianAvailabilityMutation>(
      updateClinicianAvailabilityMutation
    )
  const { t } = useTranslation('booking')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      title={t('Edit Clinician Availability')}
      {...modalProps}
    >
      <ClinicianAvailabilityForm
        clinicianAvailability={clinicianAvailabilityData}
        isSaving={isCreatingClinicianAvailability}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (patch: ClinicianAvailabilityPatch) =>
            updateClinicianAvailability({
              variables: {
                input: {
                  id: clinicianAvailabilityData?.id,
                  patch
                }
              },
              onCompleted: onClose
            }),
          [onClose, updateClinicianAvailability, clinicianAvailabilityData?.id]
        )}
        query={queryData}
      />
    </UnsavedChangesModal>
  )
}
