/**
 * @generated SignedSource<<efe0813d754fbbfbb8bec22173f0139e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatePatientAppointmentModal_patient$data = {
  readonly rowId: any;
  readonly " $fragmentSpreads": FragmentRefs<"PatientAppointmentForm_patient">;
  readonly " $fragmentType": "CreatePatientAppointmentModal_patient";
};
export type CreatePatientAppointmentModal_patient$key = {
  readonly " $data"?: CreatePatientAppointmentModal_patient$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatePatientAppointmentModal_patient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatePatientAppointmentModal_patient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PatientAppointmentForm_patient"
    }
  ],
  "type": "Patient",
  "abstractKey": null
};

(node as any).hash = "15e8b2acbba8948c7f0459551ac81a41";

export default node;
