import { type CreateUserModal_query$key } from '@app/__generated__/CreateUserModal_query.graphql'
import { type CreateUserModalCreateUserMutation } from '@app/__generated__/CreateUserModalCreateUserMutation.graphql'
import { type CreateUserModalUpdateUserPasswordMutation } from '@app/__generated__/CreateUserModalUpdateUserPasswordMutation.graphql'
import { UnsavedChangesModal, type UnsavedChangesModalProps, UserForm } from '@app/components'
import { type CreateUserInput } from '@app/lib'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const createUserMutation = graphql`
  mutation CreateUserModalCreateUserMutation($connections: [ID!]!, $input: CreateUserInput!) {
    createUser(input: $input) {
      userEdge @appendEdge(connections: $connections) {
        cursor
        node {
          clinician {
            slug
          }
          email
          id
          isAdmin
          firstName
          lastName
          patient {
            slug
          }
          rowId
          userPhones(filter: { isPrimary: { equalTo: true } }, first: 1) {
            nodes {
              phone
            }
          }
          slug
          ...UserAvatar_user
        }
      }
    }
  }
`
const queryFragment = graphql`
  fragment CreateUserModal_query on Query {
    ...UserForm_query
  }
`
const updateUserPasswordMutation = graphql`
  mutation CreateUserModalUpdateUserPasswordMutation($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      user {
        hasPassword
        id
        updatedAt
      }
    }
  }
`

export interface CreateUserModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  connectionId: string
  query: CreateUserModal_query$key
}

export const CreateUserModal: FC<CreateUserModalProps> = ({ connectionId, onClose, query, ...modalProps }) => {
  const router = useRouter()
  const queryData = useFragment<CreateUserModal_query$key>(queryFragment, query)
  const [createUser, isCreatingUser] = useMutation<CreateUserModalCreateUserMutation>(createUserMutation)
  const [updateUserPassword, isUpdatingUserPassword] =
    useMutation<CreateUserModalUpdateUserPasswordMutation>(updateUserPasswordMutation)
  const { t } = useTranslation('admin')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      size='md'
      title={t('New User')}
      {...modalProps}
    >
      <UserForm
        isSaving={isCreatingUser || isUpdatingUserPassword}
        onChange={setIsDirty}
        onSubmit={useCallback(
          ({ password, ...user }) =>
            createUser({
              variables: {
                connections: [connectionId],
                input: {
                  user
                } as CreateUserInput
              },
              onCompleted: async ({
                createUser: {
                  userEdge: {
                    node: { rowId, slug }
                  }
                }
              }) =>
                updateUserPassword({
                  variables: {
                    input: {
                      rowId,
                      password
                    }
                  },
                  onCompleted: () => {
                    onClose()
                    router.push(`/admin/users/${slug}`)
                  }
                })
            }),
          [connectionId, createUser, onClose, router, updateUserPassword]
        )}
        query={queryData}
        user={null}
      />
    </UnsavedChangesModal>
  )
}
