/**
 * @generated SignedSource<<de683dd1664360f64ee03f8386c96d87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatePolicyModal_policy$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"PolicyForm_policy">;
  readonly " $fragmentType": "UpdatePolicyModal_policy";
};
export type UpdatePolicyModal_policy$key = {
  readonly " $data"?: UpdatePolicyModal_policy$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdatePolicyModal_policy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdatePolicyModal_policy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PolicyForm_policy"
    }
  ],
  "type": "Policy",
  "abstractKey": null
};

(node as any).hash = "f1a2d905222976634c88b5e44eeb541d";

export default node;
