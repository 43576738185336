import {
  type DefaultMantineColor,
  MantineProvider as BaseMantineProvider,
  type MantineProviderProps,
  type Tuple
} from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import localFont from 'next/font/local'
import { type FC } from 'react'

const avenirBlack = localFont({
  src: './fonts/Avenir-Black.woff2'
})

const avenirRoman = localFont({
  src: './fonts/Avenir-Roman.woff2'
})

type ExtendedCustomColors = 'brandBlue' | 'brandPink' | 'brandAqua' | 'brandBrown' | 'gray' | DefaultMantineColor

declare module '@mantine/core' {
  // noinspection JSUnusedGlobalSymbols
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>
  }
}

export const MantineProvider: FC<MantineProviderProps> = ({ children }) => (
  <BaseMantineProvider
    theme={{
      fontFamily: avenirRoman.style.fontFamily,
      headings: {
        fontFamily: avenirBlack.style.fontFamily
      },
      colors: {
        brandBlue: [
          '#C6D1DF',
          '#8DA7C6',
          '#5983B9',
          '#3768A5',
          '#23538F',
          '#224065',
          '#1E3148',
          '#1A2634',
          '#161D26',
          '#11161C'
        ],
        brandPink: [
          '#FEFEFD',
          '#F9EFEB',
          '#F6DFD6',
          '#F6D0C1',
          '#fea390',
          '#FEAF90',
          '#F5A485',
          '#E19374',
          '#CB8469',
          '#C17E64'
        ],
        brandAqua: [
          '#d4e9f7',
          '#BBD2DF',
          '#b9cedb',
          '#72A9CA',
          '#368ABD',
          '#34759D',
          '#316482',
          '#2E556D',
          '#2A485B',
          '#263E4C'
        ],
        brandBrown: [
          '#DFD7CF',
          '#EDE9E5',
          '#D9CFC3',
          '#CAB7A3',
          '#BEA385',
          '#AB8E6E',
          '#967C5F',
          '#7F6B56',
          '#6C5D4D',
          '#5C5145'
        ],
        // slightly customized grays:
        gray: [
          '#F8F9FA',
          '#F1F3F5',
          '#E9ECEF',
          '#DEE2E6',
          '#CCCCCC', // customized
          '#ADB5BD',
          '#868E96',
          '#495057',
          '#343A40',
          '#212529'
        ]
      },
      globalStyles: () => ({
        'html, body': {
          overscrollBehavior: 'none'
        }
      }),
      primaryColor: 'brandAqua',
      primaryShade: {
        light: 6,
        dark: 8
      }
    }}
    withGlobalStyles
    withNormalizeCSS
  >
    <Notifications />
    <ModalsProvider>{children}</ModalsProvider>
  </BaseMantineProvider>
)
