import { type ClinicianLayout_query$key } from '@app/__generated__/ClinicianLayout_query.graphql'
import { AppHeader, AppLayout, type AppLayoutProps, Menu, NavbarLink, UserAvatar } from '@app/components'
import { Box, Group, Navbar, ScrollArea, Text, Title } from '@mantine/core'
import { useModals } from '@mantine/modals'
import { IconCalendarEvent, IconCalendarOff, IconChecklist, IconLogout, IconSettings } from '@tabler/icons-react'
import { signOut } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { type FC, type ReactNode, useCallback, useState } from 'react'
import { graphql, useFragment } from 'react-relay'

const queryFragment = graphql`
  fragment ClinicianLayout_query on Query {
    currentUser {
      firstName
      lastName
      ...UserAvatar_user
    }
  }
`

export interface ClinicianLayoutProps extends Omit<AppLayoutProps, 'query'> {
  links?: ReactNode
  query: ClinicianLayout_query$key
}

export const ClinicianLayout: FC<ClinicianLayoutProps> = ({
  children,
  query,
  links,
  navbar,
  title,
  ...appLayoutProps
}) => {
  const modals = useModals()
  const queryData = useFragment(queryFragment, query)
  const { t } = useTranslation('admin')

  const [isSigningOut, setIsSigningOut] = useState(false)

  const onSignOutClick = useCallback(() => {
    modals.openConfirmModal({
      centered: true,
      size: 'xs',
      title: t('Confirm Sign Out'),
      children: (
        <Text
          size='sm'
          pb='xs'
        >
          {t('Are you sure you want to sign out?')}
        </Text>
      ),
      labels: {
        confirm: t('Sign Out'),
        cancel: t('Cancel')
      },
      cancelProps: {
        variant: 'subtle'
      },
      confirmProps: {
        color: 'red',
        leftIcon: <IconLogout size={16} />,
        loading: isSigningOut
      },
      onConfirm: async () => {
        setIsSigningOut(true)
        await signOut({ callbackUrl: '/clinician' })
      }
    })
  }, [modals, isSigningOut, t])

  return (
    <AppLayout
      {...appLayoutProps}
      header={
        <AppHeader baseUrl='/clinician'>
          <Menu position='bottom-start'>
            <Menu.Target>
              <Box
                component='a'
                sx={{
                  textDecoration: 'none'
                }}
              >
                <UserAvatar
                  color='dark'
                  user={queryData.currentUser}
                />
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                color='red'
                icon={<IconLogout size={14} />}
                onClick={onSignOutClick}
              >
                {t('Sign Out')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </AppHeader>
      }
      navbar={
        <Navbar width={{ base: 200 }}>
          {navbar || (
            <Navbar.Section
              component={ScrollArea}
              grow
            >
              <NavbarLink
                color='red'
                href='/clinician/appointments'
                IconComponent={IconCalendarEvent}
                label={t('Appointments')}
              />
              <NavbarLink
                color='pink'
                href='/clinician/availability'
                IconComponent={IconCalendarOff}
                label={t('Availability')}
              />
              <NavbarLink
                color='grape'
                href='/clinician/policies'
                IconComponent={IconChecklist}
                label={t('Policies')}
              />
              <NavbarLink
                color='violet'
                href='/clinician/settings'
                IconComponent={IconSettings}
                label={t('Settings')}
              />
            </Navbar.Section>
          )}
        </Navbar>
      }
      title={`${t('Clinician')}${title ? ` / ${title}` : ''}`}
    >
      <Group
        mb='md'
        position='apart'
      >
        <Title order={5}>{title}</Title>
        {links}
      </Group>
      {children}
    </AppLayout>
  )
}
