import { loadStripe, type Stripe, type StripeConstructorOptions } from '@stripe/stripe-js'

let stripePromise: Promise<Stripe | null>

export const getStripe = (options?: StripeConstructorOptions) => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY, options)
  }

  return stripePromise
}
