import { type LeadsTable_query$data, type LeadsTable_query$key } from '@app/__generated__/LeadsTable_query.graphql'
import { type LeadsTablePaginationQuery } from '@app/__generated__/LeadsTablePaginationQuery.graphql'
import { CreateLeadModal, PaginationTable } from '@app/components'
import { humanize, type LeadFilter, LeadsOrderBy } from '@app/lib'
import { ActionIcon } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type LeadsTableUserEdge = ElementOf<LeadsTable_query$data['leads']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment LeadsTable_query on Query
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "LeadFilter" }
    first: { type: "Int" }
    orderBy: { type: "[LeadsOrderBy!]", defaultValue: [CREATED_AT_DESC] }
  )
  @refetchable(queryName: "LeadsTablePaginationQuery") {
    leads(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "LeadsTable_query_leads", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          createdAt
          referralPartner {
            name
          }
          slug
          source
          status
          user {
            firstName
            lastName
            slug
          }
        }
      }
      totalCount
    }
    ...CreateLeadModal_query
  }
`

export interface LeadsTableProps {
  onCreateModalClose: () => void
  query: LeadsTable_query$key
  showCreateModal: boolean
}

export const LeadsTable: FC<LeadsTableProps> = ({ onCreateModalClose, query, showCreateModal }) => {
  const { t } = useTranslation('admin')
  const {
    data: queryData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<LeadsTablePaginationQuery, LeadsTable_query$key>(paginationFragment, query)

  return (
    <>
      <PaginationTable<LeadsTableUserEdge, LeadsOrderBy, LeadsTablePaginationQuery, LeadFilter>
        columns={useMemo<ColumnDef<LeadsTableUserEdge>[]>(
          () => [
            {
              accessorKey: 'node.createdAt',
              id: 'createdAt',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Created'),
              cell: (props) => dayjs(props.row.original.node.createdAt).format('LLL')
            },
            {
              accessorKey: 'node.user.firstName',
              id: 'firstName',
              enableSorting: true,
              enableMultiSort: false,
              header: t('First Name')
            },
            {
              accessorKey: 'node.user.lastName',
              id: 'lastName',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Last Name')
            },
            {
              accessorKey: 'node.source',
              id: 'source',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Source'),
              cell: (props) => humanize(props.row.original.node.source)
            },
            {
              accessorKey: 'node.referralPartner',
              id: 'referralPartner',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Referral Partner'),
              cell: (props) => props.row.original.node.referralPartner?.name
            },
            {
              accessorKey: 'node.status',
              id: 'status',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Status'),
              cell: (props) => humanize(props.row.original.node.status)
            },
            {
              id: 'action',
              size: 30,
              cell: (props) => (
                <ActionIcon
                  color='blue'
                  component={Link}
                  href={`/admin/users/${props.row.original.node.user.slug}/lead`}
                  title={t('View Lead')}
                  variant='transparent'
                >
                  <IconChevronRight size={14} />
                </ActionIcon>
              )
            }
          ],
          [t]
        )}
        data={queryData?.leads?.edges}
        getFilterFromSearch={useCallback(
          (search) =>
            !isEmpty(search)
              ? {
                  or: [
                    {
                      referralPartner: {
                        name: {
                          includesInsensitive: search
                        }
                      }
                    },
                    {
                      user: {
                        email: {
                          includesInsensitive: search
                        }
                      }
                    },
                    {
                      user: {
                        firstName: {
                          includesInsensitive: search
                        }
                      }
                    },
                    {
                      user: {
                        lastName: {
                          includesInsensitive: search
                        }
                      }
                    }
                  ]
                }
              : null,
          []
        )}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'createdAt',
              desc: true
            }
          ],
          []
        )}
        isFilterable
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            createdAt: {
              asc: LeadsOrderBy.CreatedAtAsc,
              desc: LeadsOrderBy.CreatedAtDesc
            },
            firstName: {
              asc: LeadsOrderBy.UserByUserIdFirstNameAsc,
              desc: LeadsOrderBy.UserByUserIdFirstNameDesc
            },
            lastName: {
              asc: LeadsOrderBy.UserByUserIdLastNameAsc,
              desc: LeadsOrderBy.UserByUserIdLastNameDesc
            },
            referralPartner: {
              asc: LeadsOrderBy.ReferralPartnerByReferralPartnerIdNameAsc,
              desc: LeadsOrderBy.ReferralPartnerByReferralPartnerIdNameDesc
            },
            source: {
              asc: LeadsOrderBy.SourceAsc,
              desc: LeadsOrderBy.SourceDesc
            },
            status: {
              asc: LeadsOrderBy.StatusAsc,
              desc: LeadsOrderBy.StatusDesc
            }
          }),
          []
        )}
        totalCount={queryData?.leads?.totalCount}
      />
      <CreateLeadModal
        connectionId={queryData?.leads?.__id}
        query={queryData}
        opened={showCreateModal}
        onClose={onCreateModalClose}
      />
    </>
  )
}
