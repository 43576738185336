
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@app/lib/setupDayJs';
import { RouterTransition } from '@app/components';
import { MantineProvider, RelayProvider, StripeProvider } from '@app/providers';
import { type StripeElementLocale } from '@stripe/stripe-js';
import { type AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { type Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { type FC } from 'react';
import { type RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
interface AppProps extends NextAppProps {
    pageProps: {
        locale: StripeElementLocale | null;
        relayData: RecordMap | undefined;
        session: Session | null;
        token: string | null | undefined;
    };
}
const App: FC<AppProps> = ({ Component, pageProps: { locale, relayData, session, token, ...pageProps } }) => (<>
    <Head>
      <title>Nest Collaborative</title>
      <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width'/>
      <link rel='apple-touch-icon' sizes='114x114' href='/apple-touch-icon.png'/>
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png'/>
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png'/>
      <link rel='manifest' href='/site.webmanifest'/>
      <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5'/>
    </Head>
    <SessionProvider session={session}>
      <StripeProvider locale={locale}>
        <RelayProvider relayData={relayData} token={token}>
          <MantineProvider>
            <RouterTransition />
            <Component {...pageProps}/>
          </MantineProvider>
        </RelayProvider>
      </StripeProvider>
    </SessionProvider>
  </>);
const __Next_Translate__Page__18a3e4c7bd5__ = App;

    export default __appWithI18n(__Next_Translate__Page__18a3e4c7bd5__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  