import { type CreatePatientAppointmentModal_patient$key } from '@app/__generated__/CreatePatientAppointmentModal_patient.graphql'
import { type CreatePatientAppointmentModal_query$key } from '@app/__generated__/CreatePatientAppointmentModal_query.graphql'
import { type CreatePatientAppointmentModalCreateAppointmentMutation } from '@app/__generated__/CreatePatientAppointmentModalCreateAppointmentMutation.graphql'
import { PatientAppointmentForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type AppointmentInput } from '@app/lib'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const createAppointmentMutation = graphql`
  mutation CreatePatientAppointmentModalCreateAppointmentMutation(
    $connections: [ID!]!
    $input: CreateAppointmentInput!
  ) {
    createAppointment(input: $input) {
      appointmentEdge @appendEdge(connections: $connections) {
        cursor
        node {
          clinician {
            user {
              firstName
              lastName
            }
          }
          id
          service {
            duration {
              days
              hours
              minutes
              months
              seconds
              years
            }
            name
          }
          startsAt
          status
          userChild {
            birthdateAt
            dueAt
            name
          }
          ...PatientAppointmentForm_appointment
        }
      }
      clinician {
        ...ClinicianAppointmentsTable_clinician
      }
    }
  }
`

const patientFragment = graphql`
  fragment CreatePatientAppointmentModal_patient on Patient {
    rowId
    ...PatientAppointmentForm_patient
  }
`
const queryFragment = graphql`
  fragment CreatePatientAppointmentModal_query on Query {
    ...PatientAppointmentForm_query
  }
`

export interface CreatePatientAppointmentModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  connectionId: string
  patient: CreatePatientAppointmentModal_patient$key
  query: CreatePatientAppointmentModal_query$key
}

//FIXME: this is not used anywhere, actually on hold
export const CreatePatientAppointmentModal: FC<CreatePatientAppointmentModalProps> = ({
  connectionId,
  onClose,
  patient,
  query,
  ...modalProps
}) => {
  const patientData = useFragment(patientFragment, patient)
  const queryData = useFragment(queryFragment, query)
  const [createAppointment, isCreatingAppointment] =
    useMutation<CreatePatientAppointmentModalCreateAppointmentMutation>(createAppointmentMutation)
  const { t } = useTranslation('admin')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      title={t('New Appointment')}
      {...modalProps}
    >
      <PatientAppointmentForm
        appointment={null}
        isSaving={isCreatingAppointment}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (appointment) =>
            createAppointment({
              variables: {
                connections: [connectionId],
                input: {
                  appointment: {
                    ...appointment,
                    patientId: patientData?.rowId
                  } as AppointmentInput
                }
              },
              onCompleted: onClose
            }),
          [connectionId, createAppointment, onClose, patientData?.rowId]
        )}
        patient={patientData}
        query={queryData}
      />
    </UnsavedChangesModal>
  )
}
