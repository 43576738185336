import { type BookConfirmAppointment_appointmentData$key } from '@app/__generated__/BookConfirmAppointment_appointmentData.graphql'
import { Center, Button, Card, createStyles, Title, Flex, Badge, Stack, Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import Head from 'next/head'
import dayjs from 'dayjs'
import { AppointmentsSelectionMethod } from '@app/lib'

export interface BookConfirmAppointmentProps {
  appointment: BookConfirmAppointment_appointmentData$key
  onSubmit: () => void
  isSubmiting?: boolean
}

const appointmentFragment = graphql`
  fragment BookConfirmAppointment_appointmentData on Appointment {
    clinician {
      user {
        firstName
        lastName
      }
    }
    selectionMethod
    startsAt
  }
`

const useStyles = createStyles({
  root: {
    position: 'absolute',
    top: 100,
    left: 0,
    right: 0,
    bottom: 0
  }
})

export const BookConfirmAppointment: FC<BookConfirmAppointmentProps> = ({ appointment, onSubmit, isSubmiting }) => {
  const { t } = useTranslation('booking')
  const { classes } = useStyles()
  const appointmentData = useFragment<BookConfirmAppointment_appointmentData$key>(appointmentFragment, appointment)

  return (
    <Center className={classes.root}>
      <Head>{t('Confirm Appointment')}</Head>
      <Card
        px='xl'
        radius='md'
        shadow='md'
      >
        <Stack spacing='sm'>
          <Title order={1}>{t('Almost Done!')}</Title>
          <Text size='md'>{t('Please confirm your request and submit to finalize.')}</Text>
          <Title order={3}>{t('Your appointment request is for:')}</Title>
          <Flex
            direction='column'
            align='stretch'
          >
            <Flex
              direction='row'
              justify='space-around'
              align='center'
              mx='md'
              my='sm'
            >
              <Badge
                variant='outline'
                size='xl'
              >
                {dayjs(appointmentData.startsAt).format('h:mm A')}
              </Badge>
              <Title order={4}>{t('on')}</Title>
              <Badge
                variant='outline'
                size='xl'
              >
                {dayjs(appointmentData.startsAt).format('DD - MMM - YYYY')}
              </Badge>
            </Flex>
            {appointmentData.selectionMethod === AppointmentsSelectionMethod.Clinician && (
              <Flex
                direction='row'
                justify='space-around'
                align='center'
              >
                <Badge
                  variant='outline'
                  size='xl'
                  p='md'
                >
                  {t('with_clinician', {
                    clinician: `${appointmentData.clinician.user.firstName} ${appointmentData.clinician.user.lastName}`
                  })}
                </Badge>
              </Flex>
            )}
            <Button
              disabled={isSubmiting}
              onClick={onSubmit}
              mt='md'
            >
              {t('Submit Request')}
            </Button>
            <Text
              align='center'
              size='sm'
            >
              {t("We'll send you a confirmation by email")}
            </Text>
          </Flex>
        </Stack>
      </Card>
    </Center>
  )
}
