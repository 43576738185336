import { DatePickerInput, type DatePickerInputProps } from '@mantine/dates'
import { IconCalendar } from '@tabler/icons-react'
import { type FC } from 'react'

export const DateInput: FC<DatePickerInputProps> = ({ popoverProps, ...props }) => (
  <DatePickerInput
    firstDayOfWeek={0}
    icon={<IconCalendar size={18} />}
    mb='xs'
    popoverProps={{
      position: 'bottom-start',
      withinPortal: true,
      ...popoverProps
    }}
    {...props}
  />
)
