import { Menu as MantineMenu, type MenuProps as MantineMenuProps } from '@mantine/core'

export interface MenuProps extends MantineMenuProps {}

export const Menu = ({ children, ...mantineMenuProps }: MenuProps) => (
  <MantineMenu
    shadow='xl'
    withArrow
    withinPortal
    {...mantineMenuProps}
  >
    {children}
  </MantineMenu>
)

Menu.Divider = MantineMenu.Divider
Menu.Dropdown = MantineMenu.Dropdown
Menu.Item = MantineMenu.Item
Menu.Label = MantineMenu.Label
Menu.Target = MantineMenu.Target
