import { createStyles, Table, type TableProps } from '@mantine/core'
import { compact, map } from 'lodash'
import { type FC, type ReactNode } from 'react'

export interface DefinitionListTerm {
  label: string
  value: ReactNode
}

export interface DefinitionListProps extends TableProps {
  terms: DefinitionListTerm[]
}

const useStyles = createStyles({
  cell: {
    borderTop: '0 !important'
  },
  value: {
    width: '100%'
  },
  label: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  }
})

export const DefinitionList: FC<DefinitionListProps> = ({ terms, ...tableProps }) => {
  const { classes, cx } = useStyles()

  return (
    <Table {...tableProps}>
      <tbody>
        {map(compact(terms), ({ label, value }, idx) => (
          <tr key={idx}>
            <td className={cx(classes.cell, classes.label)}>{label}</td>
            <td className={cx(classes.cell, classes.value)}>{value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
