/**
 * @generated SignedSource<<c0220d823ef2122cf532579145d37b83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppointmentsSelectionMethod = "CLINICIAN" | "TIME" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BookConfirmAppointment_appointmentData$data = {
  readonly clinician: {
    readonly user: {
      readonly firstName: string;
      readonly lastName: string;
    } | null;
  } | null;
  readonly selectionMethod: AppointmentsSelectionMethod | null;
  readonly startsAt: any;
  readonly " $fragmentType": "BookConfirmAppointment_appointmentData";
};
export type BookConfirmAppointment_appointmentData$key = {
  readonly " $data"?: BookConfirmAppointment_appointmentData$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookConfirmAppointment_appointmentData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookConfirmAppointment_appointmentData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Clinician",
      "kind": "LinkedField",
      "name": "clinician",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectionMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    }
  ],
  "type": "Appointment",
  "abstractKey": null
};

(node as any).hash = "25a77233c20d65b551528e9c8bd20ca5";

export default node;
