import { type AdminLayout_query$key } from '@app/__generated__/AdminLayout_query.graphql'
import { AppHeader, AppLayout, type AppLayoutProps, Menu, NavbarLink, UserAvatar } from '@app/components'
import { Box, Button, Group, Navbar, ScrollArea, Text, Title } from '@mantine/core'
import { useModals } from '@mantine/modals'
import {
  IconAffiliate,
  IconCalendarEvent,
  IconChecklist,
  IconClipboardList,
  IconClock2,
  IconLogin,
  IconLogout,
  IconReportMedical,
  IconUsers
} from '@tabler/icons-react'
import Link from 'next/link'
import { signOut } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { type FC, type ReactNode, useCallback, useState } from 'react'
import { graphql, useFragment } from 'react-relay'

const queryFragment = graphql`
  fragment AdminLayout_query on Query {
    currentUser {
      firstName
      lastName
      ...UserAvatar_user
    }
  }
`

export interface AdminLayoutProps extends Omit<AppLayoutProps, 'query'> {
  links?: ReactNode
  query: AdminLayout_query$key
}

export const AdminLayout: FC<AdminLayoutProps> = ({ children, query, links, navbar, title, ...appLayoutProps }) => {
  const modals = useModals()
  const queryData = useFragment(queryFragment, query)
  const { t } = useTranslation('admin')

  const [isSigningOut, setIsSigningOut] = useState(false)

  const onSignOutClick = useCallback(() => {
    modals.openConfirmModal({
      centered: true,
      size: 'xs',
      title: t('Confirm Sign Out'),
      children: (
        <Text
          size='sm'
          pb='xs'
        >
          {t('Are you sure you want to sign out?')}
        </Text>
      ),
      labels: {
        confirm: t('Sign Out'),
        cancel: t('Cancel')
      },
      cancelProps: {
        variant: 'subtle'
      },
      confirmProps: {
        color: 'red',
        leftIcon: <IconLogout size={16} />,
        loading: isSigningOut
      },
      onConfirm: async () => {
        setIsSigningOut(true)
        await signOut({ callbackUrl: '/admin' })
      }
    })
  }, [modals, isSigningOut, t])

  return (
    <AppLayout
      {...appLayoutProps}
      header={
        <AppHeader baseUrl='/admin'>
          {queryData.currentUser ? (
            <Menu>
              <Menu.Target>
                <Box
                  component='a'
                  sx={{
                    textDecoration: 'none'
                  }}
                >
                  <UserAvatar
                    color='dark'
                    size='md'
                    user={queryData.currentUser}
                  />
                </Box>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  color='red'
                  icon={<IconLogout size={14} />}
                  onClick={onSignOutClick}
                >
                  {t('Sign Out')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ) : (
            <Button
              component={Link}
              color='green'
              href='/admin/login'
              leftIcon={<IconLogin size={18} />}
              variant='filled'
            >
              {t('Login')}
            </Button>
          )}
        </AppHeader>
      }
      navbar={
        <Navbar width={{ base: 200 }}>
          {navbar || (
            <Navbar.Section
              component={ScrollArea}
              grow
            >
              <NavbarLink
                color='red'
                href='/admin/appointments'
                IconComponent={IconCalendarEvent}
                label={t('Appointments')}
              />
              <NavbarLink
                color='pink'
                href='/admin/clinician_availabilities'
                IconComponent={IconClock2}
                label={t('Availability')}
              />
              <NavbarLink
                color='grape'
                href='/admin/policies'
                IconComponent={IconChecklist}
                label={t('Policies')}
              />
              <NavbarLink
                color='violet'
                href='/admin/referralPartners'
                IconComponent={IconAffiliate}
                label={t('Referral Partners')}
              />
              <NavbarLink
                color='indigo'
                href='/admin/insuranceProviders'
                IconComponent={IconReportMedical}
                label={t('Insurance Providers')}
              />
              <NavbarLink
                color='blue'
                href='/admin/services'
                IconComponent={IconClipboardList}
                label={t('Services')}
              />
              <NavbarLink
                color='teal'
                IconComponent={IconUsers}
                label={t('Users')}
              >
                <NavbarLink
                  label={t('Everyone')}
                  href='/admin/users'
                />
                <NavbarLink
                  label={t('Clinicians')}
                  href='/admin/users/clinicians'
                />
                <NavbarLink
                  label={t('Leads')}
                  href='/admin/users/leads'
                />
                <NavbarLink
                  label={t('Patients')}
                  href='/admin/users/patients'
                />
              </NavbarLink>
            </Navbar.Section>
          )}
        </Navbar>
      }
      title={`${t('Admin')}${title ? ` / ${title}` : ''}`}
    >
      <Group
        mb='md'
        position='apart'
      >
        <Title order={5}>{title}</Title>
        {links}
      </Group>
      {children}
    </AppLayout>
  )
}
