/**
 * @generated SignedSource<<aca798864d12775a12117e404ca33bb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferralPartnerLayout_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminLayout_query">;
  readonly " $fragmentType": "ReferralPartnerLayout_query";
};
export type ReferralPartnerLayout_query$key = {
  readonly " $data"?: ReferralPartnerLayout_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferralPartnerLayout_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferralPartnerLayout_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminLayout_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f801b9baadd9c2ed410f45b6859b26af";

export default node;
