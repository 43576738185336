import { z } from 'zod'

/**
 * handle upload fields, which can be either:
 *   - a browser File object
 *   - a graphql Upload object
 *   - null
 */
export const zodFileOrUploadOrNull = () =>
  /**
   * we have to check whether window is defined to determine if we are running
   * on the server, which doesn't have browser APIs like File/FormData/etc. if
   * we are running on the server, we just accept whatever.
   */
  typeof window === 'undefined'
    ? z.any()
    : // otherwise we are a browser File object
      z
        .instanceof(File)
        // we are a graphql Upload object
        .or(
          z.object({
            url: z.string().url()
          })
        )
        // we havent' been set, thus null
        .or(z.null())

export const zodNotOnlySpaces = (notSpacesMessage?: string) =>
  z
    .string()
    .trim()
    .min(1, notSpacesMessage || 'Cannot be only spaces')

export const zodStringEmptyOrNotOnlySpaces = (notSpacesMessage?: string) =>
  z.literal('').or(zodNotOnlySpaces(notSpacesMessage))

export const zodStringRequiredAndNotOnlySpaces = (requiredMessage?: string) =>
  z
    .string({
      required_error: requiredMessage || 'Required',
      invalid_type_error: requiredMessage || 'Invalid type'
    })
    .trim()
    .min(1, requiredMessage || 'Enter a value')

export const zodStatesUS = z.enum([
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
])
