/**
 * @generated SignedSource<<320fc0cf4c930b71300308d99ed1789b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReferralPartnerType = "CLINICAL" | "DME" | "EMPLOYER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReferralPartnerForm_referralPartner$data = {
  readonly config: any | null;
  readonly isActive: boolean | null;
  readonly name: string;
  readonly referralPartnerUploads: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly rowId: any;
      readonly upload: {
        readonly id: string;
        readonly name: string;
        readonly rowId: any;
        readonly url: string;
      } | null;
    }>;
  };
  readonly rowId: any;
  readonly slugPublic: string | null;
  readonly type: ReferralPartnerType;
  readonly " $fragmentType": "ReferralPartnerForm_referralPartner";
};
export type ReferralPartnerForm_referralPartner$key = {
  readonly " $data"?: ReferralPartnerForm_referralPartner$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferralPartnerForm_referralPartner">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferralPartnerForm_referralPartner",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "config",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": {
              "equalTo": "logo"
            }
          }
        }
      ],
      "concreteType": "ReferralPartnerUploadsConnection",
      "kind": "LinkedField",
      "name": "referralPartnerUploads",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReferralPartnerUpload",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Upload",
              "kind": "LinkedField",
              "name": "upload",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": "name",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "filename",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "referralPartnerUploads(filter:{\"name\":{\"equalTo\":\"logo\"}})"
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slugPublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "ReferralPartner",
  "abstractKey": null
};
})();

(node as any).hash = "d210483849ff677ffa8ef4c6b44d3a1d";

export default node;
