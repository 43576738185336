import { LoadingOverlay, Modal, type ModalProps, Stepper, type StepperProps } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import { type FC, type ReactNode, useCallback, useState } from 'react'

export interface WizardModalChildrenProps {
  onNext: () => void
  onPrev: () => void
  onReset: () => void
}

export interface WizardModal extends Omit<ModalProps, 'children'> {
  children: (props: WizardModalChildrenProps) => ReactNode
  isLoading?: boolean
  onStepChange?: (step: number) => void
  stepCount: number
  stepperProps?: Omit<StepperProps, 'active' | 'onStepClick'>
}

export const WizardModal: FC<WizardModal> = ({
  children,
  isLoading,
  onClose,
  onStepChange,
  stepCount,
  stepperProps,
  ...modalProps
}) => {
  const [active, setActive] = useState(0)

  const onNext = useCallback(() => setActive((value) => Math.min(stepCount, value + 1)), [stepCount])
  const onPrev = useCallback(() => setActive((value) => Math.max(0, value - 1)), [])
  const onReset = useCallback(() => setActive(0), [])

  useDidUpdate(() => {
    if (onStepChange) {
      onStepChange(active)
    }
  }, [active, onStepChange])

  return (
    <Modal
      centered
      closeOnEscape={false}
      closeOnClickOutside={false}
      onClose={() => {
        if (onClose) {
          onClose()
        }
        onReset()
      }}
      styles={{
        body: {
          position: 'relative'
        }
      }}
      withinPortal
      {...modalProps}
    >
      <LoadingOverlay
        loaderProps={{ size: 'xl' }}
        visible={isLoading}
      />
      <Stepper
        {...stepperProps}
        active={active}
        onStepClick={setActive}
      >
        {/* this is a render method called "render props", see for more information:
            https://reactjs.org/docs/render-props.html#using-props-other-than-render */}
        {children({ onNext, onPrev, onReset })}
      </Stepper>
    </Modal>
  )
}
