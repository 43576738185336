import {
  type UserInsurancesTable_user$data,
  type UserInsurancesTable_user$key
} from '@app/__generated__/UserInsurancesTable_user.graphql'
import { type UserInsurancesTablePaginationQuery } from '@app/__generated__/UserInsurancesTablePaginationQuery.graphql'
import { PaginationTable } from '@app/components'
import { humanize, type UserInsuranceFilter, UserInsurancesOrderBy } from '@app/lib'
import { Anchor } from '@mantine/core'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type UserInsurancesTableUserInsuranceEdge = ElementOf<UserInsurancesTable_user$data['userInsurances']['edges']>
// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment UserInsurancesTable_user on User
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "UserInsuranceFilter" }
    first: { type: "Int" }
    orderBy: { type: "[UserInsurancesOrderBy!]", defaultValue: [TYPE_ASC] }
  )
  @refetchable(queryName: "UserInsurancesTablePaginationQuery") {
    userInsurances(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "UserInsurancesTable_user_userInsurances", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          id
          type
          insuranceProvider {
            name
            slug
          }
          groupId
          memberId
          policyHolderRelationship
        }
      }
      totalCount
    }
  }
`

export interface UserInsurancesTableProps {
  user: UserInsurancesTable_user$key
}

export const UserInsurancesTable: FC<UserInsurancesTableProps> = ({ user }) => {
  const {
    data: userData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<UserInsurancesTablePaginationQuery, UserInsurancesTable_user$key>(paginationFragment, user)
  const { t } = useTranslation('admin')

  return (
    <PaginationTable<
      UserInsurancesTableUserInsuranceEdge,
      UserInsurancesOrderBy,
      UserInsurancesTablePaginationQuery,
      UserInsuranceFilter
    >
      columns={useMemo<ColumnDef<UserInsurancesTableUserInsuranceEdge>[]>(
        () => [
          {
            accessorKey: 'node.type',
            enableSorting: true,
            header: t('Type'),
            id: 'type',
            cell: (props) => {
              const node = props.row.original.node

              return humanize(node.type)
            }
          },
          {
            accessorKey: 'node.insuranceProvider.name',
            cell: (props) => {
              const node = props.row.original.node

              return (
                <Anchor
                  component={Link}
                  href={`/admin/insuranceProviders/${node.insuranceProvider.slug}`}
                >
                  {node.insuranceProvider.name}
                </Anchor>
              )
            },
            enableSorting: false,
            header: t('Provider'),
            id: 'insuranceProviderName'
          },
          {
            accessorKey: 'node.groupId',
            enableSorting: false,
            header: t('Group ID'),
            id: 'groupId'
          },
          {
            accessorKey: 'node.memberId',
            enableSorting: false,
            header: t('Member ID'),
            id: 'memberId'
          },
          {
            accessorKey: 'node.policy_holder_relationship',
            enableSorting: true,
            header: t('Policy Holder Relationship'),
            id: 'policy_holder_relationship',
            cell: (props) => {
              const node = props.row.original.node

              return humanize(node.policyHolderRelationship)
            }
          }
        ],
        [t]
      )}
      data={userData?.userInsurances?.edges}
      isFilterable
      getFilterFromSearch={useCallback(
        (search) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    groupId: {
                      includesInsensitive: search
                    }
                  },
                  {
                    memberId: {
                      includesInsensitive: search
                    }
                  },
                  {
                    insuranceProvider: {
                      name: {
                        includesInsensitive: search
                      }
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={useMemo(
        () => [
          {
            id: 'category',
            desc: false
          }
        ],
        []
      )}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={useMemo(
        () => ({
          type: {
            asc: UserInsurancesOrderBy.TypeAsc,
            desc: UserInsurancesOrderBy.TypeDesc
          }
        }),
        []
      )}
      totalCount={userData?.userInsurances?.totalCount}
    />
  )
}
