/**
 * @generated SignedSource<<af5dab8ecf57f08174461c14dce57e88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateClinicianAvailabilityModal_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ClinicianAvailabilityForm_query">;
  readonly " $fragmentType": "CreateClinicianAvailabilityModal_query";
};
export type CreateClinicianAvailabilityModal_query$key = {
  readonly " $data"?: CreateClinicianAvailabilityModal_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateClinicianAvailabilityModal_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateClinicianAvailabilityModal_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClinicianAvailabilityForm_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "1e178e2adc4c62454e9b39386de113d8";

export default node;
