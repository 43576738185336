import { AppHeader } from '@app/components'
import { AppShell, type AppShellProps, Box, createStyles } from '@mantine/core'
import Head from 'next/head'
import { type FC } from 'react'

const useStyles = createStyles({
  root: {
    position: 'relative',
    minHeight: '50vh'
  }
})

export interface AppLayoutProps extends AppShellProps {
  baseUrl?: string
  title?: string
}

export const AppLayout: FC<AppLayoutProps> = ({ baseUrl = '/', children, title, ...appShellProps }) => {
  const { classes } = useStyles()

  return (
    <AppShell
      header={<AppHeader baseUrl={baseUrl} />}
      padding='xs'
      {...appShellProps}
    >
      <Head>
        <title>{`Nest Collaborative${title ? ` / ${title}` : ''}`}</title>
      </Head>
      <Box className={classes.root}>{children}</Box>
    </AppShell>
  )
}
