/**
 * @generated SignedSource<<4bd7753870ce0b183b61a7194f0dd0c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type UserInsurancePolicyHolderRelationship = "CHILD" | "OTHER" | "SELF" | "SPOUSE" | "UNKNOWN" | "%future added value";
export type UserInsuranceType = "CHAMPVA" | "FECA_BLK_LUNG" | "GROUP_HEALTH_PLAN" | "MEDICAID" | "MEDICARE" | "OTHER" | "TRICARE_CHAMPUS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserInsurancesTable_user$data = {
  readonly id: string;
  readonly userInsurances: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly groupId: string | null;
        readonly id: string;
        readonly insuranceProvider: {
          readonly name: string;
          readonly slug: string | null;
        } | null;
        readonly memberId: string;
        readonly policyHolderRelationship: UserInsurancePolicyHolderRelationship;
        readonly type: UserInsuranceType;
      };
    }>;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "UserInsurancesTable_user";
};
export type UserInsurancesTable_user$key = {
  readonly " $data"?: UserInsurancesTable_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserInsurancesTable_user">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "userInsurances"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": [
        "TYPE_ASC"
      ],
      "kind": "LocalArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./UserInsurancesTablePaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "UserInsurancesTable_user",
  "selections": [
    {
      "alias": "userInsurances",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "UserInsurancesConnection",
      "kind": "LinkedField",
      "name": "__UserInsurancesTable_user_userInsurances_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserInsurancesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserInsurance",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InsuranceProvider",
                  "kind": "LinkedField",
                  "name": "insuranceProvider",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "groupId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "memberId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderRelationship",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "b9e97c45b5870f467081fb592f767d5a";

export default node;
