import { type UpdateUserPaymentMethodModal_user$key } from '@app/__generated__/UpdateUserPaymentMethodModal_user.graphql'
import { type UpdateUserPaymentMethodModal_userPaymentMethod$key } from '@app/__generated__/UpdateUserPaymentMethodModal_userPaymentMethod.graphql'
import { type UpdateUserPaymentMethodModalUpdateUserPaymentMethodMutation } from '@app/__generated__/UpdateUserPaymentMethodModalUpdateUserPaymentMethodMutation.graphql'
import { UnsavedChangesModal, type UnsavedChangesModalProps, UserPaymentMethodForm } from '@app/components'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const updateUserPaymentMethodMutation = graphql`
  mutation UpdateUserPaymentMethodModalUpdateUserPaymentMethodMutation($input: UpdateUserPaymentMethodByIdInput!) {
    updateUserPaymentMethodById(input: $input) {
      userPaymentMethod {
        isActive
        isPrimary
        ...UserPaymentMethodForm_userPaymentMethod
      }
    }
  }
`

const userFragment = graphql`
  fragment UpdateUserPaymentMethodModal_user on User {
    ...UserPaymentMethodForm_user
  }
`
const userPaymentMethodFragment = graphql`
  fragment UpdateUserPaymentMethodModal_userPaymentMethod on UserPaymentMethod {
    id
    ...UserPaymentMethodForm_userPaymentMethod
  }
`

export interface UpdateUserPaymentMethodModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  user: UpdateUserPaymentMethodModal_user$key
  userPaymentMethod: UpdateUserPaymentMethodModal_userPaymentMethod$key
}

export const UpdateUserPaymentMethodModal: FC<UpdateUserPaymentMethodModalProps> = ({
  onClose,
  user,
  userPaymentMethod,
  ...modalProps
}) => {
  const userData = useFragment(userFragment, user)
  const userPaymentMethodData = useFragment(userPaymentMethodFragment, userPaymentMethod)
  const [updateUserPaymentMethod, isCreatingUserPaymentMethod] =
    useMutation<UpdateUserPaymentMethodModalUpdateUserPaymentMethodMutation>(updateUserPaymentMethodMutation)
  const { t } = useTranslation('admin')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      size='sm'
      title={t('Edit Payment Method')}
      {...modalProps}
    >
      <UserPaymentMethodForm
        isSaving={isCreatingUserPaymentMethod}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (patch) =>
            updateUserPaymentMethod({
              variables: {
                input: {
                  id: userPaymentMethodData?.id,
                  patch
                }
              },
              onCompleted: onClose
            }),
          [updateUserPaymentMethod, userPaymentMethodData?.id, onClose]
        )}
        user={userData}
        userPaymentMethod={userPaymentMethodData}
      />
    </UnsavedChangesModal>
  )
}
