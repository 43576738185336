import { Text, Title, Center, Flex, Button, Space, Stack, Group, Paper } from '@mantine/core'
import { useState, type FC, useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import { graphql, useFragment, useMutation } from 'react-relay'
import { type BookCreditCardPayment_query$key } from '@app/__generated__/BookCreditCardPayment_query.graphql'
import { BigButton, UserPaymentMethodDisplay } from '@app/components'
import { type BookCreditCardPaymentCreateUserPaymentMethodMutation } from '@app/__generated__/BookCreditCardPaymentCreateUserPaymentMethodMutation.graphql'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { split } from 'lodash'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { PaymentProviders } from '@app/lib'

const queryFragment = graphql`
  fragment BookCreditCardPayment_query on Query {
    currentUser {
      rowId
      firstName
      lastName
      stripeCustomerId
      ...UserPaymentMethodForm_user
      userPaymentMethods(condition: { provider: STRIPE, isActive: true, isPrimary: true }, first: 1) {
        nodes {
          ...UserPaymentMethodDisplay_userPaymentMethod
        }
      }
      userAddresses(filter: { isPrimary: { equalTo: true } }) {
        nodes {
          address {
            city
            country
            postalCode
            stateOrProvince
            street1
            street2
          }
        }
      }
    }
  }
`

const createUserPaymentMethodMutation = graphql`
  mutation BookCreditCardPaymentCreateUserPaymentMethodMutation($input: CreateUserPaymentMethodInput!) {
    createUserPaymentMethod(input: $input) {
      userPaymentMethod {
        ...UserPaymentMethodForm_userPaymentMethod
      }
    }
  }
`

interface BookCreditCardPaymentPaymentMethodData {
  paymentProvider: PaymentProviders
  paymentMethodId: string
}

interface BookCreditCardPaymentProps {
  onNext?(data: { userPaymendMethodData?: BookCreditCardPaymentPaymentMethodData }): Promise<void> | void
  onPrevious?(): Promise<void> | void
  query: BookCreditCardPayment_query$key
}

export const BookCreditCardPayment: FC<BookCreditCardPaymentProps> = ({ onNext, onPrevious, query }) => {
  const queryData = useFragment(queryFragment, query)
  const { t } = useTranslation('booking')
  const [isCardElementComplete, setIsCardElementComplete] = useState(false)
  const [isCreatingToken, setIsCreatingToken] = useState(false)
  const [isUserPaymentMethodChanged, setIsUserPaymentMethodChanged] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [createUserPaymentMethod, isCreatingUserPaymentMethod] =
    useMutation<BookCreditCardPaymentCreateUserPaymentMethodMutation>(createUserPaymentMethodMutation)
  const userAddressData = queryData?.currentUser?.userAddresses?.nodes?.[0]
  const userPaymentMethodData = queryData?.currentUser?.userPaymentMethods?.nodes?.[0]
  const hasPaymentMethodData = !!userPaymentMethodData

  return (
    <Center m='sm'>
      <Flex
        justify='center'
        direction='column'
      >
        <Head>
          <title>{t('Payment')}</title>
        </Head>
        <Title>{t('Payment Details')}</Title>{' '}
        <Text
          m='sm'
          size='lg'
        >
          {t('Your card will be charged $139 ')}
          {<b>{t('after')}</b>}
          {t(' your appointment')}
        </Text>
        <Flex
          direction='column'
          p={25}
        >
          <Stack
            justify='space-between'
            px='xs'
            spacing='md'
          >
            {hasPaymentMethodData && (
              <>
                <Text weight='bold'>This is the backup payment method we have on file for you:</Text>
                <UserPaymentMethodDisplay
                  cardLogoWidth={60}
                  p={0}
                  px='xs'
                  userPaymentMethod={userPaymentMethodData}
                  withBorder={false}
                />
                <Group
                  grow
                  px='xs'
                  spacing='xs'
                >
                  <BigButton
                    active={!isUserPaymentMethodChanged}
                    onClick={() => setIsUserPaymentMethodChanged(false)}
                  >
                    {t`This is still current`}
                  </BigButton>
                  <BigButton
                    active={isUserPaymentMethodChanged}
                    onClick={() => setIsUserPaymentMethodChanged(true)}
                  >
                    {t`I need to update this`}
                  </BigButton>
                </Group>
              </>
            )}
            {(isUserPaymentMethodChanged || !hasPaymentMethodData) && (
              <Stack
                justify='space-between'
                px='xs'
                spacing='md'
              >
                <Text weight='bold'>Please provide your updated backup payment method</Text>
                <Paper
                  p='xs'
                  w={400}
                  withBorder
                >
                  <CardElement
                    onChange={(e) => setIsCardElementComplete(e.complete)}
                    options={{
                      disableLink: true,
                      value: {
                        postalCode: split(userAddressData?.address.postalCode, '-')[0]
                      }
                    }}
                  />
                </Paper>
              </Stack>
            )}
          </Stack>
        </Flex>
        <li>{t("Nest's discounted self-pay rate is $139/visit")}</li>
        <li>{t('You will be charged our discounted self-pay rate after your visit')}</li>
        <li>
          {t(
            'Changes and cancellations (including no-shows) require at least 24 hours notice ahead of your appointment, otherwise you may be charged a $25 fee.'
          )}
        </li>
        <Space h='sm' />
        <Flex justify='flex-end'>
          <Group
            align='center'
            mt='xl'
            position='apart'
            spacing='sm'
          >
            <Button
              leftIcon={<IconChevronLeft size={16} />}
              onClick={onPrevious}
              radius='lg'
              size='sm'
              variant='subtle'
            >
              {t('Back')}
            </Button>
            <Button
              disabled={
                ((!hasPaymentMethodData || isUserPaymentMethodChanged) &&
                  (isCreatingToken || !isCardElementComplete)) ||
                isCreatingUserPaymentMethod
              }
              radius='lg'
              rightIcon={<IconChevronRight size={16} />}
              size='sm'
              onClick={useCallback(async () => {
                let userPaymendMethodData: BookCreditCardPaymentPaymentMethodData | undefined

                if (isUserPaymentMethodChanged || !hasPaymentMethodData) {
                  setIsCreatingToken(true)

                  const cardElement = elements.getElement(CardElement)
                  const response = await stripe.createToken(cardElement, {
                    name: `${queryData?.currentUser?.firstName} ${queryData?.currentUser?.lastName}`,
                    address_line1: userAddressData?.address?.street1,
                    address_line2: userAddressData?.address?.street2,
                    address_city: userAddressData?.address?.city,
                    address_state: userAddressData?.address?.stateOrProvince,
                    address_zip: userAddressData?.address?.postalCode,
                    address_country: userAddressData?.address?.country
                  })

                  userPaymendMethodData = {
                    paymentProvider: PaymentProviders.Stripe,
                    paymentMethodId: response?.token?.id
                  }
                  setIsCreatingToken(false)

                  if (response?.token?.id) {
                    createUserPaymentMethod({
                      variables: {
                        input: {
                          userPaymentMethod: {
                            isActive: true,
                            isPrimary: true,
                            provider: PaymentProviders.Stripe,
                            providerId: queryData?.currentUser?.stripeCustomerId,
                            methodId: response?.token?.id
                          }
                        }
                      },
                      onCompleted: () => {
                        try {
                          onNext({ userPaymendMethodData })
                        } catch (error) {
                          console.error(error)
                        }
                      }
                    })
                  }
                }
              }, [
                createUserPaymentMethod,
                elements,
                hasPaymentMethodData,
                isUserPaymentMethodChanged,
                onNext,
                queryData?.currentUser,
                stripe,
                userAddressData
              ])}
            >
              {t('Next')}
            </Button>
          </Group>
        </Flex>
      </Flex>
    </Center>
  )
}
