import {
  type ReferralPartnerSourceForm_referralPartnerSource$data,
  type ReferralPartnerSourceForm_referralPartnerSource$key
} from '@app/__generated__/ReferralPartnerSourceForm_referralPartnerSource.graphql'
import { Form, TextInput } from '@app/components'
import { Checkbox } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const ReferralPartnerSourceFragment = graphql`
  fragment ReferralPartnerSourceForm_referralPartnerSource on ReferralPartnerSource {
    description
    isActive
    name
  }
`

export type ReferralPartnerSourceFormProps = {
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (data: Partial<ReferralPartnerSourceForm_referralPartnerSource$data>) => void
  referralPartnerSource?: ReferralPartnerSourceForm_referralPartnerSource$key
}

export const ReferralPartnerSourceForm: FC<ReferralPartnerSourceFormProps> = ({
  referralPartnerSource,
  isSaving,
  onChange,
  onSubmit
}) => {
  const ReferralPartnerSourceData = useFragment(ReferralPartnerSourceFragment, referralPartnerSource)
  const { t } = useTranslation('admin')
  const schema = z.object({
    description: z.string(),
    name: z
      .string({
        required_error: t('Choose a name')
      })
      .min(2, {
        message: t('Must be at least 2 letters long')
      }),
    isActive: z.boolean()
  })

  const form = useForm({
    initialValues: {
      description: ReferralPartnerSourceData?.description || '',
      isActive: ReferralPartnerSourceData?.isActive || false,
      name: ReferralPartnerSourceData?.name || ''
    },
    validate: zodResolver(schema),
    validateInputOnChange: true
  })

  useShallowEffect(() => {
    onChange?.(form.isDirty())
  }, [])

  return (
    <Form
      isSaving={isSaving}
      onSubmit={form.onSubmit((values) => onSubmit(values))}
    >
      <TextInput
        disabled={isSaving}
        label={t('Name')}
        required
        {...form.getInputProps('name')}
      />
      <TextInput
        disabled={isSaving}
        label={t('Description')}
        required
        {...form.getInputProps('description')}
      />
      <Checkbox
        my={9}
        disabled={isSaving}
        label={t('Active')}
        {...form.getInputProps('isActive', { type: 'checkbox' })}
      />
    </Form>
  )
}
