/**
 * @generated SignedSource<<2d8e1921d20ed2b977e1c99a894b944f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatePatientAppointmentModal_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PatientAppointmentForm_query">;
  readonly " $fragmentType": "CreatePatientAppointmentModal_query";
};
export type CreatePatientAppointmentModal_query$key = {
  readonly " $data"?: CreatePatientAppointmentModal_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatePatientAppointmentModal_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatePatientAppointmentModal_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PatientAppointmentForm_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9df477492aa29c418407e17d6490363b";

export default node;
