/**
 * @generated SignedSource<<38f2d76617b3baf7a9f2ccd054d1b516>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateUserPaymentMethodModal_userPaymentMethod$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserPaymentMethodForm_userPaymentMethod">;
  readonly " $fragmentType": "UpdateUserPaymentMethodModal_userPaymentMethod";
};
export type UpdateUserPaymentMethodModal_userPaymentMethod$key = {
  readonly " $data"?: UpdateUserPaymentMethodModal_userPaymentMethod$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateUserPaymentMethodModal_userPaymentMethod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateUserPaymentMethodModal_userPaymentMethod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserPaymentMethodForm_userPaymentMethod"
    }
  ],
  "type": "UserPaymentMethod",
  "abstractKey": null
};

(node as any).hash = "306dc0d066b696f9d72af67cb57de87f";

export default node;
