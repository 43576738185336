import {
  type ReferralPartnerSourcesTable_referralPartner$data,
  type ReferralPartnerSourcesTable_referralPartner$key
} from '@app/__generated__/ReferralPartnerSourcesTable_referralPartner.graphql'
import { type ReferralPartnerSourcesTablePaginationQuery } from '@app/__generated__/ReferralPartnerSourcesTablePaginationQuery.graphql'
import {
  CreateReferralPartnerSourceModal,
  IconBoolean,
  PaginationTable,
  UpdateReferralPartnerSourceModal
} from '@app/components'
import { type ReferralPartnerSourceFilter, ReferralPartnerSourcesOrderBy } from '@app/lib'
import { ActionIcon, Group } from '@mantine/core'
import { IconEdit } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo, useState } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type ReferralPartnerSourcesTableReferralPartnerSourceEdge = ElementOf<
  ReferralPartnerSourcesTable_referralPartner$data['referralPartnerSources']['edges']
>

// noinspection GraphQLUnresolvedReference
const referralPartnerPaginationFragment = graphql`
  fragment ReferralPartnerSourcesTable_referralPartner on ReferralPartner
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "ReferralPartnerSourceFilter" }
    first: { type: "Int" }
    orderBy: { type: "[ReferralPartnerSourcesOrderBy!]", defaultValue: [NAME_ASC] }
  )
  @refetchable(queryName: "ReferralPartnerSourcesTablePaginationQuery") {
    referralPartnerSources(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(
        key: "ReferralPartnerSourcesTable_referralPartner_referralPartnerSources"
        filters: ["filter", "orderBy"]
      ) {
      __id
      edges {
        node {
          description
          id
          name
          isActive
          ...UpdateReferralPartnerSourceModal_referralPartnerSource
        }
      }
      totalCount
    }
    ...CreateReferralPartnerSourceModal_referralPartner
  }
`

export interface ReferralPartnerSourcesTableProps {
  onCreateModalClose: () => void
  referralPartner: ReferralPartnerSourcesTable_referralPartner$key
  showCreateModal: boolean
}

export const ReferralPartnerSourcesTable: FC<ReferralPartnerSourcesTableProps> = ({
  onCreateModalClose,
  referralPartner,
  showCreateModal
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState<string | null>(null)
  const {
    data: referralPartnerData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<
    ReferralPartnerSourcesTablePaginationQuery,
    ReferralPartnerSourcesTable_referralPartner$key
  >(referralPartnerPaginationFragment, referralPartner)
  const { t } = useTranslation('admin')

  return (
    <>
      <PaginationTable<
        ReferralPartnerSourcesTableReferralPartnerSourceEdge,
        ReferralPartnerSourcesOrderBy,
        ReferralPartnerSourcesTablePaginationQuery,
        ReferralPartnerSourceFilter
      >
        columns={useMemo<ColumnDef<ReferralPartnerSourcesTableReferralPartnerSourceEdge>[]>(
          () => [
            {
              accessorKey: 'node.name',
              id: 'name',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Name')
            },
            {
              accessorKey: 'node.description',
              id: 'description',
              enableSorting: false,
              enableMultiSort: false,
              header: t('Description')
            },
            {
              id: 'isActive',
              size: 30,
              header: t('Active'),
              cell: (props) => {
                const node = props?.row?.original?.node
                return <IconBoolean value={node.isActive} />
              }
            },
            {
              id: 'actions',
              size: 30,
              cell: (props) => {
                const node = props.row.original.node
                return (
                  <Group spacing='xs'>
                    <ActionIcon
                      color='blue'
                      title={t('Edit Availability')}
                      onClick={() => setShowUpdateModal(node.id)}
                      variant='transparent'
                    >
                      <IconEdit size={14} />
                    </ActionIcon>
                    <UpdateReferralPartnerSourceModal
                      referralPartnerSource={node}
                      opened={node.id === showUpdateModal}
                      onClose={() => setShowUpdateModal(null)}
                      size='lg'
                    />
                  </Group>
                )
              }
            }
          ],
          [showUpdateModal, t]
        )}
        data={referralPartnerData?.referralPartnerSources?.edges}
        isFilterable
        getFilterFromSearch={useCallback(
          (search) =>
            !isEmpty(search)
              ? {
                  name: {
                    includesInsensitive: search
                  }
                }
              : null,
          []
        )}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'name',
              desc: false
            }
          ],
          []
        )}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            name: {
              asc: ReferralPartnerSourcesOrderBy.NameAsc,
              desc: ReferralPartnerSourcesOrderBy.NameDesc
            }
          }),
          []
        )}
        totalCount={referralPartnerData?.referralPartnerSources?.totalCount}
      />
      <CreateReferralPartnerSourceModal
        connectionId={referralPartnerData?.referralPartnerSources?.__id}
        opened={showCreateModal}
        onClose={onCreateModalClose}
        referralPartner={referralPartnerData}
      />
    </>
  )
}
