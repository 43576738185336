/**
 * @generated SignedSource<<b6881a493929910802b1162ba41da110>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserForm_user$data = {
  readonly birthdateAt: any | null;
  readonly email: string;
  readonly firstName: string;
  readonly hasPassword: boolean | null;
  readonly lastName: string;
  readonly middleName: string | null;
  readonly rowId: any;
  readonly userUploads: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly rowId: any;
      readonly upload: {
        readonly id: string;
        readonly name: string;
        readonly rowId: any;
        readonly url: string;
      } | null;
    }>;
  };
  readonly " $fragmentType": "UserForm_user";
};
export type UserForm_user$key = {
  readonly " $data"?: UserForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserForm_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserForm_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPassword",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "middleName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthdateAt",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": {
              "equalTo": "avatar"
            }
          }
        }
      ],
      "concreteType": "UserUploadsConnection",
      "kind": "LinkedField",
      "name": "userUploads",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserUpload",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Upload",
              "kind": "LinkedField",
              "name": "upload",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": "name",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "filename",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "userUploads(filter:{\"name\":{\"equalTo\":\"avatar\"}})"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "685e00e3219c948672297d8c337f1113";

export default node;
