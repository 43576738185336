import { type UserPaymentMethodForm_user$key } from '@app/__generated__/UserPaymentMethodForm_user.graphql'
import { type UserPaymentMethodForm_userPaymentMethod$key } from '@app/__generated__/UserPaymentMethodForm_userPaymentMethod.graphql'
import { CheckboxInput, Form, UserPaymentMethodDisplay } from '@app/components'
import { PaymentProviders, type UserPaymentMethodInput, type UserPaymentMethodPatch } from '@app/lib'
import { Button, Paper } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { IconCheck } from '@tabler/icons-react'
import { get } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useEffect, useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const userFragment = graphql`
  fragment UserPaymentMethodForm_user on User {
    firstName
    lastName
    middleName
    stripeCustomerId
    userAddresses(filter: { isPrimary: { equalTo: true } }) {
      nodes {
        address {
          street1
          street2
          city
          stateOrProvince
          postalCode
          country
        }
      }
    }
    activeUserPaymentMethods: userPaymentMethods(filter: { isActive: { equalTo: true } }) {
      totalCount
    }
  }
`
const userPaymentMethodFragment = graphql`
  fragment UserPaymentMethodForm_userPaymentMethod on UserPaymentMethod {
    id
    isActive
    isPrimary
    methodData
    ...UserPaymentMethodDisplay_userPaymentMethod
  }
`

export interface UserPaymentMethodFormData {
  isActive: boolean
  isPrimary: boolean
}

export interface UserPaymentMethodFormProps {
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (data: UserPaymentMethodInput | UserPaymentMethodPatch) => void
  user: UserPaymentMethodForm_user$key
  userPaymentMethod: UserPaymentMethodForm_userPaymentMethod$key
}

export const UserPaymentMethodForm: FC<UserPaymentMethodFormProps> = ({
  isSaving,
  onChange,
  onSubmit,
  user,
  userPaymentMethod
}) => {
  const [isCardElementComplete, setIsCardElementComplete] = useState(false)
  const [isCreatingToken, setIsCreatingToken] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const userData = useFragment<UserPaymentMethodForm_user$key>(userFragment, user)
  const userPaymentMethodData = useFragment<UserPaymentMethodForm_userPaymentMethod$key>(
    userPaymentMethodFragment,
    userPaymentMethod
  )
  const isEditing = Boolean(userPaymentMethodData?.id)
  const hasOtherPaymentMethods = Boolean(get(userData, 'activeUserPaymentMethods.totalCount'))
  const primaryAddress = get(userData, 'userAddresses.nodes[0].address')
  const { t } = useTranslation('admin')
  const form = useForm<UserPaymentMethodFormData>({
    initialValues: {
      isActive: userPaymentMethodData?.isActive ?? true,
      isPrimary: userPaymentMethodData?.isPrimary ?? !hasOtherPaymentMethods
    },
    validate: zodResolver(
      z.object({
        isActive: z.boolean(),
        isPrimary: z.boolean()
      })
    ),
    validateInputOnChange: true
  })

  useShallowEffect(() => {
    if (onChange) {
      onChange(form.isDirty())
    }
  }, [form.values])

  useEffect(() => {
    if (!isEditing) {
      const cardElement = elements.getElement(CardElement)
      const onChange = (e) => setIsCardElementComplete(e.complete)
      cardElement.on('change', onChange)

      return () => {
        cardElement.off('change', onChange)
      }
    }
  }, [elements, isEditing])

  return (
    <Form
      buttons={
        <Button
          color='green'
          disabled={(!isEditing && !isCardElementComplete) || isCreatingToken || isSaving || !form.isValid()}
          leftIcon={<IconCheck size={18} />}
          loading={isSaving}
          type='submit'
        >
          {t('Save')}
        </Button>
      }
      isSaving={isCreatingToken || isSaving}
      onSubmit={form.onSubmit(async (values) => {
        if (isEditing) {
          onSubmit(values)
        } else {
          setIsCreatingToken(true)

          const cardElement = elements.getElement(CardElement)
          const response = await stripe.createToken(cardElement, {
            name: `${userData?.firstName} ${userData?.lastName}`,
            address_line1: primaryAddress?.street1,
            address_line2: primaryAddress?.street2,
            address_city: primaryAddress?.city,
            address_state: primaryAddress?.stateOrProvince,
            address_zip: primaryAddress?.postalCode,
            address_country: primaryAddress?.country
          })

          await onSubmit({
            ...values,
            provider: PaymentProviders.Stripe,
            providerId: userData?.stripeCustomerId,
            methodId: response?.token?.id
          })
          // @todo - false? or just remove?
          setIsCreatingToken(true)
        }
      })}
    >
      {isEditing ? (
        <UserPaymentMethodDisplay userPaymentMethod={userPaymentMethodData} />
      ) : (
        <Paper
          p='xs'
          radius='sm'
          sx={{ height: 38 }}
          withBorder
        >
          <CardElement
            options={{
              disableLink: true,
              value: {
                postalCode: primaryAddress?.postalCode
              }
            }}
          />
        </Paper>
      )}
      <CheckboxInput
        disabled={isSaving}
        label={t('Active')}
        mt='xl'
        mb='xl'
        {...form.getInputProps('isActive', { type: 'checkbox' })}
      />
      <CheckboxInput
        disabled={isSaving}
        label={t('Primary')}
        mt='xl'
        mb='xl'
        {...form.getInputProps('isPrimary', { type: 'checkbox' })}
      />
    </Form>
  )
}
