/**
 * @generated SignedSource<<f6e599a98c0f68923d73b155a43c2bca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppointmentsSelectionMethod = "CLINICIAN" | "TIME" | "%future added value";
export type AppointmentsStatus = "CHECKED_IN" | "CONFIRMED" | "CONSULTED" | "LATE_CANCELLATION_EMERGENCY_MEDICAL_FIRE_ETC_" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_CIGNA_MOM" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_MEDICAID" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_RESCHEDULED" | "LATE_CANCELLATION_NO_SHOW_PLEASE_CHARGE" | "NO_SHOW_CHARGE" | "NO_SHOW_NO_CHARGE" | "UNCONFIRMED" | "_2_HR_LATE_CANCEL_CHARGE" | "_2_HR_LATE_CANCEL_NO_CHARGE" | "%future added value";
export type EmployeeType = "EMPLOYEE" | "GENERIC" | "PRN" | "%future added value";
export type LeadsSource = "BREAST_PUMP_ORDER" | "FRIEND" | "HEALTH" | "HEALTH_PLAN" | "OTHER" | "REFERRAL_PARTNER" | "SEARCH" | "SOCIAL" | "%future added value";
export type LeadsStatus = "ACTIVE" | "CONVERTED" | "INACTIVE" | "NEW" | "%future added value";
export type PaymentProviders = "STRIPE" | "%future added value";
export type PolicyType = "CLINICIAN" | "GENERAL" | "PATIENT" | "%future added value";
export type ReferralPartnerType = "CLINICAL" | "DME" | "EMPLOYER" | "%future added value";
export type UserGender = "FEMALE" | "MALE" | "OTHER" | "%future added value";
export type UserInsuranceCategory = "AUTO" | "OTHER" | "PRIMARY" | "SECONDARY" | "TERTIARY" | "WORKERCOMP" | "%future added value";
export type UserInsurancePolicyHolderRelationship = "CHILD" | "OTHER" | "SELF" | "SPOUSE" | "UNKNOWN" | "%future added value";
export type UserInsuranceType = "CHAMPVA" | "FECA_BLK_LUNG" | "GROUP_HEALTH_PLAN" | "MEDICAID" | "MEDICARE" | "OTHER" | "TRICARE_CHAMPUS" | "%future added value";
export type UserPhonesType = "FAX" | "HOME" | "MOBILE" | "WORK" | "%future added value";
export type CreateLeadInput = {
  clientMutationId?: string | null;
  lead: LeadInput;
};
export type LeadInput = {
  convertedAt?: any | null;
  createdAt?: any | null;
  optInAt?: any | null;
  optInMethod?: string | null;
  referralPartner?: LeadsReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  referralPartnerSource?: LeadsReferralPartnerSourceIdFkeyInput | null;
  referralPartnerSourceId?: any | null;
  rowId?: any | null;
  slug?: string | null;
  source: LeadsSource;
  status: LeadsStatus;
  updatedAt?: any | null;
  user?: LeadsUserIdFkeyInput | null;
  userId?: any | null;
  zohoId?: string | null;
};
export type LeadsReferralPartnerIdFkeyInput = {
  connectById?: ReferralPartnerNodeIdConnect | null;
  connectByRowId?: ReferralPartnerReferralPartnerPkeyConnect | null;
  connectBySlug?: ReferralPartnerReferralPartnerUniqueSlugConnect | null;
  connectBySlugPublic?: ReferralPartnerReferralPartnerSlugPublicConnect | null;
  create?: LeadsReferralPartnerIdFkeyReferralPartnersCreateInput | null;
  deleteById?: ReferralPartnerNodeIdDelete | null;
  deleteByRowId?: ReferralPartnerReferralPartnerPkeyDelete | null;
  deleteBySlug?: ReferralPartnerReferralPartnerUniqueSlugDelete | null;
  deleteBySlugPublic?: ReferralPartnerReferralPartnerSlugPublicDelete | null;
  updateById?: LeadOnLeadForLeadsReferralPartnerIdFkeyNodeIdUpdate | null;
  updateByRowId?: ReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyUsingReferralPartnerPkeyUpdate | null;
  updateBySlug?: ReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyUsingReferralPartnerUniqueSlugUpdate | null;
  updateBySlugPublic?: ReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyUsingReferralPartnerSlugPublicUpdate | null;
};
export type ReferralPartnerNodeIdConnect = {
  id: string;
};
export type ReferralPartnerReferralPartnerPkeyConnect = {
  rowId: any;
};
export type ReferralPartnerReferralPartnerUniqueSlugConnect = {
  slug: string;
};
export type ReferralPartnerReferralPartnerSlugPublicConnect = {
  slugPublic: string;
};
export type LeadsReferralPartnerIdFkeyReferralPartnersCreateInput = {
  config?: any | null;
  createdAt?: any | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerIdFkeyInverseInput | null;
  name: string;
  referralPartnerSources?: ReferralPartnerSourcesReferralPartnerIdFkeyInverseInput | null;
  referralPartnerUploads?: ReferralPartnerUploadsReferralPartnerIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  type: ReferralPartnerType;
  updatedAt?: any | null;
};
export type LeadsReferralPartnerIdFkeyInverseInput = {
  connectById?: ReadonlyArray<LeadNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<LeadLeadsPkeyConnect> | null;
  connectBySlug?: ReadonlyArray<LeadLeadsSlugKeyConnect> | null;
  connectByUserId?: ReadonlyArray<LeadLeadsUniqueUserIdConnect> | null;
  create?: ReadonlyArray<LeadsReferralPartnerIdFkeyLeadsCreateInput> | null;
  deleteById?: ReadonlyArray<LeadNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<LeadLeadsPkeyDelete> | null;
  deleteBySlug?: ReadonlyArray<LeadLeadsSlugKeyDelete> | null;
  deleteByUserId?: ReadonlyArray<LeadLeadsUniqueUserIdDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<LeadOnLeadForLeadsReferralPartnerIdFkeyUsingLeadsPkeyUpdate> | null;
  updateBySlug?: ReadonlyArray<LeadOnLeadForLeadsReferralPartnerIdFkeyUsingLeadsSlugKeyUpdate> | null;
  updateByUserId?: ReadonlyArray<LeadOnLeadForLeadsReferralPartnerIdFkeyUsingLeadsUniqueUserIdUpdate> | null;
};
export type LeadNodeIdConnect = {
  id: string;
};
export type LeadLeadsPkeyConnect = {
  rowId: any;
};
export type LeadLeadsSlugKeyConnect = {
  slug: string;
};
export type LeadLeadsUniqueUserIdConnect = {
  userId: any;
};
export type LeadsReferralPartnerIdFkeyLeadsCreateInput = {
  convertedAt?: any | null;
  createdAt?: any | null;
  optInAt?: any | null;
  optInMethod?: string | null;
  referralPartner?: LeadsReferralPartnerIdFkeyInput | null;
  referralPartnerSource?: LeadsReferralPartnerSourceIdFkeyInput | null;
  referralPartnerSourceId?: any | null;
  rowId?: any | null;
  slug?: string | null;
  source: LeadsSource;
  status: LeadsStatus;
  updatedAt?: any | null;
  user?: LeadsUserIdFkeyInput | null;
  userId?: any | null;
  zohoId?: string | null;
};
export type LeadsReferralPartnerSourceIdFkeyInput = {
  connectById?: ReferralPartnerSourceNodeIdConnect | null;
  connectByRowId?: ReferralPartnerSourceReferralPartnerSourcesPkeyConnect | null;
  create?: LeadsReferralPartnerSourceIdFkeyReferralPartnerSourcesCreateInput | null;
  deleteById?: ReferralPartnerSourceNodeIdDelete | null;
  deleteByRowId?: ReferralPartnerSourceReferralPartnerSourcesPkeyDelete | null;
  updateById?: LeadOnLeadForLeadsReferralPartnerSourceIdFkeyNodeIdUpdate | null;
  updateByRowId?: ReferralPartnerSourceOnLeadForLeadsReferralPartnerSourceIdFkeyUsingReferralPartnerSourcesPkeyUpdate | null;
};
export type ReferralPartnerSourceNodeIdConnect = {
  id: string;
};
export type ReferralPartnerSourceReferralPartnerSourcesPkeyConnect = {
  rowId: any;
};
export type LeadsReferralPartnerSourceIdFkeyReferralPartnerSourcesCreateInput = {
  description: string;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerSourceIdFkeyInverseInput | null;
  name: string;
  referralPartner?: ReferralPartnerSourcesReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  rowId?: any | null;
};
export type LeadsReferralPartnerSourceIdFkeyInverseInput = {
  connectById?: ReadonlyArray<LeadNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<LeadLeadsPkeyConnect> | null;
  connectBySlug?: ReadonlyArray<LeadLeadsSlugKeyConnect> | null;
  connectByUserId?: ReadonlyArray<LeadLeadsUniqueUserIdConnect> | null;
  create?: ReadonlyArray<LeadsReferralPartnerSourceIdFkeyLeadsCreateInput> | null;
  deleteById?: ReadonlyArray<LeadNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<LeadLeadsPkeyDelete> | null;
  deleteBySlug?: ReadonlyArray<LeadLeadsSlugKeyDelete> | null;
  deleteByUserId?: ReadonlyArray<LeadLeadsUniqueUserIdDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ReferralPartnerSourceOnLeadForLeadsReferralPartnerSourceIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<LeadOnLeadForLeadsReferralPartnerSourceIdFkeyUsingLeadsPkeyUpdate> | null;
  updateBySlug?: ReadonlyArray<LeadOnLeadForLeadsReferralPartnerSourceIdFkeyUsingLeadsSlugKeyUpdate> | null;
  updateByUserId?: ReadonlyArray<LeadOnLeadForLeadsReferralPartnerSourceIdFkeyUsingLeadsUniqueUserIdUpdate> | null;
};
export type LeadsReferralPartnerSourceIdFkeyLeadsCreateInput = {
  convertedAt?: any | null;
  createdAt?: any | null;
  optInAt?: any | null;
  optInMethod?: string | null;
  referralPartner?: LeadsReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  referralPartnerSource?: LeadsReferralPartnerSourceIdFkeyInput | null;
  rowId?: any | null;
  slug?: string | null;
  source: LeadsSource;
  status: LeadsStatus;
  updatedAt?: any | null;
  user?: LeadsUserIdFkeyInput | null;
  userId?: any | null;
  zohoId?: string | null;
};
export type LeadsUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: LeadsUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: LeadOnLeadForLeadsUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnLeadForLeadsUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnLeadForLeadsUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserNodeIdConnect = {
  id: string;
};
export type UserUsersPkeyConnect = {
  rowId: any;
};
export type UserUsersSlugUkeyConnect = {
  slug: string;
};
export type LeadsUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type CliniciansUserIdFkeyInverseInput = {
  connectById?: ClinicianNodeIdConnect | null;
  connectByRowId?: ClinicianCliniciansPkeyConnect | null;
  connectBySlug?: ClinicianCliniciansSlugKeyConnect | null;
  connectBySlugPublic?: ClinicianClinicianSlugPublicConnect | null;
  connectByUserId?: ClinicianCliniciansUniqueUserIdConnect | null;
  create?: ReadonlyArray<CliniciansUserIdFkeyCliniciansCreateInput> | null;
  deleteById?: ClinicianNodeIdDelete | null;
  deleteByRowId?: ClinicianCliniciansPkeyDelete | null;
  deleteBySlug?: ClinicianCliniciansSlugKeyDelete | null;
  deleteBySlugPublic?: ClinicianClinicianSlugPublicDelete | null;
  deleteByUserId?: ClinicianCliniciansUniqueUserIdDelete | null;
  deleteOthers?: boolean | null;
  updateById?: UserOnClinicianForCliniciansUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: ClinicianOnClinicianForCliniciansUserIdFkeyUsingCliniciansPkeyUpdate | null;
  updateBySlug?: ClinicianOnClinicianForCliniciansUserIdFkeyUsingCliniciansSlugKeyUpdate | null;
  updateBySlugPublic?: ClinicianOnClinicianForCliniciansUserIdFkeyUsingClinicianSlugPublicUpdate | null;
  updateByUserId?: ClinicianOnClinicianForCliniciansUserIdFkeyUsingCliniciansUniqueUserIdUpdate | null;
};
export type ClinicianNodeIdConnect = {
  id: string;
};
export type ClinicianCliniciansPkeyConnect = {
  rowId: any;
};
export type ClinicianCliniciansSlugKeyConnect = {
  slug: string;
};
export type ClinicianClinicianSlugPublicConnect = {
  slugPublic: string;
};
export type ClinicianCliniciansUniqueUserIdConnect = {
  userId: any;
};
export type CliniciansUserIdFkeyCliniciansCreateInput = {
  appointments?: AppointmentsClinicianIdFkeyInverseInput | null;
  charmId?: string | null;
  clinicianAvailabilities?: ClinicianAvailabilitiesClinicianIdFkeyInverseInput | null;
  createdAt?: any | null;
  employeeType?: EmployeeType | null;
  introduction?: string | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  updatedAt?: any | null;
  user?: CliniciansUserIdFkeyInput | null;
};
export type AppointmentsClinicianIdFkeyInverseInput = {
  connectById?: ReadonlyArray<AppointmentNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<AppointmentAppointmentsPkeyConnect> | null;
  create?: ReadonlyArray<AppointmentsClinicianIdFkeyAppointmentsCreateInput> | null;
  deleteById?: ReadonlyArray<AppointmentNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<AppointmentAppointmentsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ClinicianOnAppointmentForAppointmentsClinicianIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<AppointmentOnAppointmentForAppointmentsClinicianIdFkeyUsingAppointmentsPkeyUpdate> | null;
};
export type AppointmentNodeIdConnect = {
  id: string;
};
export type AppointmentAppointmentsPkeyConnect = {
  rowId: any;
};
export type AppointmentsClinicianIdFkeyAppointmentsCreateInput = {
  charmId?: string | null;
  clinician?: AppointmentsClinicianIdFkeyInput | null;
  createdAt?: any | null;
  endsAt?: any | null;
  notes?: string | null;
  patient?: AppointmentsPatientIdFkeyInput | null;
  patientId?: any | null;
  rowId?: any | null;
  selectionMethod?: AppointmentsSelectionMethod | null;
  service?: AppointmentsServiceIdFkeyInput | null;
  serviceId?: any | null;
  startsAt: any;
  status: AppointmentsStatus;
  updatedAt?: any | null;
  userChild?: AppointmentsUserChildIdFkeyInput | null;
  userChildId?: any | null;
};
export type AppointmentsClinicianIdFkeyInput = {
  connectById?: ClinicianNodeIdConnect | null;
  connectByRowId?: ClinicianCliniciansPkeyConnect | null;
  connectBySlug?: ClinicianCliniciansSlugKeyConnect | null;
  connectBySlugPublic?: ClinicianClinicianSlugPublicConnect | null;
  connectByUserId?: ClinicianCliniciansUniqueUserIdConnect | null;
  create?: AppointmentsClinicianIdFkeyCliniciansCreateInput | null;
  deleteById?: ClinicianNodeIdDelete | null;
  deleteByRowId?: ClinicianCliniciansPkeyDelete | null;
  deleteBySlug?: ClinicianCliniciansSlugKeyDelete | null;
  deleteBySlugPublic?: ClinicianClinicianSlugPublicDelete | null;
  deleteByUserId?: ClinicianCliniciansUniqueUserIdDelete | null;
  updateById?: AppointmentOnAppointmentForAppointmentsClinicianIdFkeyNodeIdUpdate | null;
  updateByRowId?: ClinicianOnAppointmentForAppointmentsClinicianIdFkeyUsingCliniciansPkeyUpdate | null;
  updateBySlug?: ClinicianOnAppointmentForAppointmentsClinicianIdFkeyUsingCliniciansSlugKeyUpdate | null;
  updateBySlugPublic?: ClinicianOnAppointmentForAppointmentsClinicianIdFkeyUsingClinicianSlugPublicUpdate | null;
  updateByUserId?: ClinicianOnAppointmentForAppointmentsClinicianIdFkeyUsingCliniciansUniqueUserIdUpdate | null;
};
export type AppointmentsClinicianIdFkeyCliniciansCreateInput = {
  appointments?: AppointmentsClinicianIdFkeyInverseInput | null;
  charmId?: string | null;
  clinicianAvailabilities?: ClinicianAvailabilitiesClinicianIdFkeyInverseInput | null;
  createdAt?: any | null;
  employeeType?: EmployeeType | null;
  introduction?: string | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  updatedAt?: any | null;
  user?: CliniciansUserIdFkeyInput | null;
  userId?: any | null;
};
export type ClinicianAvailabilitiesClinicianIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ClinicianAvailabilityNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ClinicianAvailabilityClinicianAvailabilitiesPkeyConnect> | null;
  create?: ReadonlyArray<ClinicianAvailabilitiesClinicianIdFkeyClinicianAvailabilitiesCreateInput> | null;
  deleteById?: ReadonlyArray<ClinicianAvailabilityNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ClinicianAvailabilityClinicianAvailabilitiesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ClinicianAvailabilityOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingClinicianAvailabilitiesPkeyUpdate> | null;
};
export type ClinicianAvailabilityNodeIdConnect = {
  id: string;
};
export type ClinicianAvailabilityClinicianAvailabilitiesPkeyConnect = {
  rowId: any;
};
export type ClinicianAvailabilitiesClinicianIdFkeyClinicianAvailabilitiesCreateInput = {
  clinician?: ClinicianAvailabilitiesClinicianIdFkeyInput | null;
  createdAt?: any | null;
  endsAt?: any | null;
  googleCalendarEventId?: string | null;
  isExclusive: boolean;
  recurDuration?: IntervalInput | null;
  recurEvery?: IntervalInput | null;
  rowId?: any | null;
  startsAt: any;
  updatedAt?: any | null;
};
export type ClinicianAvailabilitiesClinicianIdFkeyInput = {
  connectById?: ClinicianNodeIdConnect | null;
  connectByRowId?: ClinicianCliniciansPkeyConnect | null;
  connectBySlug?: ClinicianCliniciansSlugKeyConnect | null;
  connectBySlugPublic?: ClinicianClinicianSlugPublicConnect | null;
  connectByUserId?: ClinicianCliniciansUniqueUserIdConnect | null;
  create?: ClinicianAvailabilitiesClinicianIdFkeyCliniciansCreateInput | null;
  deleteById?: ClinicianNodeIdDelete | null;
  deleteByRowId?: ClinicianCliniciansPkeyDelete | null;
  deleteBySlug?: ClinicianCliniciansSlugKeyDelete | null;
  deleteBySlugPublic?: ClinicianClinicianSlugPublicDelete | null;
  deleteByUserId?: ClinicianCliniciansUniqueUserIdDelete | null;
  updateById?: ClinicianAvailabilityOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyNodeIdUpdate | null;
  updateByRowId?: ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingCliniciansPkeyUpdate | null;
  updateBySlug?: ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingCliniciansSlugKeyUpdate | null;
  updateBySlugPublic?: ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingClinicianSlugPublicUpdate | null;
  updateByUserId?: ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingCliniciansUniqueUserIdUpdate | null;
};
export type ClinicianAvailabilitiesClinicianIdFkeyCliniciansCreateInput = {
  appointments?: AppointmentsClinicianIdFkeyInverseInput | null;
  charmId?: string | null;
  clinicianAvailabilities?: ClinicianAvailabilitiesClinicianIdFkeyInverseInput | null;
  createdAt?: any | null;
  employeeType?: EmployeeType | null;
  introduction?: string | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  updatedAt?: any | null;
  user?: CliniciansUserIdFkeyInput | null;
  userId?: any | null;
};
export type CliniciansUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: CliniciansUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: ClinicianOnClinicianForCliniciansUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnClinicianForCliniciansUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnClinicianForCliniciansUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type CliniciansUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type LeadsUserIdFkeyInverseInput = {
  connectById?: LeadNodeIdConnect | null;
  connectByRowId?: LeadLeadsPkeyConnect | null;
  connectBySlug?: LeadLeadsSlugKeyConnect | null;
  connectByUserId?: LeadLeadsUniqueUserIdConnect | null;
  create?: ReadonlyArray<LeadsUserIdFkeyLeadsCreateInput> | null;
  deleteById?: LeadNodeIdDelete | null;
  deleteByRowId?: LeadLeadsPkeyDelete | null;
  deleteBySlug?: LeadLeadsSlugKeyDelete | null;
  deleteByUserId?: LeadLeadsUniqueUserIdDelete | null;
  deleteOthers?: boolean | null;
  updateById?: UserOnLeadForLeadsUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: LeadOnLeadForLeadsUserIdFkeyUsingLeadsPkeyUpdate | null;
  updateBySlug?: LeadOnLeadForLeadsUserIdFkeyUsingLeadsSlugKeyUpdate | null;
  updateByUserId?: LeadOnLeadForLeadsUserIdFkeyUsingLeadsUniqueUserIdUpdate | null;
};
export type LeadsUserIdFkeyLeadsCreateInput = {
  convertedAt?: any | null;
  createdAt?: any | null;
  optInAt?: any | null;
  optInMethod?: string | null;
  referralPartner?: LeadsReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  referralPartnerSource?: LeadsReferralPartnerSourceIdFkeyInput | null;
  referralPartnerSourceId?: any | null;
  rowId?: any | null;
  slug?: string | null;
  source: LeadsSource;
  status: LeadsStatus;
  updatedAt?: any | null;
  user?: LeadsUserIdFkeyInput | null;
  zohoId?: string | null;
};
export type LeadNodeIdDelete = {
  id: string;
};
export type LeadLeadsPkeyDelete = {
  rowId: any;
};
export type LeadLeadsSlugKeyDelete = {
  slug: string;
};
export type LeadLeadsUniqueUserIdDelete = {
  userId: any;
};
export type UserOnLeadForLeadsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: LeadPatch;
};
export type LeadPatch = {
  convertedAt?: any | null;
  createdAt?: any | null;
  optInAt?: any | null;
  optInMethod?: string | null;
  referralPartner?: LeadsReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  referralPartnerSource?: LeadsReferralPartnerSourceIdFkeyInput | null;
  referralPartnerSourceId?: any | null;
  rowId?: any | null;
  slug?: string | null;
  source?: LeadsSource | null;
  status?: LeadsStatus | null;
  updatedAt?: any | null;
  user?: LeadsUserIdFkeyInput | null;
  userId?: any | null;
  zohoId?: string | null;
};
export type LeadOnLeadForLeadsUserIdFkeyUsingLeadsPkeyUpdate = {
  patch: updateLeadOnLeadForLeadsUserIdFkeyPatch;
  rowId: any;
};
export type updateLeadOnLeadForLeadsUserIdFkeyPatch = {
  convertedAt?: any | null;
  createdAt?: any | null;
  optInAt?: any | null;
  optInMethod?: string | null;
  referralPartner?: LeadsReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  referralPartnerSource?: LeadsReferralPartnerSourceIdFkeyInput | null;
  referralPartnerSourceId?: any | null;
  rowId?: any | null;
  slug?: string | null;
  source?: LeadsSource | null;
  status?: LeadsStatus | null;
  updatedAt?: any | null;
  user?: LeadsUserIdFkeyInput | null;
  zohoId?: string | null;
};
export type LeadOnLeadForLeadsUserIdFkeyUsingLeadsSlugKeyUpdate = {
  patch: updateLeadOnLeadForLeadsUserIdFkeyPatch;
  slug: string;
};
export type LeadOnLeadForLeadsUserIdFkeyUsingLeadsUniqueUserIdUpdate = {
  patch: updateLeadOnLeadForLeadsUserIdFkeyPatch;
  userId: any;
};
export type PatientsUserIdFkeyInverseInput = {
  connectByCharmRecordId?: PatientPatientsUniqueCharmRecordIdConnect | null;
  connectById?: PatientNodeIdConnect | null;
  connectByRowId?: PatientPatientsPkeyConnect | null;
  connectBySlug?: PatientPatientsSlugKeyConnect | null;
  connectByUserId?: PatientPatientsUniqueUserIdConnect | null;
  create?: ReadonlyArray<PatientsUserIdFkeyPatientsCreateInput> | null;
  deleteByCharmRecordId?: PatientPatientsUniqueCharmRecordIdDelete | null;
  deleteById?: PatientNodeIdDelete | null;
  deleteByRowId?: PatientPatientsPkeyDelete | null;
  deleteBySlug?: PatientPatientsSlugKeyDelete | null;
  deleteByUserId?: PatientPatientsUniqueUserIdDelete | null;
  deleteOthers?: boolean | null;
  updateByCharmRecordId?: PatientOnPatientForPatientsUserIdFkeyUsingPatientsUniqueCharmRecordIdUpdate | null;
  updateById?: UserOnPatientForPatientsUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: PatientOnPatientForPatientsUserIdFkeyUsingPatientsPkeyUpdate | null;
  updateBySlug?: PatientOnPatientForPatientsUserIdFkeyUsingPatientsSlugKeyUpdate | null;
  updateByUserId?: PatientOnPatientForPatientsUserIdFkeyUsingPatientsUniqueUserIdUpdate | null;
};
export type PatientPatientsUniqueCharmRecordIdConnect = {
  charmRecordId: string;
};
export type PatientNodeIdConnect = {
  id: string;
};
export type PatientPatientsPkeyConnect = {
  rowId: any;
};
export type PatientPatientsSlugKeyConnect = {
  slug: string;
};
export type PatientPatientsUniqueUserIdConnect = {
  userId: any;
};
export type PatientsUserIdFkeyPatientsCreateInput = {
  appointments?: AppointmentsPatientIdFkeyInverseInput | null;
  charmId?: string | null;
  charmRecordId?: string | null;
  createdAt?: any | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: PatientsUserIdFkeyInput | null;
  zohoId?: string | null;
};
export type AppointmentsPatientIdFkeyInverseInput = {
  connectById?: ReadonlyArray<AppointmentNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<AppointmentAppointmentsPkeyConnect> | null;
  create?: ReadonlyArray<AppointmentsPatientIdFkeyAppointmentsCreateInput> | null;
  deleteById?: ReadonlyArray<AppointmentNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<AppointmentAppointmentsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<PatientOnAppointmentForAppointmentsPatientIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<AppointmentOnAppointmentForAppointmentsPatientIdFkeyUsingAppointmentsPkeyUpdate> | null;
};
export type AppointmentsPatientIdFkeyAppointmentsCreateInput = {
  charmId?: string | null;
  clinician?: AppointmentsClinicianIdFkeyInput | null;
  clinicianId?: any | null;
  createdAt?: any | null;
  endsAt?: any | null;
  notes?: string | null;
  patient?: AppointmentsPatientIdFkeyInput | null;
  rowId?: any | null;
  selectionMethod?: AppointmentsSelectionMethod | null;
  service?: AppointmentsServiceIdFkeyInput | null;
  serviceId?: any | null;
  startsAt: any;
  status: AppointmentsStatus;
  updatedAt?: any | null;
  userChild?: AppointmentsUserChildIdFkeyInput | null;
  userChildId?: any | null;
};
export type AppointmentsPatientIdFkeyInput = {
  connectByCharmRecordId?: PatientPatientsUniqueCharmRecordIdConnect | null;
  connectById?: PatientNodeIdConnect | null;
  connectByRowId?: PatientPatientsPkeyConnect | null;
  connectBySlug?: PatientPatientsSlugKeyConnect | null;
  connectByUserId?: PatientPatientsUniqueUserIdConnect | null;
  create?: AppointmentsPatientIdFkeyPatientsCreateInput | null;
  deleteByCharmRecordId?: PatientPatientsUniqueCharmRecordIdDelete | null;
  deleteById?: PatientNodeIdDelete | null;
  deleteByRowId?: PatientPatientsPkeyDelete | null;
  deleteBySlug?: PatientPatientsSlugKeyDelete | null;
  deleteByUserId?: PatientPatientsUniqueUserIdDelete | null;
  updateByCharmRecordId?: PatientOnAppointmentForAppointmentsPatientIdFkeyUsingPatientsUniqueCharmRecordIdUpdate | null;
  updateById?: AppointmentOnAppointmentForAppointmentsPatientIdFkeyNodeIdUpdate | null;
  updateByRowId?: PatientOnAppointmentForAppointmentsPatientIdFkeyUsingPatientsPkeyUpdate | null;
  updateBySlug?: PatientOnAppointmentForAppointmentsPatientIdFkeyUsingPatientsSlugKeyUpdate | null;
  updateByUserId?: PatientOnAppointmentForAppointmentsPatientIdFkeyUsingPatientsUniqueUserIdUpdate | null;
};
export type AppointmentsPatientIdFkeyPatientsCreateInput = {
  appointments?: AppointmentsPatientIdFkeyInverseInput | null;
  charmId?: string | null;
  charmRecordId?: string | null;
  createdAt?: any | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: PatientsUserIdFkeyInput | null;
  userId?: any | null;
  zohoId?: string | null;
};
export type PatientsUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: PatientsUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: PatientOnPatientForPatientsUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnPatientForPatientsUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnPatientForPatientsUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type PatientsUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UploadsUploaderIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UploadNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UploadUploadsPkeyConnect> | null;
  connectBySlug?: ReadonlyArray<UploadUploadsSlugKeyConnect> | null;
  create?: ReadonlyArray<UploadsUploaderIdFkeyUploadsCreateInput> | null;
  deleteById?: ReadonlyArray<UploadNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UploadUploadsPkeyDelete> | null;
  deleteBySlug?: ReadonlyArray<UploadUploadsSlugKeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUploadForUploadsUploaderIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UploadOnUploadForUploadsUploaderIdFkeyUsingUploadsPkeyUpdate> | null;
  updateBySlug?: ReadonlyArray<UploadOnUploadForUploadsUploaderIdFkeyUsingUploadsSlugKeyUpdate> | null;
};
export type UploadNodeIdConnect = {
  id: string;
};
export type UploadUploadsPkeyConnect = {
  rowId: any;
};
export type UploadUploadsSlugKeyConnect = {
  slug: string;
};
export type UploadsUploaderIdFkeyUploadsCreateInput = {
  bucket: string;
  createdAt?: any | null;
  filename: string;
  filesize: number;
  filetype: string;
  key: string;
  referralPartnerUploads?: ReferralPartnerUploadsUploadIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: UploadsUploaderIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUploadIdFkeyInverseInput | null;
  userUploads?: UserUploadsUploadIdFkeyInverseInput | null;
};
export type ReferralPartnerUploadsUploadIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ReferralPartnerUploadNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ReferralPartnerUploadReferralPartnerUploadsPkeyConnect> | null;
  create?: ReadonlyArray<ReferralPartnerUploadsUploadIdFkeyReferralPartnerUploadsCreateInput> | null;
  deleteById?: ReadonlyArray<ReferralPartnerUploadNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ReferralPartnerUploadReferralPartnerUploadsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyUsingReferralPartnerUploadsPkeyUpdate> | null;
};
export type ReferralPartnerUploadNodeIdConnect = {
  id: string;
};
export type ReferralPartnerUploadReferralPartnerUploadsPkeyConnect = {
  rowId: any;
};
export type ReferralPartnerUploadsUploadIdFkeyReferralPartnerUploadsCreateInput = {
  createdAt?: any | null;
  name: string;
  referralPartner?: ReferralPartnerUploadsReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: ReferralPartnerUploadsUploadIdFkeyInput | null;
};
export type ReferralPartnerUploadsReferralPartnerIdFkeyInput = {
  connectById?: ReferralPartnerNodeIdConnect | null;
  connectByRowId?: ReferralPartnerReferralPartnerPkeyConnect | null;
  connectBySlug?: ReferralPartnerReferralPartnerUniqueSlugConnect | null;
  connectBySlugPublic?: ReferralPartnerReferralPartnerSlugPublicConnect | null;
  create?: ReferralPartnerUploadsReferralPartnerIdFkeyReferralPartnersCreateInput | null;
  deleteById?: ReferralPartnerNodeIdDelete | null;
  deleteByRowId?: ReferralPartnerReferralPartnerPkeyDelete | null;
  deleteBySlug?: ReferralPartnerReferralPartnerUniqueSlugDelete | null;
  deleteBySlugPublic?: ReferralPartnerReferralPartnerSlugPublicDelete | null;
  updateById?: ReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyNodeIdUpdate | null;
  updateByRowId?: ReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyUsingReferralPartnerPkeyUpdate | null;
  updateBySlug?: ReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyUsingReferralPartnerUniqueSlugUpdate | null;
  updateBySlugPublic?: ReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyUsingReferralPartnerSlugPublicUpdate | null;
};
export type ReferralPartnerUploadsReferralPartnerIdFkeyReferralPartnersCreateInput = {
  config?: any | null;
  createdAt?: any | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerIdFkeyInverseInput | null;
  name: string;
  referralPartnerSources?: ReferralPartnerSourcesReferralPartnerIdFkeyInverseInput | null;
  referralPartnerUploads?: ReferralPartnerUploadsReferralPartnerIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  type: ReferralPartnerType;
  updatedAt?: any | null;
};
export type ReferralPartnerSourcesReferralPartnerIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ReferralPartnerSourceNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ReferralPartnerSourceReferralPartnerSourcesPkeyConnect> | null;
  create?: ReadonlyArray<ReferralPartnerSourcesReferralPartnerIdFkeyReferralPartnerSourcesCreateInput> | null;
  deleteById?: ReadonlyArray<ReferralPartnerSourceNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ReferralPartnerSourceReferralPartnerSourcesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ReferralPartnerSourceOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyUsingReferralPartnerSourcesPkeyUpdate> | null;
};
export type ReferralPartnerSourcesReferralPartnerIdFkeyReferralPartnerSourcesCreateInput = {
  description: string;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerSourceIdFkeyInverseInput | null;
  name: string;
  referralPartner?: ReferralPartnerSourcesReferralPartnerIdFkeyInput | null;
  rowId?: any | null;
};
export type ReferralPartnerSourcesReferralPartnerIdFkeyInput = {
  connectById?: ReferralPartnerNodeIdConnect | null;
  connectByRowId?: ReferralPartnerReferralPartnerPkeyConnect | null;
  connectBySlug?: ReferralPartnerReferralPartnerUniqueSlugConnect | null;
  connectBySlugPublic?: ReferralPartnerReferralPartnerSlugPublicConnect | null;
  create?: ReferralPartnerSourcesReferralPartnerIdFkeyReferralPartnersCreateInput | null;
  deleteById?: ReferralPartnerNodeIdDelete | null;
  deleteByRowId?: ReferralPartnerReferralPartnerPkeyDelete | null;
  deleteBySlug?: ReferralPartnerReferralPartnerUniqueSlugDelete | null;
  deleteBySlugPublic?: ReferralPartnerReferralPartnerSlugPublicDelete | null;
  updateById?: ReferralPartnerSourceOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyNodeIdUpdate | null;
  updateByRowId?: ReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyUsingReferralPartnerPkeyUpdate | null;
  updateBySlug?: ReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyUsingReferralPartnerUniqueSlugUpdate | null;
  updateBySlugPublic?: ReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyUsingReferralPartnerSlugPublicUpdate | null;
};
export type ReferralPartnerSourcesReferralPartnerIdFkeyReferralPartnersCreateInput = {
  config?: any | null;
  createdAt?: any | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerIdFkeyInverseInput | null;
  name: string;
  referralPartnerSources?: ReferralPartnerSourcesReferralPartnerIdFkeyInverseInput | null;
  referralPartnerUploads?: ReferralPartnerUploadsReferralPartnerIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  type: ReferralPartnerType;
  updatedAt?: any | null;
};
export type ReferralPartnerUploadsReferralPartnerIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ReferralPartnerUploadNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ReferralPartnerUploadReferralPartnerUploadsPkeyConnect> | null;
  create?: ReadonlyArray<ReferralPartnerUploadsReferralPartnerIdFkeyReferralPartnerUploadsCreateInput> | null;
  deleteById?: ReadonlyArray<ReferralPartnerUploadNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ReferralPartnerUploadReferralPartnerUploadsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyUsingReferralPartnerUploadsPkeyUpdate> | null;
};
export type ReferralPartnerUploadsReferralPartnerIdFkeyReferralPartnerUploadsCreateInput = {
  createdAt?: any | null;
  name: string;
  referralPartner?: ReferralPartnerUploadsReferralPartnerIdFkeyInput | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: ReferralPartnerUploadsUploadIdFkeyInput | null;
  uploadId?: any | null;
};
export type ReferralPartnerUploadsUploadIdFkeyInput = {
  connectById?: UploadNodeIdConnect | null;
  connectByRowId?: UploadUploadsPkeyConnect | null;
  connectBySlug?: UploadUploadsSlugKeyConnect | null;
  create?: ReferralPartnerUploadsUploadIdFkeyUploadsCreateInput | null;
  deleteById?: UploadNodeIdDelete | null;
  deleteByRowId?: UploadUploadsPkeyDelete | null;
  deleteBySlug?: UploadUploadsSlugKeyDelete | null;
  updateById?: ReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyNodeIdUpdate | null;
  updateByRowId?: UploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyUsingUploadsPkeyUpdate | null;
  updateBySlug?: UploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyUsingUploadsSlugKeyUpdate | null;
};
export type ReferralPartnerUploadsUploadIdFkeyUploadsCreateInput = {
  bucket: string;
  createdAt?: any | null;
  filename: string;
  filesize: number;
  filetype: string;
  key: string;
  referralPartnerUploads?: ReferralPartnerUploadsUploadIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploaderId?: any | null;
  user?: UploadsUploaderIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUploadIdFkeyInverseInput | null;
  userUploads?: UserUploadsUploadIdFkeyInverseInput | null;
};
export type UploadsUploaderIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UploadsUploaderIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UploadOnUploadForUploadsUploaderIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUploadForUploadsUploaderIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUploadForUploadsUploaderIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UploadsUploaderIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserAddressesUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserAddressNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserAddressUserAddressesPkeyConnect> | null;
  create?: ReadonlyArray<UserAddressesUserIdFkeyUserAddressesCreateInput> | null;
  deleteById?: ReadonlyArray<UserAddressNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserAddressUserAddressesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUserAddressForUserAddressesUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserAddressOnUserAddressForUserAddressesUserIdFkeyUsingUserAddressesPkeyUpdate> | null;
};
export type UserAddressNodeIdConnect = {
  id: string;
};
export type UserAddressUserAddressesPkeyConnect = {
  rowId: any;
};
export type UserAddressesUserIdFkeyUserAddressesCreateInput = {
  address?: UserAddressesAddressIdFkeyInput | null;
  addressId?: any | null;
  createdAt?: any | null;
  isPrimary: boolean;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserAddressesUserIdFkeyInput | null;
};
export type UserAddressesAddressIdFkeyInput = {
  connectById?: AddressNodeIdConnect | null;
  connectByRowId?: AddressAddressesPkeyConnect | null;
  create?: UserAddressesAddressIdFkeyAddressesCreateInput | null;
  deleteById?: AddressNodeIdDelete | null;
  deleteByRowId?: AddressAddressesPkeyDelete | null;
  updateById?: UserAddressOnUserAddressForUserAddressesAddressIdFkeyNodeIdUpdate | null;
  updateByRowId?: AddressOnUserAddressForUserAddressesAddressIdFkeyUsingAddressesPkeyUpdate | null;
};
export type AddressNodeIdConnect = {
  id: string;
};
export type AddressAddressesPkeyConnect = {
  rowId: any;
};
export type UserAddressesAddressIdFkeyAddressesCreateInput = {
  city: string;
  country: string;
  createdAt?: any | null;
  postalCode: string;
  rowId?: any | null;
  stateOrProvince: string;
  street1: string;
  street2?: string | null;
  updatedAt?: any | null;
  userAddresses?: UserAddressesAddressIdFkeyInverseInput | null;
};
export type UserAddressesAddressIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserAddressNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserAddressUserAddressesPkeyConnect> | null;
  create?: ReadonlyArray<UserAddressesAddressIdFkeyUserAddressesCreateInput> | null;
  deleteById?: ReadonlyArray<UserAddressNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserAddressUserAddressesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AddressOnUserAddressForUserAddressesAddressIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserAddressOnUserAddressForUserAddressesAddressIdFkeyUsingUserAddressesPkeyUpdate> | null;
};
export type UserAddressesAddressIdFkeyUserAddressesCreateInput = {
  address?: UserAddressesAddressIdFkeyInput | null;
  createdAt?: any | null;
  isPrimary: boolean;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserAddressesUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserAddressesUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UserAddressesUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UserAddressOnUserAddressForUserAddressesUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUserAddressForUserAddressesUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUserAddressForUserAddressesUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserAddressesUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserChildrenUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserChildNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserChildUserChildrenPkeyConnect> | null;
  create?: ReadonlyArray<UserChildrenUserIdFkeyUserChildrenCreateInput> | null;
  deleteById?: ReadonlyArray<UserChildNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserChildUserChildrenPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUserChildForUserChildrenUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserChildOnUserChildForUserChildrenUserIdFkeyUsingUserChildrenPkeyUpdate> | null;
};
export type UserChildNodeIdConnect = {
  id: string;
};
export type UserChildUserChildrenPkeyConnect = {
  rowId: any;
};
export type UserChildrenUserIdFkeyUserChildrenCreateInput = {
  appointments?: AppointmentsUserChildIdFkeyInverseInput | null;
  birthdateAt?: any | null;
  createdAt?: any | null;
  dueAt?: any | null;
  name: string;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserChildrenUserIdFkeyInput | null;
};
export type AppointmentsUserChildIdFkeyInverseInput = {
  connectById?: ReadonlyArray<AppointmentNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<AppointmentAppointmentsPkeyConnect> | null;
  create?: ReadonlyArray<AppointmentsUserChildIdFkeyAppointmentsCreateInput> | null;
  deleteById?: ReadonlyArray<AppointmentNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<AppointmentAppointmentsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserChildOnAppointmentForAppointmentsUserChildIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<AppointmentOnAppointmentForAppointmentsUserChildIdFkeyUsingAppointmentsPkeyUpdate> | null;
};
export type AppointmentsUserChildIdFkeyAppointmentsCreateInput = {
  charmId?: string | null;
  clinician?: AppointmentsClinicianIdFkeyInput | null;
  clinicianId?: any | null;
  createdAt?: any | null;
  endsAt?: any | null;
  notes?: string | null;
  patient?: AppointmentsPatientIdFkeyInput | null;
  patientId?: any | null;
  rowId?: any | null;
  selectionMethod?: AppointmentsSelectionMethod | null;
  service?: AppointmentsServiceIdFkeyInput | null;
  serviceId?: any | null;
  startsAt: any;
  status: AppointmentsStatus;
  updatedAt?: any | null;
  userChild?: AppointmentsUserChildIdFkeyInput | null;
};
export type AppointmentsServiceIdFkeyInput = {
  connectById?: ServiceNodeIdConnect | null;
  connectByRowId?: ServiceServicesPkeyConnect | null;
  connectBySlug?: ServiceServicesSlugKeyConnect | null;
  create?: AppointmentsServiceIdFkeyServicesCreateInput | null;
  deleteById?: ServiceNodeIdDelete | null;
  deleteByRowId?: ServiceServicesPkeyDelete | null;
  deleteBySlug?: ServiceServicesSlugKeyDelete | null;
  updateById?: AppointmentOnAppointmentForAppointmentsServiceIdFkeyNodeIdUpdate | null;
  updateByRowId?: ServiceOnAppointmentForAppointmentsServiceIdFkeyUsingServicesPkeyUpdate | null;
  updateBySlug?: ServiceOnAppointmentForAppointmentsServiceIdFkeyUsingServicesSlugKeyUpdate | null;
};
export type ServiceNodeIdConnect = {
  id: string;
};
export type ServiceServicesPkeyConnect = {
  rowId: any;
};
export type ServiceServicesSlugKeyConnect = {
  slug: string;
};
export type AppointmentsServiceIdFkeyServicesCreateInput = {
  appointments?: AppointmentsServiceIdFkeyInverseInput | null;
  charmServiceId?: string | null;
  createdAt?: any | null;
  duration: IntervalInput;
  isActive: boolean;
  name: string;
  price: any;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
};
export type AppointmentsServiceIdFkeyInverseInput = {
  connectById?: ReadonlyArray<AppointmentNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<AppointmentAppointmentsPkeyConnect> | null;
  create?: ReadonlyArray<AppointmentsServiceIdFkeyAppointmentsCreateInput> | null;
  deleteById?: ReadonlyArray<AppointmentNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<AppointmentAppointmentsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ServiceOnAppointmentForAppointmentsServiceIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<AppointmentOnAppointmentForAppointmentsServiceIdFkeyUsingAppointmentsPkeyUpdate> | null;
};
export type AppointmentsServiceIdFkeyAppointmentsCreateInput = {
  charmId?: string | null;
  clinician?: AppointmentsClinicianIdFkeyInput | null;
  clinicianId?: any | null;
  createdAt?: any | null;
  endsAt?: any | null;
  notes?: string | null;
  patient?: AppointmentsPatientIdFkeyInput | null;
  patientId?: any | null;
  rowId?: any | null;
  selectionMethod?: AppointmentsSelectionMethod | null;
  service?: AppointmentsServiceIdFkeyInput | null;
  startsAt: any;
  status: AppointmentsStatus;
  updatedAt?: any | null;
  userChild?: AppointmentsUserChildIdFkeyInput | null;
  userChildId?: any | null;
};
export type AppointmentsUserChildIdFkeyInput = {
  connectById?: UserChildNodeIdConnect | null;
  connectByRowId?: UserChildUserChildrenPkeyConnect | null;
  create?: AppointmentsUserChildIdFkeyUserChildrenCreateInput | null;
  deleteById?: UserChildNodeIdDelete | null;
  deleteByRowId?: UserChildUserChildrenPkeyDelete | null;
  updateById?: AppointmentOnAppointmentForAppointmentsUserChildIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserChildOnAppointmentForAppointmentsUserChildIdFkeyUsingUserChildrenPkeyUpdate | null;
};
export type AppointmentsUserChildIdFkeyUserChildrenCreateInput = {
  appointments?: AppointmentsUserChildIdFkeyInverseInput | null;
  birthdateAt?: any | null;
  createdAt?: any | null;
  dueAt?: any | null;
  name: string;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserChildrenUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserChildrenUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UserChildrenUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UserChildOnUserChildForUserChildrenUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUserChildForUserChildrenUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUserChildForUserChildrenUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserChildrenUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserInsurancesUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserInsuranceNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserInsuranceUserInsurancesPkeyConnect> | null;
  create?: ReadonlyArray<UserInsurancesUserIdFkeyUserInsurancesCreateInput> | null;
  deleteById?: ReadonlyArray<UserInsuranceNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserInsuranceUserInsurancesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUserInsuranceForUserInsurancesUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserInsuranceOnUserInsuranceForUserInsurancesUserIdFkeyUsingUserInsurancesPkeyUpdate> | null;
};
export type UserInsuranceNodeIdConnect = {
  id: string;
};
export type UserInsuranceUserInsurancesPkeyConnect = {
  rowId: any;
};
export type UserInsurancesUserIdFkeyUserInsurancesCreateInput = {
  category?: UserInsuranceCategory | null;
  charmId?: string | null;
  comments?: string | null;
  createdAt?: any | null;
  groupId?: string | null;
  insuranceProvider?: UserInsurancesInsuranceProviderIdFkeyInput | null;
  insuranceProviderId?: any | null;
  memberId: string;
  policyHolderAddressCity?: string | null;
  policyHolderAddressCountry?: string | null;
  policyHolderAddressPostalCode?: string | null;
  policyHolderAddressStateOrProvince?: string | null;
  policyHolderAddressStreet1?: string | null;
  policyHolderAddressStreet2?: string | null;
  policyHolderBirthdateAt?: any | null;
  policyHolderFirstName?: string | null;
  policyHolderGender?: string | null;
  policyHolderHomePhone?: string | null;
  policyHolderLastName?: string | null;
  policyHolderMobilePhone?: string | null;
  policyHolderRelationship: UserInsurancePolicyHolderRelationship;
  policyHolderSsn?: string | null;
  rowId?: any | null;
  type: UserInsuranceType;
  updatedAt?: any | null;
  user?: UserInsurancesUserIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUserInsuranceIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type UserInsurancesInsuranceProviderIdFkeyInput = {
  connectById?: InsuranceProviderNodeIdConnect | null;
  connectByRowId?: InsuranceProviderInsuranceProvidersPkeyConnect | null;
  connectBySlug?: InsuranceProviderInsuranceProvidersSlugKeyConnect | null;
  create?: UserInsurancesInsuranceProviderIdFkeyInsuranceProvidersCreateInput | null;
  deleteById?: InsuranceProviderNodeIdDelete | null;
  deleteByRowId?: InsuranceProviderInsuranceProvidersPkeyDelete | null;
  deleteBySlug?: InsuranceProviderInsuranceProvidersSlugKeyDelete | null;
  updateById?: UserInsuranceOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyNodeIdUpdate | null;
  updateByRowId?: InsuranceProviderOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyUsingInsuranceProvidersPkeyUpdate | null;
  updateBySlug?: InsuranceProviderOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyUsingInsuranceProvidersSlugKeyUpdate | null;
};
export type InsuranceProviderNodeIdConnect = {
  id: string;
};
export type InsuranceProviderInsuranceProvidersPkeyConnect = {
  rowId: any;
};
export type InsuranceProviderInsuranceProvidersSlugKeyConnect = {
  slug: string;
};
export type UserInsurancesInsuranceProviderIdFkeyInsuranceProvidersCreateInput = {
  charmId?: string | null;
  createdAt?: any | null;
  name: string;
  payerId?: string | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  userInsurances?: UserInsurancesInsuranceProviderIdFkeyInverseInput | null;
};
export type UserInsurancesInsuranceProviderIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserInsuranceNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserInsuranceUserInsurancesPkeyConnect> | null;
  create?: ReadonlyArray<UserInsurancesInsuranceProviderIdFkeyUserInsurancesCreateInput> | null;
  deleteById?: ReadonlyArray<UserInsuranceNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserInsuranceUserInsurancesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<InsuranceProviderOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserInsuranceOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyUsingUserInsurancesPkeyUpdate> | null;
};
export type UserInsurancesInsuranceProviderIdFkeyUserInsurancesCreateInput = {
  category?: UserInsuranceCategory | null;
  charmId?: string | null;
  comments?: string | null;
  createdAt?: any | null;
  groupId?: string | null;
  insuranceProvider?: UserInsurancesInsuranceProviderIdFkeyInput | null;
  memberId: string;
  policyHolderAddressCity?: string | null;
  policyHolderAddressCountry?: string | null;
  policyHolderAddressPostalCode?: string | null;
  policyHolderAddressStateOrProvince?: string | null;
  policyHolderAddressStreet1?: string | null;
  policyHolderAddressStreet2?: string | null;
  policyHolderBirthdateAt?: any | null;
  policyHolderFirstName?: string | null;
  policyHolderGender?: string | null;
  policyHolderHomePhone?: string | null;
  policyHolderLastName?: string | null;
  policyHolderMobilePhone?: string | null;
  policyHolderRelationship: UserInsurancePolicyHolderRelationship;
  policyHolderSsn?: string | null;
  rowId?: any | null;
  type: UserInsuranceType;
  updatedAt?: any | null;
  user?: UserInsurancesUserIdFkeyInput | null;
  userId?: any | null;
  userInsuranceUploads?: UserInsuranceUploadsUserInsuranceIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type UserInsurancesUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UserInsurancesUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UserInsuranceOnUserInsuranceForUserInsurancesUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUserInsuranceForUserInsurancesUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUserInsuranceForUserInsurancesUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserInsurancesUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserLanguagesUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserLanguageNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserLanguageUserLanguagesPkeyConnect> | null;
  create?: ReadonlyArray<UserLanguagesUserIdFkeyUserLanguagesCreateInput> | null;
  deleteById?: ReadonlyArray<UserLanguageNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserLanguageUserLanguagesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUserLanguageForUserLanguagesUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserLanguageOnUserLanguageForUserLanguagesUserIdFkeyUsingUserLanguagesPkeyUpdate> | null;
};
export type UserLanguageNodeIdConnect = {
  id: string;
};
export type UserLanguageUserLanguagesPkeyConnect = {
  rowId: any;
};
export type UserLanguagesUserIdFkeyUserLanguagesCreateInput = {
  createdAt?: any | null;
  isPrimary: boolean;
  language: string;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserLanguagesUserIdFkeyInput | null;
};
export type UserLanguagesUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UserLanguagesUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UserLanguageOnUserLanguageForUserLanguagesUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUserLanguageForUserLanguagesUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUserLanguageForUserLanguagesUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserLanguagesUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserPaymentMethodsUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserPaymentMethodNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserPaymentMethodUserPaymentMethodsPkeyConnect> | null;
  connectBySlug?: ReadonlyArray<UserPaymentMethodUserPaymentMethodsSlugKeyConnect> | null;
  create?: ReadonlyArray<UserPaymentMethodsUserIdFkeyUserPaymentMethodsCreateInput> | null;
  deleteById?: ReadonlyArray<UserPaymentMethodNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserPaymentMethodUserPaymentMethodsPkeyDelete> | null;
  deleteBySlug?: ReadonlyArray<UserPaymentMethodUserPaymentMethodsSlugKeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserPaymentMethodOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyUsingUserPaymentMethodsPkeyUpdate> | null;
  updateBySlug?: ReadonlyArray<UserPaymentMethodOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyUsingUserPaymentMethodsSlugKeyUpdate> | null;
};
export type UserPaymentMethodNodeIdConnect = {
  id: string;
};
export type UserPaymentMethodUserPaymentMethodsPkeyConnect = {
  rowId: any;
};
export type UserPaymentMethodUserPaymentMethodsSlugKeyConnect = {
  slug: string;
};
export type UserPaymentMethodsUserIdFkeyUserPaymentMethodsCreateInput = {
  createdAt?: any | null;
  isActive?: boolean | null;
  isPrimary?: boolean | null;
  methodId: string;
  provider: PaymentProviders;
  providerId: string;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: UserPaymentMethodsUserIdFkeyInput | null;
};
export type UserPaymentMethodsUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UserPaymentMethodsUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UserPaymentMethodOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserPaymentMethodsUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserPaymentProvidersUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserPaymentProviderNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserPaymentProviderUserPaymentProvidersPkeyConnect> | null;
  connectBySlug?: ReadonlyArray<UserPaymentProviderUserPaymentProvidersSlugKeyConnect> | null;
  create?: ReadonlyArray<UserPaymentProvidersUserIdFkeyUserPaymentProvidersCreateInput> | null;
  deleteById?: ReadonlyArray<UserPaymentProviderNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserPaymentProviderUserPaymentProvidersPkeyDelete> | null;
  deleteBySlug?: ReadonlyArray<UserPaymentProviderUserPaymentProvidersSlugKeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserPaymentProviderOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyUsingUserPaymentProvidersPkeyUpdate> | null;
  updateBySlug?: ReadonlyArray<UserPaymentProviderOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyUsingUserPaymentProvidersSlugKeyUpdate> | null;
};
export type UserPaymentProviderNodeIdConnect = {
  id: string;
};
export type UserPaymentProviderUserPaymentProvidersPkeyConnect = {
  rowId: any;
};
export type UserPaymentProviderUserPaymentProvidersSlugKeyConnect = {
  slug: string;
};
export type UserPaymentProvidersUserIdFkeyUserPaymentProvidersCreateInput = {
  createdAt?: any | null;
  isActive?: boolean | null;
  isPrimary?: boolean | null;
  provider: PaymentProviders;
  providerId: string;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: UserPaymentProvidersUserIdFkeyInput | null;
};
export type UserPaymentProvidersUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UserPaymentProvidersUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UserPaymentProviderOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserPaymentProvidersUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserPhonesUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserPhoneNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserPhoneUserPhonesPkeyConnect> | null;
  create?: ReadonlyArray<UserPhonesUserIdFkeyUserPhonesCreateInput> | null;
  deleteById?: ReadonlyArray<UserPhoneNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserPhoneUserPhonesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUserPhoneForUserPhonesUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserPhoneOnUserPhoneForUserPhonesUserIdFkeyUsingUserPhonesPkeyUpdate> | null;
};
export type UserPhoneNodeIdConnect = {
  id: string;
};
export type UserPhoneUserPhonesPkeyConnect = {
  rowId: any;
};
export type UserPhonesUserIdFkeyUserPhonesCreateInput = {
  createdAt?: any | null;
  isPrimary: boolean;
  phone: string;
  rowId?: any | null;
  type: UserPhonesType;
  updatedAt?: any | null;
  user?: UserPhonesUserIdFkeyInput | null;
};
export type UserPhonesUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UserPhonesUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UserPhoneOnUserPhoneForUserPhonesUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUserPhoneForUserPhonesUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUserPhoneForUserPhonesUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserPhonesUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserPoliciesUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserPolicyNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserPolicyUserPoliciesPkeyConnect> | null;
  create?: ReadonlyArray<UserPoliciesUserIdFkeyUserPoliciesCreateInput> | null;
  deleteById?: ReadonlyArray<UserPolicyNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserPolicyUserPoliciesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUserPolicyForUserPoliciesUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserPolicyOnUserPolicyForUserPoliciesUserIdFkeyUsingUserPoliciesPkeyUpdate> | null;
};
export type UserPolicyNodeIdConnect = {
  id: string;
};
export type UserPolicyUserPoliciesPkeyConnect = {
  rowId: any;
};
export type UserPoliciesUserIdFkeyUserPoliciesCreateInput = {
  acceptedAt?: any | null;
  createdAt?: any | null;
  policy?: UserPoliciesPolicyIdFkeyInput | null;
  policyId?: any | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserPoliciesUserIdFkeyInput | null;
};
export type UserPoliciesPolicyIdFkeyInput = {
  connectById?: PolicyNodeIdConnect | null;
  connectByRowId?: PolicyPoliciesPkeyConnect | null;
  connectBySlug?: PolicyPoliciesSlugKeyConnect | null;
  create?: UserPoliciesPolicyIdFkeyPoliciesCreateInput | null;
  deleteById?: PolicyNodeIdDelete | null;
  deleteByRowId?: PolicyPoliciesPkeyDelete | null;
  deleteBySlug?: PolicyPoliciesSlugKeyDelete | null;
  updateById?: UserPolicyOnUserPolicyForUserPoliciesPolicyIdFkeyNodeIdUpdate | null;
  updateByRowId?: PolicyOnUserPolicyForUserPoliciesPolicyIdFkeyUsingPoliciesPkeyUpdate | null;
  updateBySlug?: PolicyOnUserPolicyForUserPoliciesPolicyIdFkeyUsingPoliciesSlugKeyUpdate | null;
};
export type PolicyNodeIdConnect = {
  id: string;
};
export type PolicyPoliciesPkeyConnect = {
  rowId: any;
};
export type PolicyPoliciesSlugKeyConnect = {
  slug: string;
};
export type UserPoliciesPolicyIdFkeyPoliciesCreateInput = {
  bodyEn?: string | null;
  bodyEs?: string | null;
  createdAt?: any | null;
  description?: string | null;
  name: string;
  rowId?: any | null;
  slug?: string | null;
  type: PolicyType;
  updatedAt?: any | null;
  userPolicies?: UserPoliciesPolicyIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type UserPoliciesPolicyIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserPolicyNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserPolicyUserPoliciesPkeyConnect> | null;
  create?: ReadonlyArray<UserPoliciesPolicyIdFkeyUserPoliciesCreateInput> | null;
  deleteById?: ReadonlyArray<UserPolicyNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserPolicyUserPoliciesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<PolicyOnUserPolicyForUserPoliciesPolicyIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserPolicyOnUserPolicyForUserPoliciesPolicyIdFkeyUsingUserPoliciesPkeyUpdate> | null;
};
export type UserPoliciesPolicyIdFkeyUserPoliciesCreateInput = {
  acceptedAt?: any | null;
  createdAt?: any | null;
  policy?: UserPoliciesPolicyIdFkeyInput | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserPoliciesUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserPoliciesUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UserPoliciesUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UserPolicyOnUserPolicyForUserPoliciesUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUserPolicyForUserPoliciesUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUserPolicyForUserPoliciesUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserPoliciesUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserUploadsUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserUploadNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserUploadUserUploadsPkeyConnect> | null;
  create?: ReadonlyArray<UserUploadsUserIdFkeyUserUploadsCreateInput> | null;
  deleteById?: ReadonlyArray<UserUploadNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserUploadUserUploadsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserOnUserUploadForUserUploadsUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserUploadOnUserUploadForUserUploadsUserIdFkeyUsingUserUploadsPkeyUpdate> | null;
};
export type UserUploadNodeIdConnect = {
  id: string;
};
export type UserUploadUserUploadsPkeyConnect = {
  rowId: any;
};
export type UserUploadsUserIdFkeyUserUploadsCreateInput = {
  createdAt?: any | null;
  name: string;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserUploadsUploadIdFkeyInput | null;
  uploadId?: any | null;
  user?: UserUploadsUserIdFkeyInput | null;
};
export type UserUploadsUploadIdFkeyInput = {
  connectById?: UploadNodeIdConnect | null;
  connectByRowId?: UploadUploadsPkeyConnect | null;
  connectBySlug?: UploadUploadsSlugKeyConnect | null;
  create?: UserUploadsUploadIdFkeyUploadsCreateInput | null;
  deleteById?: UploadNodeIdDelete | null;
  deleteByRowId?: UploadUploadsPkeyDelete | null;
  deleteBySlug?: UploadUploadsSlugKeyDelete | null;
  updateById?: UserUploadOnUserUploadForUserUploadsUploadIdFkeyNodeIdUpdate | null;
  updateByRowId?: UploadOnUserUploadForUserUploadsUploadIdFkeyUsingUploadsPkeyUpdate | null;
  updateBySlug?: UploadOnUserUploadForUserUploadsUploadIdFkeyUsingUploadsSlugKeyUpdate | null;
};
export type UserUploadsUploadIdFkeyUploadsCreateInput = {
  bucket: string;
  createdAt?: any | null;
  filename: string;
  filesize: number;
  filetype: string;
  key: string;
  referralPartnerUploads?: ReferralPartnerUploadsUploadIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploaderId?: any | null;
  user?: UploadsUploaderIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUploadIdFkeyInverseInput | null;
  userUploads?: UserUploadsUploadIdFkeyInverseInput | null;
};
export type UserInsuranceUploadsUploadIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserInsuranceUploadNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserInsuranceUploadUserInsuranceUploadsPkeyConnect> | null;
  create?: ReadonlyArray<UserInsuranceUploadsUploadIdFkeyUserInsuranceUploadsCreateInput> | null;
  deleteById?: ReadonlyArray<UserInsuranceUploadNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserInsuranceUploadUserInsuranceUploadsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyUsingUserInsuranceUploadsPkeyUpdate> | null;
};
export type UserInsuranceUploadNodeIdConnect = {
  id: string;
};
export type UserInsuranceUploadUserInsuranceUploadsPkeyConnect = {
  rowId: any;
};
export type UserInsuranceUploadsUploadIdFkeyUserInsuranceUploadsCreateInput = {
  createdAt?: any | null;
  name: string;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserInsuranceUploadsUploadIdFkeyInput | null;
  userInsurance?: UserInsuranceUploadsUserInsuranceIdFkeyInput | null;
  userInsuranceId?: any | null;
};
export type UserInsuranceUploadsUploadIdFkeyInput = {
  connectById?: UploadNodeIdConnect | null;
  connectByRowId?: UploadUploadsPkeyConnect | null;
  connectBySlug?: UploadUploadsSlugKeyConnect | null;
  create?: UserInsuranceUploadsUploadIdFkeyUploadsCreateInput | null;
  deleteById?: UploadNodeIdDelete | null;
  deleteByRowId?: UploadUploadsPkeyDelete | null;
  deleteBySlug?: UploadUploadsSlugKeyDelete | null;
  updateById?: UserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyNodeIdUpdate | null;
  updateByRowId?: UploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyUsingUploadsPkeyUpdate | null;
  updateBySlug?: UploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyUsingUploadsSlugKeyUpdate | null;
};
export type UserInsuranceUploadsUploadIdFkeyUploadsCreateInput = {
  bucket: string;
  createdAt?: any | null;
  filename: string;
  filesize: number;
  filetype: string;
  key: string;
  referralPartnerUploads?: ReferralPartnerUploadsUploadIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploaderId?: any | null;
  user?: UploadsUploaderIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUploadIdFkeyInverseInput | null;
  userUploads?: UserUploadsUploadIdFkeyInverseInput | null;
};
export type UserUploadsUploadIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserUploadNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserUploadUserUploadsPkeyConnect> | null;
  create?: ReadonlyArray<UserUploadsUploadIdFkeyUserUploadsCreateInput> | null;
  deleteById?: ReadonlyArray<UserUploadNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserUploadUserUploadsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UploadOnUserUploadForUserUploadsUploadIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserUploadOnUserUploadForUserUploadsUploadIdFkeyUsingUserUploadsPkeyUpdate> | null;
};
export type UserUploadsUploadIdFkeyUserUploadsCreateInput = {
  createdAt?: any | null;
  name: string;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserUploadsUploadIdFkeyInput | null;
  user?: UserUploadsUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserUploadsUserIdFkeyInput = {
  connectById?: UserNodeIdConnect | null;
  connectByRowId?: UserUsersPkeyConnect | null;
  connectBySlug?: UserUsersSlugUkeyConnect | null;
  create?: UserUploadsUserIdFkeyUsersCreateInput | null;
  deleteById?: UserNodeIdDelete | null;
  deleteByRowId?: UserUsersPkeyDelete | null;
  deleteBySlug?: UserUsersSlugUkeyDelete | null;
  updateById?: UserUploadOnUserUploadForUserUploadsUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserOnUserUploadForUserUploadsUserIdFkeyUsingUsersPkeyUpdate | null;
  updateBySlug?: UserOnUserUploadForUserUploadsUserIdFkeyUsingUsersSlugUkeyUpdate | null;
};
export type UserUploadsUserIdFkeyUsersCreateInput = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email: string;
  firstName: string;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName: string;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserNodeIdDelete = {
  id: string;
};
export type UserUsersPkeyDelete = {
  rowId: any;
};
export type UserUsersSlugUkeyDelete = {
  slug: string;
};
export type UserUploadOnUserUploadForUserUploadsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserUploadForUserUploadsUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserUploadForUserUploadsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserUploadForUserUploadsUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserUploadForUserUploadsUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUserUploadForUserUploadsUserIdFkeyPatch;
  slug: string;
};
export type UserUploadNodeIdDelete = {
  id: string;
};
export type UserUploadUserUploadsPkeyDelete = {
  rowId: any;
};
export type UploadOnUserUploadForUserUploadsUploadIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserUploadPatch;
};
export type UserUploadPatch = {
  createdAt?: any | null;
  name?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserUploadsUploadIdFkeyInput | null;
  uploadId?: any | null;
  user?: UserUploadsUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserUploadOnUserUploadForUserUploadsUploadIdFkeyUsingUserUploadsPkeyUpdate = {
  patch: updateUserUploadOnUserUploadForUserUploadsUploadIdFkeyPatch;
  rowId: any;
};
export type updateUserUploadOnUserUploadForUserUploadsUploadIdFkeyPatch = {
  createdAt?: any | null;
  name?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserUploadsUploadIdFkeyInput | null;
  user?: UserUploadsUserIdFkeyInput | null;
  userId?: any | null;
};
export type UploadNodeIdDelete = {
  id: string;
};
export type UploadUploadsPkeyDelete = {
  rowId: any;
};
export type UploadUploadsSlugKeyDelete = {
  slug: string;
};
export type UserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyNodeIdUpdate = {
  id: string;
  patch: UploadPatch;
};
export type UploadPatch = {
  bucket?: string | null;
  createdAt?: any | null;
  filename?: string | null;
  filesize?: number | null;
  filetype?: string | null;
  key?: string | null;
  referralPartnerUploads?: ReferralPartnerUploadsUploadIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploaderId?: any | null;
  user?: UploadsUploaderIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUploadIdFkeyInverseInput | null;
  userUploads?: UserUploadsUploadIdFkeyInverseInput | null;
};
export type UploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyUsingUploadsPkeyUpdate = {
  patch: updateUploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyPatch;
  rowId: any;
};
export type updateUploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyPatch = {
  bucket?: string | null;
  createdAt?: any | null;
  filename?: string | null;
  filesize?: number | null;
  filetype?: string | null;
  key?: string | null;
  referralPartnerUploads?: ReferralPartnerUploadsUploadIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploaderId?: any | null;
  user?: UploadsUploaderIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUploadIdFkeyInverseInput | null;
  userUploads?: UserUploadsUploadIdFkeyInverseInput | null;
};
export type UploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyUsingUploadsSlugKeyUpdate = {
  patch: updateUploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyPatch;
  slug: string;
};
export type UserInsuranceUploadsUserInsuranceIdFkeyInput = {
  connectById?: UserInsuranceNodeIdConnect | null;
  connectByRowId?: UserInsuranceUserInsurancesPkeyConnect | null;
  create?: UserInsuranceUploadsUserInsuranceIdFkeyUserInsurancesCreateInput | null;
  deleteById?: UserInsuranceNodeIdDelete | null;
  deleteByRowId?: UserInsuranceUserInsurancesPkeyDelete | null;
  updateById?: UserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyNodeIdUpdate | null;
  updateByRowId?: UserInsuranceOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyUsingUserInsurancesPkeyUpdate | null;
};
export type UserInsuranceUploadsUserInsuranceIdFkeyUserInsurancesCreateInput = {
  category?: UserInsuranceCategory | null;
  charmId?: string | null;
  comments?: string | null;
  createdAt?: any | null;
  groupId?: string | null;
  insuranceProvider?: UserInsurancesInsuranceProviderIdFkeyInput | null;
  insuranceProviderId?: any | null;
  memberId: string;
  policyHolderAddressCity?: string | null;
  policyHolderAddressCountry?: string | null;
  policyHolderAddressPostalCode?: string | null;
  policyHolderAddressStateOrProvince?: string | null;
  policyHolderAddressStreet1?: string | null;
  policyHolderAddressStreet2?: string | null;
  policyHolderBirthdateAt?: any | null;
  policyHolderFirstName?: string | null;
  policyHolderGender?: string | null;
  policyHolderHomePhone?: string | null;
  policyHolderLastName?: string | null;
  policyHolderMobilePhone?: string | null;
  policyHolderRelationship: UserInsurancePolicyHolderRelationship;
  policyHolderSsn?: string | null;
  rowId?: any | null;
  type: UserInsuranceType;
  updatedAt?: any | null;
  user?: UserInsurancesUserIdFkeyInput | null;
  userId?: any | null;
  userInsuranceUploads?: UserInsuranceUploadsUserInsuranceIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type UserInsuranceUploadsUserInsuranceIdFkeyInverseInput = {
  connectById?: ReadonlyArray<UserInsuranceUploadNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<UserInsuranceUploadUserInsuranceUploadsPkeyConnect> | null;
  create?: ReadonlyArray<UserInsuranceUploadsUserInsuranceIdFkeyUserInsuranceUploadsCreateInput> | null;
  deleteById?: ReadonlyArray<UserInsuranceUploadNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<UserInsuranceUploadUserInsuranceUploadsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<UserInsuranceOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<UserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyUsingUserInsuranceUploadsPkeyUpdate> | null;
};
export type UserInsuranceUploadsUserInsuranceIdFkeyUserInsuranceUploadsCreateInput = {
  createdAt?: any | null;
  name: string;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserInsuranceUploadsUploadIdFkeyInput | null;
  uploadId?: any | null;
  userInsurance?: UserInsuranceUploadsUserInsuranceIdFkeyInput | null;
};
export type UserInsuranceUploadNodeIdDelete = {
  id: string;
};
export type UserInsuranceUploadUserInsuranceUploadsPkeyDelete = {
  rowId: any;
};
export type UserInsuranceOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserInsuranceUploadPatch;
};
export type UserInsuranceUploadPatch = {
  createdAt?: any | null;
  name?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserInsuranceUploadsUploadIdFkeyInput | null;
  uploadId?: any | null;
  userInsurance?: UserInsuranceUploadsUserInsuranceIdFkeyInput | null;
  userInsuranceId?: any | null;
};
export type UserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyUsingUserInsuranceUploadsPkeyUpdate = {
  patch: updateUserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyPatch;
  rowId: any;
};
export type updateUserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyPatch = {
  createdAt?: any | null;
  name?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserInsuranceUploadsUploadIdFkeyInput | null;
  uploadId?: any | null;
  userInsurance?: UserInsuranceUploadsUserInsuranceIdFkeyInput | null;
};
export type UserInsuranceNodeIdDelete = {
  id: string;
};
export type UserInsuranceUserInsurancesPkeyDelete = {
  rowId: any;
};
export type UserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserInsurancePatch;
};
export type UserInsurancePatch = {
  category?: UserInsuranceCategory | null;
  charmId?: string | null;
  comments?: string | null;
  createdAt?: any | null;
  groupId?: string | null;
  insuranceProvider?: UserInsurancesInsuranceProviderIdFkeyInput | null;
  insuranceProviderId?: any | null;
  memberId?: string | null;
  policyHolderAddressCity?: string | null;
  policyHolderAddressCountry?: string | null;
  policyHolderAddressPostalCode?: string | null;
  policyHolderAddressStateOrProvince?: string | null;
  policyHolderAddressStreet1?: string | null;
  policyHolderAddressStreet2?: string | null;
  policyHolderBirthdateAt?: any | null;
  policyHolderFirstName?: string | null;
  policyHolderGender?: string | null;
  policyHolderHomePhone?: string | null;
  policyHolderLastName?: string | null;
  policyHolderMobilePhone?: string | null;
  policyHolderRelationship?: UserInsurancePolicyHolderRelationship | null;
  policyHolderSsn?: string | null;
  rowId?: any | null;
  type?: UserInsuranceType | null;
  updatedAt?: any | null;
  user?: UserInsurancesUserIdFkeyInput | null;
  userId?: any | null;
  userInsuranceUploads?: UserInsuranceUploadsUserInsuranceIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type UserInsuranceOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyUsingUserInsurancesPkeyUpdate = {
  patch: updateUserInsuranceOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyPatch;
  rowId: any;
};
export type updateUserInsuranceOnUserInsuranceUploadForUserInsuranceUploadsUserInsuranceIdFkeyPatch = {
  category?: UserInsuranceCategory | null;
  charmId?: string | null;
  comments?: string | null;
  createdAt?: any | null;
  groupId?: string | null;
  insuranceProvider?: UserInsurancesInsuranceProviderIdFkeyInput | null;
  insuranceProviderId?: any | null;
  memberId?: string | null;
  policyHolderAddressCity?: string | null;
  policyHolderAddressCountry?: string | null;
  policyHolderAddressPostalCode?: string | null;
  policyHolderAddressStateOrProvince?: string | null;
  policyHolderAddressStreet1?: string | null;
  policyHolderAddressStreet2?: string | null;
  policyHolderBirthdateAt?: any | null;
  policyHolderFirstName?: string | null;
  policyHolderGender?: string | null;
  policyHolderHomePhone?: string | null;
  policyHolderLastName?: string | null;
  policyHolderMobilePhone?: string | null;
  policyHolderRelationship?: UserInsurancePolicyHolderRelationship | null;
  policyHolderSsn?: string | null;
  rowId?: any | null;
  type?: UserInsuranceType | null;
  updatedAt?: any | null;
  user?: UserInsurancesUserIdFkeyInput | null;
  userId?: any | null;
  userInsuranceUploads?: UserInsuranceUploadsUserInsuranceIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type UploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserInsuranceUploadPatch;
};
export type UserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyUsingUserInsuranceUploadsPkeyUpdate = {
  patch: updateUserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyPatch;
  rowId: any;
};
export type updateUserInsuranceUploadOnUserInsuranceUploadForUserInsuranceUploadsUploadIdFkeyPatch = {
  createdAt?: any | null;
  name?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserInsuranceUploadsUploadIdFkeyInput | null;
  userInsurance?: UserInsuranceUploadsUserInsuranceIdFkeyInput | null;
  userInsuranceId?: any | null;
};
export type UserUploadOnUserUploadForUserUploadsUploadIdFkeyNodeIdUpdate = {
  id: string;
  patch: UploadPatch;
};
export type UploadOnUserUploadForUserUploadsUploadIdFkeyUsingUploadsPkeyUpdate = {
  patch: updateUploadOnUserUploadForUserUploadsUploadIdFkeyPatch;
  rowId: any;
};
export type updateUploadOnUserUploadForUserUploadsUploadIdFkeyPatch = {
  bucket?: string | null;
  createdAt?: any | null;
  filename?: string | null;
  filesize?: number | null;
  filetype?: string | null;
  key?: string | null;
  referralPartnerUploads?: ReferralPartnerUploadsUploadIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploaderId?: any | null;
  user?: UploadsUploaderIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUploadIdFkeyInverseInput | null;
  userUploads?: UserUploadsUploadIdFkeyInverseInput | null;
};
export type UploadOnUserUploadForUserUploadsUploadIdFkeyUsingUploadsSlugKeyUpdate = {
  patch: updateUploadOnUserUploadForUserUploadsUploadIdFkeyPatch;
  slug: string;
};
export type UserOnUserUploadForUserUploadsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserUploadPatch;
};
export type UserUploadOnUserUploadForUserUploadsUserIdFkeyUsingUserUploadsPkeyUpdate = {
  patch: updateUserUploadOnUserUploadForUserUploadsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserUploadOnUserUploadForUserUploadsUserIdFkeyPatch = {
  createdAt?: any | null;
  name?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: UserUploadsUploadIdFkeyInput | null;
  uploadId?: any | null;
  user?: UserUploadsUserIdFkeyInput | null;
};
export type UserPolicyOnUserPolicyForUserPoliciesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserPolicyForUserPoliciesUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserPolicyForUserPoliciesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserPolicyForUserPoliciesUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserPolicyForUserPoliciesUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUserPolicyForUserPoliciesUserIdFkeyPatch;
  slug: string;
};
export type UserPolicyNodeIdDelete = {
  id: string;
};
export type UserPolicyUserPoliciesPkeyDelete = {
  rowId: any;
};
export type PolicyOnUserPolicyForUserPoliciesPolicyIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPolicyPatch;
};
export type UserPolicyPatch = {
  acceptedAt?: any | null;
  createdAt?: any | null;
  policy?: UserPoliciesPolicyIdFkeyInput | null;
  policyId?: any | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserPoliciesUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserPolicyOnUserPolicyForUserPoliciesPolicyIdFkeyUsingUserPoliciesPkeyUpdate = {
  patch: updateUserPolicyOnUserPolicyForUserPoliciesPolicyIdFkeyPatch;
  rowId: any;
};
export type updateUserPolicyOnUserPolicyForUserPoliciesPolicyIdFkeyPatch = {
  acceptedAt?: any | null;
  createdAt?: any | null;
  policy?: UserPoliciesPolicyIdFkeyInput | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserPoliciesUserIdFkeyInput | null;
  userId?: any | null;
};
export type PolicyNodeIdDelete = {
  id: string;
};
export type PolicyPoliciesPkeyDelete = {
  rowId: any;
};
export type PolicyPoliciesSlugKeyDelete = {
  slug: string;
};
export type UserPolicyOnUserPolicyForUserPoliciesPolicyIdFkeyNodeIdUpdate = {
  id: string;
  patch: PolicyPatch;
};
export type PolicyPatch = {
  bodyEn?: string | null;
  bodyEs?: string | null;
  createdAt?: any | null;
  description?: string | null;
  name?: string | null;
  rowId?: any | null;
  slug?: string | null;
  type?: PolicyType | null;
  updatedAt?: any | null;
  userPolicies?: UserPoliciesPolicyIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type PolicyOnUserPolicyForUserPoliciesPolicyIdFkeyUsingPoliciesPkeyUpdate = {
  patch: updatePolicyOnUserPolicyForUserPoliciesPolicyIdFkeyPatch;
  rowId: any;
};
export type updatePolicyOnUserPolicyForUserPoliciesPolicyIdFkeyPatch = {
  bodyEn?: string | null;
  bodyEs?: string | null;
  createdAt?: any | null;
  description?: string | null;
  name?: string | null;
  rowId?: any | null;
  slug?: string | null;
  type?: PolicyType | null;
  updatedAt?: any | null;
  userPolicies?: UserPoliciesPolicyIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type PolicyOnUserPolicyForUserPoliciesPolicyIdFkeyUsingPoliciesSlugKeyUpdate = {
  patch: updatePolicyOnUserPolicyForUserPoliciesPolicyIdFkeyPatch;
  slug: string;
};
export type UserOnUserPolicyForUserPoliciesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPolicyPatch;
};
export type UserPolicyOnUserPolicyForUserPoliciesUserIdFkeyUsingUserPoliciesPkeyUpdate = {
  patch: updateUserPolicyOnUserPolicyForUserPoliciesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserPolicyOnUserPolicyForUserPoliciesUserIdFkeyPatch = {
  acceptedAt?: any | null;
  createdAt?: any | null;
  policy?: UserPoliciesPolicyIdFkeyInput | null;
  policyId?: any | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserPoliciesUserIdFkeyInput | null;
};
export type UserPhoneOnUserPhoneForUserPhonesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserPhoneForUserPhonesUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserPhoneForUserPhonesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserPhoneForUserPhonesUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserPhoneForUserPhonesUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUserPhoneForUserPhonesUserIdFkeyPatch;
  slug: string;
};
export type UserPhoneNodeIdDelete = {
  id: string;
};
export type UserPhoneUserPhonesPkeyDelete = {
  rowId: any;
};
export type UserOnUserPhoneForUserPhonesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPhonePatch;
};
export type UserPhonePatch = {
  createdAt?: any | null;
  isPrimary?: boolean | null;
  phone?: string | null;
  rowId?: any | null;
  type?: UserPhonesType | null;
  updatedAt?: any | null;
  user?: UserPhonesUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserPhoneOnUserPhoneForUserPhonesUserIdFkeyUsingUserPhonesPkeyUpdate = {
  patch: updateUserPhoneOnUserPhoneForUserPhonesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserPhoneOnUserPhoneForUserPhonesUserIdFkeyPatch = {
  createdAt?: any | null;
  isPrimary?: boolean | null;
  phone?: string | null;
  rowId?: any | null;
  type?: UserPhonesType | null;
  updatedAt?: any | null;
  user?: UserPhonesUserIdFkeyInput | null;
};
export type UserPaymentProviderOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyPatch;
  slug: string;
};
export type UserPaymentProviderNodeIdDelete = {
  id: string;
};
export type UserPaymentProviderUserPaymentProvidersPkeyDelete = {
  rowId: any;
};
export type UserPaymentProviderUserPaymentProvidersSlugKeyDelete = {
  slug: string;
};
export type UserOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPaymentProviderPatch;
};
export type UserPaymentProviderPatch = {
  createdAt?: any | null;
  isActive?: boolean | null;
  isPrimary?: boolean | null;
  provider?: PaymentProviders | null;
  providerId?: string | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: UserPaymentProvidersUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserPaymentProviderOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyUsingUserPaymentProvidersPkeyUpdate = {
  patch: updateUserPaymentProviderOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyPatch;
  rowId: any;
};
export type updateUserPaymentProviderOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyPatch = {
  createdAt?: any | null;
  isActive?: boolean | null;
  isPrimary?: boolean | null;
  provider?: PaymentProviders | null;
  providerId?: string | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: UserPaymentProvidersUserIdFkeyInput | null;
};
export type UserPaymentProviderOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyUsingUserPaymentProvidersSlugKeyUpdate = {
  patch: updateUserPaymentProviderOnUserPaymentProviderForUserPaymentProvidersUserIdFkeyPatch;
  slug: string;
};
export type UserPaymentMethodOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyPatch;
  slug: string;
};
export type UserPaymentMethodNodeIdDelete = {
  id: string;
};
export type UserPaymentMethodUserPaymentMethodsPkeyDelete = {
  rowId: any;
};
export type UserPaymentMethodUserPaymentMethodsSlugKeyDelete = {
  slug: string;
};
export type UserOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPaymentMethodPatch;
};
export type UserPaymentMethodPatch = {
  createdAt?: any | null;
  isActive?: boolean | null;
  isPrimary?: boolean | null;
  methodId?: string | null;
  provider?: PaymentProviders | null;
  providerId?: string | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: UserPaymentMethodsUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserPaymentMethodOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyUsingUserPaymentMethodsPkeyUpdate = {
  patch: updateUserPaymentMethodOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserPaymentMethodOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyPatch = {
  createdAt?: any | null;
  isActive?: boolean | null;
  isPrimary?: boolean | null;
  methodId?: string | null;
  provider?: PaymentProviders | null;
  providerId?: string | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: UserPaymentMethodsUserIdFkeyInput | null;
};
export type UserPaymentMethodOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyUsingUserPaymentMethodsSlugKeyUpdate = {
  patch: updateUserPaymentMethodOnUserPaymentMethodForUserPaymentMethodsUserIdFkeyPatch;
  slug: string;
};
export type UserLanguageOnUserLanguageForUserLanguagesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserLanguageForUserLanguagesUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserLanguageForUserLanguagesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserLanguageForUserLanguagesUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserLanguageForUserLanguagesUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUserLanguageForUserLanguagesUserIdFkeyPatch;
  slug: string;
};
export type UserLanguageNodeIdDelete = {
  id: string;
};
export type UserLanguageUserLanguagesPkeyDelete = {
  rowId: any;
};
export type UserOnUserLanguageForUserLanguagesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserLanguagePatch;
};
export type UserLanguagePatch = {
  createdAt?: any | null;
  isPrimary?: boolean | null;
  language?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserLanguagesUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserLanguageOnUserLanguageForUserLanguagesUserIdFkeyUsingUserLanguagesPkeyUpdate = {
  patch: updateUserLanguageOnUserLanguageForUserLanguagesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserLanguageOnUserLanguageForUserLanguagesUserIdFkeyPatch = {
  createdAt?: any | null;
  isPrimary?: boolean | null;
  language?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserLanguagesUserIdFkeyInput | null;
};
export type UserInsuranceOnUserInsuranceForUserInsurancesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserInsuranceForUserInsurancesUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserInsuranceForUserInsurancesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserInsuranceForUserInsurancesUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserInsuranceForUserInsurancesUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUserInsuranceForUserInsurancesUserIdFkeyPatch;
  slug: string;
};
export type InsuranceProviderOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserInsurancePatch;
};
export type UserInsuranceOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyUsingUserInsurancesPkeyUpdate = {
  patch: updateUserInsuranceOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyPatch;
  rowId: any;
};
export type updateUserInsuranceOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyPatch = {
  category?: UserInsuranceCategory | null;
  charmId?: string | null;
  comments?: string | null;
  createdAt?: any | null;
  groupId?: string | null;
  insuranceProvider?: UserInsurancesInsuranceProviderIdFkeyInput | null;
  memberId?: string | null;
  policyHolderAddressCity?: string | null;
  policyHolderAddressCountry?: string | null;
  policyHolderAddressPostalCode?: string | null;
  policyHolderAddressStateOrProvince?: string | null;
  policyHolderAddressStreet1?: string | null;
  policyHolderAddressStreet2?: string | null;
  policyHolderBirthdateAt?: any | null;
  policyHolderFirstName?: string | null;
  policyHolderGender?: string | null;
  policyHolderHomePhone?: string | null;
  policyHolderLastName?: string | null;
  policyHolderMobilePhone?: string | null;
  policyHolderRelationship?: UserInsurancePolicyHolderRelationship | null;
  policyHolderSsn?: string | null;
  rowId?: any | null;
  type?: UserInsuranceType | null;
  updatedAt?: any | null;
  user?: UserInsurancesUserIdFkeyInput | null;
  userId?: any | null;
  userInsuranceUploads?: UserInsuranceUploadsUserInsuranceIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type InsuranceProviderNodeIdDelete = {
  id: string;
};
export type InsuranceProviderInsuranceProvidersPkeyDelete = {
  rowId: any;
};
export type InsuranceProviderInsuranceProvidersSlugKeyDelete = {
  slug: string;
};
export type UserInsuranceOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyNodeIdUpdate = {
  id: string;
  patch: InsuranceProviderPatch;
};
export type InsuranceProviderPatch = {
  charmId?: string | null;
  createdAt?: any | null;
  name?: string | null;
  payerId?: string | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  userInsurances?: UserInsurancesInsuranceProviderIdFkeyInverseInput | null;
};
export type InsuranceProviderOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyUsingInsuranceProvidersPkeyUpdate = {
  patch: updateInsuranceProviderOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyPatch;
  rowId: any;
};
export type updateInsuranceProviderOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyPatch = {
  charmId?: string | null;
  createdAt?: any | null;
  name?: string | null;
  payerId?: string | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  userInsurances?: UserInsurancesInsuranceProviderIdFkeyInverseInput | null;
};
export type InsuranceProviderOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyUsingInsuranceProvidersSlugKeyUpdate = {
  patch: updateInsuranceProviderOnUserInsuranceForUserInsurancesInsuranceProviderIdFkeyPatch;
  slug: string;
};
export type UserOnUserInsuranceForUserInsurancesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserInsurancePatch;
};
export type UserInsuranceOnUserInsuranceForUserInsurancesUserIdFkeyUsingUserInsurancesPkeyUpdate = {
  patch: updateUserInsuranceOnUserInsuranceForUserInsurancesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserInsuranceOnUserInsuranceForUserInsurancesUserIdFkeyPatch = {
  category?: UserInsuranceCategory | null;
  charmId?: string | null;
  comments?: string | null;
  createdAt?: any | null;
  groupId?: string | null;
  insuranceProvider?: UserInsurancesInsuranceProviderIdFkeyInput | null;
  insuranceProviderId?: any | null;
  memberId?: string | null;
  policyHolderAddressCity?: string | null;
  policyHolderAddressCountry?: string | null;
  policyHolderAddressPostalCode?: string | null;
  policyHolderAddressStateOrProvince?: string | null;
  policyHolderAddressStreet1?: string | null;
  policyHolderAddressStreet2?: string | null;
  policyHolderBirthdateAt?: any | null;
  policyHolderFirstName?: string | null;
  policyHolderGender?: string | null;
  policyHolderHomePhone?: string | null;
  policyHolderLastName?: string | null;
  policyHolderMobilePhone?: string | null;
  policyHolderRelationship?: UserInsurancePolicyHolderRelationship | null;
  policyHolderSsn?: string | null;
  rowId?: any | null;
  type?: UserInsuranceType | null;
  updatedAt?: any | null;
  user?: UserInsurancesUserIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUserInsuranceIdFkeyInverseInput | null;
  validAt?: any | null;
};
export type UserChildOnUserChildForUserChildrenUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserChildForUserChildrenUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserChildForUserChildrenUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserChildForUserChildrenUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserChildForUserChildrenUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUserChildForUserChildrenUserIdFkeyPatch;
  slug: string;
};
export type UserChildNodeIdDelete = {
  id: string;
};
export type UserChildUserChildrenPkeyDelete = {
  rowId: any;
};
export type AppointmentOnAppointmentForAppointmentsUserChildIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserChildPatch;
};
export type UserChildPatch = {
  appointments?: AppointmentsUserChildIdFkeyInverseInput | null;
  birthdateAt?: any | null;
  createdAt?: any | null;
  dueAt?: any | null;
  name?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserChildrenUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserChildOnAppointmentForAppointmentsUserChildIdFkeyUsingUserChildrenPkeyUpdate = {
  patch: updateUserChildOnAppointmentForAppointmentsUserChildIdFkeyPatch;
  rowId: any;
};
export type updateUserChildOnAppointmentForAppointmentsUserChildIdFkeyPatch = {
  appointments?: AppointmentsUserChildIdFkeyInverseInput | null;
  birthdateAt?: any | null;
  createdAt?: any | null;
  dueAt?: any | null;
  name?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserChildrenUserIdFkeyInput | null;
  userId?: any | null;
};
export type AppointmentNodeIdDelete = {
  id: string;
};
export type AppointmentAppointmentsPkeyDelete = {
  rowId: any;
};
export type ServiceOnAppointmentForAppointmentsServiceIdFkeyNodeIdUpdate = {
  id: string;
  patch: AppointmentPatch;
};
export type AppointmentPatch = {
  charmId?: string | null;
  clinician?: AppointmentsClinicianIdFkeyInput | null;
  clinicianId?: any | null;
  createdAt?: any | null;
  endsAt?: any | null;
  notes?: string | null;
  patient?: AppointmentsPatientIdFkeyInput | null;
  patientId?: any | null;
  rowId?: any | null;
  selectionMethod?: AppointmentsSelectionMethod | null;
  service?: AppointmentsServiceIdFkeyInput | null;
  serviceId?: any | null;
  startsAt?: any | null;
  status?: AppointmentsStatus | null;
  updatedAt?: any | null;
  userChild?: AppointmentsUserChildIdFkeyInput | null;
  userChildId?: any | null;
};
export type AppointmentOnAppointmentForAppointmentsServiceIdFkeyUsingAppointmentsPkeyUpdate = {
  patch: updateAppointmentOnAppointmentForAppointmentsServiceIdFkeyPatch;
  rowId: any;
};
export type updateAppointmentOnAppointmentForAppointmentsServiceIdFkeyPatch = {
  charmId?: string | null;
  clinician?: AppointmentsClinicianIdFkeyInput | null;
  clinicianId?: any | null;
  createdAt?: any | null;
  endsAt?: any | null;
  notes?: string | null;
  patient?: AppointmentsPatientIdFkeyInput | null;
  patientId?: any | null;
  rowId?: any | null;
  selectionMethod?: AppointmentsSelectionMethod | null;
  service?: AppointmentsServiceIdFkeyInput | null;
  startsAt?: any | null;
  status?: AppointmentsStatus | null;
  updatedAt?: any | null;
  userChild?: AppointmentsUserChildIdFkeyInput | null;
  userChildId?: any | null;
};
export type IntervalInput = {
  days?: number | null;
  hours?: number | null;
  minutes?: number | null;
  months?: number | null;
  seconds?: number | null;
  years?: number | null;
};
export type ServiceNodeIdDelete = {
  id: string;
};
export type ServiceServicesPkeyDelete = {
  rowId: any;
};
export type ServiceServicesSlugKeyDelete = {
  slug: string;
};
export type AppointmentOnAppointmentForAppointmentsServiceIdFkeyNodeIdUpdate = {
  id: string;
  patch: ServicePatch;
};
export type ServicePatch = {
  appointments?: AppointmentsServiceIdFkeyInverseInput | null;
  charmServiceId?: string | null;
  createdAt?: any | null;
  duration?: IntervalInput | null;
  isActive?: boolean | null;
  name?: string | null;
  price?: any | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
};
export type ServiceOnAppointmentForAppointmentsServiceIdFkeyUsingServicesPkeyUpdate = {
  patch: updateServiceOnAppointmentForAppointmentsServiceIdFkeyPatch;
  rowId: any;
};
export type updateServiceOnAppointmentForAppointmentsServiceIdFkeyPatch = {
  appointments?: AppointmentsServiceIdFkeyInverseInput | null;
  charmServiceId?: string | null;
  createdAt?: any | null;
  duration?: IntervalInput | null;
  isActive?: boolean | null;
  name?: string | null;
  price?: any | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
};
export type ServiceOnAppointmentForAppointmentsServiceIdFkeyUsingServicesSlugKeyUpdate = {
  patch: updateServiceOnAppointmentForAppointmentsServiceIdFkeyPatch;
  slug: string;
};
export type UserChildOnAppointmentForAppointmentsUserChildIdFkeyNodeIdUpdate = {
  id: string;
  patch: AppointmentPatch;
};
export type AppointmentOnAppointmentForAppointmentsUserChildIdFkeyUsingAppointmentsPkeyUpdate = {
  patch: updateAppointmentOnAppointmentForAppointmentsUserChildIdFkeyPatch;
  rowId: any;
};
export type updateAppointmentOnAppointmentForAppointmentsUserChildIdFkeyPatch = {
  charmId?: string | null;
  clinician?: AppointmentsClinicianIdFkeyInput | null;
  clinicianId?: any | null;
  createdAt?: any | null;
  endsAt?: any | null;
  notes?: string | null;
  patient?: AppointmentsPatientIdFkeyInput | null;
  patientId?: any | null;
  rowId?: any | null;
  selectionMethod?: AppointmentsSelectionMethod | null;
  service?: AppointmentsServiceIdFkeyInput | null;
  serviceId?: any | null;
  startsAt?: any | null;
  status?: AppointmentsStatus | null;
  updatedAt?: any | null;
  userChild?: AppointmentsUserChildIdFkeyInput | null;
};
export type UserOnUserChildForUserChildrenUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserChildPatch;
};
export type UserChildOnUserChildForUserChildrenUserIdFkeyUsingUserChildrenPkeyUpdate = {
  patch: updateUserChildOnUserChildForUserChildrenUserIdFkeyPatch;
  rowId: any;
};
export type updateUserChildOnUserChildForUserChildrenUserIdFkeyPatch = {
  appointments?: AppointmentsUserChildIdFkeyInverseInput | null;
  birthdateAt?: any | null;
  createdAt?: any | null;
  dueAt?: any | null;
  name?: string | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserChildrenUserIdFkeyInput | null;
};
export type UserAddressOnUserAddressForUserAddressesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUserAddressForUserAddressesUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUserAddressForUserAddressesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUserAddressForUserAddressesUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUserAddressForUserAddressesUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUserAddressForUserAddressesUserIdFkeyPatch;
  slug: string;
};
export type UserAddressNodeIdDelete = {
  id: string;
};
export type UserAddressUserAddressesPkeyDelete = {
  rowId: any;
};
export type AddressOnUserAddressForUserAddressesAddressIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserAddressPatch;
};
export type UserAddressPatch = {
  address?: UserAddressesAddressIdFkeyInput | null;
  addressId?: any | null;
  createdAt?: any | null;
  isPrimary?: boolean | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserAddressesUserIdFkeyInput | null;
  userId?: any | null;
};
export type UserAddressOnUserAddressForUserAddressesAddressIdFkeyUsingUserAddressesPkeyUpdate = {
  patch: updateUserAddressOnUserAddressForUserAddressesAddressIdFkeyPatch;
  rowId: any;
};
export type updateUserAddressOnUserAddressForUserAddressesAddressIdFkeyPatch = {
  address?: UserAddressesAddressIdFkeyInput | null;
  createdAt?: any | null;
  isPrimary?: boolean | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserAddressesUserIdFkeyInput | null;
  userId?: any | null;
};
export type AddressNodeIdDelete = {
  id: string;
};
export type AddressAddressesPkeyDelete = {
  rowId: any;
};
export type UserAddressOnUserAddressForUserAddressesAddressIdFkeyNodeIdUpdate = {
  id: string;
  patch: AddressPatch;
};
export type AddressPatch = {
  city?: string | null;
  country?: string | null;
  createdAt?: any | null;
  postalCode?: string | null;
  rowId?: any | null;
  stateOrProvince?: string | null;
  street1?: string | null;
  street2?: string | null;
  updatedAt?: any | null;
  userAddresses?: UserAddressesAddressIdFkeyInverseInput | null;
};
export type AddressOnUserAddressForUserAddressesAddressIdFkeyUsingAddressesPkeyUpdate = {
  patch: updateAddressOnUserAddressForUserAddressesAddressIdFkeyPatch;
  rowId: any;
};
export type updateAddressOnUserAddressForUserAddressesAddressIdFkeyPatch = {
  city?: string | null;
  country?: string | null;
  createdAt?: any | null;
  postalCode?: string | null;
  rowId?: any | null;
  stateOrProvince?: string | null;
  street1?: string | null;
  street2?: string | null;
  updatedAt?: any | null;
  userAddresses?: UserAddressesAddressIdFkeyInverseInput | null;
};
export type UserOnUserAddressForUserAddressesUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserAddressPatch;
};
export type UserAddressOnUserAddressForUserAddressesUserIdFkeyUsingUserAddressesPkeyUpdate = {
  patch: updateUserAddressOnUserAddressForUserAddressesUserIdFkeyPatch;
  rowId: any;
};
export type updateUserAddressOnUserAddressForUserAddressesUserIdFkeyPatch = {
  address?: UserAddressesAddressIdFkeyInput | null;
  addressId?: any | null;
  createdAt?: any | null;
  isPrimary?: boolean | null;
  rowId?: any | null;
  updatedAt?: any | null;
  user?: UserAddressesUserIdFkeyInput | null;
};
export type UploadOnUploadForUploadsUploaderIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnUploadForUploadsUploaderIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnUploadForUploadsUploaderIdFkeyPatch;
  rowId: any;
};
export type updateUserOnUploadForUploadsUploaderIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnUploadForUploadsUploaderIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnUploadForUploadsUploaderIdFkeyPatch;
  slug: string;
};
export type ReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyNodeIdUpdate = {
  id: string;
  patch: UploadPatch;
};
export type UploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyUsingUploadsPkeyUpdate = {
  patch: updateUploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyPatch;
  rowId: any;
};
export type updateUploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyPatch = {
  bucket?: string | null;
  createdAt?: any | null;
  filename?: string | null;
  filesize?: number | null;
  filetype?: string | null;
  key?: string | null;
  referralPartnerUploads?: ReferralPartnerUploadsUploadIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploaderId?: any | null;
  user?: UploadsUploaderIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUploadIdFkeyInverseInput | null;
  userUploads?: UserUploadsUploadIdFkeyInverseInput | null;
};
export type UploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyUsingUploadsSlugKeyUpdate = {
  patch: updateUploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyPatch;
  slug: string;
};
export type ReferralPartnerUploadNodeIdDelete = {
  id: string;
};
export type ReferralPartnerUploadReferralPartnerUploadsPkeyDelete = {
  rowId: any;
};
export type ReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: ReferralPartnerUploadPatch;
};
export type ReferralPartnerUploadPatch = {
  createdAt?: any | null;
  name?: string | null;
  referralPartner?: ReferralPartnerUploadsReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: ReferralPartnerUploadsUploadIdFkeyInput | null;
  uploadId?: any | null;
};
export type ReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyUsingReferralPartnerUploadsPkeyUpdate = {
  patch: updateReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyPatch;
  rowId: any;
};
export type updateReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyPatch = {
  createdAt?: any | null;
  name?: string | null;
  referralPartner?: ReferralPartnerUploadsReferralPartnerIdFkeyInput | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: ReferralPartnerUploadsUploadIdFkeyInput | null;
  uploadId?: any | null;
};
export type ReferralPartnerNodeIdDelete = {
  id: string;
};
export type ReferralPartnerReferralPartnerPkeyDelete = {
  rowId: any;
};
export type ReferralPartnerReferralPartnerUniqueSlugDelete = {
  slug: string;
};
export type ReferralPartnerReferralPartnerSlugPublicDelete = {
  slugPublic: string;
};
export type ReferralPartnerSourceOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: ReferralPartnerPatch;
};
export type ReferralPartnerPatch = {
  config?: any | null;
  createdAt?: any | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerIdFkeyInverseInput | null;
  name?: string | null;
  referralPartnerSources?: ReferralPartnerSourcesReferralPartnerIdFkeyInverseInput | null;
  referralPartnerUploads?: ReferralPartnerUploadsReferralPartnerIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  type?: ReferralPartnerType | null;
  updatedAt?: any | null;
};
export type ReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyUsingReferralPartnerPkeyUpdate = {
  patch: updateReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyPatch;
  rowId: any;
};
export type updateReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyPatch = {
  config?: any | null;
  createdAt?: any | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerIdFkeyInverseInput | null;
  name?: string | null;
  referralPartnerSources?: ReferralPartnerSourcesReferralPartnerIdFkeyInverseInput | null;
  referralPartnerUploads?: ReferralPartnerUploadsReferralPartnerIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  type?: ReferralPartnerType | null;
  updatedAt?: any | null;
};
export type ReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyUsingReferralPartnerUniqueSlugUpdate = {
  patch: updateReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyPatch;
  slug: string;
};
export type ReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyUsingReferralPartnerSlugPublicUpdate = {
  patch: updateReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyPatch;
  slugPublic: string;
};
export type ReferralPartnerSourceNodeIdDelete = {
  id: string;
};
export type ReferralPartnerSourceReferralPartnerSourcesPkeyDelete = {
  rowId: any;
};
export type ReferralPartnerOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: ReferralPartnerSourcePatch;
};
export type ReferralPartnerSourcePatch = {
  description?: string | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerSourceIdFkeyInverseInput | null;
  name?: string | null;
  referralPartner?: ReferralPartnerSourcesReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  rowId?: any | null;
};
export type ReferralPartnerSourceOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyUsingReferralPartnerSourcesPkeyUpdate = {
  patch: updateReferralPartnerSourceOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyPatch;
  rowId: any;
};
export type updateReferralPartnerSourceOnReferralPartnerSourceForReferralPartnerSourcesReferralPartnerIdFkeyPatch = {
  description?: string | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerSourceIdFkeyInverseInput | null;
  name?: string | null;
  referralPartner?: ReferralPartnerSourcesReferralPartnerIdFkeyInput | null;
  rowId?: any | null;
};
export type ReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: ReferralPartnerPatch;
};
export type ReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyUsingReferralPartnerPkeyUpdate = {
  patch: updateReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyPatch;
  rowId: any;
};
export type updateReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyPatch = {
  config?: any | null;
  createdAt?: any | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerIdFkeyInverseInput | null;
  name?: string | null;
  referralPartnerSources?: ReferralPartnerSourcesReferralPartnerIdFkeyInverseInput | null;
  referralPartnerUploads?: ReferralPartnerUploadsReferralPartnerIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  type?: ReferralPartnerType | null;
  updatedAt?: any | null;
};
export type ReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyUsingReferralPartnerUniqueSlugUpdate = {
  patch: updateReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyPatch;
  slug: string;
};
export type ReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyUsingReferralPartnerSlugPublicUpdate = {
  patch: updateReferralPartnerOnReferralPartnerUploadForReferralPartnerUploadsReferralPartnerIdFkeyPatch;
  slugPublic: string;
};
export type UploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyNodeIdUpdate = {
  id: string;
  patch: ReferralPartnerUploadPatch;
};
export type ReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyUsingReferralPartnerUploadsPkeyUpdate = {
  patch: updateReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyPatch;
  rowId: any;
};
export type updateReferralPartnerUploadOnReferralPartnerUploadForReferralPartnerUploadsUploadIdFkeyPatch = {
  createdAt?: any | null;
  name?: string | null;
  referralPartner?: ReferralPartnerUploadsReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  rowId?: any | null;
  updatedAt?: any | null;
  upload?: ReferralPartnerUploadsUploadIdFkeyInput | null;
};
export type UserOnUploadForUploadsUploaderIdFkeyNodeIdUpdate = {
  id: string;
  patch: UploadPatch;
};
export type UploadOnUploadForUploadsUploaderIdFkeyUsingUploadsPkeyUpdate = {
  patch: updateUploadOnUploadForUploadsUploaderIdFkeyPatch;
  rowId: any;
};
export type updateUploadOnUploadForUploadsUploaderIdFkeyPatch = {
  bucket?: string | null;
  createdAt?: any | null;
  filename?: string | null;
  filesize?: number | null;
  filetype?: string | null;
  key?: string | null;
  referralPartnerUploads?: ReferralPartnerUploadsUploadIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: UploadsUploaderIdFkeyInput | null;
  userInsuranceUploads?: UserInsuranceUploadsUploadIdFkeyInverseInput | null;
  userUploads?: UserUploadsUploadIdFkeyInverseInput | null;
};
export type UploadOnUploadForUploadsUploaderIdFkeyUsingUploadsSlugKeyUpdate = {
  patch: updateUploadOnUploadForUploadsUploaderIdFkeyPatch;
  slug: string;
};
export type PatientOnPatientForPatientsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnPatientForPatientsUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnPatientForPatientsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnPatientForPatientsUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnPatientForPatientsUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnPatientForPatientsUserIdFkeyPatch;
  slug: string;
};
export type PatientPatientsUniqueCharmRecordIdDelete = {
  charmRecordId: string;
};
export type PatientNodeIdDelete = {
  id: string;
};
export type PatientPatientsPkeyDelete = {
  rowId: any;
};
export type PatientPatientsSlugKeyDelete = {
  slug: string;
};
export type PatientPatientsUniqueUserIdDelete = {
  userId: any;
};
export type PatientOnAppointmentForAppointmentsPatientIdFkeyUsingPatientsUniqueCharmRecordIdUpdate = {
  charmRecordId: string;
  patch: updatePatientOnAppointmentForAppointmentsPatientIdFkeyPatch;
};
export type updatePatientOnAppointmentForAppointmentsPatientIdFkeyPatch = {
  appointments?: AppointmentsPatientIdFkeyInverseInput | null;
  charmId?: string | null;
  charmRecordId?: string | null;
  createdAt?: any | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: PatientsUserIdFkeyInput | null;
  userId?: any | null;
  zohoId?: string | null;
};
export type AppointmentOnAppointmentForAppointmentsPatientIdFkeyNodeIdUpdate = {
  id: string;
  patch: PatientPatch;
};
export type PatientPatch = {
  appointments?: AppointmentsPatientIdFkeyInverseInput | null;
  charmId?: string | null;
  charmRecordId?: string | null;
  createdAt?: any | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: PatientsUserIdFkeyInput | null;
  userId?: any | null;
  zohoId?: string | null;
};
export type PatientOnAppointmentForAppointmentsPatientIdFkeyUsingPatientsPkeyUpdate = {
  patch: updatePatientOnAppointmentForAppointmentsPatientIdFkeyPatch;
  rowId: any;
};
export type PatientOnAppointmentForAppointmentsPatientIdFkeyUsingPatientsSlugKeyUpdate = {
  patch: updatePatientOnAppointmentForAppointmentsPatientIdFkeyPatch;
  slug: string;
};
export type PatientOnAppointmentForAppointmentsPatientIdFkeyUsingPatientsUniqueUserIdUpdate = {
  patch: updatePatientOnAppointmentForAppointmentsPatientIdFkeyPatch;
  userId: any;
};
export type PatientOnAppointmentForAppointmentsPatientIdFkeyNodeIdUpdate = {
  id: string;
  patch: AppointmentPatch;
};
export type AppointmentOnAppointmentForAppointmentsPatientIdFkeyUsingAppointmentsPkeyUpdate = {
  patch: updateAppointmentOnAppointmentForAppointmentsPatientIdFkeyPatch;
  rowId: any;
};
export type updateAppointmentOnAppointmentForAppointmentsPatientIdFkeyPatch = {
  charmId?: string | null;
  clinician?: AppointmentsClinicianIdFkeyInput | null;
  clinicianId?: any | null;
  createdAt?: any | null;
  endsAt?: any | null;
  notes?: string | null;
  patient?: AppointmentsPatientIdFkeyInput | null;
  rowId?: any | null;
  selectionMethod?: AppointmentsSelectionMethod | null;
  service?: AppointmentsServiceIdFkeyInput | null;
  serviceId?: any | null;
  startsAt?: any | null;
  status?: AppointmentsStatus | null;
  updatedAt?: any | null;
  userChild?: AppointmentsUserChildIdFkeyInput | null;
  userChildId?: any | null;
};
export type PatientOnPatientForPatientsUserIdFkeyUsingPatientsUniqueCharmRecordIdUpdate = {
  charmRecordId: string;
  patch: updatePatientOnPatientForPatientsUserIdFkeyPatch;
};
export type updatePatientOnPatientForPatientsUserIdFkeyPatch = {
  appointments?: AppointmentsPatientIdFkeyInverseInput | null;
  charmId?: string | null;
  charmRecordId?: string | null;
  createdAt?: any | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  user?: PatientsUserIdFkeyInput | null;
  zohoId?: string | null;
};
export type UserOnPatientForPatientsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: PatientPatch;
};
export type PatientOnPatientForPatientsUserIdFkeyUsingPatientsPkeyUpdate = {
  patch: updatePatientOnPatientForPatientsUserIdFkeyPatch;
  rowId: any;
};
export type PatientOnPatientForPatientsUserIdFkeyUsingPatientsSlugKeyUpdate = {
  patch: updatePatientOnPatientForPatientsUserIdFkeyPatch;
  slug: string;
};
export type PatientOnPatientForPatientsUserIdFkeyUsingPatientsUniqueUserIdUpdate = {
  patch: updatePatientOnPatientForPatientsUserIdFkeyPatch;
  userId: any;
};
export type ClinicianOnClinicianForCliniciansUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnClinicianForCliniciansUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnClinicianForCliniciansUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnClinicianForCliniciansUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnClinicianForCliniciansUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnClinicianForCliniciansUserIdFkeyPatch;
  slug: string;
};
export type ClinicianNodeIdDelete = {
  id: string;
};
export type ClinicianCliniciansPkeyDelete = {
  rowId: any;
};
export type ClinicianCliniciansSlugKeyDelete = {
  slug: string;
};
export type ClinicianClinicianSlugPublicDelete = {
  slugPublic: string;
};
export type ClinicianCliniciansUniqueUserIdDelete = {
  userId: any;
};
export type ClinicianAvailabilityOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyNodeIdUpdate = {
  id: string;
  patch: ClinicianPatch;
};
export type ClinicianPatch = {
  appointments?: AppointmentsClinicianIdFkeyInverseInput | null;
  charmId?: string | null;
  clinicianAvailabilities?: ClinicianAvailabilitiesClinicianIdFkeyInverseInput | null;
  createdAt?: any | null;
  employeeType?: EmployeeType | null;
  introduction?: string | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  updatedAt?: any | null;
  user?: CliniciansUserIdFkeyInput | null;
  userId?: any | null;
};
export type ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingCliniciansPkeyUpdate = {
  patch: updateClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyPatch;
  rowId: any;
};
export type updateClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyPatch = {
  appointments?: AppointmentsClinicianIdFkeyInverseInput | null;
  charmId?: string | null;
  clinicianAvailabilities?: ClinicianAvailabilitiesClinicianIdFkeyInverseInput | null;
  createdAt?: any | null;
  employeeType?: EmployeeType | null;
  introduction?: string | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  updatedAt?: any | null;
  user?: CliniciansUserIdFkeyInput | null;
  userId?: any | null;
};
export type ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingCliniciansSlugKeyUpdate = {
  patch: updateClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyPatch;
  slug: string;
};
export type ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingClinicianSlugPublicUpdate = {
  patch: updateClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyPatch;
  slugPublic: string;
};
export type ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingCliniciansUniqueUserIdUpdate = {
  patch: updateClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyPatch;
  userId: any;
};
export type ClinicianAvailabilityNodeIdDelete = {
  id: string;
};
export type ClinicianAvailabilityClinicianAvailabilitiesPkeyDelete = {
  rowId: any;
};
export type ClinicianOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyNodeIdUpdate = {
  id: string;
  patch: ClinicianAvailabilityPatch;
};
export type ClinicianAvailabilityPatch = {
  clinician?: ClinicianAvailabilitiesClinicianIdFkeyInput | null;
  clinicianId?: any | null;
  createdAt?: any | null;
  endsAt?: any | null;
  googleCalendarEventId?: string | null;
  isExclusive?: boolean | null;
  recurDuration?: IntervalInput | null;
  recurEvery?: IntervalInput | null;
  rowId?: any | null;
  startsAt?: any | null;
  updatedAt?: any | null;
};
export type ClinicianAvailabilityOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyUsingClinicianAvailabilitiesPkeyUpdate = {
  patch: updateClinicianAvailabilityOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyPatch;
  rowId: any;
};
export type updateClinicianAvailabilityOnClinicianAvailabilityForClinicianAvailabilitiesClinicianIdFkeyPatch = {
  clinician?: ClinicianAvailabilitiesClinicianIdFkeyInput | null;
  createdAt?: any | null;
  endsAt?: any | null;
  googleCalendarEventId?: string | null;
  isExclusive?: boolean | null;
  recurDuration?: IntervalInput | null;
  recurEvery?: IntervalInput | null;
  rowId?: any | null;
  startsAt?: any | null;
  updatedAt?: any | null;
};
export type AppointmentOnAppointmentForAppointmentsClinicianIdFkeyNodeIdUpdate = {
  id: string;
  patch: ClinicianPatch;
};
export type ClinicianOnAppointmentForAppointmentsClinicianIdFkeyUsingCliniciansPkeyUpdate = {
  patch: updateClinicianOnAppointmentForAppointmentsClinicianIdFkeyPatch;
  rowId: any;
};
export type updateClinicianOnAppointmentForAppointmentsClinicianIdFkeyPatch = {
  appointments?: AppointmentsClinicianIdFkeyInverseInput | null;
  charmId?: string | null;
  clinicianAvailabilities?: ClinicianAvailabilitiesClinicianIdFkeyInverseInput | null;
  createdAt?: any | null;
  employeeType?: EmployeeType | null;
  introduction?: string | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  updatedAt?: any | null;
  user?: CliniciansUserIdFkeyInput | null;
  userId?: any | null;
};
export type ClinicianOnAppointmentForAppointmentsClinicianIdFkeyUsingCliniciansSlugKeyUpdate = {
  patch: updateClinicianOnAppointmentForAppointmentsClinicianIdFkeyPatch;
  slug: string;
};
export type ClinicianOnAppointmentForAppointmentsClinicianIdFkeyUsingClinicianSlugPublicUpdate = {
  patch: updateClinicianOnAppointmentForAppointmentsClinicianIdFkeyPatch;
  slugPublic: string;
};
export type ClinicianOnAppointmentForAppointmentsClinicianIdFkeyUsingCliniciansUniqueUserIdUpdate = {
  patch: updateClinicianOnAppointmentForAppointmentsClinicianIdFkeyPatch;
  userId: any;
};
export type ClinicianOnAppointmentForAppointmentsClinicianIdFkeyNodeIdUpdate = {
  id: string;
  patch: AppointmentPatch;
};
export type AppointmentOnAppointmentForAppointmentsClinicianIdFkeyUsingAppointmentsPkeyUpdate = {
  patch: updateAppointmentOnAppointmentForAppointmentsClinicianIdFkeyPatch;
  rowId: any;
};
export type updateAppointmentOnAppointmentForAppointmentsClinicianIdFkeyPatch = {
  charmId?: string | null;
  clinician?: AppointmentsClinicianIdFkeyInput | null;
  createdAt?: any | null;
  endsAt?: any | null;
  notes?: string | null;
  patient?: AppointmentsPatientIdFkeyInput | null;
  patientId?: any | null;
  rowId?: any | null;
  selectionMethod?: AppointmentsSelectionMethod | null;
  service?: AppointmentsServiceIdFkeyInput | null;
  serviceId?: any | null;
  startsAt?: any | null;
  status?: AppointmentsStatus | null;
  updatedAt?: any | null;
  userChild?: AppointmentsUserChildIdFkeyInput | null;
  userChildId?: any | null;
};
export type UserOnClinicianForCliniciansUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: ClinicianPatch;
};
export type ClinicianOnClinicianForCliniciansUserIdFkeyUsingCliniciansPkeyUpdate = {
  patch: updateClinicianOnClinicianForCliniciansUserIdFkeyPatch;
  rowId: any;
};
export type updateClinicianOnClinicianForCliniciansUserIdFkeyPatch = {
  appointments?: AppointmentsClinicianIdFkeyInverseInput | null;
  charmId?: string | null;
  clinicianAvailabilities?: ClinicianAvailabilitiesClinicianIdFkeyInverseInput | null;
  createdAt?: any | null;
  employeeType?: EmployeeType | null;
  introduction?: string | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  updatedAt?: any | null;
  user?: CliniciansUserIdFkeyInput | null;
};
export type ClinicianOnClinicianForCliniciansUserIdFkeyUsingCliniciansSlugKeyUpdate = {
  patch: updateClinicianOnClinicianForCliniciansUserIdFkeyPatch;
  slug: string;
};
export type ClinicianOnClinicianForCliniciansUserIdFkeyUsingClinicianSlugPublicUpdate = {
  patch: updateClinicianOnClinicianForCliniciansUserIdFkeyPatch;
  slugPublic: string;
};
export type ClinicianOnClinicianForCliniciansUserIdFkeyUsingCliniciansUniqueUserIdUpdate = {
  patch: updateClinicianOnClinicianForCliniciansUserIdFkeyPatch;
  userId: any;
};
export type LeadOnLeadForLeadsUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: UserPatch;
};
export type UserOnLeadForLeadsUserIdFkeyUsingUsersPkeyUpdate = {
  patch: updateUserOnLeadForLeadsUserIdFkeyPatch;
  rowId: any;
};
export type updateUserOnLeadForLeadsUserIdFkeyPatch = {
  birthdateAt?: any | null;
  clinician?: CliniciansUserIdFkeyInverseInput | null;
  createdAt?: any | null;
  email?: string | null;
  firstName?: string | null;
  gender?: UserGender | null;
  isAdmin?: boolean | null;
  lastName?: string | null;
  lead?: LeadsUserIdFkeyInverseInput | null;
  middleName?: string | null;
  patient?: PatientsUserIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  updatedAt?: any | null;
  uploads?: UploadsUploaderIdFkeyInverseInput | null;
  userAddresses?: UserAddressesUserIdFkeyInverseInput | null;
  userChildren?: UserChildrenUserIdFkeyInverseInput | null;
  userInsurances?: UserInsurancesUserIdFkeyInverseInput | null;
  userLanguages?: UserLanguagesUserIdFkeyInverseInput | null;
  userPaymentMethods?: UserPaymentMethodsUserIdFkeyInverseInput | null;
  userPaymentProviders?: UserPaymentProvidersUserIdFkeyInverseInput | null;
  userPhones?: UserPhonesUserIdFkeyInverseInput | null;
  userPolicies?: UserPoliciesUserIdFkeyInverseInput | null;
  userUploads?: UserUploadsUserIdFkeyInverseInput | null;
  verifiedAt?: any | null;
};
export type UserOnLeadForLeadsUserIdFkeyUsingUsersSlugUkeyUpdate = {
  patch: updateUserOnLeadForLeadsUserIdFkeyPatch;
  slug: string;
};
export type ReferralPartnerSourceOnLeadForLeadsReferralPartnerSourceIdFkeyNodeIdUpdate = {
  id: string;
  patch: LeadPatch;
};
export type LeadOnLeadForLeadsReferralPartnerSourceIdFkeyUsingLeadsPkeyUpdate = {
  patch: updateLeadOnLeadForLeadsReferralPartnerSourceIdFkeyPatch;
  rowId: any;
};
export type updateLeadOnLeadForLeadsReferralPartnerSourceIdFkeyPatch = {
  convertedAt?: any | null;
  createdAt?: any | null;
  optInAt?: any | null;
  optInMethod?: string | null;
  referralPartner?: LeadsReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  referralPartnerSource?: LeadsReferralPartnerSourceIdFkeyInput | null;
  rowId?: any | null;
  slug?: string | null;
  source?: LeadsSource | null;
  status?: LeadsStatus | null;
  updatedAt?: any | null;
  user?: LeadsUserIdFkeyInput | null;
  userId?: any | null;
  zohoId?: string | null;
};
export type LeadOnLeadForLeadsReferralPartnerSourceIdFkeyUsingLeadsSlugKeyUpdate = {
  patch: updateLeadOnLeadForLeadsReferralPartnerSourceIdFkeyPatch;
  slug: string;
};
export type LeadOnLeadForLeadsReferralPartnerSourceIdFkeyUsingLeadsUniqueUserIdUpdate = {
  patch: updateLeadOnLeadForLeadsReferralPartnerSourceIdFkeyPatch;
  userId: any;
};
export type LeadOnLeadForLeadsReferralPartnerSourceIdFkeyNodeIdUpdate = {
  id: string;
  patch: ReferralPartnerSourcePatch;
};
export type ReferralPartnerSourceOnLeadForLeadsReferralPartnerSourceIdFkeyUsingReferralPartnerSourcesPkeyUpdate = {
  patch: updateReferralPartnerSourceOnLeadForLeadsReferralPartnerSourceIdFkeyPatch;
  rowId: any;
};
export type updateReferralPartnerSourceOnLeadForLeadsReferralPartnerSourceIdFkeyPatch = {
  description?: string | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerSourceIdFkeyInverseInput | null;
  name?: string | null;
  referralPartner?: ReferralPartnerSourcesReferralPartnerIdFkeyInput | null;
  referralPartnerId?: any | null;
  rowId?: any | null;
};
export type ReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: LeadPatch;
};
export type LeadOnLeadForLeadsReferralPartnerIdFkeyUsingLeadsPkeyUpdate = {
  patch: updateLeadOnLeadForLeadsReferralPartnerIdFkeyPatch;
  rowId: any;
};
export type updateLeadOnLeadForLeadsReferralPartnerIdFkeyPatch = {
  convertedAt?: any | null;
  createdAt?: any | null;
  optInAt?: any | null;
  optInMethod?: string | null;
  referralPartner?: LeadsReferralPartnerIdFkeyInput | null;
  referralPartnerSource?: LeadsReferralPartnerSourceIdFkeyInput | null;
  referralPartnerSourceId?: any | null;
  rowId?: any | null;
  slug?: string | null;
  source?: LeadsSource | null;
  status?: LeadsStatus | null;
  updatedAt?: any | null;
  user?: LeadsUserIdFkeyInput | null;
  userId?: any | null;
  zohoId?: string | null;
};
export type LeadOnLeadForLeadsReferralPartnerIdFkeyUsingLeadsSlugKeyUpdate = {
  patch: updateLeadOnLeadForLeadsReferralPartnerIdFkeyPatch;
  slug: string;
};
export type LeadOnLeadForLeadsReferralPartnerIdFkeyUsingLeadsUniqueUserIdUpdate = {
  patch: updateLeadOnLeadForLeadsReferralPartnerIdFkeyPatch;
  userId: any;
};
export type LeadOnLeadForLeadsReferralPartnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: ReferralPartnerPatch;
};
export type ReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyUsingReferralPartnerPkeyUpdate = {
  patch: updateReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyPatch;
  rowId: any;
};
export type updateReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyPatch = {
  config?: any | null;
  createdAt?: any | null;
  isActive?: boolean | null;
  leads?: LeadsReferralPartnerIdFkeyInverseInput | null;
  name?: string | null;
  referralPartnerSources?: ReferralPartnerSourcesReferralPartnerIdFkeyInverseInput | null;
  referralPartnerUploads?: ReferralPartnerUploadsReferralPartnerIdFkeyInverseInput | null;
  rowId?: any | null;
  slug?: string | null;
  slugPublic?: string | null;
  type?: ReferralPartnerType | null;
  updatedAt?: any | null;
};
export type ReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyUsingReferralPartnerUniqueSlugUpdate = {
  patch: updateReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyPatch;
  slug: string;
};
export type ReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyUsingReferralPartnerSlugPublicUpdate = {
  patch: updateReferralPartnerOnLeadForLeadsReferralPartnerIdFkeyPatch;
  slugPublic: string;
};
export type CreateLeadModalCreateLeadMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateLeadInput;
};
export type CreateLeadModalCreateLeadMutation$data = {
  readonly createLead: {
    readonly leadEdge: {
      readonly cursor: any | null;
      readonly node: {
        readonly convertedAt: any | null;
        readonly createdAt: any;
        readonly optInAt: any | null;
        readonly optInMethod: string | null;
        readonly referralPartner: {
          readonly name: string;
        } | null;
        readonly slug: string | null;
        readonly source: LeadsSource;
        readonly status: LeadsStatus;
        readonly user: {
          readonly firstName: string;
          readonly lastName: string;
          readonly slug: string | null;
        } | null;
        readonly zohoId: string | null;
      };
    } | null;
  } | null;
};
export type CreateLeadModalCreateLeadMutation = {
  response: CreateLeadModalCreateLeadMutation$data;
  variables: CreateLeadModalCreateLeadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zohoId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "optInAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "optInMethod",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "convertedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateLeadModalCreateLeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLeadPayload",
        "kind": "LinkedField",
        "name": "createLead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadsEdge",
            "kind": "LinkedField",
            "name": "leadEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Lead",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReferralPartner",
                    "kind": "LinkedField",
                    "name": "referralPartner",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateLeadModalCreateLeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateLeadPayload",
        "kind": "LinkedField",
        "name": "createLead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadsEdge",
            "kind": "LinkedField",
            "name": "leadEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Lead",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v3/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReferralPartner",
                    "kind": "LinkedField",
                    "name": "referralPartner",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "leadEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9dcc8915cb06f033271c27d3a162ece",
    "id": null,
    "metadata": {},
    "name": "CreateLeadModalCreateLeadMutation",
    "operationKind": "mutation",
    "text": "mutation CreateLeadModalCreateLeadMutation(\n  $input: CreateLeadInput!\n) {\n  createLead(input: $input) {\n    leadEdge {\n      cursor\n      node {\n        slug\n        createdAt\n        status\n        source\n        zohoId\n        optInAt\n        optInMethod\n        convertedAt\n        user {\n          firstName\n          lastName\n          slug\n          id\n        }\n        referralPartner {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ce49b87c962245a4094bce2922e6444";

export default node;
