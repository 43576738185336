/**
 * @generated SignedSource<<3cb334a30ed6dc3a1d277646736edec3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateLeadModal_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LeadForm_query">;
  readonly " $fragmentType": "CreateLeadModal_query";
};
export type CreateLeadModal_query$key = {
  readonly " $data"?: CreateLeadModal_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateLeadModal_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateLeadModal_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LeadForm_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "60d02414fcd5ad63f242a560a2f0a0e2";

export default node;
