/**
 * @generated SignedSource<<74b15d27a8ba93d13726ce07a8dbb15b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookCreditCardPayment_query$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly rowId: any;
    readonly stripeCustomerId: string | null;
    readonly userAddresses: {
      readonly nodes: ReadonlyArray<{
        readonly address: {
          readonly city: string;
          readonly country: string;
          readonly postalCode: string;
          readonly stateOrProvince: string;
          readonly street1: string;
          readonly street2: string | null;
        } | null;
      }>;
    };
    readonly userPaymentMethods: {
      readonly nodes: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"UserPaymentMethodDisplay_userPaymentMethod">;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"UserPaymentMethodForm_user">;
  } | null;
  readonly " $fragmentType": "BookCreditCardPayment_query";
};
export type BookCreditCardPayment_query$key = {
  readonly " $data"?: BookCreditCardPayment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookCreditCardPayment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookCreditCardPayment_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stripeCustomerId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserPaymentMethodForm_user"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "isActive": true,
                "isPrimary": true,
                "provider": "STRIPE"
              }
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "UserPaymentMethodsConnection",
          "kind": "LinkedField",
          "name": "userPaymentMethods",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserPaymentMethod",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserPaymentMethodDisplay_userPaymentMethod"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "userPaymentMethods(condition:{\"isActive\":true,\"isPrimary\":true,\"provider\":\"STRIPE\"},first:1)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "isPrimary": {
                  "equalTo": true
                }
              }
            }
          ],
          "concreteType": "UserAddressesConnection",
          "kind": "LinkedField",
          "name": "userAddresses",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserAddress",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Address",
                  "kind": "LinkedField",
                  "name": "address",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "city",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "country",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "postalCode",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "stateOrProvince",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "street1",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "street2",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "userAddresses(filter:{\"isPrimary\":{\"equalTo\":true}})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b47eb7a4c9cc118e51f61067c38bc5cd";

export default node;
