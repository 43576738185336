/**
 * @generated SignedSource<<5467d0ddb62a043f97ad95ef8e4bdbe1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardLogo_userPaymentMethod$data = {
  readonly methodData: any;
  readonly " $fragmentType": "CardLogo_userPaymentMethod";
};
export type CardLogo_userPaymentMethod$key = {
  readonly " $data"?: CardLogo_userPaymentMethod$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardLogo_userPaymentMethod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardLogo_userPaymentMethod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "methodData",
      "storageKey": null
    }
  ],
  "type": "UserPaymentMethod",
  "abstractKey": null
};

(node as any).hash = "eef3f1c830d7808eb946eca0e5125c6d";

export default node;
