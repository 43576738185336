// noinspection GraphQLUnresolvedReference

import {
  type ClinicianAvailabilitiesTable_query$data,
  type ClinicianAvailabilitiesTable_query$key
} from '@app/__generated__/ClinicianAvailabilitiesTable_query.graphql'
import { type ClinicianAvailabilitiesTableDeleteClinicianAvailabilityMutation } from '@app/__generated__/ClinicianAvailabilitiesTableDeleteClinicianAvailabilityMutation.graphql'
import { type ClinicianAvailabilitiesTablePaginationQuery } from '@app/__generated__/ClinicianAvailabilitiesTablePaginationQuery.graphql'
import { CreateClinicianAvailabilityModal, PaginationTable, UpdateClinicianAvailabilityModal } from '@app/components'
import { ClinicianAvailabilitiesOrderBy, type ClinicianAvailabilityFilter, longDate } from '@app/lib'
import { ActionIcon, Anchor, Badge, Group, Text } from '@mantine/core'
import { useModals } from '@mantine/modals'
import { IconCheck, IconEdit, IconTrash } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo, useState } from 'react'
import { graphql, useMutation, usePaginationFragment } from 'react-relay'

type ClinicianAvailabilitiesTableEdge = ElementOf<
  ClinicianAvailabilitiesTable_query$data['clinicianAvailabilities']['edges']
>

const paginationFragment = graphql`
  fragment ClinicianAvailabilitiesTable_query on Query
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "ClinicianAvailabilityFilter" }
    first: { type: "Int" }
    orderBy: { type: "[ClinicianAvailabilitiesOrderBy!]", defaultValue: [STARTS_AT_ASC] }
  )
  @refetchable(queryName: "ClinicianAvailabilitiesTablePaginationQuery") {
    clinicianAvailabilities(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "ClinicianAvailabilitiesTable_clinicianAvailabilities", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          id
          rowId
          startsAt
          endsAt
          clinician {
            user {
              firstName
              lastName
              slug
            }
          }
          googleCalendarEventId
          ...UpdateClinicianAvailabilityModal_clinicianAvailability
        }
      }
      totalCount
    }
    ...UpdateClinicianAvailabilityModal_query
    ...CreateClinicianAvailabilityModal_query
  }
`

const deleteClinicianAvailabilityMutation = graphql`
  mutation ClinicianAvailabilitiesTableDeleteClinicianAvailabilityMutation(
    $connections: [ID!]!
    $input: DeleteClinicianAvailabilityByIdInput!
  ) {
    deleteClinicianAvailabilityById(input: $input) {
      deletedClinicianAvailabilityId @deleteEdge(connections: $connections)
    }
  }
`

export interface ClinicianAvailabilitiesTableProps {
  query: ClinicianAvailabilitiesTable_query$key
  filters: ClinicianAvailabilityFilter
  onCreateModalClose(): void
  showCreateModal: boolean
}

export const ClinicianAvailabilitiesTable: FC<ClinicianAvailabilitiesTableProps> = ({
  query,
  filters,
  onCreateModalClose,
  showCreateModal
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState<string | null>(null)
  const { t } = useTranslation('booking')
  const modals = useModals()
  const [deleteClinicianAvailability, isDeletingClinicianAvailability] =
    useMutation<ClinicianAvailabilitiesTableDeleteClinicianAvailabilityMutation>(deleteClinicianAvailabilityMutation)
  const {
    data: queryData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<ClinicianAvailabilitiesTablePaginationQuery, ClinicianAvailabilitiesTable_query$key>(
    paginationFragment,
    query
  )

  return (
    <>
      <PaginationTable<
        ClinicianAvailabilitiesTableEdge,
        ClinicianAvailabilitiesOrderBy,
        ClinicianAvailabilitiesTablePaginationQuery,
        ClinicianAvailabilityFilter
      >
        columns={useMemo<ColumnDef<ClinicianAvailabilitiesTableEdge>[]>(
          () => [
            {
              accessorKey: 'node.startsAt',
              id: 'startsAt',
              enableSorting: true,
              header: t('Starts At'),
              cell: (props) => {
                const node = props.row.original.node

                return longDate(node.startsAt)
              }
            },
            {
              accessorKey: 'node.endsAt',
              id: 'endsAt',
              enableSorting: true,
              header: t('Ends At'),
              cell: (props) => {
                const node = props.row.original.node

                return longDate(node.endsAt)
              }
            },
            {
              accessorKey: 'node.clinicianId',
              id: 'clinicianId',
              enableSorting: false,
              header: t('Clinician'),
              cell: (props) => {
                const node = props.row.original.node

                return (
                  <Anchor
                    component={Link}
                    href={`/admin/users/${node.clinician.user.slug}/clinician`}
                  >
                    {node.clinician.user.firstName} {node.clinician.user.lastName}
                  </Anchor>
                )
              }
            },
            {
              id: 'actions',
              size: 30,
              cell: (props) => {
                const node = props.row.original.node

                return node.googleCalendarEventId ? (
                  <Badge
                    color='pink'
                    variant='light'
                  >
                    Sync&apos;d from Google
                  </Badge>
                ) : (
                  <Group spacing='xs'>
                    <ActionIcon
                      color='blue'
                      title={t('Edit Availability')}
                      onClick={() => setShowUpdateModal(node.id)}
                      variant='transparent'
                    >
                      <IconEdit size={14} />
                    </ActionIcon>
                    <ActionIcon
                      color='red'
                      title={t('Delete Availability')}
                      onClick={() =>
                        modals.openConfirmModal({
                          centered: true,
                          title: 'Delete Availability?',
                          children: <Text size='sm'>{t('Are you sure you want to delete this availability?')}</Text>,
                          labels: {
                            confirm: t('Confirm'),
                            cancel: t('Cancel')
                          },
                          confirmProps: {
                            leftIcon: <IconCheck size={16} />,
                            loading: isDeletingClinicianAvailability
                          },
                          onConfirm: () => {
                            deleteClinicianAvailability({
                              variables: {
                                input: {
                                  id: node.id
                                },
                                connections: [queryData?.clinicianAvailabilities?.__id]
                              }
                            })
                          }
                        })
                      }
                    >
                      <IconTrash size={14} />
                    </ActionIcon>
                    <UpdateClinicianAvailabilityModal
                      clinicianAvailability={node}
                      opened={node.id === showUpdateModal}
                      onClose={() => setShowUpdateModal(null)}
                      query={queryData}
                      size='lg'
                    />
                  </Group>
                )
              }
            }
          ],
          [t, queryData, showUpdateModal, isDeletingClinicianAvailability, modals, deleteClinicianAvailability]
        )}
        data={queryData?.clinicianAvailabilities?.edges}
        isFilterable
        getFilterFromSearch={useCallback(
          (search) => {
            return !isEmpty(search)
              ? {
                  ...filters,
                  or: [
                    {
                      clinician: {
                        user: {
                          firstName: {
                            includesInsensitive: search
                          }
                        }
                      }
                    },
                    {
                      clinician: {
                        user: {
                          lastName: {
                            includesInsensitive: search
                          }
                        }
                      }
                    }
                  ]
                }
              : filters
          },
          [filters]
        )}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'startsAt',
              desc: true
            }
          ],
          []
        )}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            startsAt: {
              asc: ClinicianAvailabilitiesOrderBy.StartsAtAsc,
              desc: ClinicianAvailabilitiesOrderBy.StartsAtDesc
            }
          }),
          []
        )}
        totalCount={queryData?.clinicianAvailabilities?.totalCount}
      />
      <CreateClinicianAvailabilityModal
        connectionId={queryData?.clinicianAvailabilities?.__id}
        opened={showCreateModal}
        onClose={onCreateModalClose}
        query={queryData}
      />
    </>
  )
}
