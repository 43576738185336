import {
  type PoliciesTable_query$data,
  type PoliciesTable_query$key
} from '@app/__generated__/PoliciesTable_query.graphql'
import { type PoliciesTablePaginationQuery } from '@app/__generated__/PoliciesTablePaginationQuery.graphql'
import { CreatePolicyModal, PaginationTable, UpdatePolicyModal } from '@app/components'
import { friendlyDate, humanize, PoliciesOrderBy, type PolicyFilter } from '@app/lib'
import { ActionIcon } from '@mantine/core'
import { IconEdit } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo, useState } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type PoliciesTablePolicyEdge = ElementOf<PoliciesTable_query$data['policies']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment PoliciesTable_query on Query
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "PolicyFilter" }
    first: { type: "Int" }
    orderBy: { type: "[PoliciesOrderBy!]", defaultValue: [VALID_AT_ASC] }
  )
  @refetchable(queryName: "PoliciesTablePaginationQuery") {
    policies(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "PoliciesTable_query_policies", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          id
          name
          type
          validAt
          ...UpdatePolicyModal_policy
        }
      }
      totalCount
    }
  }
`

export interface PoliciesTableProps {
  onCreateModalClose: () => void
  query: PoliciesTable_query$key
  showCreateModal: boolean
}

export const PoliciesTable: FC<PoliciesTableProps> = ({ onCreateModalClose, query, showCreateModal }) => {
  const [showUpdateModal, setShowUpdateModal] = useState<string | null>(null)
  const { t } = useTranslation('admin')
  const {
    data: queryData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<PoliciesTablePaginationQuery, PoliciesTable_query$key>(paginationFragment, query)

  return (
    <>
      <PaginationTable<PoliciesTablePolicyEdge, PoliciesOrderBy, PoliciesTablePaginationQuery, PolicyFilter>
        columns={useMemo<ColumnDef<PoliciesTablePolicyEdge>[]>(
          () => [
            {
              accessorKey: 'node.type',
              id: 'type',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Type'),
              cell: (props) => humanize(props.row.original.node.type)
            },
            {
              accessorKey: 'node.name',
              id: 'name',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Name')
            },
            {
              accessorKey: 'node.validAt',
              id: 'validAt',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Valid From'),
              cell: (props) => friendlyDate(props.row.original.node.validAt)
            },
            {
              id: 'actions',
              size: 30,
              cell: (props) => {
                const node = props.row.original.node

                return (
                  <>
                    <ActionIcon
                      color='blue'
                      title='Edit Policy'
                      onClick={() => setShowUpdateModal(node.id)}
                      variant='transparent'
                    >
                      <IconEdit size={14} />
                    </ActionIcon>
                    <UpdatePolicyModal
                      policy={node}
                      opened={node.id === showUpdateModal}
                      onClose={() => setShowUpdateModal(null)}
                    />
                  </>
                )
              }
            }
          ],
          [showUpdateModal, t]
        )}
        data={queryData?.policies?.edges}
        isFilterable
        getFilterFromSearch={useCallback(
          (search) =>
            !isEmpty(search)
              ? {
                  name: {
                    includesInsensitive: search
                  }
                }
              : null,
          []
        )}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'validAt',
              desc: false
            }
          ],
          []
        )}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            type: {
              asc: PoliciesOrderBy.TypeAsc,
              desc: PoliciesOrderBy.TypeDesc
            },
            name: {
              asc: PoliciesOrderBy.NameAsc,
              desc: PoliciesOrderBy.NameDesc
            },
            validAt: {
              asc: PoliciesOrderBy.ValidAtAsc,
              desc: PoliciesOrderBy.ValidAtDesc
            }
          }),
          []
        )}
        totalCount={queryData?.policies?.totalCount}
      />
      <CreatePolicyModal
        connectionId={queryData?.policies?.__id}
        opened={showCreateModal}
        onClose={onCreateModalClose}
      />
    </>
  )
}
