import { Group, Input } from '@mantine/core'
import dayjs from 'dayjs'
import { type FC, useCallback, useEffect, useState } from 'react'

import { SelectInput, type SelectInputProps } from '.'

export interface TimeInputProps extends Omit<SelectInputProps, 'data' | 'onChange' | 'value'> {
  format?: string
  label?: string
  minuteIncrement?: number
  onChange(value: Date): void
  value?: Date
}

export const TimeInput: FC<TimeInputProps> = ({
  error,
  minuteIncrement = 15,
  onChange,
  value = new Date(),
  ...props
}) => {
  const getHour = useCallback((hourValue, ampmValue) => {
    if (ampmValue === 'AM' && hourValue === '12') {
      return 0
    }
    if (ampmValue === 'PM' && hourValue === '12') {
      return 12
    }
    if (ampmValue === 'PM' && hourValue !== '12') {
      return 12 + parseInt(hourValue)
    }
    if (ampmValue === 'AM' && hourValue !== '12') {
      return parseInt(hourValue)
    }
  }, [])
  const [hour, setHour] = useState(dayjs(value).format('h'))
  const [minute, setMinute] = useState(
    `${dayjs(value).minute() < 10 ? '0' + dayjs(value).minute() : dayjs(value).minute()}`
  )
  const [ampm, setAmpm] = useState(dayjs(value).format('A'))

  useEffect(() => {
    setHour(dayjs(value).format('h'))
    setMinute(`${dayjs(value).minute() < 10 ? '0' + dayjs(value).minute() : dayjs(value).minute()}`)
    setAmpm(dayjs(value).format('A'))
  }, [value])

  return (
    <Input.Wrapper
      error={error}
      {...props}
    >
      <Group
        align='start'
        noWrap
        position='left'
        spacing={4}
      >
        <SelectInput
          data={Array.from({ length: 12 }, (_, index) => `${index + 1}`)}
          error={Boolean(error)}
          icon={false}
          onChange={useCallback(
            (nextValue: string) => {
              onChange(dayjs(value).hour(getHour(nextValue, ampm)).minute(parseInt(minute)).toDate())
              setHour(nextValue.toString())
            },
            [ampm, getHour, minute, onChange, value]
          )}
          value={hour === '0' ? '12' : hour}
        />
        <SelectInput
          data={Array.from(
            { length: 60 / minuteIncrement },
            (_, index) => `${index * minuteIncrement < 10 ? '0' + index * minuteIncrement : index * minuteIncrement}`
          )}
          error={Boolean(error)}
          icon={false}
          onChange={useCallback(
            (nextValue: string) => {
              onChange(dayjs(value).hour(getHour(hour, ampm)).minute(parseInt(nextValue)).toDate())
              setMinute(nextValue)
            },
            [ampm, getHour, hour, onChange, value]
          )}
          value={minute}
        />
        <SelectInput
          data={['AM', 'PM']}
          error={Boolean(error)}
          icon={false}
          onChange={useCallback(
            (nextValue: string) => {
              onChange(dayjs(value).hour(getHour(hour, nextValue)).minute(parseInt(minute)).toDate())
              setAmpm(nextValue)
            },
            [getHour, hour, minute, onChange, value]
          )}
          value={ampm}
        />
      </Group>
    </Input.Wrapper>
  )
}
