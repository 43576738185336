import { type DefaultMantineColor } from '@mantine/core'

const mantineColors: DefaultMantineColor[] = [
  'red',
  'pink',
  'grape',
  'violet',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'green',
  'lime',
  'yellow',
  'orange'
]

let call = 0

export const getMantineColor = (reset?: boolean) => {
  if (reset) {
    call = 0
  }

  return mantineColors[((call++ % mantineColors.length) + mantineColors.length) % mantineColors.length]
}
