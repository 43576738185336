import {
  type InsuranceProvidersTable_query$data,
  type InsuranceProvidersTable_query$key
} from '@app/__generated__/InsuranceProvidersTable_query.graphql'
import { type InsuranceProvidersTablePaginationQuery } from '@app/__generated__/InsuranceProvidersTablePaginationQuery.graphql'
import { PaginationTable } from '@app/components'
import { type InsuranceProviderFilter, InsuranceProvidersOrderBy } from '@app/lib'
import { ActionIcon } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type InsuranceProvidersTableInsuranceProviderEdge = ElementOf<
  InsuranceProvidersTable_query$data['insuranceProviders']['edges']
>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment InsuranceProvidersTable_query on Query
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "InsuranceProviderFilter" }
    first: { type: "Int" }
    orderBy: { type: "[InsuranceProvidersOrderBy!]", defaultValue: [NAME_ASC] }
  )
  @refetchable(queryName: "InsuranceProvidersTablePaginationQuery") {
    insuranceProviders(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "InsuranceProvidersTable_query_insuranceProviders", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          name
          slug
        }
      }
      totalCount
    }
  }
`

export interface InsuranceProvidersTableProps {
  query: InsuranceProvidersTable_query$key
}

export const InsuranceProvidersTable: FC<InsuranceProvidersTableProps> = ({ query }) => {
  const { t } = useTranslation('admin')
  const {
    data: queryData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<InsuranceProvidersTablePaginationQuery, InsuranceProvidersTable_query$key>(
    paginationFragment,
    query
  )

  return (
    <PaginationTable<
      InsuranceProvidersTableInsuranceProviderEdge,
      InsuranceProvidersOrderBy,
      InsuranceProvidersTablePaginationQuery,
      InsuranceProviderFilter
    >
      columns={useMemo<ColumnDef<InsuranceProvidersTableInsuranceProviderEdge>[]>(
        () => [
          {
            accessorKey: 'node.name',
            id: 'name',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Name')
          },
          {
            id: 'actions',
            size: 30,
            cell: (props) => (
              <ActionIcon
                color='blue'
                component={Link}
                href={`/admin/insuranceProviders/${props.row.original.node.slug}`}
                title={t('View Insurance Provider')}
                variant='transparent'
              >
                <IconChevronRight size={14} />
              </ActionIcon>
            )
          }
        ],
        [t]
      )}
      data={queryData?.insuranceProviders?.edges}
      isFilterable
      getFilterFromSearch={useCallback(
        (search) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    name: {
                      includesInsensitive: search
                    }
                  },
                  {
                    payerId: {
                      includesInsensitive: search
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={useMemo(
        () => [
          {
            id: 'name',
            desc: false
          }
        ],
        []
      )}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={useMemo(
        () => ({
          name: {
            asc: InsuranceProvidersOrderBy.NameAsc,
            desc: InsuranceProvidersOrderBy.NameDesc
          }
        }),
        []
      )}
      totalCount={queryData?.insuranceProviders?.totalCount}
    />
  )
}
