/**
 * @generated SignedSource<<95485fd3021451a2bc416bb4efc3bcdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppointmentsStatus = "CHECKED_IN" | "CONFIRMED" | "CONSULTED" | "LATE_CANCELLATION_EMERGENCY_MEDICAL_FIRE_ETC_" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_CIGNA_MOM" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_MEDICAID" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_RESCHEDULED" | "LATE_CANCELLATION_NO_SHOW_PLEASE_CHARGE" | "NO_SHOW_CHARGE" | "NO_SHOW_NO_CHARGE" | "UNCONFIRMED" | "_2_HR_LATE_CANCEL_CHARGE" | "_2_HR_LATE_CANCEL_NO_CHARGE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PatientAppointmentForm_appointment$data = {
  readonly clinicianId: any;
  readonly notes: string | null;
  readonly serviceId: any;
  readonly startsAt: any;
  readonly status: AppointmentsStatus;
  readonly userChildId: any | null;
  readonly " $fragmentType": "PatientAppointmentForm_appointment";
};
export type PatientAppointmentForm_appointment$key = {
  readonly " $data"?: PatientAppointmentForm_appointment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PatientAppointmentForm_appointment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PatientAppointmentForm_appointment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clinicianId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userChildId",
      "storageKey": null
    }
  ],
  "type": "Appointment",
  "abstractKey": null
};

(node as any).hash = "def0682613eaf526dfc6b24690fd50f8";

export default node;
