import { type UpdateReferralPartnerSourceModal_referralPartnerSource$key } from '@app/__generated__/UpdateReferralPartnerSourceModal_referralPartnerSource.graphql'
import { type UpdateReferralPartnerSourceModalUpdateReferralPartnerSourceMutation } from '@app/__generated__/UpdateReferralPartnerSourceModalUpdateReferralPartnerSourceMutation.graphql'
import { ReferralPartnerSourceForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type ReferralPartnerSourcePatch } from '@app/lib'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const referralPartnerSourceFragment = graphql`
  fragment UpdateReferralPartnerSourceModal_referralPartnerSource on ReferralPartnerSource {
    id
    ...ReferralPartnerSourceForm_referralPartnerSource
  }
`
const updateReferralPartnerSourceMutation = graphql`
  mutation UpdateReferralPartnerSourceModalUpdateReferralPartnerSourceMutation(
    $input: UpdateReferralPartnerSourceByIdInput!
  ) {
    updateReferralPartnerSourceById(input: $input) {
      referralPartnerSource {
        description
        isActive
        name
        ...ReferralPartnerSourceForm_referralPartnerSource
      }
    }
  }
`

export interface UpdateReferralPartnerSourceModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  referralPartnerSource: UpdateReferralPartnerSourceModal_referralPartnerSource$key
}

export const UpdateReferralPartnerSourceModal: FC<UpdateReferralPartnerSourceModalProps> = ({
  onClose,
  referralPartnerSource,
  ...modalProps
}) => {
  const referralPartnerSourceData = useFragment(referralPartnerSourceFragment, referralPartnerSource)
  const [updateReferralPartnerSource, isCreatingReferralPartnerSource] =
    useMutation<UpdateReferralPartnerSourceModalUpdateReferralPartnerSourceMutation>(
      updateReferralPartnerSourceMutation
    )
  const { t } = useTranslation('booking')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      title={t('Edit Source')}
      {...modalProps}
    >
      <ReferralPartnerSourceForm
        referralPartnerSource={referralPartnerSourceData}
        isSaving={isCreatingReferralPartnerSource}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (patch: ReferralPartnerSourcePatch) =>
            updateReferralPartnerSource({
              variables: {
                input: {
                  id: referralPartnerSourceData?.id,
                  patch
                }
              },
              onCompleted: onClose
            }),
          [onClose, updateReferralPartnerSource, referralPartnerSourceData?.id]
        )}
      />
    </UnsavedChangesModal>
  )
}
