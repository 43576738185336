import {
  type UserAddressesTable_user$data,
  type UserAddressesTable_user$key
} from '@app/__generated__/UserAddressesTable_user.graphql'
import { type UserAddressesTablePaginationQuery } from '@app/__generated__/UserAddressesTablePaginationQuery.graphql'
import { IconBoolean, PaginationTable } from '@app/components'
import { UserAddressesOrderBy, type UserAddressFilter } from '@app/lib'
import { Stack, Text } from '@mantine/core'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type UserAddressesTableUserAddressEdge = ElementOf<UserAddressesTable_user$data['userAddresses']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment UserAddressesTable_user on User
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "UserAddressFilter" }
    first: { type: "Int" }
    orderBy: { type: "[UserAddressesOrderBy!]", defaultValue: [IS_PRIMARY_DESC] }
  )
  @refetchable(queryName: "UserAddressesTablePaginationQuery") {
    userAddresses(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "UserAddressesTable_user_userAddresses", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          address {
            city
            country
            id
            postalCode
            stateOrProvince
            street1
            street2
          }
          id
          isPrimary
        }
      }
      totalCount
    }
  }
`

export interface UserAddressesTableProps {
  user: UserAddressesTable_user$key
}

export const UserAddressesTable: FC<UserAddressesTableProps> = ({ user }) => {
  const {
    data: userData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<UserAddressesTablePaginationQuery, UserAddressesTable_user$key>(paginationFragment, user)
  const { t } = useTranslation('admin')

  return (
    <PaginationTable<
      UserAddressesTableUserAddressEdge,
      UserAddressesOrderBy,
      UserAddressesTablePaginationQuery,
      UserAddressFilter
    >
      columns={useMemo<ColumnDef<UserAddressesTableUserAddressEdge>[]>(
        () => [
          {
            id: 'address',
            header: t('Street'),
            cell: (props) => {
              const node = props.row.original.node

              return (
                <Stack
                  justify='center'
                  spacing='xs'
                >
                  <Text>{node.address.street1}</Text>
                  {node.address.street2 && <Text size='xs'>{node.address.street2}</Text>}
                </Stack>
              )
            }
          },
          {
            accessorKey: 'node.address.city',
            id: 'city',
            enableMultiSort: false,
            enableSorting: true,
            header: t('City')
          },
          {
            accessorKey: 'node.address.stateOrProvince',
            id: 'stateOrProvince',
            enableMultiSort: false,
            enableSorting: true,
            header: t('State/Province')
          },
          {
            accessorKey: 'node.address.postalCode',
            id: 'postalCode',
            enableMultiSort: false,
            enableSorting: true,
            header: t('Postal Code')
          },
          {
            accessorKey: 'node.address.country',
            id: 'country',
            enableMultiSort: false,
            enableSorting: true,
            header: t('Country'),
            size: 30
          },
          {
            accessorKey: 'node.isPrimary',
            id: 'isPrimary',
            enableMultiSort: false,
            enableSorting: true,
            header: t('Primary'),
            size: 30,
            cell: (props) => {
              const node = props.row.original.node

              return <IconBoolean value={node.isPrimary} />
            }
          }
        ],
        [t]
      )}
      data={userData?.userAddresses?.edges}
      isFilterable
      getFilterFromSearch={useCallback(
        (search) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    address: {
                      street1: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    address: {
                      street2: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    address: {
                      city: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    address: {
                      stateOrProvince: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    address: {
                      postalCode: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    address: {
                      country: {
                        includesInsensitive: search
                      }
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={useMemo(
        () => [
          {
            id: 'isPrimary',
            desc: true
          }
        ],
        []
      )}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={useMemo(
        () => ({
          city: {
            asc: UserAddressesOrderBy.AddressByAddressIdCityAsc,
            desc: UserAddressesOrderBy.AddressByAddressIdCityDesc
          },
          country: {
            asc: UserAddressesOrderBy.AddressByAddressIdCountryAsc,
            desc: UserAddressesOrderBy.AddressByAddressIdCountryDesc
          },
          isPrimary: {
            asc: UserAddressesOrderBy.IsPrimaryAsc,
            desc: UserAddressesOrderBy.IsPrimaryDesc
          },
          postalCode: {
            asc: UserAddressesOrderBy.AddressByAddressIdPostalCodeAsc,
            desc: UserAddressesOrderBy.AddressByAddressIdPostalCodeDesc
          },
          stateOrProvince: {
            asc: UserAddressesOrderBy.AddressByAddressIdStateOrProvinceAsc,
            desc: UserAddressesOrderBy.AddressByAddressIdStateOrProvinceDesc
          }
        }),
        []
      )}
      totalCount={userData?.userAddresses?.totalCount}
    />
  )
}
