import {
  type UserLanguagesTable_user$data,
  type UserLanguagesTable_user$key
} from '@app/__generated__/UserLanguagesTable_user.graphql'
import { type UserLanguagesTablePaginationQuery } from '@app/__generated__/UserLanguagesTablePaginationQuery.graphql'
import { IconBoolean, PaginationTable } from '@app/components'
import { type UserLanguageFilter, UserLanguagesOrderBy } from '@app/lib'
import { type ColumnDef } from '@tanstack/react-table'
import langs from 'langs'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

type UserLanguagesTableUserLanguageEdge = ElementOf<UserLanguagesTable_user$data['userLanguages']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment UserLanguagesTable_user on User
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "UserLanguageFilter" }
    first: { type: "Int" }
    orderBy: { type: "[UserLanguagesOrderBy!]", defaultValue: [IS_PRIMARY_DESC] }
  )
  @refetchable(queryName: "UserLanguagesTablePaginationQuery") {
    id
    userLanguages(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "UserLanguagesTable_user_userLanguages", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          id
          isPrimary
          language
        }
      }
      totalCount
    }
  }
`

export interface UserLanguagesTableProps {
  user: UserLanguagesTable_user$key
}

export const UserLanguagesTable: FC<UserLanguagesTableProps> = ({ user }) => {
  const {
    data: userData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<UserLanguagesTablePaginationQuery, UserLanguagesTable_user$key>(paginationFragment, user)

  const { t } = useTranslation('admin')

  return (
    <>
      <PaginationTable<
        UserLanguagesTableUserLanguageEdge,
        UserLanguagesOrderBy,
        UserLanguagesTablePaginationQuery,
        UserLanguageFilter
      >
        columns={useMemo<ColumnDef<UserLanguagesTableUserLanguageEdge>[]>(
          () => [
            {
              id: 'language',
              header: t('Language'),
              cell: (props) => {
                const node = props.row.original.node
                const lang = langs.where('1', node.language)

                return lang ? (lang.name !== lang.local ? `${lang.name} / ${lang.local}` : lang.name) : t('Unknown')
              }
            },
            {
              id: 'isPrimary',
              header: t('Primary'),
              size: 30,
              cell: (props) => {
                const node = props.row.original.node

                return <IconBoolean value={node.isPrimary} />
              }
            }
          ],
          [t]
        )}
        data={userData?.userLanguages?.edges}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'isPrimary',
              desc: true
            }
          ],
          []
        )}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            isPrimary: {
              asc: UserLanguagesOrderBy.IsPrimaryAsc,
              desc: UserLanguagesOrderBy.IsPrimaryDesc
            }
          }),
          []
        )}
        totalCount={userData?.userLanguages?.totalCount}
      />
    </>
  )
}
