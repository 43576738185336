import dayjs from 'dayjs'
import { entries, get, isNull, map, reduce, split, startCase, toLower, toUpper } from 'lodash'

const formatterMap = new Map()

// this works by coercing input to number by marking it positive, which results in NaN for non-numeric things (except null)
export const isNumeric = (x: number | string | null) => !isNull(x) && +x === +x
export const connectionEdgesToKeyPairs = <TConnection extends { readonly edges: ReadonlyArray<{ node: any }> }>(
  connection: TConnection | null,
  valuePath: string = 'rowId',
  labelPath: string = 'name'
) =>
  map(connection?.edges, ({ node }) => ({
    value: get(node, valuePath, ''),
    label: get(node, labelPath, '')
  }))
export const connectionNodesToKeyPairs = <TConnection extends { readonly nodes: ReadonlyArray<any> }>(
  connection: TConnection | null,
  valuePath: string = 'rowId',
  labelPath: string = 'name'
) =>
  map(connection?.nodes, (node) => ({
    value: get(node, valuePath, ''),
    label: get(node, labelPath, '')
  }))
export const initials = (str?: string | null) =>
  reduce(split(str), (acc, word) => (!isNumeric(word) ? `${acc}${toUpper(word.charAt(0))}` : `${acc}${word}`), '')
export const humanize = (str?: string): string => startCase(toLower(str))
export const shortDate = (date: string | Date): string => dayjs(date).format('MM/DD')
export const friendlyDate = (maybeDate: Date | string) => dayjs(maybeDate).format('LL')
export const longDate = (maybeDate: Date | string) => dayjs(maybeDate).format('LLL')
export const enumTypeToKeyPairs = (type: object, formatter: Function = startCase) =>
  map(entries(type), ([label, value]) => ({
    label: formatter(label),
    value: value
  }))
export const toCurrency = (amount: number, locale: string = 'en') => {
  if (!formatterMap.has(locale)) {
    formatterMap.set(
      locale,
      new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'USD'
      })
    )
  }

  return formatterMap.get(locale).format(amount)
}
