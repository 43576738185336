import { type CreateReferralPartnerModal_query$key } from '@app/__generated__/CreateReferralPartnerModal_query.graphql'
import { type CreateReferralPartnerModalCreateReferralPartnerMutation } from '@app/__generated__/CreateReferralPartnerModalCreateReferralPartnerMutation.graphql'
import { ReferralPartnerForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type CreateReferralPartnerInput } from '@app/lib'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const createReferralPartnerMutation = graphql`
  mutation CreateReferralPartnerModalCreateReferralPartnerMutation(
    $connections: [ID!]!
    $input: CreateReferralPartnerInput!
  ) {
    createReferralPartner(input: $input) {
      referralPartnerEdge @appendEdge(connections: $connections) {
        cursor
        node {
          id
          name
          rowId
          type
          slug
          ...ReferralPartnerForm_referralPartner
        }
      }
    }
  }
`
const queryFragment = graphql`
  fragment CreateReferralPartnerModal_query on Query {
    ...ReferralPartnerForm_query
  }
`

export interface CreateReferralPartnerModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  connectionId: string
  query: CreateReferralPartnerModal_query$key
}

export const CreateReferralPartnerModal: FC<CreateReferralPartnerModalProps> = ({
  connectionId,
  onClose,
  query,
  ...modalProps
}) => {
  const router = useRouter()
  const queryData = useFragment<CreateReferralPartnerModal_query$key>(queryFragment, query)
  const [createReferralPartner, isCreatingReferralPartner] =
    useMutation<CreateReferralPartnerModalCreateReferralPartnerMutation>(createReferralPartnerMutation)
  const { t } = useTranslation('admin')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      size='lg'
      title={t('New Referral Partner')}
      {...modalProps}
    >
      <ReferralPartnerForm
        isSaving={isCreatingReferralPartner}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (referralPartner) =>
            createReferralPartner({
              variables: {
                connections: [connectionId],
                input: {
                  referralPartner
                } as CreateReferralPartnerInput
              },
              onCompleted: async ({
                createReferralPartner: {
                  referralPartnerEdge: {
                    node: { slug }
                  }
                }
              }) => {
                await onClose()
                await router.push(`/admin/referralPartners/${slug}`)
              }
            }),
          [connectionId, createReferralPartner, onClose, router]
        )}
        query={queryData}
        referralPartner={null}
      />
    </UnsavedChangesModal>
  )
}
