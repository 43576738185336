import {
  type UserPhonesTable_user$data,
  type UserPhonesTable_user$key
} from '@app/__generated__/UserPhonesTable_user.graphql'
import { type UserPhonesTablePaginationQuery } from '@app/__generated__/UserPhonesTablePaginationQuery.graphql'
import { IconBoolean, PaginationTable } from '@app/components'
import { humanize, type UserPhoneFilter, UserPhonesOrderBy } from '@app/lib'
import { Anchor } from '@mantine/core'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

type UserPhonesTableUserPhoneEdge = ElementOf<UserPhonesTable_user$data['userPhones']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment UserPhonesTable_user on User
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "UserPhoneFilter" }
    first: { type: "Int" }
    orderBy: { type: "[UserPhonesOrderBy!]", defaultValue: [IS_PRIMARY_DESC] }
  )
  @refetchable(queryName: "UserPhonesTablePaginationQuery") {
    userPhones(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "UserPhonesTable_user_userPhones", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          id
          isPrimary
          phone
          type
        }
      }
      totalCount
    }
  }
`

export interface UserPhonesTableProps {
  user: UserPhonesTable_user$key
}

export const UserPhonesTable: FC<UserPhonesTableProps> = ({ user }) => {
  const {
    data: userData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<UserPhonesTablePaginationQuery, UserPhonesTable_user$key>(paginationFragment, user)
  const { t } = useTranslation('admin')

  return (
    <>
      <PaginationTable<UserPhonesTableUserPhoneEdge, UserPhonesOrderBy, UserPhonesTablePaginationQuery, UserPhoneFilter>
        columns={useMemo<ColumnDef<UserPhonesTableUserPhoneEdge>[]>(
          () => [
            {
              accessorKey: 'node.type',
              id: 'type',
              header: t('Type'),
              enableSorting: true,
              cell: (props) => {
                const node = props.row.original.node

                return humanize(node.type)
              }
            },
            {
              id: 'phone',
              header: t('Phone Number'),
              cell: (props) => {
                const node = props.row.original.node

                return <Anchor href={`tel:${node.phone}`}>{node.phone}</Anchor>
              }
            },
            {
              accessorKey: 'node.isPrimary',
              id: 'isPrimary',
              header: t('Primary'),
              enableSorting: true,
              size: 30,
              cell: (props) => {
                const node = props.row.original.node

                return <IconBoolean value={node.isPrimary} />
              }
            }
          ],
          [t]
        )}
        data={userData?.userPhones?.edges}
        isFilterable
        getFilterFromSearch={useCallback(
          (search) =>
            !isEmpty(search)
              ? {
                  phone: {
                    includesInsensitive: search
                  }
                }
              : null,
          []
        )}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'isPrimary',
              desc: true
            }
          ],
          []
        )}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            isPrimary: {
              asc: UserPhonesOrderBy.IsPrimaryAsc,
              desc: UserPhonesOrderBy.IsPrimaryDesc
            },
            type: {
              asc: UserPhonesOrderBy.TypeAsc,
              desc: UserPhonesOrderBy.TypeDesc
            }
          }),
          []
        )}
        totalCount={userData?.userPhones?.totalCount}
      />
    </>
  )
}
