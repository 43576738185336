import { Button, Center, Container, Group, Image, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { DateInput, SelectInput, Form } from '@app/components'
import React, { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'
import useTranslation from 'next-translate/useTranslation'
import { map } from 'lodash'
import { type AppointmentTypeStep_query$key } from '@app/__generated__/AppointmentTypeStep_query.graphql'
import { useForm, zodResolver } from '@mantine/form'
import { z } from 'zod'

const queryFragment = graphql`
  fragment AppointmentTypeStep_query on Query {
    supportedLanguages {
      nodes {
        name
      }
    }
  }
`

export interface AppointmentTypeStepProps {
  isSaving?: boolean
  onPrev?: () => void
  onNext?: (data: { appointmentType: 'preBirth' | 'postBirth'; babyDOB: Date; language: string }) => void
  query: AppointmentTypeStep_query$key
}

export interface AppointmentTypeFormProps {
  appointmentType: 'preBirth' | 'postBirth'
  babyDOB: Date
  language: string
}

export const AppointmentTypeStep: FC<AppointmentTypeStepProps> = ({ isSaving, onPrev, onNext, query }) => {
  const queryData = useFragment<AppointmentTypeStep_query$key>(queryFragment, query)
  const { t } = useTranslation('booking')
  const theme = useMantineTheme()

  const appointmentTypeForm = useForm<AppointmentTypeFormProps>({
    initialValues: {
      appointmentType: 'preBirth',
      babyDOB: new Date(),
      language: 'English'
    },
    validate: zodResolver(
      z.object({
        appointmentType: z.enum(['preBirth', 'postBirth']),
        babyDOB: z.date(),
        language: z.string()
      })
    )
  })

  return (
    <Form
      isSaving={isSaving}
      isValid={appointmentTypeForm.isValid()}
      onSubmit={appointmentTypeForm.onSubmit((data) => {
        onNext?.({
          appointmentType: data.appointmentType,
          babyDOB: data.babyDOB,
          language: data.language
        })
      })}
      buttons={false}
    >
      <Center sx={{ marginTop: 40 }}>
        <Container size={500}>
          <Stack>
            <Center>
              <Title
                order={1}
                sx={{ fontFamily: theme.fontFamily }}
              >
                {t("Let's find the right")}
              </Title>
            </Center>
            <Center>
              <Title
                order={1}
                sx={{ fontFamily: theme.fontFamily }}
              >
                {t('appointment for you')}
              </Title>
            </Center>
            <Title
              mt={20}
              order={5}
              sx={{ fontFamily: theme.fontFamily }}
            >
              {t('When would you like to see a lactation consultant?')}
            </Title>
            <Button
              color='dark'
              size='xl'
              onClick={() => appointmentTypeForm.setFieldValue('appointmentType', 'preBirth')}
              variant={appointmentTypeForm.values.appointmentType === 'preBirth' ? 'filled' : 'outline'}
              leftIcon={
                appointmentTypeForm.values.appointmentType === 'preBirth' ? (
                  <Image
                    alt='fetus'
                    src='/icons/fetus-white.svg'
                    height={50}
                  />
                ) : (
                  <Image
                    alt='fetus'
                    src='/icons/fetus.svg'
                    height={50}
                  />
                )
              }
              sx={{
                fontFamily: theme.fontFamily
              }}
            >
              Before baby arrives
            </Button>
            <Button
              size='xl'
              variant={appointmentTypeForm.values.appointmentType === 'postBirth' ? 'filled' : 'outline'}
              color='dark'
              onClick={() => appointmentTypeForm.setFieldValue('appointmentType', 'postBirth')}
              leftIcon={
                appointmentTypeForm.values.appointmentType === 'postBirth' ? (
                  <Image
                    alt='nursing mother'
                    src='/icons/nursing-mother-white.svg'
                    height={50}
                  />
                ) : (
                  <Image
                    alt='nursing mother'
                    src='/icons/nursing-mother.svg'
                    height={50}
                  />
                )
              }
              sx={{
                fontFamily: theme.fontFamily
              }}
            >
              After baby arrives
            </Button>
            <Title
              mt={20}
              order={5}
              sx={{ fontFamily: theme.fontFamily }}
            >
              {t("What is your baby's (expected) date of birth?")}
            </Title>
            <DateInput {...appointmentTypeForm.getInputProps('babyDOB')} />
            <Title
              mt={20}
              order={5}
              sx={{ fontFamily: theme.fontFamily }}
            >
              {t('Language Preference')}
            </Title>
            <Text
              mt={-10}
              sx={{
                fontFamily: theme.fontFamily,
                fontSize: 12
              }}
            >
              {t(
                'We speak your language! Let us know what language you prefer to use when meeting with your clinician'
              )}
            </Text>
            <SelectInput data={useMemo(() => map(queryData?.supportedLanguages?.nodes, 'name'), [queryData])} />

            <Group
              mt='xl'
              position='apart'
              grow
              spacing='xl'
            >
              <Button
                color='dark'
                onClick={onPrev}
              >
                Back
              </Button>
              <Button
                color='dark'
                type='submit'
              >
                Next
              </Button>
            </Group>
          </Stack>
        </Container>
      </Center>
    </Form>
  )
}
