import { type CreateUserPaymentMethodModal_user$key } from '@app/__generated__/CreateUserPaymentMethodModal_user.graphql'
import {
  type CreateUserPaymentMethodModalCreateUserPaymentMethodMutation,
  type UserPaymentMethodInput
} from '@app/__generated__/CreateUserPaymentMethodModalCreateUserPaymentMethodMutation.graphql'
import { UnsavedChangesModal, type UnsavedChangesModalProps, UserPaymentMethodForm } from '@app/components'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const createUserPaymentMethodMutation = graphql`
  mutation CreateUserPaymentMethodModalCreateUserPaymentMethodMutation(
    $connections: [ID!]!
    $input: CreateUserPaymentMethodInput!
  ) {
    createUserPaymentMethod(input: $input) {
      userPaymentMethodEdge @appendEdge(connections: $connections) {
        cursor
        node {
          isActive
          isPrimary
          ...UserPaymentMethodForm_userPaymentMethod
        }
      }
    }
  }
`
const userFragment = graphql`
  fragment CreateUserPaymentMethodModal_user on User {
    rowId
    ...UserPaymentMethodForm_user
  }
`

export interface CreateUserPaymentMethodModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  connectionId: string
  user: CreateUserPaymentMethodModal_user$key
}

export const CreateUserPaymentMethodModal: FC<CreateUserPaymentMethodModalProps> = ({
  connectionId,
  onClose,
  user,
  ...modalProps
}) => {
  const userData = useFragment<CreateUserPaymentMethodModal_user$key>(userFragment, user)
  const [createUserPaymentMethod, isCreatingUserPaymentMethod] =
    useMutation<CreateUserPaymentMethodModalCreateUserPaymentMethodMutation>(createUserPaymentMethodMutation)
  const { t } = useTranslation('admin')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      size='md'
      title={t('New Payment Method')}
      {...modalProps}
    >
      <UserPaymentMethodForm
        isSaving={isCreatingUserPaymentMethod}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (userPaymentMethod) =>
            createUserPaymentMethod({
              variables: {
                connections: [connectionId],
                input: {
                  userPaymentMethod: {
                    ...userPaymentMethod,
                    userId: userData?.rowId
                  } as UserPaymentMethodInput
                }
              },
              onCompleted: () => {
                onClose()
              }
            }),
          [connectionId, createUserPaymentMethod, onClose, userData?.rowId]
        )}
        user={userData}
        userPaymentMethod={null}
      />
    </UnsavedChangesModal>
  )
}
