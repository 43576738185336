import {
  type CliniciansTable_query$data,
  type CliniciansTable_query$key
} from '@app/__generated__/CliniciansTable_query.graphql'
import { type CliniciansTablePaginationQuery } from '@app/__generated__/CliniciansTablePaginationQuery.graphql'
import { PaginationTable, UserAvatar } from '@app/components'
import { type ClinicianFilter, CliniciansOrderBy } from '@app/lib'
import { ActionIcon } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type CliniciansTableUserEdge = ElementOf<CliniciansTable_query$data['clinicians']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment CliniciansTable_query on Query
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "ClinicianFilter" }
    first: { type: "Int" }
    orderBy: { type: "[CliniciansOrderBy!]", defaultValue: [USER_BY_USER_ID__LAST_NAME_ASC] }
  )
  @refetchable(queryName: "CliniciansTablePaginationQuery") {
    clinicians(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "CliniciansTable_query_clinicians", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          slug
          user {
            firstName
            lastName
            middleName
            slug
            ...UserAvatar_user
          }
        }
      }
      totalCount
    }
  }
`

export interface CliniciansTableProps {
  query: CliniciansTable_query$key
}

export const CliniciansTable: FC<CliniciansTableProps> = ({ query }) => {
  const {
    data: queryData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<CliniciansTablePaginationQuery, CliniciansTable_query$key>(paginationFragment, query)
  const { t } = useTranslation('admin')

  return (
    <PaginationTable<CliniciansTableUserEdge, CliniciansOrderBy, CliniciansTablePaginationQuery, ClinicianFilter>
      columns={useMemo<ColumnDef<CliniciansTableUserEdge>[]>(
        () => [
          {
            id: 'avatar',
            header: t('Avatar'),
            size: 50,
            cell: (props) => (
              <UserAvatar
                alt='logo'
                size='md'
                user={props.row.original.node.user}
              />
            )
          },
          {
            accessorKey: 'node.user.firstName',
            id: 'firstName',
            enableSorting: true,
            enableMultiSort: false,
            header: t('First Name')
          },
          {
            accessorKey: 'node.user.lastName',
            id: 'lastName',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Last Name')
          },
          {
            id: 'actions',
            size: 30,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <ActionIcon
                  color='blue'
                  component={Link}
                  href={`/admin/users/${node.user.slug}/clinician`}
                  title={t('View Clinician')}
                  variant='transparent'
                >
                  <IconChevronRight size={14} />
                </ActionIcon>
              )
            }
          }
        ],
        [t]
      )}
      data={queryData?.clinicians?.edges}
      getFilterFromSearch={useCallback(
        (search) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    user: {
                      email: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    user: {
                      firstName: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    user: {
                      lastName: {
                        includesInsensitive: search
                      }
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={useMemo(
        () => [
          {
            id: 'lastName',
            desc: false
          }
        ],
        []
      )}
      isFilterable
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={useMemo(
        () => ({
          firstName: {
            asc: CliniciansOrderBy.UserByUserIdFirstNameAsc,
            desc: CliniciansOrderBy.UserByUserIdFirstNameDesc
          },
          lastName: {
            asc: CliniciansOrderBy.UserByUserIdLastNameAsc,
            desc: CliniciansOrderBy.UserByUserIdLastNameDesc
          }
        }),
        []
      )}
      totalCount={queryData?.clinicians?.totalCount}
    />
  )
}
