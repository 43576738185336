/**
 * @generated SignedSource<<46eea66d62c8c8f464469c248cac8e36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateUserPaymentMethodModal_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserPaymentMethodForm_user">;
  readonly " $fragmentType": "UpdateUserPaymentMethodModal_user";
};
export type UpdateUserPaymentMethodModal_user$key = {
  readonly " $data"?: UpdateUserPaymentMethodModal_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateUserPaymentMethodModal_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateUserPaymentMethodModal_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserPaymentMethodForm_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ccd3d995f3f9daab68368295c905d343";

export default node;
