import { getCountries, getCountry } from '@loophq/country-state-list'
import { map, toUpper } from 'lodash'
import { getPostalCodeData } from 'postal-code-data'

export const getCountriesData = () =>
  map(getCountries(), (country: any) => ({
    label: `${country.name} (${country.code})`,
    value: country.code
  }))

export const getProvincesData = (countryCode: string) => {
  const country = getCountry(toUpper(countryCode))

  return map(country?.states, (state: string) => ({
    label: state,
    value: state
  }))
}

export const getPostalCodesData = (countryCode: string) => getPostalCodeData(countryCode)
