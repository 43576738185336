import { Form, TextInput, PhoneInput, SelectInput } from '@app/components'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useMemo } from 'react'
import z from 'zod'
import { useForm, zodResolver } from '@mantine/form'
import { Text, Title, Center, Group, rem, Grid, Button, Flex, Radio, Stack, Space } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { IconCalendar } from '@tabler/icons-react'
import { Icon123 } from '@tabler/icons-react'
import { zodStatesUS, zodStringEmptyOrNotOnlySpaces, zodStringRequiredAndNotOnlySpaces } from '@app/lib'
import { graphql, useFragment } from 'react-relay'
import dayjs from 'dayjs'
import { type BookPersonalInfoForm_user$key } from '@app/__generated__/BookPersonalInfoForm_user.graphql'
import { includes, replace } from 'lodash'

const userFragment = graphql`
  fragment BookPersonalInfoForm_user on User {
    firstName
    lastName
    birthdateAt
    userPhones(condition: { isPrimary: true }, first: 1) {
      nodes {
        phone
      }
    }
    userAddresses(condition: { isPrimary: true }, first: 1) {
      nodes {
        address {
          city
          country
          postalCode
          stateOrProvince
          street1
          street2
        }
      }
    }
    userInsurances {
      totalCount
    }
    userPaymentMethods(condition: { provider: STRIPE, isActive: true }) {
      totalCount
    }
  }
`

const schema = z.object({
  firstName: zodStringRequiredAndNotOnlySpaces(),
  lastName: zodStringRequiredAndNotOnlySpaces(),
  birthdateAt: z.date().nullable(),
  phone: zodStringRequiredAndNotOnlySpaces().regex(
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    'Invalid Number!'
  ),
  address: z.object({
    street1: zodStringRequiredAndNotOnlySpaces(),
    street2: zodStringEmptyOrNotOnlySpaces(),
    city: zodStringRequiredAndNotOnlySpaces(),
    postalCode: zodStringRequiredAndNotOnlySpaces(),
    stateOrProvince: zodStatesUS
  }),
  paymentMethod: z.enum(['creditCard', 'insurance'])
})

export interface BookPersonalInfoFormProps {
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (data: z.infer<typeof schema>) => void
  user: BookPersonalInfoForm_user$key
}


export const BookPersonalInfoForm: FC<BookPersonalInfoFormProps> = ({ isSaving, onSubmit, user }) => {
  const { t } = useTranslation('booking')
  const userData = useFragment(userFragment, user)

  const userPhoneData = userData?.userPhones?.nodes?.[0]?.phone
  const userAddressData = userData?.userAddresses?.nodes?.[0]?.address

  const form = useForm({
    initialValues: {
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      birthdateAt: userData?.birthdateAt ? dayjs(userData?.birthdateAt).toDate() : null,
      phone: userPhoneData || '',
      address: {
        street1: userAddressData?.street1 || '',
        street2: userAddressData?.street2 || '',
        city: userAddressData?.city || '',
        postalCode: userAddressData?.postalCode || '',
        stateOrProvince: userAddressData?.stateOrProvince || ''
      },
      paymentMethod:
        userData?.userInsurances?.totalCount > 0
          ? 'insurance'
          : userData?.userPaymentMethods?.totalCount > 0
          ? 'creditCard'
          : ''
    },
    validate: zodResolver(schema),
    validateInputOnChange: true
  })
  const now = useMemo(() => dayjs().toDate(), [])

  return (
    <Center
      sx={(theme) => ({
        padding: rem(30),
        [theme.fn.smallerThan('sm')]: {
          padding: rem(12)
        }
      })}
    >
      <Form
        buttons={false}
        isSaving={isSaving}
        isValid={form.isValid()}
        onSubmit={form.onSubmit(onSubmit)}
      >
        <Stack spacing='sm'>
          <Title order={5}>{t('Patient Details')}</Title>
          <Text>{t('Full Name')}</Text>
          <Group
            spacing='sm'
            noWrap
          >
            <TextInput
              autoComplete='given-name'
              label={t('First Name')}
              required
              w='100%'
              {...form.getInputProps('firstName')}
            />
            <TextInput
              autoComplete='family-name'
              label={t('Last Name')}
              required
              w='100%'
              {...form.getInputProps('lastName')}
            />
          </Group>
          <DateInput
            disabled={isSaving}
            label={t('Date of Birth')}
            icon={<IconCalendar size={18} />}
            valueFormat='MM/DD/YYYY'
            required
            maxDate={now}
            onInput={(evt: any) => {
              if (!evt.nativeEvent.data) {
                return
              }

              evt.target.value = replace(evt.target.value, /[^0-9/]/g, '')
              if (includes([2, 5], evt.target.value.length)) {
                evt.target.value = evt.target.value + '/'
              }
              if (evt.target.value.length >= 10) {
                evt.target.value = evt.target.value.slice(0, 10)
                evt.target.blur()
              }
            }}
            placeholder='MM/DD/YYYY'
            {...form.getInputProps('birthdateAt')}
          />
          <PhoneInput
            size='sm'
            mb='xl'
            label={t('Phone')}
            required
            {...form.getInputProps('phone')}
          />
          <Text>{t('Mailing Address')}</Text>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                autoComplete='address-line1'
                label={t('Street Address Line 1')}
                required
                {...form.getInputProps('address.street1')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                autoComplete='address-line2'
                label={t('Street Address Line 2')}
                {...form.getInputProps('address.street2')}
              />
            </Grid.Col>
            <Grid.Col span={5}>
              <TextInput
                autoComplete='address-level2'
                label={t('City')}
                required
                {...form.getInputProps('address.city')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                autoComplete='postal-code'
                icon={<Icon123 size={18} />}
                label={t('Postal Code')}
                required
                {...form.getInputProps('address.postalCode')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <SelectInput
                label={t('State')}
                autoComplete='list'
                searchable
                required
                data={zodStatesUS.options}
                {...form.getInputProps('address.stateOrProvince')}
              />
            </Grid.Col>
          </Grid>
          <Title order={5}>{t('Payment Options')}</Title>
          <Radio.Group
            label={t('Will you be using insurance?')}
            {...form.getInputProps('paymentMethod')}
          >
            <Group
              noWrap
              align='flex-start'
            >
              <Radio
                value='creditCard'
                label={t("I'll pay by credit card")}
              />
              <Radio
                value='insurance'
                label={t("I'll use insurance")}
              />
            </Group>
          </Radio.Group>
          <Flex justify='flex-end'>
            <Button disabled={isSaving}>{t('<  Back')}</Button>
            <Space w='sm' />
            <Button
              disabled={isSaving || !form.isValid()}
              type='submit'
            >{t('Next')}</Button>
          </Flex>
        </Stack>
      </Form>
    </Center>
  )
}
