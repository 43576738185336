/**
 * @generated SignedSource<<269c3d3ef417c38e0b761eb3f62791d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserPaymentMethodForm_userPaymentMethod$data = {
  readonly id: string;
  readonly isActive: boolean;
  readonly isPrimary: boolean;
  readonly methodData: any;
  readonly " $fragmentSpreads": FragmentRefs<"UserPaymentMethodDisplay_userPaymentMethod">;
  readonly " $fragmentType": "UserPaymentMethodForm_userPaymentMethod";
};
export type UserPaymentMethodForm_userPaymentMethod$key = {
  readonly " $data"?: UserPaymentMethodForm_userPaymentMethod$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserPaymentMethodForm_userPaymentMethod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserPaymentMethodForm_userPaymentMethod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPrimary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "methodData",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserPaymentMethodDisplay_userPaymentMethod"
    }
  ],
  "type": "UserPaymentMethod",
  "abstractKey": null
};

(node as any).hash = "8bcb15789913f2545819b217138e5918";

export default node;
