import { type CreateClinicianAvailabilityModal_query$key } from '@app/__generated__/CreateClinicianAvailabilityModal_query.graphql'
import { type CreateClinicianAvailabilityModalCreateClinicianAvailabilityMutation } from '@app/__generated__/CreateClinicianAvailabilityModalCreateClinicianAvailabilityMutation.graphql'
import { ClinicianAvailabilityForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type CreateClinicianAvailabilityInput } from '@app/lib'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const createClinicianAvailabilityMutation = graphql`
  mutation CreateClinicianAvailabilityModalCreateClinicianAvailabilityMutation(
    $connections: [ID!]!
    $input: CreateClinicianAvailabilityInput!
  ) {
    createClinicianAvailability(input: $input) {
      clinicianAvailabilityEdge @appendEdge(connections: $connections) {
        cursor
        node {
          id
          rowId
          startsAt
          endsAt
          isExclusive
          clinician {
            user {
              firstName
              lastName
              slug
            }
          }
          googleCalendarEventId
          ...ClinicianAvailabilityForm_clinicianAvailability
        }
      }
    }
  }
`
const queryFragment = graphql`
  fragment CreateClinicianAvailabilityModal_query on Query {
    ...ClinicianAvailabilityForm_query
  }
`

export interface CreateClinicianAvailabilityModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  connectionId: string
  query: CreateClinicianAvailabilityModal_query$key
}

export const CreateClinicianAvailabilityModal: FC<CreateClinicianAvailabilityModalProps> = ({
  connectionId,
  onClose,
  query,
  ...modalProps
}) => {
  const queryData = useFragment<CreateClinicianAvailabilityModal_query$key>(queryFragment, query)
  const [createClinicianAvailability, isCreatingClinicianAvailability] =
    useMutation<CreateClinicianAvailabilityModalCreateClinicianAvailabilityMutation>(
      createClinicianAvailabilityMutation
    )
  const { t } = useTranslation('booking')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      size='md'
      title={t('New Clinician Availability')}
      {...modalProps}
    >
      <ClinicianAvailabilityForm
        isSaving={isCreatingClinicianAvailability}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (clinicianAvailability) =>
            createClinicianAvailability({
              variables: {
                connections: [connectionId],
                input: {
                  clinicianAvailability
                } as CreateClinicianAvailabilityInput
              },
              onCompleted: onClose
            }),
          [connectionId, createClinicianAvailability, onClose]
        )}
        query={queryData}
        clinicianAvailability={null}
      />
    </UnsavedChangesModal>
  )
}
