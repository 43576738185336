import { createStyles, Input, type InputProps } from '@mantine/core'
import { useId } from '@mantine/hooks'
import { IconPhone } from '@tabler/icons-react'
import { type FC } from 'react'
import { default as PhoneInputComponent } from 'react-phone-number-input/input'

const useStyles = createStyles((theme) => ({
  responsiveInput: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      width: '100%',
      maxWidth: 'inherit'
    }
  }
}))

export interface PhoneInputProps extends InputProps {
  error?: string
  label?: string | boolean
  placeholder?: string | boolean
}

export const PhoneInput: FC<PhoneInputProps> = ({ error, label = false, required = false, ...inputProps }) => {
  const id = useId()
  const { classes } = useStyles()

  return (
    <Input.Wrapper
      id={id}
      withAsterisk={false}
      label={label}
      required={required}
      className={classes.responsiveInput}
      error={error}
    >
      <Input
        id={id}
        size='lg'
        component={PhoneInputComponent}
        icon={<IconPhone size={18} />}
        type='tel'
        country='US'
        {...inputProps}
      />
    </Input.Wrapper>
  )
}
