import { type AppointmentForm_appointment$key } from '@app/__generated__/AppointmentForm_appointment.graphql'
import { type AppointmentForm_query$key } from '@app/__generated__/AppointmentForm_query.graphql'
import { DateInput, Form, SelectInput, TextareaInput, TimeInput } from '@app/components'
import {
  type AppointmentInput,
  type AppointmentPatch,
  AppointmentsSelectionMethod,
  AppointmentsStatus,
  enumTypeToKeyPairs,
  zodStringEmptyOrNotOnlySpaces
} from '@app/lib'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import dayjs from 'dayjs'
import { find, map } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const appointmentFragment = graphql`
  fragment AppointmentForm_appointment on Appointment {
    clinicianId
    notes
    patientId
    patient {
      user {
        firstName
        lastName
        userChildren {
          nodes {
            birthdateAt
            dueAt
            name
            rowId
          }
        }
      }
    }
    selectionMethod
    serviceId
    startsAt
    status
    userChildId
  }
`
const dateFormatWithoutZone = 'YYYY-MM-DDTHH:mm:ss.SSS'
const queryFragment = graphql`
  fragment AppointmentForm_query on Query {
    clinicians(orderBy: [USER_BY_USER_ID__FIRST_NAME_ASC, USER_BY_USER_ID__LAST_NAME_ASC]) {
      nodes {
        rowId
        user {
          firstName
          lastName
        }
      }
    }
    services(orderBy: [NAME_ASC]) {
      nodes {
        duration {
          minutes
        }
        name
        rowId
      }
    }
  }
`

export interface AppointmentFormProps {
  appointment: AppointmentForm_appointment$key
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (data: AppointmentInput | AppointmentPatch) => void
  query: AppointmentForm_query$key
}

export interface AppointmentFormData {
  clinicianId: string
  notes: string
  patientId: string
  selectionMethod: AppointmentsSelectionMethod
  serviceId: string
  status: AppointmentsStatus
  userChildId: string
  _startsAtDate: Date
  _startsAtTime: Date
}

export const AppointmentForm: FC<AppointmentFormProps> = ({ appointment, isSaving, onChange, onSubmit, query }) => {
  const appointmentData = useFragment<AppointmentForm_appointment$key>(appointmentFragment, appointment)
  const queryData = useFragment<AppointmentForm_query$key>(queryFragment, query)
  const { t } = useTranslation('admin')

  const now = useMemo(() => dayjs(), [])
  const form = useForm<AppointmentFormData>({
    initialValues: {
      clinicianId: appointmentData?.clinicianId || null,
      notes: appointmentData?.notes || '',
      patientId: appointmentData?.patientId || null,
      selectionMethod: (appointmentData?.selectionMethod as AppointmentsSelectionMethod) || null,
      serviceId: appointmentData?.serviceId || null,
      status: (appointmentData?.status as AppointmentsStatus) || AppointmentsStatus.Unconfirmed,
      userChildId: appointmentData?.userChildId || null,
      ...useMemo(() => {
        const startsAt = appointmentData?.startsAt
          ? dayjs(appointmentData.startsAt)
          : now.add(1, 'hour').minute(0).second(0).millisecond(0)

        // we need to return two distinct date objects, so that each form field doesn't corrupt the other's data
        return {
          _startsAtDate: startsAt.toDate(),
          _startsAtTime: startsAt.toDate()
        }
      }, [appointmentData?.startsAt, now])
    },
    validate: zodResolver(
      z.object({
        _startsAtDate: z.date(),
        _startsAtTime: z.date(),
        clinicianId: z.string().uuid(),
        notes: zodStringEmptyOrNotOnlySpaces(t('Cannot be only spaces')),
        selectionMethod: z.nativeEnum(AppointmentsSelectionMethod).or(z.null()),
        serviceId: z.string().uuid(),
        status: z.nativeEnum(AppointmentsStatus),
        userChildId: z.string().uuid().or(z.null())
      })
    ),
    validateInputOnChange: true
  })

  useShallowEffect(() => {
    if (onChange) {
      onChange(form.isDirty())
    }
  }, [form.values])

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(({ clinicianId, notes, serviceId, _startsAtDate, _startsAtTime, ...values }) => {
        const service = find(queryData?.services.nodes, ['rowId', serviceId])
        const startsAtTime = dayjs(_startsAtTime)
        const startsAt = dayjs(_startsAtDate)
          .hour(startsAtTime.hour())
          .minute(startsAtTime.minute())
          .second(0)
          .millisecond(0)

        return onSubmit({
          ...values,
          clinicianId,
          endsAt: dayjs(startsAt)
            .add(service.duration?.minutes)
            .format(dateFormatWithoutZone),
          notes: notes || null,
          serviceId,
          startsAt: startsAt.format(dateFormatWithoutZone)
        } as AppointmentInput | AppointmentPatch)
      })}
    >
      <DateInput
        disabled={isSaving}
        label={t('Date')}
        minDate={now.toDate()}
        maxDate={now.add(1, 'year').toDate()}
        required
        {...form.getInputProps('_startsAtDate')}
      />
      <TimeInput
        disabled={isSaving}
        label={t('Time')}
        required
        {...form.getInputProps('_startsAtTime')}
      />
      <SelectInput
        disabled={isSaving}
        data={useMemo(
          () =>
            map(queryData.clinicians.nodes, (clinician) => ({
              label: `${clinician.user.firstName} ${clinician.user.lastName}`,
              value: clinician.rowId
            })),
          [queryData?.clinicians?.nodes]
        )}
        label={t('Clinician')}
        required
        searchable
        {...form.getInputProps('clinicianId')}
      />
      <SelectInput
        disabled
        data={[
          {
            label: `${appointmentData?.patient?.user?.firstName} ${appointmentData?.patient?.user?.lastName}`,
            value: appointmentData?.patientId
          }
        ]}
        label={t('Patient')}
        {...form.getInputProps('patientId')}
      />
      <SelectInput
        clearable
        disabled={isSaving}
        data={useMemo(
          () =>
            map(appointmentData?.patient?.user?.userChildren?.nodes, (child) => {
              const now = dayjs()
              let age

              if (child?.birthdateAt) {
                const birthdate = dayjs(child.birthdateAt)
                const ageInYears = now.diff(birthdate, 'year')

                age =
                  ageInYears >= 1
                    ? t('admin:x_years', { x: ageInYears })
                    : t('admin:x_months', { x: now.diff(birthdate, 'month') })
              } else {
                age = t('admin:due_in_x_months', {
                  x: Math.round(dayjs(child?.dueAt).diff(now, 'month', true))
                })
              }

              return {
                label: `${child.name} (${age})`,
                value: child.rowId
              }
            }),
          [appointmentData?.patient?.user?.userChildren?.nodes, t]
        )}
        label={t('Child')}
        {...form.getInputProps('userChildId')}
      />
      <SelectInput
        disabled={isSaving || !form.values.clinicianId}
        data={useMemo(
          () =>
            map(queryData?.services.nodes, (service) => ({
              label: `${service.name} (${service.duration.minutes}min)`,
              value: service.rowId
            })),
          [queryData?.services.nodes]
        )}
        label={t('Service')}
        required
        searchable
        {...form.getInputProps('serviceId')}
      />
      <TextareaInput
        disabled={isSaving}
        label={t('Notes')}
        minRows={2}
        {...form.getInputProps('notes')}
      />
      <SelectInput
        disabled={isSaving}
        data={enumTypeToKeyPairs(AppointmentsStatus)}
        label={t('Status')}
        required
        searchable
        {...form.getInputProps('status')}
      />
      <SelectInput
        disabled={isSaving}
        data={enumTypeToKeyPairs(AppointmentsSelectionMethod)}
        label={t('Selection Method')}
        searchable
        mb='sm'
        {...form.getInputProps('selectionMethod')}
      />
    </Form>
  )
}
