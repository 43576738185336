import { type UsersTable_query$data, type UsersTable_query$key } from '@app/__generated__/UsersTable_query.graphql'
import { type UsersTablePaginationQuery } from '@app/__generated__/UsersTablePaginationQuery.graphql'
import { CreateUserModal, PaginationTable, UserAvatar } from '@app/components'
import { type UserFilter, UsersOrderBy } from '@app/lib'
import { ActionIcon, Anchor, Badge, Group } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { get, isEmpty } from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type UsersTableUserEdge = ElementOf<UsersTable_query$data['users']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment UsersTable_query on Query
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "UserFilter" }
    first: { type: "Int" }
    orderBy: { type: "[UsersOrderBy!]", defaultValue: [LAST_NAME_ASC] }
  )
  @refetchable(queryName: "UsersTablePaginationQuery") {
    users(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "UsersTable_query_users", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          clinician {
            slug
          }
          email
          isAdmin
          firstName
          lastName
          patient {
            slug
          }
          userPhones(filter: { isPrimary: { equalTo: true } }, first: 1) {
            nodes {
              phone
            }
          }
          slug
          verifiedAt
          ...UserAvatar_user
        }
      }
      totalCount
    }
    ...CreateUserModal_query
  }
`

export interface UsersTableProps {
  onCreateModalClose: () => void
  query: UsersTable_query$key
  showCreateModal: boolean
}

export const UsersTable: FC<UsersTableProps> = ({ onCreateModalClose, showCreateModal, query }) => {
  const {
    data: queryData,
    hasNext,
    isLoadingNext,
    loadNext,
    refetch
  } = usePaginationFragment<UsersTablePaginationQuery, UsersTable_query$key>(paginationFragment, query)
  const { t } = useTranslation('admin')

  return (
    <>
      <PaginationTable<UsersTableUserEdge, UsersOrderBy, UsersTablePaginationQuery, UserFilter>
        columns={useMemo<ColumnDef<UsersTableUserEdge>[]>(
          () => [
            {
              id: 'avatar',
              header: t('Avatar'),
              size: 50,
              cell: (props) => (
                <UserAvatar
                  alt='logo'
                  size='md'
                  user={props.row.original.node}
                />
              )
            },
            {
              accessorKey: 'node.firstName',
              id: 'firstName',
              enableSorting: true,
              enableMultiSort: false,
              header: t('First Name')
            },
            {
              accessorKey: 'node.lastName',
              id: 'lastName',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Last Name')
            },
            {
              accessorKey: 'node.email',
              id: 'email',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Email'),
              cell: (props) => {
                const email = props.row.original.node.email

                return email ? <Anchor href={`mailto:${email}`}>{email}</Anchor> : '-'
              }
            },
            {
              id: 'phone',
              header: t('Phone'),
              cell: (props) => {
                const primaryPhone = get(props.row.original.node, 'userPhones.nodes[0].phone')

                return primaryPhone ? <Anchor href={`tel:${primaryPhone}`}>{primaryPhone}</Anchor> : '-'
              }
            },
            {
              id: 'type',
              header: t('Type'),
              cell: (props) => {
                const node = props.row.original.node

                return (
                  <Group
                    position='left'
                    spacing='xs'
                  >
                    {!node.isAdmin && !node.clinician && !node.patient && (
                      <Badge
                        color='gray'
                        variant='light'
                      >
                        Visitor
                      </Badge>
                    )}
                    {node.isAdmin && (
                      <Badge
                        color='pink'
                        variant='light'
                      >
                        Admin
                      </Badge>
                    )}
                    {node.clinician && (
                      <Badge
                        color='lime'
                        variant='light'
                      >
                        Clinician
                      </Badge>
                    )}
                    {node.patient && (
                      <Badge
                        color='cyan'
                        variant='light'
                      >
                        Patient
                      </Badge>
                    )}
                  </Group>
                )
              }
            },
            {
              accessorKey: 'node.verifiedAt',
              id: 'verifiedAt',
              enableSorting: false,
              enableMultiSort: false,
              header: t('Email Verified'),
              cell: (props) => {
                const verifiedAt = props.row.original.node.verifiedAt

                return verifiedAt ? dayjs(verifiedAt).format('MM/DD/YYYY') : '-'
              }
            },
            {
              id: 'actions',
              size: 30,
              cell: (props) => (
                <ActionIcon
                  color='blue'
                  component={Link}
                  href={`/admin/users/${props.row.original.node.slug}`}
                  title={t('View User')}
                  variant='transparent'
                >
                  <IconChevronRight size={14} />
                </ActionIcon>
              )
            }
          ],
          [t]
        )}
        data={queryData?.users?.edges}
        isFilterable
        getFilterFromSearch={useCallback(
          (search) =>
            !isEmpty(search)
              ? {
                  or: [
                    {
                      email: {
                        includesInsensitive: search
                      }
                    },
                    {
                      firstName: {
                        includesInsensitive: search
                      }
                    },
                    {
                      lastName: {
                        includesInsensitive: search
                      }
                    },
                    {
                      userPhones: {
                        some: {
                          phone: {
                            includesInsensitive: search
                          }
                        }
                      }
                    }
                  ]
                }
              : null,
          []
        )}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'lastName',
              desc: false
            }
          ],
          []
        )}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            email: {
              asc: UsersOrderBy.EmailAsc,
              desc: UsersOrderBy.EmailDesc
            },
            firstName: {
              asc: UsersOrderBy.FirstNameAsc,
              desc: UsersOrderBy.FirstNameDesc
            },
            lastName: {
              asc: UsersOrderBy.LastNameAsc,
              desc: UsersOrderBy.LastNameDesc
            }
          }),
          []
        )}
        totalCount={queryData?.users?.totalCount}
      />
      <CreateUserModal
        connectionId={queryData?.users?.__id}
        onClose={onCreateModalClose}
        opened={showCreateModal}
        query={queryData}
      />
    </>
  )
}
