import { type CreatePolicyModalCreatePolicyMutation } from '@app/__generated__/CreatePolicyModalCreatePolicyMutation.graphql'
import { PolicyForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type CreatePolicyInput } from '@app/lib'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useMutation } from 'react-relay'

const createPolicyMutation = graphql`
  mutation CreatePolicyModalCreatePolicyMutation($connections: [ID!]!, $input: CreatePolicyInput!) {
    createPolicy(input: $input) {
      policyEdge @appendEdge(connections: $connections) {
        node {
          id
          type
          ...PolicyForm_policy
        }
      }
    }
  }
`

export interface CreatePolicyModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  connectionId: string
}

export const CreatePolicyModal: FC<CreatePolicyModalProps> = ({ connectionId, onClose, ...modalProps }) => {
  const [createPolicy, isCreatingPolicy] = useMutation<CreatePolicyModalCreatePolicyMutation>(createPolicyMutation)
  const { t } = useTranslation('admin')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      size='xl'
      title={t('New Policy')}
      {...modalProps}
    >
      <PolicyForm
        isSaving={isCreatingPolicy}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (policy) =>
            createPolicy({
              variables: {
                connections: [connectionId],
                input: {
                  policy
                } as CreatePolicyInput
              },
              onCompleted: onClose
            }),
          [connectionId, createPolicy, onClose]
        )}
        policy={null}
      />
    </UnsavedChangesModal>
  )
}
