import { type CreateReferralPartnerSourceModal_referralPartner$key } from '@app/__generated__/CreateReferralPartnerSourceModal_referralPartner.graphql'
import { type CreateReferralPartnerSourceModalCreateReferralPartnerSourceMutation } from '@app/__generated__/CreateReferralPartnerSourceModalCreateReferralPartnerSourceMutation.graphql'
import { ReferralPartnerSourceForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type CreateReferralPartnerSourceInput } from '@app/lib'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const createReferralPartnerSourceMutation = graphql`
  mutation CreateReferralPartnerSourceModalCreateReferralPartnerSourceMutation(
    $connections: [ID!]!
    $input: CreateReferralPartnerSourceInput!
  ) {
    createReferralPartnerSource(input: $input) {
      referralPartnerSourceEdge @appendEdge(connections: $connections) {
        cursor
        node {
          id
          isActive
          name
          rowId
          description
        }
      }
    }
  }
`
const referralPartnerFragment = graphql`
  fragment CreateReferralPartnerSourceModal_referralPartner on ReferralPartner {
    rowId
  }
`

export interface CreateReferralPartnerSourceModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  connectionId: string
  referralPartner: CreateReferralPartnerSourceModal_referralPartner$key
}

export const CreateReferralPartnerSourceModal: FC<CreateReferralPartnerSourceModalProps> = ({
  connectionId,
  onClose,
  referralPartner,
  ...modalProps
}) => {
  const referralPartnerData = useFragment<CreateReferralPartnerSourceModal_referralPartner$key>(
    referralPartnerFragment,
    referralPartner
  )
  const [createReferralPartnerSource, isCreatingReferralPartnerSource] =
    useMutation<CreateReferralPartnerSourceModalCreateReferralPartnerSourceMutation>(
      createReferralPartnerSourceMutation
    )
  const { t } = useTranslation('admin')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      size='lg'
      title={t('New Referral Partner')}
      {...modalProps}
    >
      <ReferralPartnerSourceForm
        isSaving={isCreatingReferralPartnerSource}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (referralPartnerSource) =>
            createReferralPartnerSource({
              variables: {
                connections: [connectionId],
                input: {
                  referralPartnerSource: {
                    ...referralPartnerSource,
                    referralPartnerId: referralPartnerData.rowId
                  }
                } as CreateReferralPartnerSourceInput
              },
              onCompleted: onClose
            }),
          [connectionId, createReferralPartnerSource, onClose, referralPartnerData.rowId]
        )}
        referralPartnerSource={null}
      />
    </UnsavedChangesModal>
  )
}
