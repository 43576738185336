/**
 * @generated SignedSource<<d2b4d2d9500ed1e0a5a29d75c09d07f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateReferralPartnerModal_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ReferralPartnerForm_query">;
  readonly " $fragmentType": "CreateReferralPartnerModal_query";
};
export type CreateReferralPartnerModal_query$key = {
  readonly " $data"?: CreateReferralPartnerModal_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateReferralPartnerModal_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateReferralPartnerModal_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReferralPartnerForm_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "81535dee3158396e3915ff7161f862f1";

export default node;
