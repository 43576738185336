import { createStyles, Input, type InputWrapperProps } from '@mantine/core'
import { Link, RichTextEditor, type RichTextEditorProps } from '@mantine/tiptap'
import SubScript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { type FC, useCallback } from 'react'

const useStyles = createStyles((theme) => ({
  editorContent: {
    padding: theme.spacing.xs,
    minHeight: 180
  },
  editorRoot: {
    minHeight: 180
  },
  inputWrapper: {
    minHeight: 250
  }
}))

export interface RichTextInputProps extends Omit<InputWrapperProps, 'children' | 'onChange'> {
  disabled?: boolean
  editorProps?: Omit<RichTextEditorProps, 'children' | 'editor'>
  onChange?(value: string): void
  value?: string
}

// eslint-disable-next-line react/display-name
export const RichTextInput: FC<RichTextInputProps> = ({
  autoCapitalize,
  disabled,
  editorProps,
  onChange,
  spellCheck,
  value,
  ...props
}) => {
  const { classes } = useStyles()
  const editor = useEditor({
    content: value,
    editable: !disabled,
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      TextAlign.configure({
        types: ['heading', 'paragraph']
      })
    ],
    onUpdate: ({ editor }) => onChange?.(editor.getHTML())
  })

  return (
    <Input.Wrapper
      classNames={{
        root: classes.inputWrapper
      }}
      mb='xs'
      {...props}
    >
      <RichTextEditor
        classNames={{
          content: classes.editorContent,
          root: classes.editorRoot
        }}
        editor={editor}
        {...editorProps}
      >
        <RichTextEditor.Toolbar
          sticky
          stickyOffset={54}
        >
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold tabIndex={-1} />
            <RichTextEditor.Italic tabIndex={-1} />
            <RichTextEditor.Underline tabIndex={-1} />
            <RichTextEditor.Strikethrough tabIndex={-1} />
            <RichTextEditor.ClearFormatting tabIndex={-1} />
            <RichTextEditor.Code tabIndex={-1} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote tabIndex={-1} />
            <RichTextEditor.Hr tabIndex={-1} />
            <RichTextEditor.BulletList tabIndex={-1} />
            <RichTextEditor.OrderedList tabIndex={-1} />
            <RichTextEditor.Subscript tabIndex={-1} />
            <RichTextEditor.Superscript tabIndex={-1} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link tabIndex={-1} />
            <RichTextEditor.Unlink tabIndex={-1} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft tabIndex={-1} />
            <RichTextEditor.AlignCenter tabIndex={-1} />
            <RichTextEditor.AlignJustify tabIndex={-1} />
            <RichTextEditor.AlignRight tabIndex={-1} />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content
          autoCapitalize={autoCapitalize}
          onClick={useCallback(() => {
            if (!editor.isFocused) {
              editor.commands.focus('end')
            }
          }, [editor?.commands, editor?.isFocused])}
          spellCheck={spellCheck}
        />
      </RichTextEditor>
    </Input.Wrapper>
  )
}
