/**
 * @generated SignedSource<<882f236912a9969e5d10f77277577640>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserPaymentMethodDisplay_userPaymentMethod$data = {
  readonly methodData: any;
  readonly " $fragmentSpreads": FragmentRefs<"CardLogo_userPaymentMethod">;
  readonly " $fragmentType": "UserPaymentMethodDisplay_userPaymentMethod";
};
export type UserPaymentMethodDisplay_userPaymentMethod$key = {
  readonly " $data"?: UserPaymentMethodDisplay_userPaymentMethod$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserPaymentMethodDisplay_userPaymentMethod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserPaymentMethodDisplay_userPaymentMethod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "methodData",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CardLogo_userPaymentMethod"
    }
  ],
  "type": "UserPaymentMethod",
  "abstractKey": null
};

(node as any).hash = "5b22491dd425bdc8fb50832b64e82cc0";

export default node;
