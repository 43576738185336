import { useCallback, useState } from 'react'
import { Upload } from 'upload'

interface UploadOptions {
  data: Record<string, string | Blob> | FormData
  headers?: Record<string, string>
  url?: string
}

interface UploadResponse {
  acl: string
  bucket: string
  contentDisposition: string | null
  contentEncoding: string | null
  contentType: string
  etag: string
  fieldname: string
  key: string
  location: string
  metadata: object | null
  serverSideEncryption: string | null
  size: number
  storageClass: string
  versionId: string | null
}

export const useUploader = () => {
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const uploadFile = useCallback(async (file: File, { data, headers, url }: UploadOptions): Promise<UploadResponse> => {
    const uploader = new Upload({
      form: {
        ...data,
        file
      },
      headers,
      url: url || '/api/upload'
    })

    uploader.on('progress', setUploadProgress)

    setIsUploading(true)

    try {
      const response = await uploader.upload()

      return JSON.parse(response.data as string).data
    } finally {
      setIsUploading(false)
    }
  }, [])

  return {
    isUploading,
    uploadFile,
    uploadProgress
  }
}
