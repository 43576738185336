import { getStripe } from '@app/lib'
import { Elements } from '@stripe/react-stripe-js'
import { type StripeElementLocale } from '@stripe/stripe-js'
import { type FC, type PropsWithChildren } from 'react'

export interface StripeProviderProps extends PropsWithChildren {
  locale: StripeElementLocale
}

export const StripeProvider: FC<StripeProviderProps> = ({ children, locale = 'auto' }) => (
  <Elements
    options={{
      appearance: {
        theme: 'stripe'
      },
      locale
    }}
    stripe={getStripe({ locale })}
  >
    {children}
  </Elements>
)
