import { isEmpty } from 'lodash'
import { type FC, type ReactNode } from 'react'
import { RelayEnvironmentProvider } from 'react-relay'
import { Environment, Network, RecordSource, type RequestParameters, Store, type Variables } from 'relay-runtime'
import { type RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes'

let currentEnvironment: Environment | null = null
let currentToken: string | null = null

// this performs the actual network fetch from the client to the graphql endpoint,
// using whatever the current token is for authorization
const fetchFn = async (operation: RequestParameters, variables: Variables) => {
  const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  if (currentToken) {
    headers.Authorization = `Bearer ${currentToken}`
  }

  const res = await fetch(`${process.env.NEXT_PUBLIC_APP_URL}/api/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: operation.text,
      variables
    })
  })

  return res.json()
}

const initRelayEnvironment = (token: string | null | undefined, relayData: RecordMap | undefined) => {
  const configName = token || 'unauthenticated'

  // create a new relay environment, with an empty store, if there's not one already
  // or if the user signs in or out
  if (!currentEnvironment || (currentToken && !token) || (!currentToken && token)) {
    currentEnvironment = new Environment({
      configName,
      network: Network.create(fetchFn),
      store: new Store(new RecordSource()),
      isServer: false
    })
  }

  // the token changes regularly, so we need to always keep track of what it is
  currentToken = token

  if (!isEmpty(relayData)) {
    currentEnvironment.getStore().publish(new RecordSource(relayData))
  }

  return currentEnvironment
}

export interface RelayProviderProps {
  children: ReactNode
  relayData: RecordMap | undefined
  token: string | null | undefined
}

export const RelayProvider: FC<RelayProviderProps> = ({ children, relayData, token }) => (
  <RelayEnvironmentProvider environment={initRelayEnvironment(token, relayData)}>{children}</RelayEnvironmentProvider>
)
