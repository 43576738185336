import { type UserAvatar_user$key } from '@app/__generated__/UserAvatar_user.graphql'
import { initials } from '@app/lib'
import { Avatar, type AvatarProps } from '@mantine/core'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const userFragment = graphql`
  fragment UserAvatar_user on User {
    firstName
    lastName
    userUploads(filter: { name: { equalTo: "avatar" } }) {
      nodes {
        upload {
          url
        }
      }
    }
  }
`

export interface UserAvatarProps extends AvatarProps {
  user: UserAvatar_user$key
}

export const UserAvatar: FC<UserAvatarProps> = ({ user, ...avatarProps }) => {
  const userData = useFragment<UserAvatar_user$key>(userFragment, user)

  return (
    <Avatar
      {...avatarProps}
      src={userData?.userUploads?.nodes?.[0]?.upload?.url}
      title={`${userData?.firstName} ${userData?.lastName}`}
    >
      {initials(`${userData?.firstName} ${userData?.lastName}`)}
    </Avatar>
  )
}
