import { BookPersonalInfoForm } from '@app/components'
import { Text, Title, Center } from '@mantine/core'
import { type FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import { graphql, useFragment, useMutation } from 'react-relay'
import { type BookPersonalInfo_user$key } from '@app/__generated__/BookPersonalInfo_user.graphql'
import { type BookPersonalInfoPageUpdateUserMutation } from '@app/__generated__/BookPersonalInfoPageUpdateUserMutation.graphql'
import {
  type UserAddressesUserIdFkeyInverseInput,
  UserPhonesType,
  type UserPhonesUserIdFkeyInverseInput
} from '@app/lib'
import { find } from 'lodash'
import { useRouter } from 'next/router'

const userFragment = graphql`
  fragment BookPersonalInfo_user on User {
    rowId
    allUserPhones: userPhones {
      nodes {
        rowId
        phone
        isPrimary
      }
    }
    allUserAddresses: userAddresses {
      nodes {
        rowId
        address {
          rowId
          city
          postalCode
          stateOrProvince
          street1
          street2
        }
        isPrimary
      }
    }
    ...BookPersonalInfoForm_user
  }
`

const updateUserMutation = graphql`
  mutation BookPersonalInfoPageUpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...BookPersonalInfoForm_user
        ...BookPersonalInfo_user
      }
    }
  }
`

interface BookPersonalInfoProps {
  user: BookPersonalInfo_user$key
}

export const BookPersonalInfo: FC<BookPersonalInfoProps> = ({ user }) => {
  const { t } = useTranslation('booking')
  const userData = useFragment(userFragment, user)
  const router = useRouter()
  const [updateUser, isUpdatingUser] = useMutation<BookPersonalInfoPageUpdateUserMutation>(updateUserMutation)

  return (
    <>
      <Center
        mt={100}
        m='sm'
      >
        <Title>{t("We're holding your spot")}</Title>{' '}
      </Center>
      <Center>
        <Text
          m='sm'
          size='lg'
        >
          {t('We need a few more details in order to complete your appointment request')}
        </Text>
      </Center>
      <Head>
        <title>{t('Personal Info')}</title>
      </Head>
      <BookPersonalInfoForm
        user={userData}
        isSaving={isUpdatingUser}
        onSubmit={(data) => {
          if (!userData) {
            return
          }
          // check if the phone number is already in user phones
          const userPhone = find(userData.allUserPhones.nodes, (phone) => phone.phone === data.phone)
          let userPhones: UserPhonesUserIdFkeyInverseInput = {}
          // if phone number does not exist, create a new user phone
          if (!userPhone) {
            userPhones = {
              create: [
                {
                  phone: data.phone,
                  //@todo: should we create a trigger to set the others as not primary?
                  isPrimary: true,
                  type: UserPhonesType.Mobile
                }
              ]
            }
          } else if (!userPhone.isPrimary) {
            // if phone number exists and it's not primary, update it to be primary
            userPhones = {
              updateByRowId: [
                {
                  rowId: userPhone.rowId,
                  patch: {
                    isPrimary: true
                  }
                }
              ]
            }
          }

          // check if the address is already in user addresses
          const userAddress = userData.allUserAddresses.nodes.find(
            ({ address: { city, postalCode, stateOrProvince, street1, street2 } }) => {
              return (
                city === data.address.city &&
                postalCode === data.address.postalCode &&
                stateOrProvince === data.address.stateOrProvince &&
                street1 === data.address.street1 &&
                street2 === data.address.street2
              )
            }
          )
          let userAddresses: UserAddressesUserIdFkeyInverseInput = {}
          // if address with this data does not exist, create a new address
          if (!userAddress) {
            userAddresses = {
              create: [
                {
                  //@todo: should we create a trigger to set the others as not primary?
                  isPrimary: true,
                  address: {
                    create: {
                      city: data.address.city,
                      postalCode: data.address.postalCode,
                      stateOrProvince: data.address.stateOrProvince,
                      street1: data.address.street1,
                      street2: data.address.street2,
                      country: 'US'
                    }
                  }
                }
              ]
            }
          } else if (
            find(
              userData.allUserAddresses.nodes,
              ({ address, isPrimary }) => address.rowId === userAddress?.address.rowId && !isPrimary
            )
          ) {
            // if address exists and it's not primary, update it to be primary
            userAddresses = {
              connectByRowId: [
                {
                  rowId: userData.allUserAddresses.nodes.find(
                    ({ address }) => address.rowId === userAddress?.address.rowId
                  )?.rowId as string
                }
              ],
              updateByRowId: [
                {
                  rowId: userAddress.address.rowId,
                  patch: {
                    isPrimary: true
                  }
                }
              ]
            }
          }

          updateUser({
            variables: {
              input: {
                rowId: userData.rowId,
                patch: {
                  firstName: data.firstName,
                  lastName: data.lastName,
                  birthdateAt: data.birthdateAt,
                  userPhones,
                  userAddresses
                }
              }
            }
          })

          return router.push(data.paymentMethod === 'creditCard' ? '/bookCreditCardPayment' : '/bookInsuranceDetails')
        }}
      />
    </>
  )
}
