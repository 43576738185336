/**
 * @generated SignedSource<<de0959da4890738604c8f65942d1510e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClinicianAvailabilityForm_clinicianAvailability$data = {
  readonly clinicianId: any;
  readonly endsAt: any | null;
  readonly isExclusive: boolean;
  readonly startsAt: any;
  readonly " $fragmentType": "ClinicianAvailabilityForm_clinicianAvailability";
};
export type ClinicianAvailabilityForm_clinicianAvailability$key = {
  readonly " $data"?: ClinicianAvailabilityForm_clinicianAvailability$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClinicianAvailabilityForm_clinicianAvailability">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianAvailabilityForm_clinicianAvailability",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clinicianId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExclusive",
      "storageKey": null
    }
  ],
  "type": "ClinicianAvailability",
  "abstractKey": null
};

(node as any).hash = "5ce259e897d55e832dc359d7b6a8533d";

export default node;
