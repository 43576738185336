import { Card, Center, type CenterProps, createStyles, Group, LoadingOverlay } from '@mantine/core'
import Head from 'next/head'
import Image from 'next/image'
import { type FC } from 'react'

import logo from './logo.png'

const useStyles = createStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
})

export interface AuthLayoutProps extends CenterProps {
  loading?: boolean
  title: string
}

export const AuthLayout: FC<AuthLayoutProps> = ({ children, className, loading, title }) => {
  const { classes, cx } = useStyles()

  return (
    <Center className={cx(classes.root, className)}>
      <Head>
        <title>{`Nest Collaborative / ${title}`}</title>
      </Head>
      <Card
        p='xl'
        pt={0}
        radius='md'
        shadow='md'
        sx={{ width: 350 }}
        withBorder
      >
        <LoadingOverlay visible={loading} />
        <Card.Section
          m={0}
          mb='sm'
          p='xl'
        >
          <Center>
            <Image
              alt='logo'
              src={logo}
              height={100}
            />
          </Center>
        </Card.Section>
        {children}
      </Card>
    </Center>
  )
}
