/**
 * @generated SignedSource<<6868f0a272fbefb69aaddbe9d1ae7bce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppointmentsSelectionMethod = "CLINICIAN" | "TIME" | "%future added value";
export type AppointmentsStatus = "CHECKED_IN" | "CONFIRMED" | "CONSULTED" | "LATE_CANCELLATION_EMERGENCY_MEDICAL_FIRE_ETC_" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_CIGNA_MOM" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_MEDICAID" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_RESCHEDULED" | "LATE_CANCELLATION_NO_SHOW_PLEASE_CHARGE" | "NO_SHOW_CHARGE" | "NO_SHOW_NO_CHARGE" | "UNCONFIRMED" | "_2_HR_LATE_CANCEL_CHARGE" | "_2_HR_LATE_CANCEL_NO_CHARGE" | "%future added value";
export type EmployeeType = "EMPLOYEE" | "GENERIC" | "PRN" | "%future added value";
export type LeadsSource = "BREAST_PUMP_ORDER" | "FRIEND" | "HEALTH" | "HEALTH_PLAN" | "OTHER" | "REFERRAL_PARTNER" | "SEARCH" | "SOCIAL" | "%future added value";
export type LeadsStatus = "ACTIVE" | "CONVERTED" | "INACTIVE" | "NEW" | "%future added value";
export type PaymentProviders = "STRIPE" | "%future added value";
export type PolicyType = "CLINICIAN" | "GENERAL" | "PATIENT" | "%future added value";
export type ReferralPartnerType = "CLINICAL" | "DME" | "EMPLOYER" | "%future added value";
export type ServicesOrderBy = "APPOINTMENTS_BY_SERVICE_ID__COUNT_ASC" | "APPOINTMENTS_BY_SERVICE_ID__COUNT_DESC" | "CHARM_SERVICE_ID_ASC" | "CHARM_SERVICE_ID_DESC" | "CREATED_AT_ASC" | "CREATED_AT_DESC" | "DURATION_ASC" | "DURATION_DESC" | "ID_ASC" | "ID_DESC" | "IS_ACTIVE_ASC" | "IS_ACTIVE_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "PRICE_ASC" | "PRICE_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "SLUG_ASC" | "SLUG_DESC" | "UPDATED_AT_ASC" | "UPDATED_AT_DESC" | "%future added value";
export type UserGender = "FEMALE" | "MALE" | "OTHER" | "%future added value";
export type UserInsuranceCategory = "AUTO" | "OTHER" | "PRIMARY" | "SECONDARY" | "TERTIARY" | "WORKERCOMP" | "%future added value";
export type UserInsurancePolicyHolderRelationship = "CHILD" | "OTHER" | "SELF" | "SPOUSE" | "UNKNOWN" | "%future added value";
export type UserInsuranceType = "CHAMPVA" | "FECA_BLK_LUNG" | "GROUP_HEALTH_PLAN" | "MEDICAID" | "MEDICARE" | "OTHER" | "TRICARE_CHAMPUS" | "%future added value";
export type UserPhonesType = "FAX" | "HOME" | "MOBILE" | "WORK" | "%future added value";
export type ServiceFilter = {
  and?: ReadonlyArray<ServiceFilter> | null;
  appointments?: ServiceToManyAppointmentFilter | null;
  appointmentsExist?: boolean | null;
  charmServiceId?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  duration?: IntervalFilter | null;
  isActive?: BooleanFilter | null;
  name?: StringFilter | null;
  not?: ServiceFilter | null;
  or?: ReadonlyArray<ServiceFilter> | null;
  price?: BigFloatFilter | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type ServiceToManyAppointmentFilter = {
  every?: AppointmentFilter | null;
  none?: AppointmentFilter | null;
  some?: AppointmentFilter | null;
};
export type AppointmentFilter = {
  and?: ReadonlyArray<AppointmentFilter> | null;
  charmId?: StringFilter | null;
  clinician?: ClinicianFilter | null;
  clinicianId?: UUIDFilter | null;
  createdAt?: DatetimeFilter | null;
  endsAt?: DatetimeFilter | null;
  not?: AppointmentFilter | null;
  notes?: StringFilter | null;
  or?: ReadonlyArray<AppointmentFilter> | null;
  patient?: PatientFilter | null;
  patientId?: UUIDFilter | null;
  rowId?: UUIDFilter | null;
  selectionMethod?: AppointmentsSelectionMethodFilter | null;
  service?: ServiceFilter | null;
  serviceId?: UUIDFilter | null;
  startsAt?: DatetimeFilter | null;
  status?: AppointmentsStatusFilter | null;
  updatedAt?: DatetimeFilter | null;
  userChild?: UserChildFilter | null;
  userChildExists?: boolean | null;
  userChildId?: UUIDFilter | null;
};
export type StringFilter = {
  distinctFrom?: string | null;
  distinctFromInsensitive?: string | null;
  endsWith?: string | null;
  endsWithInsensitive?: string | null;
  equalTo?: string | null;
  equalToInsensitive?: string | null;
  greaterThan?: string | null;
  greaterThanInsensitive?: string | null;
  greaterThanOrEqualTo?: string | null;
  greaterThanOrEqualToInsensitive?: string | null;
  in?: ReadonlyArray<string> | null;
  inInsensitive?: ReadonlyArray<string> | null;
  includes?: string | null;
  includesInsensitive?: string | null;
  isNull?: boolean | null;
  lessThan?: string | null;
  lessThanInsensitive?: string | null;
  lessThanOrEqualTo?: string | null;
  lessThanOrEqualToInsensitive?: string | null;
  like?: string | null;
  likeInsensitive?: string | null;
  notDistinctFrom?: string | null;
  notDistinctFromInsensitive?: string | null;
  notEndsWith?: string | null;
  notEndsWithInsensitive?: string | null;
  notEqualTo?: string | null;
  notEqualToInsensitive?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notInInsensitive?: ReadonlyArray<string> | null;
  notIncludes?: string | null;
  notIncludesInsensitive?: string | null;
  notLike?: string | null;
  notLikeInsensitive?: string | null;
  notStartsWith?: string | null;
  notStartsWithInsensitive?: string | null;
  startsWith?: string | null;
  startsWithInsensitive?: string | null;
};
export type ClinicianFilter = {
  and?: ReadonlyArray<ClinicianFilter> | null;
  appointments?: ClinicianToManyAppointmentFilter | null;
  appointmentsExist?: boolean | null;
  charmId?: StringFilter | null;
  clinicianAvailabilities?: ClinicianToManyClinicianAvailabilityFilter | null;
  clinicianAvailabilitiesExist?: boolean | null;
  createdAt?: DatetimeFilter | null;
  employeeType?: EmployeeTypeFilter | null;
  introduction?: StringFilter | null;
  not?: ClinicianFilter | null;
  or?: ReadonlyArray<ClinicianFilter> | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  slugPublic?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
};
export type ClinicianToManyAppointmentFilter = {
  every?: AppointmentFilter | null;
  none?: AppointmentFilter | null;
  some?: AppointmentFilter | null;
};
export type ClinicianToManyClinicianAvailabilityFilter = {
  every?: ClinicianAvailabilityFilter | null;
  none?: ClinicianAvailabilityFilter | null;
  some?: ClinicianAvailabilityFilter | null;
};
export type ClinicianAvailabilityFilter = {
  and?: ReadonlyArray<ClinicianAvailabilityFilter> | null;
  clinician?: ClinicianFilter | null;
  clinicianId?: UUIDFilter | null;
  createdAt?: DatetimeFilter | null;
  endsAt?: DatetimeFilter | null;
  googleCalendarEventId?: StringFilter | null;
  isExclusive?: BooleanFilter | null;
  not?: ClinicianAvailabilityFilter | null;
  or?: ReadonlyArray<ClinicianAvailabilityFilter> | null;
  recurDuration?: IntervalFilter | null;
  recurEvery?: IntervalFilter | null;
  rowId?: UUIDFilter | null;
  startsAt?: DatetimeFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type UUIDFilter = {
  distinctFrom?: any | null;
  equalTo?: any | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any> | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notDistinctFrom?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any> | null;
};
export type DatetimeFilter = {
  distinctFrom?: any | null;
  equalTo?: any | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any> | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notDistinctFrom?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any> | null;
};
export type BooleanFilter = {
  distinctFrom?: boolean | null;
  equalTo?: boolean | null;
  greaterThan?: boolean | null;
  greaterThanOrEqualTo?: boolean | null;
  in?: ReadonlyArray<boolean> | null;
  isNull?: boolean | null;
  lessThan?: boolean | null;
  lessThanOrEqualTo?: boolean | null;
  notDistinctFrom?: boolean | null;
  notEqualTo?: boolean | null;
  notIn?: ReadonlyArray<boolean> | null;
};
export type IntervalFilter = {
  distinctFrom?: IntervalInput | null;
  equalTo?: IntervalInput | null;
  greaterThan?: IntervalInput | null;
  greaterThanOrEqualTo?: IntervalInput | null;
  in?: ReadonlyArray<IntervalInput> | null;
  isNull?: boolean | null;
  lessThan?: IntervalInput | null;
  lessThanOrEqualTo?: IntervalInput | null;
  notDistinctFrom?: IntervalInput | null;
  notEqualTo?: IntervalInput | null;
  notIn?: ReadonlyArray<IntervalInput> | null;
};
export type IntervalInput = {
  days?: number | null;
  hours?: number | null;
  minutes?: number | null;
  months?: number | null;
  seconds?: number | null;
  years?: number | null;
};
export type EmployeeTypeFilter = {
  distinctFrom?: EmployeeType | null;
  equalTo?: EmployeeType | null;
  greaterThan?: EmployeeType | null;
  greaterThanOrEqualTo?: EmployeeType | null;
  in?: ReadonlyArray<EmployeeType> | null;
  isNull?: boolean | null;
  lessThan?: EmployeeType | null;
  lessThanOrEqualTo?: EmployeeType | null;
  notDistinctFrom?: EmployeeType | null;
  notEqualTo?: EmployeeType | null;
  notIn?: ReadonlyArray<EmployeeType> | null;
};
export type UserFilter = {
  and?: ReadonlyArray<UserFilter> | null;
  birthdateAt?: DateFilter | null;
  clinician?: ClinicianFilter | null;
  clinicianExists?: boolean | null;
  createdAt?: DatetimeFilter | null;
  email?: StringFilter | null;
  firstName?: StringFilter | null;
  gender?: UserGenderFilter | null;
  hasPassword?: BooleanFilter | null;
  isAdmin?: BooleanFilter | null;
  lastName?: StringFilter | null;
  lead?: LeadFilter | null;
  leadExists?: boolean | null;
  middleName?: StringFilter | null;
  not?: UserFilter | null;
  or?: ReadonlyArray<UserFilter> | null;
  patient?: PatientFilter | null;
  patientExists?: boolean | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
  uploadsByUploaderId?: UserToManyUploadFilter | null;
  uploadsByUploaderIdExist?: boolean | null;
  userAddresses?: UserToManyUserAddressFilter | null;
  userAddressesExist?: boolean | null;
  userChildren?: UserToManyUserChildFilter | null;
  userChildrenExist?: boolean | null;
  userInsurances?: UserToManyUserInsuranceFilter | null;
  userInsurancesExist?: boolean | null;
  userLanguages?: UserToManyUserLanguageFilter | null;
  userLanguagesExist?: boolean | null;
  userPaymentMethods?: UserToManyUserPaymentMethodFilter | null;
  userPaymentMethodsExist?: boolean | null;
  userPaymentProviders?: UserToManyUserPaymentProviderFilter | null;
  userPaymentProvidersExist?: boolean | null;
  userPhones?: UserToManyUserPhoneFilter | null;
  userPhonesExist?: boolean | null;
  userPolicies?: UserToManyUserPolicyFilter | null;
  userPoliciesExist?: boolean | null;
  userUploads?: UserToManyUserUploadFilter | null;
  userUploadsExist?: boolean | null;
  verifiedAt?: DatetimeFilter | null;
};
export type DateFilter = {
  distinctFrom?: any | null;
  equalTo?: any | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any> | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notDistinctFrom?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any> | null;
};
export type UserGenderFilter = {
  distinctFrom?: UserGender | null;
  equalTo?: UserGender | null;
  greaterThan?: UserGender | null;
  greaterThanOrEqualTo?: UserGender | null;
  in?: ReadonlyArray<UserGender> | null;
  isNull?: boolean | null;
  lessThan?: UserGender | null;
  lessThanOrEqualTo?: UserGender | null;
  notDistinctFrom?: UserGender | null;
  notEqualTo?: UserGender | null;
  notIn?: ReadonlyArray<UserGender> | null;
};
export type LeadFilter = {
  and?: ReadonlyArray<LeadFilter> | null;
  convertedAt?: DatetimeFilter | null;
  createdAt?: DatetimeFilter | null;
  not?: LeadFilter | null;
  optInAt?: DatetimeFilter | null;
  optInMethod?: StringFilter | null;
  or?: ReadonlyArray<LeadFilter> | null;
  referralPartner?: ReferralPartnerFilter | null;
  referralPartnerExists?: boolean | null;
  referralPartnerId?: UUIDFilter | null;
  referralPartnerSource?: ReferralPartnerSourceFilter | null;
  referralPartnerSourceExists?: boolean | null;
  referralPartnerSourceId?: UUIDFilter | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  source?: LeadsSourceFilter | null;
  status?: LeadsStatusFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
  zohoId?: StringFilter | null;
};
export type ReferralPartnerFilter = {
  and?: ReadonlyArray<ReferralPartnerFilter> | null;
  config?: JSONFilter | null;
  createdAt?: DatetimeFilter | null;
  isActive?: BooleanFilter | null;
  leads?: ReferralPartnerToManyLeadFilter | null;
  leadsExist?: boolean | null;
  name?: StringFilter | null;
  not?: ReferralPartnerFilter | null;
  or?: ReadonlyArray<ReferralPartnerFilter> | null;
  referralPartnerSources?: ReferralPartnerToManyReferralPartnerSourceFilter | null;
  referralPartnerSourcesExist?: boolean | null;
  referralPartnerUploads?: ReferralPartnerToManyReferralPartnerUploadFilter | null;
  referralPartnerUploadsExist?: boolean | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  slugPublic?: StringFilter | null;
  type?: ReferralPartnerTypeFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type JSONFilter = {
  containedBy?: any | null;
  contains?: any | null;
  containsAllKeys?: ReadonlyArray<string> | null;
  containsAnyKeys?: ReadonlyArray<string> | null;
  containsKey?: string | null;
  distinctFrom?: any | null;
  equalTo?: any | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any> | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notDistinctFrom?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any> | null;
};
export type ReferralPartnerToManyLeadFilter = {
  every?: LeadFilter | null;
  none?: LeadFilter | null;
  some?: LeadFilter | null;
};
export type ReferralPartnerToManyReferralPartnerSourceFilter = {
  every?: ReferralPartnerSourceFilter | null;
  none?: ReferralPartnerSourceFilter | null;
  some?: ReferralPartnerSourceFilter | null;
};
export type ReferralPartnerSourceFilter = {
  and?: ReadonlyArray<ReferralPartnerSourceFilter> | null;
  description?: StringFilter | null;
  isActive?: BooleanFilter | null;
  leads?: ReferralPartnerSourceToManyLeadFilter | null;
  leadsExist?: boolean | null;
  name?: StringFilter | null;
  not?: ReferralPartnerSourceFilter | null;
  or?: ReadonlyArray<ReferralPartnerSourceFilter> | null;
  referralPartner?: ReferralPartnerFilter | null;
  referralPartnerId?: UUIDFilter | null;
  rowId?: UUIDFilter | null;
};
export type ReferralPartnerSourceToManyLeadFilter = {
  every?: LeadFilter | null;
  none?: LeadFilter | null;
  some?: LeadFilter | null;
};
export type ReferralPartnerToManyReferralPartnerUploadFilter = {
  every?: ReferralPartnerUploadFilter | null;
  none?: ReferralPartnerUploadFilter | null;
  some?: ReferralPartnerUploadFilter | null;
};
export type ReferralPartnerUploadFilter = {
  and?: ReadonlyArray<ReferralPartnerUploadFilter> | null;
  createdAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: ReferralPartnerUploadFilter | null;
  or?: ReadonlyArray<ReferralPartnerUploadFilter> | null;
  referralPartner?: ReferralPartnerFilter | null;
  referralPartnerId?: UUIDFilter | null;
  rowId?: UUIDFilter | null;
  updatedAt?: DatetimeFilter | null;
  upload?: UploadFilter | null;
  uploadId?: UUIDFilter | null;
};
export type UploadFilter = {
  and?: ReadonlyArray<UploadFilter> | null;
  bucket?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  filename?: StringFilter | null;
  filesize?: IntFilter | null;
  filetype?: StringFilter | null;
  key?: StringFilter | null;
  not?: UploadFilter | null;
  or?: ReadonlyArray<UploadFilter> | null;
  referralPartnerUploads?: UploadToManyReferralPartnerUploadFilter | null;
  referralPartnerUploadsExist?: boolean | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
  uploader?: UserFilter | null;
  uploaderExists?: boolean | null;
  uploaderId?: UUIDFilter | null;
  userInsuranceUploads?: UploadToManyUserInsuranceUploadFilter | null;
  userInsuranceUploadsExist?: boolean | null;
  userUploads?: UploadToManyUserUploadFilter | null;
  userUploadsExist?: boolean | null;
};
export type IntFilter = {
  distinctFrom?: number | null;
  equalTo?: number | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notDistinctFrom?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
};
export type UploadToManyReferralPartnerUploadFilter = {
  every?: ReferralPartnerUploadFilter | null;
  none?: ReferralPartnerUploadFilter | null;
  some?: ReferralPartnerUploadFilter | null;
};
export type UploadToManyUserInsuranceUploadFilter = {
  every?: UserInsuranceUploadFilter | null;
  none?: UserInsuranceUploadFilter | null;
  some?: UserInsuranceUploadFilter | null;
};
export type UserInsuranceUploadFilter = {
  and?: ReadonlyArray<UserInsuranceUploadFilter> | null;
  createdAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: UserInsuranceUploadFilter | null;
  or?: ReadonlyArray<UserInsuranceUploadFilter> | null;
  rowId?: UUIDFilter | null;
  updatedAt?: DatetimeFilter | null;
  upload?: UploadFilter | null;
  uploadId?: UUIDFilter | null;
  userInsurance?: UserInsuranceFilter | null;
  userInsuranceId?: UUIDFilter | null;
};
export type UserInsuranceFilter = {
  and?: ReadonlyArray<UserInsuranceFilter> | null;
  category?: UserInsuranceCategoryFilter | null;
  charmId?: StringFilter | null;
  comments?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  groupId?: StringFilter | null;
  insuranceProvider?: InsuranceProviderFilter | null;
  insuranceProviderId?: UUIDFilter | null;
  memberId?: StringFilter | null;
  not?: UserInsuranceFilter | null;
  or?: ReadonlyArray<UserInsuranceFilter> | null;
  policyHolderAddressCity?: StringFilter | null;
  policyHolderAddressCountry?: StringFilter | null;
  policyHolderAddressPostalCode?: StringFilter | null;
  policyHolderAddressStateOrProvince?: StringFilter | null;
  policyHolderAddressStreet1?: StringFilter | null;
  policyHolderAddressStreet2?: StringFilter | null;
  policyHolderBirthdateAt?: DateFilter | null;
  policyHolderFirstName?: StringFilter | null;
  policyHolderGender?: StringFilter | null;
  policyHolderHomePhone?: StringFilter | null;
  policyHolderLastName?: StringFilter | null;
  policyHolderMobilePhone?: StringFilter | null;
  policyHolderRelationship?: UserInsurancePolicyHolderRelationshipFilter | null;
  policyHolderSsn?: StringFilter | null;
  rowId?: UUIDFilter | null;
  type?: UserInsuranceTypeFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
  userInsuranceUploads?: UserInsuranceToManyUserInsuranceUploadFilter | null;
  userInsuranceUploadsExist?: boolean | null;
  validAt?: DateFilter | null;
};
export type UserInsuranceCategoryFilter = {
  distinctFrom?: UserInsuranceCategory | null;
  equalTo?: UserInsuranceCategory | null;
  greaterThan?: UserInsuranceCategory | null;
  greaterThanOrEqualTo?: UserInsuranceCategory | null;
  in?: ReadonlyArray<UserInsuranceCategory> | null;
  isNull?: boolean | null;
  lessThan?: UserInsuranceCategory | null;
  lessThanOrEqualTo?: UserInsuranceCategory | null;
  notDistinctFrom?: UserInsuranceCategory | null;
  notEqualTo?: UserInsuranceCategory | null;
  notIn?: ReadonlyArray<UserInsuranceCategory> | null;
};
export type InsuranceProviderFilter = {
  and?: ReadonlyArray<InsuranceProviderFilter> | null;
  charmId?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: InsuranceProviderFilter | null;
  or?: ReadonlyArray<InsuranceProviderFilter> | null;
  payerId?: StringFilter | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
  userInsurances?: InsuranceProviderToManyUserInsuranceFilter | null;
  userInsurancesExist?: boolean | null;
};
export type InsuranceProviderToManyUserInsuranceFilter = {
  every?: UserInsuranceFilter | null;
  none?: UserInsuranceFilter | null;
  some?: UserInsuranceFilter | null;
};
export type UserInsurancePolicyHolderRelationshipFilter = {
  distinctFrom?: UserInsurancePolicyHolderRelationship | null;
  equalTo?: UserInsurancePolicyHolderRelationship | null;
  greaterThan?: UserInsurancePolicyHolderRelationship | null;
  greaterThanOrEqualTo?: UserInsurancePolicyHolderRelationship | null;
  in?: ReadonlyArray<UserInsurancePolicyHolderRelationship> | null;
  isNull?: boolean | null;
  lessThan?: UserInsurancePolicyHolderRelationship | null;
  lessThanOrEqualTo?: UserInsurancePolicyHolderRelationship | null;
  notDistinctFrom?: UserInsurancePolicyHolderRelationship | null;
  notEqualTo?: UserInsurancePolicyHolderRelationship | null;
  notIn?: ReadonlyArray<UserInsurancePolicyHolderRelationship> | null;
};
export type UserInsuranceTypeFilter = {
  distinctFrom?: UserInsuranceType | null;
  equalTo?: UserInsuranceType | null;
  greaterThan?: UserInsuranceType | null;
  greaterThanOrEqualTo?: UserInsuranceType | null;
  in?: ReadonlyArray<UserInsuranceType> | null;
  isNull?: boolean | null;
  lessThan?: UserInsuranceType | null;
  lessThanOrEqualTo?: UserInsuranceType | null;
  notDistinctFrom?: UserInsuranceType | null;
  notEqualTo?: UserInsuranceType | null;
  notIn?: ReadonlyArray<UserInsuranceType> | null;
};
export type UserInsuranceToManyUserInsuranceUploadFilter = {
  every?: UserInsuranceUploadFilter | null;
  none?: UserInsuranceUploadFilter | null;
  some?: UserInsuranceUploadFilter | null;
};
export type UploadToManyUserUploadFilter = {
  every?: UserUploadFilter | null;
  none?: UserUploadFilter | null;
  some?: UserUploadFilter | null;
};
export type UserUploadFilter = {
  and?: ReadonlyArray<UserUploadFilter> | null;
  createdAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: UserUploadFilter | null;
  or?: ReadonlyArray<UserUploadFilter> | null;
  rowId?: UUIDFilter | null;
  updatedAt?: DatetimeFilter | null;
  upload?: UploadFilter | null;
  uploadId?: UUIDFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
};
export type ReferralPartnerTypeFilter = {
  distinctFrom?: ReferralPartnerType | null;
  equalTo?: ReferralPartnerType | null;
  greaterThan?: ReferralPartnerType | null;
  greaterThanOrEqualTo?: ReferralPartnerType | null;
  in?: ReadonlyArray<ReferralPartnerType> | null;
  isNull?: boolean | null;
  lessThan?: ReferralPartnerType | null;
  lessThanOrEqualTo?: ReferralPartnerType | null;
  notDistinctFrom?: ReferralPartnerType | null;
  notEqualTo?: ReferralPartnerType | null;
  notIn?: ReadonlyArray<ReferralPartnerType> | null;
};
export type LeadsSourceFilter = {
  distinctFrom?: LeadsSource | null;
  equalTo?: LeadsSource | null;
  greaterThan?: LeadsSource | null;
  greaterThanOrEqualTo?: LeadsSource | null;
  in?: ReadonlyArray<LeadsSource> | null;
  isNull?: boolean | null;
  lessThan?: LeadsSource | null;
  lessThanOrEqualTo?: LeadsSource | null;
  notDistinctFrom?: LeadsSource | null;
  notEqualTo?: LeadsSource | null;
  notIn?: ReadonlyArray<LeadsSource> | null;
};
export type LeadsStatusFilter = {
  distinctFrom?: LeadsStatus | null;
  equalTo?: LeadsStatus | null;
  greaterThan?: LeadsStatus | null;
  greaterThanOrEqualTo?: LeadsStatus | null;
  in?: ReadonlyArray<LeadsStatus> | null;
  isNull?: boolean | null;
  lessThan?: LeadsStatus | null;
  lessThanOrEqualTo?: LeadsStatus | null;
  notDistinctFrom?: LeadsStatus | null;
  notEqualTo?: LeadsStatus | null;
  notIn?: ReadonlyArray<LeadsStatus> | null;
};
export type PatientFilter = {
  and?: ReadonlyArray<PatientFilter> | null;
  appointments?: PatientToManyAppointmentFilter | null;
  appointmentsExist?: boolean | null;
  charmId?: StringFilter | null;
  charmRecordId?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  not?: PatientFilter | null;
  or?: ReadonlyArray<PatientFilter> | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
  zohoId?: StringFilter | null;
};
export type PatientToManyAppointmentFilter = {
  every?: AppointmentFilter | null;
  none?: AppointmentFilter | null;
  some?: AppointmentFilter | null;
};
export type UserToManyUploadFilter = {
  every?: UploadFilter | null;
  none?: UploadFilter | null;
  some?: UploadFilter | null;
};
export type UserToManyUserAddressFilter = {
  every?: UserAddressFilter | null;
  none?: UserAddressFilter | null;
  some?: UserAddressFilter | null;
};
export type UserAddressFilter = {
  address?: AddressFilter | null;
  addressId?: UUIDFilter | null;
  and?: ReadonlyArray<UserAddressFilter> | null;
  createdAt?: DatetimeFilter | null;
  isPrimary?: BooleanFilter | null;
  not?: UserAddressFilter | null;
  or?: ReadonlyArray<UserAddressFilter> | null;
  rowId?: UUIDFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
};
export type AddressFilter = {
  and?: ReadonlyArray<AddressFilter> | null;
  city?: StringFilter | null;
  country?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  not?: AddressFilter | null;
  or?: ReadonlyArray<AddressFilter> | null;
  postalCode?: StringFilter | null;
  rowId?: UUIDFilter | null;
  stateOrProvince?: StringFilter | null;
  street1?: StringFilter | null;
  street2?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
  userAddresses?: AddressToManyUserAddressFilter | null;
  userAddressesExist?: boolean | null;
};
export type AddressToManyUserAddressFilter = {
  every?: UserAddressFilter | null;
  none?: UserAddressFilter | null;
  some?: UserAddressFilter | null;
};
export type UserToManyUserChildFilter = {
  every?: UserChildFilter | null;
  none?: UserChildFilter | null;
  some?: UserChildFilter | null;
};
export type UserChildFilter = {
  and?: ReadonlyArray<UserChildFilter> | null;
  appointments?: UserChildToManyAppointmentFilter | null;
  appointmentsExist?: boolean | null;
  birthdateAt?: DateFilter | null;
  createdAt?: DatetimeFilter | null;
  dueAt?: DateFilter | null;
  name?: StringFilter | null;
  not?: UserChildFilter | null;
  or?: ReadonlyArray<UserChildFilter> | null;
  rowId?: UUIDFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
};
export type UserChildToManyAppointmentFilter = {
  every?: AppointmentFilter | null;
  none?: AppointmentFilter | null;
  some?: AppointmentFilter | null;
};
export type UserToManyUserInsuranceFilter = {
  every?: UserInsuranceFilter | null;
  none?: UserInsuranceFilter | null;
  some?: UserInsuranceFilter | null;
};
export type UserToManyUserLanguageFilter = {
  every?: UserLanguageFilter | null;
  none?: UserLanguageFilter | null;
  some?: UserLanguageFilter | null;
};
export type UserLanguageFilter = {
  and?: ReadonlyArray<UserLanguageFilter> | null;
  createdAt?: DatetimeFilter | null;
  isPrimary?: BooleanFilter | null;
  language?: StringFilter | null;
  not?: UserLanguageFilter | null;
  or?: ReadonlyArray<UserLanguageFilter> | null;
  rowId?: UUIDFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
};
export type UserToManyUserPaymentMethodFilter = {
  every?: UserPaymentMethodFilter | null;
  none?: UserPaymentMethodFilter | null;
  some?: UserPaymentMethodFilter | null;
};
export type UserPaymentMethodFilter = {
  and?: ReadonlyArray<UserPaymentMethodFilter> | null;
  createdAt?: DatetimeFilter | null;
  isActive?: BooleanFilter | null;
  isPrimary?: BooleanFilter | null;
  methodId?: StringFilter | null;
  not?: UserPaymentMethodFilter | null;
  or?: ReadonlyArray<UserPaymentMethodFilter> | null;
  provider?: PaymentProvidersFilter | null;
  providerId?: StringFilter | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
};
export type PaymentProvidersFilter = {
  distinctFrom?: PaymentProviders | null;
  equalTo?: PaymentProviders | null;
  greaterThan?: PaymentProviders | null;
  greaterThanOrEqualTo?: PaymentProviders | null;
  in?: ReadonlyArray<PaymentProviders> | null;
  isNull?: boolean | null;
  lessThan?: PaymentProviders | null;
  lessThanOrEqualTo?: PaymentProviders | null;
  notDistinctFrom?: PaymentProviders | null;
  notEqualTo?: PaymentProviders | null;
  notIn?: ReadonlyArray<PaymentProviders> | null;
};
export type UserToManyUserPaymentProviderFilter = {
  every?: UserPaymentProviderFilter | null;
  none?: UserPaymentProviderFilter | null;
  some?: UserPaymentProviderFilter | null;
};
export type UserPaymentProviderFilter = {
  and?: ReadonlyArray<UserPaymentProviderFilter> | null;
  createdAt?: DatetimeFilter | null;
  isActive?: BooleanFilter | null;
  isPrimary?: BooleanFilter | null;
  not?: UserPaymentProviderFilter | null;
  or?: ReadonlyArray<UserPaymentProviderFilter> | null;
  provider?: PaymentProvidersFilter | null;
  providerId?: StringFilter | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
};
export type UserToManyUserPhoneFilter = {
  every?: UserPhoneFilter | null;
  none?: UserPhoneFilter | null;
  some?: UserPhoneFilter | null;
};
export type UserPhoneFilter = {
  and?: ReadonlyArray<UserPhoneFilter> | null;
  createdAt?: DatetimeFilter | null;
  isPrimary?: BooleanFilter | null;
  not?: UserPhoneFilter | null;
  or?: ReadonlyArray<UserPhoneFilter> | null;
  phone?: StringFilter | null;
  rowId?: UUIDFilter | null;
  type?: UserPhonesTypeFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
};
export type UserPhonesTypeFilter = {
  distinctFrom?: UserPhonesType | null;
  equalTo?: UserPhonesType | null;
  greaterThan?: UserPhonesType | null;
  greaterThanOrEqualTo?: UserPhonesType | null;
  in?: ReadonlyArray<UserPhonesType> | null;
  isNull?: boolean | null;
  lessThan?: UserPhonesType | null;
  lessThanOrEqualTo?: UserPhonesType | null;
  notDistinctFrom?: UserPhonesType | null;
  notEqualTo?: UserPhonesType | null;
  notIn?: ReadonlyArray<UserPhonesType> | null;
};
export type UserToManyUserPolicyFilter = {
  every?: UserPolicyFilter | null;
  none?: UserPolicyFilter | null;
  some?: UserPolicyFilter | null;
};
export type UserPolicyFilter = {
  acceptedAt?: DatetimeFilter | null;
  and?: ReadonlyArray<UserPolicyFilter> | null;
  createdAt?: DatetimeFilter | null;
  not?: UserPolicyFilter | null;
  or?: ReadonlyArray<UserPolicyFilter> | null;
  policy?: PolicyFilter | null;
  policyId?: UUIDFilter | null;
  rowId?: UUIDFilter | null;
  updatedAt?: DatetimeFilter | null;
  user?: UserFilter | null;
  userId?: UUIDFilter | null;
};
export type PolicyFilter = {
  and?: ReadonlyArray<PolicyFilter> | null;
  bodyEn?: StringFilter | null;
  bodyEs?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  description?: StringFilter | null;
  name?: StringFilter | null;
  not?: PolicyFilter | null;
  or?: ReadonlyArray<PolicyFilter> | null;
  rowId?: UUIDFilter | null;
  slug?: StringFilter | null;
  type?: PolicyTypeFilter | null;
  updatedAt?: DatetimeFilter | null;
  userPolicies?: PolicyToManyUserPolicyFilter | null;
  userPoliciesExist?: boolean | null;
  validAt?: DatetimeFilter | null;
};
export type PolicyTypeFilter = {
  distinctFrom?: PolicyType | null;
  equalTo?: PolicyType | null;
  greaterThan?: PolicyType | null;
  greaterThanOrEqualTo?: PolicyType | null;
  in?: ReadonlyArray<PolicyType> | null;
  isNull?: boolean | null;
  lessThan?: PolicyType | null;
  lessThanOrEqualTo?: PolicyType | null;
  notDistinctFrom?: PolicyType | null;
  notEqualTo?: PolicyType | null;
  notIn?: ReadonlyArray<PolicyType> | null;
};
export type PolicyToManyUserPolicyFilter = {
  every?: UserPolicyFilter | null;
  none?: UserPolicyFilter | null;
  some?: UserPolicyFilter | null;
};
export type UserToManyUserUploadFilter = {
  every?: UserUploadFilter | null;
  none?: UserUploadFilter | null;
  some?: UserUploadFilter | null;
};
export type AppointmentsSelectionMethodFilter = {
  distinctFrom?: AppointmentsSelectionMethod | null;
  equalTo?: AppointmentsSelectionMethod | null;
  greaterThan?: AppointmentsSelectionMethod | null;
  greaterThanOrEqualTo?: AppointmentsSelectionMethod | null;
  in?: ReadonlyArray<AppointmentsSelectionMethod> | null;
  isNull?: boolean | null;
  lessThan?: AppointmentsSelectionMethod | null;
  lessThanOrEqualTo?: AppointmentsSelectionMethod | null;
  notDistinctFrom?: AppointmentsSelectionMethod | null;
  notEqualTo?: AppointmentsSelectionMethod | null;
  notIn?: ReadonlyArray<AppointmentsSelectionMethod> | null;
};
export type AppointmentsStatusFilter = {
  distinctFrom?: AppointmentsStatus | null;
  equalTo?: AppointmentsStatus | null;
  greaterThan?: AppointmentsStatus | null;
  greaterThanOrEqualTo?: AppointmentsStatus | null;
  in?: ReadonlyArray<AppointmentsStatus> | null;
  isNull?: boolean | null;
  lessThan?: AppointmentsStatus | null;
  lessThanOrEqualTo?: AppointmentsStatus | null;
  notDistinctFrom?: AppointmentsStatus | null;
  notEqualTo?: AppointmentsStatus | null;
  notIn?: ReadonlyArray<AppointmentsStatus> | null;
};
export type BigFloatFilter = {
  distinctFrom?: any | null;
  equalTo?: any | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any> | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notDistinctFrom?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any> | null;
};
export type ServicesTablePaginationQuery$variables = {
  after?: any | null;
  filter?: ServiceFilter | null;
  first?: number | null;
  orderBy?: ReadonlyArray<ServicesOrderBy> | null;
};
export type ServicesTablePaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ServicesTable_query">;
};
export type ServicesTablePaginationQuery = {
  response: ServicesTablePaginationQuery$data;
  variables: ServicesTablePaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": [
      "NAME_ASC"
    ],
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ServicesTablePaginationQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ServicesTable_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ServicesTablePaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ServicesConnection",
        "kind": "LinkedField",
        "name": "services",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServicesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Service",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Interval",
                    "kind": "LinkedField",
                    "name": "duration",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "days",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hours",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "minutes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "months",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "seconds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "years",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filter",
          "orderBy"
        ],
        "handle": "connection",
        "key": "ServicesTable_query_services",
        "kind": "LinkedHandle",
        "name": "services"
      }
    ]
  },
  "params": {
    "cacheID": "1ff66f3970c24a7ba4fa88765297035c",
    "id": null,
    "metadata": {},
    "name": "ServicesTablePaginationQuery",
    "operationKind": "query",
    "text": "query ServicesTablePaginationQuery(\n  $after: Cursor\n  $filter: ServiceFilter\n  $first: Int\n  $orderBy: [ServicesOrderBy!] = [NAME_ASC]\n) {\n  ...ServicesTable_query_5SL3L\n}\n\nfragment ServicesTable_query_5SL3L on Query {\n  services(after: $after, filter: $filter, first: $first, orderBy: $orderBy) {\n    edges {\n      node {\n        duration {\n          days\n          hours\n          minutes\n          months\n          seconds\n          years\n        }\n        id\n        isActive\n        name\n        price\n        slug\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c193e25b5d192e44c68b62139d0af9c";

export default node;
