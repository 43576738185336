/**
 * @generated SignedSource<<c4c8a67b0d590341e7e1b4e8db52a735>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateClinicianAvailabilityModal_clinicianAvailability$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ClinicianAvailabilityForm_clinicianAvailability">;
  readonly " $fragmentType": "UpdateClinicianAvailabilityModal_clinicianAvailability";
};
export type UpdateClinicianAvailabilityModal_clinicianAvailability$key = {
  readonly " $data"?: UpdateClinicianAvailabilityModal_clinicianAvailability$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateClinicianAvailabilityModal_clinicianAvailability">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateClinicianAvailabilityModal_clinicianAvailability",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClinicianAvailabilityForm_clinicianAvailability"
    }
  ],
  "type": "ClinicianAvailability",
  "abstractKey": null
};

(node as any).hash = "66357c1107e87702af5cfe8a92a21109";

export default node;
