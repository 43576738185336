import {
  type PatientAppointmentsTable_patient$data,
  type PatientAppointmentsTable_patient$key
} from '@app/__generated__/PatientAppointmentsTable_patient.graphql'
import { type PatientAppointmentsTablePaginationQuery } from '@app/__generated__/PatientAppointmentsTablePaginationQuery.graphql'
import { PaginationTable } from '@app/components'
import { type AppointmentFilter, AppointmentsOrderBy, humanize, longDate } from '@app/lib'
import { Anchor } from '@mantine/core'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

type PatientAppointmentsTableAppointmentEdge = ElementOf<PatientAppointmentsTable_patient$data['appointments']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment PatientAppointmentsTable_patient on Patient
  @argumentDefinitions(
    first: { type: "Int" }
    after: { type: "Cursor" }
    orderBy: { type: "[AppointmentsOrderBy!]", defaultValue: [STARTS_AT_DESC] }
  )
  @refetchable(queryName: "PatientAppointmentsTablePaginationQuery") {
    appointments(first: $first, after: $after, orderBy: $orderBy)
      @connection(key: "PatientAppointmentsTable_patient_appointments", filters: ["orderBy"]) {
      __id
      edges {
        node {
          clinician {
            user {
              firstName
              lastName
              slug
            }
          }
          id
          service {
            duration {
              days
              hours
              minutes
              months
              seconds
              years
            }
            name
            slug
          }
          startsAt
          status
          userChild {
            birthdateAt
            dueAt
            name
          }
        }
      }
      totalCount
    }
    user {
      slug
    }
  }
`

export interface PatientAppointmentsTableProps {
  patient: PatientAppointmentsTable_patient$key
}

export const PatientAppointmentsTable: FC<PatientAppointmentsTableProps> = ({ patient }) => {
  const { t } = useTranslation('admin')
  const {
    data: patientData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<PatientAppointmentsTablePaginationQuery, PatientAppointmentsTable_patient$key>(
    paginationFragment,
    patient
  )

  return (
    <>
      <PaginationTable<
        PatientAppointmentsTableAppointmentEdge,
        AppointmentsOrderBy,
        PatientAppointmentsTablePaginationQuery,
        AppointmentFilter
      >
        columns={useMemo(
          () => [
            {
              accessorKey: 'node.startsAt',
              id: 'startsAt',
              enableSorting: true,
              header: t('Date/Time'),
              cell: (props) => {
                const node = props.row.original.node

                return longDate(node.startsAt)
              }
            },
            {
              accessorKey: 'node.userChildId',
              id: 'userChildId',
              enableSorting: false,
              header: t('Child'),
              cell: (props) => {
                const node = props.row.original.node

                if (node.userChild) {
                  const now = dayjs()
                  let age: number

                  if (node.userChild.birthdateAt) {
                    const birthdate = dayjs(node.userChild.birthdateAt)
                    const ageInYears = now.diff(birthdate, 'year')

                    age =
                      ageInYears >= 1
                        ? t('admin:x_years', { x: ageInYears })
                        : t('admin:x_months', { x: now.diff(birthdate, 'month') })
                  } else {
                    age = t('admin:due_in_x_months', {
                      x: Math.round(dayjs(node.userChild?.dueAt).diff(now, 'month', true))
                    })
                  }

                  return (
                    <Anchor
                      component={Link}
                      href={`/admin/users/${patientData.user.slug}/children`}
                    >
                      {node.userChild.name} ({age})
                    </Anchor>
                  )
                }

                return '-'
              }
            },
            {
              accessorKey: 'node.clinicianId',
              id: 'clinicianId',
              enableSorting: false,
              header: t('Clinician'),
              cell: (props) => {
                const node = props.row.original.node

                return (
                  <Anchor
                    component={Link}
                    href={`/admin/users/${node.clinician.user.slug}/clinician`}
                  >
                    {node.clinician.user.firstName} {node.clinician.user.lastName}
                  </Anchor>
                )
              }
            },
            {
              accessorKey: 'node.serviceId',
              id: 'serviceId',
              enableSorting: false,
              header: t('Service'),
              cell: (props) => {
                const node = props.row.original.node

                return (
                  <Anchor
                    component={Link}
                    href={`/admin/services/${node.service.slug}`}
                  >
                    {node.service.name} ({dayjs.duration(node.service.duration).asMinutes()}min)
                  </Anchor>
                )
              }
            },
            {
              id: 'status',
              header: t('Status'),
              cell: (props) => {
                const node = props.row.original.node

                return humanize(node.status)
              }
            }
          ],
          [patientData.user.slug, t]
        )}
        data={patientData?.appointments?.edges}
        isFilterable
        getFilterFromSearch={useCallback(
          (search) =>
            !isEmpty(search)
              ? {
                  or: [
                    {
                      clinician: {
                        user: {
                          firstName: {
                            includesInsensitive: search
                          }
                        }
                      }
                    },
                    {
                      clinician: {
                        user: {
                          lastName: {
                            includesInsensitive: search
                          }
                        }
                      }
                    },
                    {
                      service: {
                        name: {
                          includesInsensitive: search
                        }
                      }
                    },
                    {
                      userChild: {
                        name: {
                          includesInsensitive: search
                        }
                      }
                    }
                  ]
                }
              : null,
          []
        )}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'startsAt',
              desc: true
            }
          ],
          []
        )}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            startsAt: {
              asc: AppointmentsOrderBy.StartsAtAsc,
              desc: AppointmentsOrderBy.StartsAtDesc
            }
          }),
          []
        )}
        totalCount={patientData?.appointments?.totalCount}
      />
    </>
  )
}
