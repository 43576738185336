/**
 * @generated SignedSource<<bbc01e84bc670518ae3ee67664f48c9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookPersonalInfoForm_user$data = {
  readonly birthdateAt: any | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly userAddresses: {
    readonly nodes: ReadonlyArray<{
      readonly address: {
        readonly city: string;
        readonly country: string;
        readonly postalCode: string;
        readonly stateOrProvince: string;
        readonly street1: string;
        readonly street2: string | null;
      } | null;
    }>;
  };
  readonly userInsurances: {
    readonly totalCount: number;
  };
  readonly userPaymentMethods: {
    readonly totalCount: number;
  };
  readonly userPhones: {
    readonly nodes: ReadonlyArray<{
      readonly phone: string;
    }>;
  };
  readonly " $fragmentType": "BookPersonalInfoForm_user";
};
export type BookPersonalInfoForm_user$key = {
  readonly " $data"?: BookPersonalInfoForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookPersonalInfoForm_user">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "isPrimary": true
    }
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookPersonalInfoForm_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthdateAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "UserPhonesConnection",
      "kind": "LinkedField",
      "name": "userPhones",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserPhone",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phone",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "userPhones(condition:{\"isPrimary\":true},first:1)"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "UserAddressesConnection",
      "kind": "LinkedField",
      "name": "userAddresses",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserAddress",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "address",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "city",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "country",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postalCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stateOrProvince",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "street1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "street2",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "userAddresses(condition:{\"isPrimary\":true},first:1)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInsurancesConnection",
      "kind": "LinkedField",
      "name": "userInsurances",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "isActive": true,
            "provider": "STRIPE"
          }
        }
      ],
      "concreteType": "UserPaymentMethodsConnection",
      "kind": "LinkedField",
      "name": "userPaymentMethods",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "userPaymentMethods(condition:{\"isActive\":true,\"provider\":\"STRIPE\"})"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "706dde552cd71717cc53447ca3091350";

export default node;
