import {
  type UserChildrenTable_user$data,
  type UserChildrenTable_user$key
} from '@app/__generated__/UserChildrenTable_user.graphql'
import { type UserChildrenTablePaginationQuery } from '@app/__generated__/UserChildrenTablePaginationQuery.graphql'
import { type UserChildrenTableUpdateUserChildMutation } from '@app/__generated__/UserChildrenTableUpdateUserChildMutation.graphql'
import { PaginationTable } from '@app/components'
import { type UserChildFilter, UserChildrenOrderBy } from '@app/lib'
import { ActionIcon } from '@mantine/core'
import { useModals } from '@mantine/modals'
import { IconEdit } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, useMutation, usePaginationFragment } from 'react-relay'

export type UserChildrenTableUserChildEdge = ElementOf<UserChildrenTable_user$data['userChildren']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment UserChildrenTable_user on User
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "UserChildFilter" }
    first: { type: "Int" }
    orderBy: { type: "[UserChildrenOrderBy!]", defaultValue: [NAME_ASC] }
  )
  @refetchable(queryName: "UserChildrenTablePaginationQuery") {
    id
    rowId
    userChildren(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "UserChildrenTable_query_userChildren", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          birthdateAt
          dueAt
          id
          name
        }
      }
      totalCount
    }
  }
`

const updateUserChildMutation = graphql`
  mutation UserChildrenTableUpdateUserChildMutation($input: UpdateUserChildByIdInput!) {
    updateUserChildById(input: $input) {
      userChild {
        birthdateAt
        dueAt
        name
      }
    }
  }
`

export interface UserChildrenTableProps {
  user: UserChildrenTable_user$key
}

export const UserChildrenTable: FC<UserChildrenTableProps> = ({ user }) => {
  const {
    data: userData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<UserChildrenTablePaginationQuery, UserChildrenTable_user$key>(paginationFragment, user)

  const [updateUserChild, isUpdatingUserChild] =
    useMutation<UserChildrenTableUpdateUserChildMutation>(updateUserChildMutation)
  const { t } = useTranslation('admin')

  return (
    <PaginationTable<
      UserChildrenTableUserChildEdge,
      UserChildrenOrderBy,
      UserChildrenTablePaginationQuery,
      UserChildFilter
    >
      columns={useMemo<ColumnDef<UserChildrenTableUserChildEdge>[]>(
        () => [
          {
            accessorKey: 'node.name',
            id: 'name',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Name')
          },
          {
            accessorKey: 'node.dueAt',
            id: 'dueAt',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Due Date')
          },
          {
            accessorKey: 'node.birthdateAt',
            id: 'birthdateAt',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Birth Date')
          }
        ],
        [t]
      )}
      data={userData?.userChildren?.edges}
      isFilterable
      hasNext={hasNext}
      getFilterFromSearch={useCallback(
        (search) => (!isEmpty(search) ? { name: { includesInsensitive: search } } : null),
        []
      )}
      initialSorting={useMemo(
        () => [
          {
            id: 'name',
            desc: false
          }
        ],
        []
      )}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={useMemo(
        () => ({
          name: {
            asc: UserChildrenOrderBy.NameAsc,
            desc: UserChildrenOrderBy.NameDesc
          },
          dueAt: {
            asc: UserChildrenOrderBy.DueAtAsc,
            desc: UserChildrenOrderBy.DueAtDesc
          },
          birthdateAt: {
            asc: UserChildrenOrderBy.BirthdateAtAsc,
            desc: UserChildrenOrderBy.BirthdateAtDesc
          }
        }),
        []
      )}
      totalCount={userData?.userChildren?.totalCount}
    />
  )
}
