import { Textarea, type TextareaProps } from '@mantine/core'
import { IconAlphabetLatin } from '@tabler/icons-react'
import { type FC } from 'react'

export interface TextareaInputProps extends TextareaProps {}

export const TextareaInput: FC<TextareaInputProps> = (textareaProps) => (
  <Textarea
    autosize
    minRows={3}
    maxRows={6}
    icon={<IconAlphabetLatin size={18} />}
    {...textareaProps}
  />
)
