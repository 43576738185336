import {
  type ServicesTable_query$data,
  type ServicesTable_query$key
} from '@app/__generated__/ServicesTable_query.graphql'
import { type ServicesTablePaginationQuery } from '@app/__generated__/ServicesTablePaginationQuery.graphql'
import { IconBoolean, PaginationTable } from '@app/components'
import { type ServiceFilter, ServicesOrderBy, toCurrency } from '@app/lib'
import { type ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'
import { ActionIcon } from '@mantine/core'
import Link from 'next/link'
import { IconChevronRight } from '@tabler/icons-react'

export type ServicesTableServiceEdge = ElementOf<ServicesTable_query$data['services']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment ServicesTable_query on Query
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "ServiceFilter" }
    first: { type: "Int" }
    orderBy: { type: "[ServicesOrderBy!]", defaultValue: [NAME_ASC] }
  )
  @refetchable(queryName: "ServicesTablePaginationQuery") {
    services(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "ServicesTable_query_services", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          duration {
            days
            hours
            minutes
            months
            seconds
            years
          }
          id
          isActive
          name
          price
          slug
        }
      }
      totalCount
    }
  }
`

export interface ServicesTableProps {
  query: ServicesTable_query$key
}

export const ServicesTable: FC<ServicesTableProps> = ({ query }) => {
  const router = useRouter()
  const { t } = useTranslation('admin')
  const {
    data: queryData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<ServicesTablePaginationQuery, ServicesTable_query$key>(paginationFragment, query)

  return (
    <PaginationTable<ServicesTableServiceEdge, ServicesOrderBy, ServicesTablePaginationQuery, ServiceFilter>
      columns={useMemo<ColumnDef<ServicesTableServiceEdge>[]>(
        () => [
          {
            accessorKey: 'node.name',
            id: 'name',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Name')
          },
          {
            accessorKey: 'node.duration',
            id: 'duration',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Duration'),
            cell: (props) => {
              const node = props.row.original.node

              return `${dayjs.duration(node.duration).asMinutes()}min`
            }
          },
          {
            accessorKey: 'node.price',
            id: 'price',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Price'),
            cell: (props) => {
              const node = props.row.original.node

              return toCurrency(node.price, router.locale)
            }
          },
          {
            id: 'isActive',
            header: t('Active'),
            size: 30,
            cell: (props) => {
              const node = props.row.original.node

              return <IconBoolean value={node.isActive} />
            }
          },
          {
            id: 'actions',
            size: 30,
            cell: (props) => (
              <ActionIcon
                color='blue'
                component={Link}
                href={`/admin/services/${props.row.original.node.slug}`}
                title={t('View Service')}
                variant='transparent'
              >
                <IconChevronRight size={14} />
              </ActionIcon>
            )
          }
        ],
        [router.locale, t]
      )}
      data={queryData?.services?.edges}
      isFilterable
      getFilterFromSearch={useCallback(
        (search) =>
          !isEmpty(search)
            ? {
                name: {
                  includesInsensitive: search
                }
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={useMemo(
        () => [
          {
            id: 'name',
            desc: false
          }
        ],
        []
      )}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={useMemo(
        () => ({
          duration: {
            asc: ServicesOrderBy.DurationAsc,
            desc: ServicesOrderBy.DurationDesc
          },
          name: {
            asc: ServicesOrderBy.NameAsc,
            desc: ServicesOrderBy.NameDesc
          },
          price: {
            asc: ServicesOrderBy.PriceAsc,
            desc: ServicesOrderBy.PriceDesc
          }
        }),
        []
      )}
      totalCount={queryData?.services?.totalCount}
    />
  )
}
