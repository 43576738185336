import { type ReferralPartnerForm_query$key } from '@app/__generated__/ReferralPartnerForm_query.graphql'
import { type ReferralPartnerForm_referralPartner$key } from '@app/__generated__/ReferralPartnerForm_referralPartner.graphql'
import {
  CheckboxInput,
  DropzoneInput,
  Form,
  RichTextInput,
  SelectInput,
  TextInput,
  UploadProgressOverlay
} from '@app/components'
import {
  connectionNodesToKeyPairs,
  enumTypeToKeyPairs,
  type ReferralPartnerInput,
  type ReferralPartnerPatch,
  ReferralPartnerType,
  UserInsuranceType,
  useUploader,
  zodFileOrUploadOrNull,
  zodStringEmptyOrNotOnlySpaces,
  zodStringRequiredAndNotOnlySpaces
} from '@app/lib'
import { Paper, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import { IconMail } from '@tabler/icons-react'
import { kebabCase, toUpper } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const referralPartnerFragment = graphql`
  fragment ReferralPartnerForm_referralPartner on ReferralPartner {
    isActive
    config
    name
    referralPartnerUploads(filter: { name: { equalTo: "logo" } }) {
      nodes {
        id
        rowId
        upload {
          id
          name: filename
          rowId
          url
        }
      }
    }
    rowId
    slugPublic
    type
  }
`

const queryFragment = graphql`
  fragment ReferralPartnerForm_query on Query {
    currentUser {
      rowId
    }
    insuranceProviders(orderBy: [NAME_ASC]) {
      nodes {
        rowId
        name
      }
    }
  }
`

export interface ReferralPartnerFormData {
  isActive: boolean
  logo: {
    id: string
    name: string
    rowId: any
    url: string
  }
  name: string
  type: ReferralPartnerType
  slugPublic: string
  config: {
    topBannerText: string
    welcomeText: string
    whatToHaveReadyText: string
    customHeaderComponent: string
    logoWidthInPixels: string
    timeSelectionCalloutText: string
    callUsButtonPhoneNumber: string
    isPrenatalRecommendedTimeEnabled: boolean
    alternativeLatePolicyText: string
    overrideInsuranceProviderId: string
    overrideInsuranceProviderType: string
    memberIdFieldLabel: string
    groupNumberFieldLabel: string
    isAddressFieldsetDisabled: boolean
    isPolicyHolderAddressFieldsetDisabled: boolean
    isInsuranceImageFieldsetDisabled: boolean
  }
}

export interface ReferralPartnerFormProps {
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (referralPartner: ReferralPartnerPatch | ReferralPartnerInput) => void
  referralPartner: ReferralPartnerForm_referralPartner$key
  query: ReferralPartnerForm_query$key
}

export const ReferralPartnerForm: FC<ReferralPartnerFormProps> = ({
  isSaving,
  onChange,
  onSubmit,
  referralPartner,
  query
}) => {
  const referralPartnerData = useFragment<ReferralPartnerForm_referralPartner$key>(
    referralPartnerFragment,
    referralPartner
  )
  const queryData = useFragment<ReferralPartnerForm_query$key>(queryFragment, query)
  const logoUpload = referralPartnerData?.referralPartnerUploads.nodes[0]
  const { t } = useTranslation('admin')

  const form = useForm<ReferralPartnerFormData>({
    initialValues: {
      isActive: referralPartnerData?.isActive ?? true,
      logo: logoUpload?.upload || null,
      name: referralPartnerData?.name || '',
      type: (referralPartnerData?.type as ReferralPartnerType) || ReferralPartnerType.Clinical,
      slugPublic: referralPartnerData?.slugPublic || '',
      config: {
        // header
        topBannerText: referralPartnerData?.config?.topBannerText || '',
        welcomeText: referralPartnerData?.config?.welcomeText || '',
        whatToHaveReadyText: referralPartnerData?.config?.whatToHaveReadyText || '',
        customHeaderComponent: referralPartnerData?.config?.customHeaderComponent || '',
        logoWidthInPixels: referralPartnerData?.config?.logoWidthInPixels || '',

        // slot selection
        timeSelectionCalloutText: referralPartnerData?.config?.timeSelectionCalloutText || '',
        callUsButtonPhoneNumber: referralPartnerData?.config?.callUsButtonPhoneNumber || '',
        isPrenatalRecommendedTimeEnabled: referralPartnerData?.config?.isPrenatalRecommendedTimeEnabled || false,

        // @todo - ditch this:
        // isLeadCaptureFormEnabled: referralPartnerData?.config?.isLeadCaptureFormEnabled || false,
        // @todo - asking Chelsie if we can ditch
        alternativeLatePolicyText: referralPartnerData?.config?.alternativeLatePolicyText || '',
        // hard-code the insurance payer + type for this partner
        overrideInsuranceProviderId: referralPartnerData?.config?.overrideInsuranceProviderId || null,
        overrideInsuranceProviderType: referralPartnerData?.config?.overrideInsuranceProviderType || null,
        memberIdFieldLabel: referralPartnerData?.config?.memberIdFieldLabel || '',
        groupNumberFieldLabel: referralPartnerData?.config?.groupNumberFieldLabel || '',
        isAddressFieldsetDisabled: referralPartnerData?.config?.isAddressFieldsetDisabled || false,
        isPolicyHolderAddressFieldsetDisabled:
          referralPartnerData?.config?.isPolicyHolderAddressFieldsetDisabled || false,
        isInsuranceImageFieldsetDisabled: referralPartnerData?.config?.isInsuranceImageFieldsetDisabled || false
      }
    },
    validate: zodResolver(
      z.object({
        isActive: z.boolean(),
        logo: zodFileOrUploadOrNull(),
        name: zodStringEmptyOrNotOnlySpaces(t('Cannot be only spaces')),
        type: z.nativeEnum(ReferralPartnerType),
        slugPublic: zodStringRequiredAndNotOnlySpaces(t('Slug is required')).regex(/^[a-zA-Z0-9_-]*$/, {
          message: t('Can only contain alphanumeric characters, hyphens and underscores (no spaces)')
        }),
        config: z.object({
          topBannerText: z.string(),
          welcomeText: z.string(),
          whatToHaveReadyText: z.string(),
          customHeaderComponent: z.string(),
          logoWidthInPixels: z.string(),

          timeSelectionCalloutText: z.string(),
          callUsButtonPhoneNumber: z.string(), //
          isPrenatalRecommendedTimeEnabled: z.boolean(),

          // @todo - deprecate
          // isLeadCaptureFormEnabled: z.boolean(),
          // lateCancellationPolicyOverrideMessage: z.string(),

          overrideInsuranceProviderId: z.string().uuid().or(z.null()),
          overrideInsuranceProviderType: z.nativeEnum(UserInsuranceType).or(z.null()),
          memberIdFieldLabel: z.string(),
          groupNumberFieldLabel: z.string(),
          isAddressFieldsetDisabled: z.boolean(),
          isPolicyHolderAddressFieldsetDisabled: z.boolean(),
          isInsuranceImageFieldsetDisabled: z.boolean()
        })
      })
    ),
    validateInputOnChange: true
  })
  const { isUploading, uploadFile, uploadProgress } = useUploader()

  useShallowEffect(() => {
    if (onChange) {
      onChange(form.isDirty())
    }
  }, [form.values])

  return (
    <>
      <UploadProgressOverlay
        progress={uploadProgress}
        visible={isUploading}
      />
      <Form
        isSaving={isSaving}
        isValid={form.isValid()}
        onSubmit={form.onSubmit(async ({ logo, ...values }) => {
          const data: any = {
            ...values
          }

          if (logo instanceof File) {
            const { bucket, key } = await uploadFile(logo, {
              data: {
                path: 'referralPartners/logos'
              }
            })

            data.referralPartnerUploads = {
              // get rid of the previous logo upload, if present
              deleteById: logoUpload?.id
                ? [
                    {
                      id: logoUpload?.id
                    }
                  ]
                : null,
              // create a single new user_uploads record, with the name of "logo"
              create: [
                {
                  name: 'logo',
                  // and for its related upload record:
                  upload: {
                    // create a new uploads record, with the s3 object data and the user as uploader
                    create: {
                      uploaderId: queryData?.currentUser.rowId,
                      filename: logo.name,
                      filesize: logo.size,
                      filetype: logo.type,
                      bucket,
                      key
                    }
                  }
                }
              ]
            }
          }

          await onSubmit(data)
        })}
      >
        <Paper
          shadow='xs'
          p='md'
          mb='md'
          withBorder
        >
          <Title
            order={3}
            underline
            mb='xl'
          >
            {t('Details')}
          </Title>
          <SelectInput
            disabled={isSaving}
            label={t('Type')}
            description={t('The type of referral partner. Clinical & Employer partners disable payment by card.')}
            data={enumTypeToKeyPairs(ReferralPartnerType, toUpper)}
            required
            icon={<IconMail size={18} />}
            {...form.getInputProps('type')}
          />
          <TextInput
            disabled={isSaving}
            label={t('Name')}
            required
            {...form.getInputProps('name')}
            onBlur={(event) => {
              if (!form.values.slugPublic) {
                form.setFieldValue('slugPublic', kebabCase(event.currentTarget.value))
              }
            }}
          />
          <TextInput
            disabled={isSaving}
            label={t('URL Code')}
            required
            {...form.getInputProps('slugPublic')}
          />
          <DropzoneInput
            disabled={isSaving}
            label={t('Logo')}
            // @ts-ignore manually specifying these, since form.getInputProps() only knows how to deal with html form inputs
            onChange={(file) => form.setFieldValue('logo', file)}
            value={form.values.logo}
          />
          <CheckboxInput
            disabled={isSaving}
            label={t('Active')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('isActive', { type: 'checkbox' })}
          />
        </Paper>

        <Paper
          shadow='xs'
          p='md'
          mb='md'
          withBorder
        >
          <Title
            order={3}
            underline
            mb='xl'
          >
            {t('Booking Flow Configurations')}
          </Title>
          <Title
            order={4}
            color='gray.7'
          >
            {t('Welcome/Header')}
          </Title>
          <TextInput
            disabled={isSaving}
            label={t('Top Banner Header Text')}
            description={t(
              'Primary call to action at the top of the booking flow, defaults to "See a Lactation Consultant Today!"'
            )}
            placeholder={t('See a Lactation Consultant Today!')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.topBannerText')}
          />

          <TextInput
            disabled={isSaving}
            label={t('Logo Width (px)')}
            description={t('Custom width for the partner logo, defaults to 400px')}
            placeholder={t('400px')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.logoWidthInPixels')}
          />

          <CheckboxInput
            disabled={isSaving}
            label={t('Enable Prenatal "Recommended Time" Header')}
            description={t(
              'If enabled, displays additional header: "Prenatal appointments recommended between 28 and 38 weeks"'
            )}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.isPrenatalRecommendedTimeEnabled', { type: 'checkbox' })}
          />

          <RichTextInput
            disabled={isSaving}
            label={t('Welcome Message')}
            description={t(
              'The full welcome message at the top of the booking flow, including the "accept all insurance..." byline and a callout to call us.'
            )}
            placeholder={t('WE ACCEPT ALL INSURANCE PLANS + MEDICAID ... ')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.welcomeText')}
          />

          <TextInput
            disabled={isSaving}
            label={t('"What to Have Ready" Message')}
            placeholder={t('Aetna Better Health Medicaid ID Card')}
            description={t(
              'Indicate what a patient should have on hand when filling out the form. Defaults to "Please have your insurance card ready"'
            )}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.whatToHaveReadyText')}
          />

          <TextInput
            disabled={isSaving}
            placeholder={t('CignaCustomHeaderComponent')}
            description={t(
              'TODO: Custom header component to use for this partner - if set, this will override all header settings'
            )}
            label={t('Custom Header Component (dev only)')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.customHeaderComponent')}
          />

          <Title
            order={4}
            color='gray.7'
          >
            {t('Time Selection')}
          </Title>

          <TextInput
            disabled={isSaving}
            label={t('Phone Number for custom "Call Us Now" button')}
            description={t(
              'Defaults to disabled; renders an additional "Call Us" button above the slot selection grid.'
            )}
            placeholder={t('800-111-2222')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.callUsButtonPhoneNumber')}
          />

          <TextInput
            disabled={isSaving}
            label={t('Callout Text')}
            description={t('Displayed just below slot selection.')}
            placeholder={t("Don't see a time that works?")}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.timeSelectionCalloutText')}
          />

          <Title
            order={4}
            color='gray.7'
          >
            {t('Policies')}
          </Title>

          <TextInput
            disabled={isSaving}
            label={t('Alternative Late Policy Text')}
            description={t('Alternative messaging for late policy/cancelations')}
            placeholder={t(
              'Out of courtesy to our consultants and families on our waitlist, we request our clients to provide more than 24 hours notice if needing to reschedule or cancel.'
            )}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.alternativeLatePolicyText')}
          />

          <Title
            order={4}
            color='gray.7'
          >
            {t('Insurance')}
          </Title>

          <SelectInput
            disabled={isSaving}
            label={t('Override Insurance Type')}
            description={t('Force selection of a single Insurance Type, ex: "Medicaid" only')}
            data={enumTypeToKeyPairs(UserInsuranceType)}
            clearable
            {...form.getInputProps('config.overrideInsuranceProviderType')}
          />

          <SelectInput
            disabled={isSaving}
            label={t('Override Insurance Provider')}
            description={t('Force selection of a single Insurance Provider, ex: "Aetna Better Health" only')}
            data={connectionNodesToKeyPairs(queryData.insuranceProviders)}
            clearable
            {...form.getInputProps('config.overrideInsuranceProviderId')}
          />

          <TextInput
            disabled={isSaving}
            label={t('Insurance "Member ID" field label')}
            description={t('Override the label for the "Member ID" field, defaults to "Your Insurance ID"')}
            placeholder={t('Your Insurance ID')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.memberIdFieldLabel')}
          />

          <TextInput
            disabled={isSaving}
            label={t('Insurance "Group Number" field label')}
            description={t('Override the label for the "Group Number" field, defaults to "Group #"')}
            placeholder={t('Group #')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.groupNumberFieldLabel')}
          />

          <CheckboxInput
            disabled={isSaving}
            label={t('Disable Insurance Card Image Upload Requirement?')}
            description={t('If checked, the insurance card image collection step will be skipped')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.isInsuranceImageFieldsetDisabled', { type: 'checkbox' })}
          />

          <CheckboxInput
            disabled={isSaving}
            label={t('Disable Patient Address Requirement?')}
            description={t('If checked, the patient address collection step will be skipped')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.isAddressFieldsetDisabled', { type: 'checkbox' })}
          />

          <CheckboxInput
            disabled={isSaving}
            label={t('Disable Policy Holder Address Requirement?')}
            description={t('If checked, the policy holder address collection step will be skipped')}
            mt='xl'
            mb='xl'
            {...form.getInputProps('config.isPolicyHolderAddressFieldsetDisabled', { type: 'checkbox' })}
          />
        </Paper>
      </Form>
    </>
  )
}
