import { type ReferralPartnerLayout_query$key } from '@app/__generated__/ReferralPartnerLayout_query.graphql'
import { type ReferralPartnerLayout_referralPartner$key } from '@app/__generated__/ReferralPartnerLayout_referralPartner.graphql'
import { NavbarLink } from '@app/components'
import { Divider, Navbar } from '@mantine/core'
import { IconAffiliate, IconChevronLeft, IconSchema } from '@tabler/icons-react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

import { AdminLayout, type AdminLayoutProps } from './AdminLayout'

const referralPartnerFragment = graphql`
  fragment ReferralPartnerLayout_referralPartner on ReferralPartner {
    name
    slug
  }
`

const queryFragment = graphql`
  fragment ReferralPartnerLayout_query on Query {
    ...AdminLayout_query
  }
`

export type ReferralPartnerLayoutProps = Omit<AdminLayoutProps, 'query'> & {
  referralPartner: ReferralPartnerLayout_referralPartner$key
  query: ReferralPartnerLayout_query$key
}

export const ReferralPartnerLayout: FC<ReferralPartnerLayoutProps> = ({
  children,
  referralPartner,
  query,
  title,
  ...adminLayoutProps
}) => {
  const router = useRouter()
  const referralPartnerData = useFragment<ReferralPartnerLayout_referralPartner$key>(
    referralPartnerFragment,
    referralPartner
  )
  const queryData = useFragment<ReferralPartnerLayout_query$key>(queryFragment, query)
  const { t } = useTranslation('admin')
  return (
    <AdminLayout
      {...adminLayoutProps}
      navbar={
        <Navbar.Section>
          <NavbarLink
            color='gray'
            IconComponent={IconChevronLeft}
            iconVariant='light'
            label={t('Back')}
            onClick={() => router.back()}
            variant='light'
          />
          <Divider color='gray.2' />
          <NavbarLink
            color='pink'
            href={`/admin/referralPartners/${referralPartnerData?.slug}`}
            IconComponent={IconAffiliate}
            label={t('Referral Partner')}
          />
          <NavbarLink
            color='violet'
            href={`/admin/referralPartners/${referralPartnerData?.slug}/referralPartnerSources`}
            IconComponent={IconSchema}
            label={t('Sources')}
          />
        </Navbar.Section>
      }
      query={queryData}
      title={`${t('Referral Partner')} / ${referralPartnerData?.name}${title ? ` / ${title}` : ''}`}
    >
      {children}
    </AdminLayout>
  )
}
