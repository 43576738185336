import { Group, Image, Input, type InputWrapperProps, Text, useMantineTheme } from '@mantine/core'
import { Dropzone, type DropzoneProps } from '@mantine/dropzone'
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react'
import { isArray, isEmpty, map } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback } from 'react'

interface ImageData {
  url: string
}

export interface DropzoneInputProps extends Omit<InputWrapperProps, 'children' | 'onChange'> {
  disabled?: boolean
  dropZoneProps?: DropzoneProps
  multiple?: boolean
  onChange?: (value: File | File[]) => void
  value?: ImageData | File | ImageData[] | File[] | null
}

export const DropzoneInput: FC<DropzoneInputProps> = ({
  disabled,
  dropZoneProps,
  multiple,
  onChange,
  value,
  ...inputWrapperProps
}) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('admin')

  const renderAvatar = useCallback((image: ImageData | File, idx: number = 0) => {
    const isFile = !('url' in image)
    const src = isFile ? URL.createObjectURL(image) : image.url

    return (
      <Image
        alt='upload'
        key={`image-${idx}`}
        onLoad={isFile ? () => URL.revokeObjectURL(src) : undefined}
        src={src}
      />
    )
  }, [])

  return (
    <Input.Wrapper {...inputWrapperProps}>
      <Dropzone
        accept={['image/*']}
        multiple={false}
        {...dropZoneProps}
        disabled={disabled}
        onDrop={useCallback(
          (files: File[]) => {
            if (onChange) {
              onChange(multiple ? files : files[0])
            }

            if (dropZoneProps?.onDrop) {
              dropZoneProps.onDrop(files)
            }
          },
          [multiple, onChange, dropZoneProps]
        )}
      >
        <Group
          position='center'
          spacing='xl'
        >
          <Dropzone.Accept>
            <IconUpload
              size={150}
              stroke={1.5}
              color={theme.colors.lime[6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size={150}
              stroke={1.5}
              color={theme.colors.red[6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            {isEmpty(value) ? (
              // render a default placeholder
              <IconPhoto
                size={50}
                stroke={1.5}
              />
            ) : isArray(value) ? (
              // render a grid of avatars
              map(value, renderAvatar)
            ) : (
              // render a single avatar
              renderAvatar(value)
            )}
          </Dropzone.Idle>

          <Text
            color='dimmed'
            size='sm'
          >
            {t('Drag an image here or click to select a file')}
          </Text>
        </Group>
      </Dropzone>
    </Input.Wrapper>
  )
}
