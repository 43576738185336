import {
  type UserPaymentMethodsTable_user$data,
  type UserPaymentMethodsTable_user$key
} from '@app/__generated__/UserPaymentMethodsTable_user.graphql'
import { type UserPaymentMethodsTablePaginationQuery } from '@app/__generated__/UserPaymentMethodsTablePaginationQuery.graphql'
import {
  CardLogo,
  CreateUserPaymentMethodModal,
  IconBoolean,
  PaginationTable,
  UpdateUserPaymentMethodModal
} from '@app/components'
import { type UserPaymentMethodFilter, UserPaymentMethodsOrderBy } from '@app/lib'
import { ActionIcon, Center } from '@mantine/core'
import { IconEdit } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import { padStart } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useMemo, useState } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

type UserPaymentMethodsTableUserPaymentMethodEdge = ElementOf<
  UserPaymentMethodsTable_user$data['userPaymentMethods']['edges']
>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment UserPaymentMethodsTable_user on User
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "UserPaymentMethodFilter" }
    first: { type: "Int" }
    orderBy: { type: "[UserPaymentMethodsOrderBy!]", defaultValue: [IS_PRIMARY_DESC] }
  )
  @refetchable(queryName: "UserPaymentMethodsTablePaginationQuery") {
    id
    userPaymentMethods(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "UserPaymentMethodsTable_user_userPaymentMethods", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          id
          isActive
          isPrimary
          methodData
          ...CardLogo_userPaymentMethod
          ...UpdateUserPaymentMethodModal_userPaymentMethod
        }
      }
      totalCount
    }
    ...CreateUserPaymentMethodModal_user
    ...UpdateUserPaymentMethodModal_user
  }
`

export interface UserPaymentMethodsTableProps {
  onCreateModalClose: () => void
  showCreateModal: boolean
  user: UserPaymentMethodsTable_user$key
}

export const UserPaymentMethodsTable: FC<UserPaymentMethodsTableProps> = ({
  onCreateModalClose,
  showCreateModal,
  user
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState<string | null>(null)
  const {
    data: userData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<UserPaymentMethodsTablePaginationQuery, UserPaymentMethodsTable_user$key>(
    paginationFragment,
    user
  )
  const { t } = useTranslation('admin')

  return (
    <>
      <PaginationTable<
        UserPaymentMethodsTableUserPaymentMethodEdge,
        UserPaymentMethodsOrderBy,
        UserPaymentMethodsTablePaginationQuery,
        UserPaymentMethodFilter
      >
        columns={useMemo<ColumnDef<UserPaymentMethodsTableUserPaymentMethodEdge>[]>(
          () => [
            {
              id: 'type',
              header: t('Type'),
              size: 60,
              cell: (props) => {
                const node = props.row.original.node

                return (
                  <Center>
                    <CardLogo
                      width={60}
                      userPaymentMethod={node}
                    />
                  </Center>
                )
              }
            },
            {
              id: 'name',
              header: t('Name'),
              cell: (props) => {
                const node = props.row.original.node
                const { name } = node.methodData

                return name
              }
            },
            {
              id: 'last4',
              header: t('Last 4'),
              cell: (props) => {
                const node = props.row.original.node
                const { last4 } = node.methodData

                return last4
              }
            },
            {
              id: 'expires',
              header: t('Expires'),
              cell: (props) => {
                const node = props.row.original.node
                const { expMonth, expYear } = node.methodData

                return `${padStart(expMonth, 2, '0')} / ${expYear}`
              }
            },
            {
              accessorKey: 'node.isActive',
              id: 'isActive',
              enableMultiSort: false,
              enableSorting: true,
              size: 30,
              header: t('Active'),
              cell: (props) => {
                const node = props.row.original.node

                return <IconBoolean value={node.isActive} />
              }
            },
            {
              accessorKey: 'node.isPrimary',
              id: 'isPrimary',
              header: t('Primary'),
              enableMultiSort: false,
              enableSorting: true,
              size: 30,
              cell: (props) => {
                const node = props.row.original.node

                return <IconBoolean value={node.isPrimary} />
              }
            },
            {
              id: 'actions',
              size: 30,
              cell: (props) => {
                const node = props.row.original.node

                return (
                  <>
                    <ActionIcon
                      color='blue'
                      title={t('Edit Payment Method')}
                      onClick={() => setShowUpdateModal(node.id)}
                      variant='transparent'
                    >
                      <IconEdit size={14} />
                    </ActionIcon>
                    <UpdateUserPaymentMethodModal
                      onClose={() => setShowUpdateModal(null)}
                      opened={node.id === showUpdateModal}
                      user={userData}
                      userPaymentMethod={node}
                    />
                  </>
                )
              }
            }
          ],
          [t, showUpdateModal, userData]
        )}
        data={userData?.userPaymentMethods?.edges}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'isPrimary',
              desc: true
            }
          ],
          []
        )}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            isActive: {
              asc: UserPaymentMethodsOrderBy.IsActiveAsc,
              desc: UserPaymentMethodsOrderBy.IsActiveDesc
            },
            isPrimary: {
              asc: UserPaymentMethodsOrderBy.IsPrimaryAsc,
              desc: UserPaymentMethodsOrderBy.IsPrimaryDesc
            }
          }),
          []
        )}
        totalCount={userData?.userPaymentMethods?.totalCount}
      />
      <CreateUserPaymentMethodModal
        connectionId={userData?.userPaymentMethods?.__id}
        user={userData}
        onClose={onCreateModalClose}
        opened={showCreateModal}
      />
    </>
  )
}
