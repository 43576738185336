import { type ReferralPartnerLogo_referralPartner$key } from '@app/__generated__/ReferralPartnerLogo_referralPartner.graphql'
import { Image, type ImageProps } from '@mantine/core'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const referralPartnerFragment = graphql`
  fragment ReferralPartnerLogo_referralPartner on ReferralPartner {
    name
    referralPartnerUploads(filter: { name: { equalTo: "logo" } }) {
      nodes {
        upload {
          url
        }
      }
    }
    config
  }
`

export interface ReferralPartnerLogoProps extends Omit<ImageProps, 'alt' | 'src' | 'title'> {
  referralPartner: ReferralPartnerLogo_referralPartner$key
}

export const ReferralPartnerLogo: FC<ReferralPartnerLogoProps> = ({ referralPartner, width, ...imageProps }) => {
  const referralPartnerData = useFragment<ReferralPartnerLogo_referralPartner$key>(
    referralPartnerFragment,
    referralPartner
  )

  return referralPartnerData?.referralPartnerUploads?.nodes?.[0]?.upload?.url ? (
    <Image
      {...imageProps}
      alt='logo'
      src={referralPartnerData.referralPartnerUploads.nodes[0].upload.url}
      title={referralPartnerData.name}
      width={referralPartnerData.config?.logoWidthInPixels || width || 400}
    />
  ) : (
    '—'
  )
}
