import { Button, Group, LoadingOverlay } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'
import { type FC, type FormEvent, type PropsWithChildren, type ReactNode } from 'react'

export interface FormProps extends PropsWithChildren {
  buttons?: ReactNode | boolean
  isSaving?: boolean
  isValid?: boolean
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
}

// @todo make it so you can't double-submit
export const Form: FC<FormProps> = ({ buttons = true, children, isSaving, isValid = true, onSubmit, ...formProps }) => {
  const { t } = useTranslation('admin')

  return (
    <form
      {...formProps}
      onSubmit={onSubmit}
      style={{ position: 'relative' }}
    >
      <LoadingOverlay visible={isSaving} />
      {children}
      {buttons ? (
        <Group position='right'>
          {buttons === true ? (
            <Button
              color='green'
              disabled={isSaving || !isValid}
              leftIcon={<IconCheck size={18} />}
              loading={isSaving}
              type='submit'
            >
              {t('Save')}
            </Button>
          ) : (
            buttons
          )}
        </Group>
      ) : null}
    </form>
  )
}
