import { type CreateLeadModal_query$key } from '@app/__generated__/CreateLeadModal_query.graphql'
import { type CreateLeadModalCreateLeadMutation } from '@app/__generated__/CreateLeadModalCreateLeadMutation.graphql'
import { LeadForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import { type CreateLeadInput } from '@app/lib'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const createLeadMutation = graphql`
  mutation CreateLeadModalCreateLeadMutation($connections: [ID!]!, $input: CreateLeadInput!) {
    createLead(input: $input) {
      leadEdge @appendEdge(connections: $connections) {
        cursor
        node {
          slug
          createdAt
          status
          source
          zohoId
          optInAt
          optInMethod
          convertedAt
          user {
            firstName
            lastName
            slug
          }
          referralPartner {
            name
          }
        }
      }
    }
  }
`
const queryFragment = graphql`
  fragment CreateLeadModal_query on Query {
    ...LeadForm_query
  }
`
export interface CreateLeadModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  connectionId: string
  query: CreateLeadModal_query$key
}

export const CreateLeadModal: FC<CreateLeadModalProps> = ({ connectionId, onClose, query, ...modalProps }) => {
  const router = useRouter()
  const queryData = useFragment<CreateLeadModal_query$key>(queryFragment, query)
  const [createLead, isCreatingLead] = useMutation<CreateLeadModalCreateLeadMutation>(createLeadMutation)
  const { t } = useTranslation('admin')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      size='md'
      title={t('New User')}
      {...modalProps}
    >
      <LeadForm
        isSaving={isCreatingLead}
        onChange={setIsDirty}
        onSubmit={(lead) =>
          createLead({
            variables: {
              connections: [connectionId],
              input: {
                lead
              } as CreateLeadInput
            },
            onCompleted: async (response) => {
              await onClose()
              await router.push(`/admin/users/${response?.createLead?.leadEdge?.node?.user?.slug}/lead`)
            }
          })
        }
        lead={null}
        query={queryData}
      />
    </UnsavedChangesModal>
  )
}
