import { type UserLayout_query$key } from '@app/__generated__/UserLayout_query.graphql'
import { type UserLayout_user$key } from '@app/__generated__/UserLayout_user.graphql'
import { NavbarLink } from '@app/components'
import { getMantineColor } from '@app/lib'
import { Divider, Navbar, ScrollArea } from '@mantine/core'
import {
  IconBabyCarriage,
  IconBuildingBank,
  IconCalendarEvent,
  IconChevronLeft,
  IconCreditCard,
  IconDetails,
  IconHeartHandshake,
  IconLanguage,
  IconMailbox,
  IconMedicalCross,
  IconPhone,
  IconUser,
  IconUserExclamation
} from '@tabler/icons-react'
import { has } from 'lodash'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { AdminLayout, type AdminLayoutProps } from './AdminLayout'

const userFragment = graphql`
  fragment UserLayout_user on User {
    firstName
    lastName
    clinician {
      slug
    }
    lead {
      slug
    }
    patient {
      slug
    }
    rowId
    slug
  }
`

const queryFragment = graphql`
  fragment UserLayout_query on Query {
    ...AdminLayout_query
  }
`

export type UserLayoutProps = Omit<AdminLayoutProps, 'query'> & {
  user: UserLayout_user$key
  query: UserLayout_query$key
}

export const UserLayout: FC<UserLayoutProps> = ({ children, user, query, title, ...adminLayoutProps }) => {
  const router = useRouter()
  const userData = useFragment<UserLayout_user$key>(userFragment, user)
  const queryData = useFragment<UserLayout_query$key>(queryFragment, query)
  const { t } = useTranslation('admin')

  const isClinician = has(userData, 'clinician.slug')
  const isLead = has(userData, 'lead.slug')
  const isPatient = has(userData, 'patient.slug')

  return (
    <AdminLayout
      {...adminLayoutProps}
      navbar={
        <Navbar.Section
          component={ScrollArea}
          grow
        >
          <NavbarLink
            color='gray'
            IconComponent={IconChevronLeft}
            iconVariant='light'
            label={t('Back')}
            onClick={() => router.back()}
            variant='light'
          />
          <Divider color='gray.2' />
          <NavbarLink
            color={getMantineColor(true)}
            IconComponent={IconUser}
            label={t('User')}
            href={`/admin/users/${userData?.slug}`}
          />
          {isClinician && (
            <NavbarLink
              color={getMantineColor()}
              IconComponent={IconHeartHandshake}
              label={t('Clinician')}
            >
              <NavbarLink
                label={t('Details')}
                href={`/admin/users/${userData?.slug}/clinician`}
              />
              <NavbarLink
                label={t('Appointments')}
                href={`/admin/users/${userData?.slug}/clinicianAppointments`}
              />
            </NavbarLink>
          )}
          {isLead && (
            <NavbarLink
              color={getMantineColor()}
              href={`/admin/users/${userData?.slug}/lead`}
              IconComponent={IconUserExclamation}
              label={t('Lead')}
            />
          )}
          {isPatient && (
            <>
              <NavbarLink
                color={getMantineColor()}
                IconComponent={IconMedicalCross}
                label={t('Patient')}
              >
                <NavbarLink
                  label={t('Details')}
                  href={`/admin/users/${userData?.slug}/patient`}
                />
                <NavbarLink
                  href={`/admin/users/${userData?.slug}/addresses`}
                  label={t('Addresses')}
                />
                <NavbarLink
                  label={t('Appointments')}
                  href={`/admin/users/${userData?.slug}/patientAppointments`}
                />
                <NavbarLink
                  href={`/admin/users/${userData?.slug}/children`}
                  label={t('Children')}
                />
                <NavbarLink
                  href={`/admin/users/${userData.slug}/userInsurances`}
                  label={t('Insurance')}
                />
                <NavbarLink
                  href={`/admin/users/${userData?.slug}/paymentMethods`}
                  label={t('Payment Methods')}
                />
              </NavbarLink>
            </>
          )}
          <NavbarLink
            color={getMantineColor()}
            href={`/admin/users/${userData?.slug}/languages`}
            IconComponent={IconLanguage}
            label={t('Languages')}
          />
          <NavbarLink
            color={getMantineColor()}
            href={`/admin/users/${userData?.slug}/phones`}
            IconComponent={IconPhone}
            label={t('Phone Numbers')}
          />
        </Navbar.Section>
      }
      query={queryData}
      title={`${t('Users')} / ${userData?.firstName} ${userData?.lastName}${title ? ` / ${title}` : ''}`}
    >
      {children}
    </AdminLayout>
  )
}
