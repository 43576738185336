/**
 * @generated SignedSource<<744b44d7f2c305de87a66949f444fdef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserLayout_user$data = {
  readonly clinician: {
    readonly slug: string | null;
  } | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly lead: {
    readonly slug: string | null;
  } | null;
  readonly patient: {
    readonly slug: string | null;
  } | null;
  readonly rowId: any;
  readonly slug: string | null;
  readonly " $fragmentType": "UserLayout_user";
};
export type UserLayout_user$key = {
  readonly " $data"?: UserLayout_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserLayout_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserLayout_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Clinician",
      "kind": "LinkedField",
      "name": "clinician",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lead",
      "kind": "LinkedField",
      "name": "lead",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "e426289c452c0f7afaa0aace25509e06";

export default node;
