import { type BookInsuranceDetails_query$key } from '@app/__generated__/BookInsuranceDetails_query.graphql'
import {
  DateInput,
  DefinitionList,
  DropzoneInput,
  SelectInput,
  TextInput,
  UserPaymentMethodDisplay,
  BigButton
} from '@app/components'
import {
  connectionNodesToKeyPairs,
  enumTypeToKeyPairs,
  humanize,
  PaymentProviders,
  UserInsurancePolicyHolderRelationship,
  UserInsuranceType,
  zodFileOrUploadOrNull,
  zodStringEmptyOrNotOnlySpaces
} from '@app/lib'
import { Button, Container, Group, Paper, Stack, Text, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import dayjs from 'dayjs'
import { isEmpty, isNull, split, startCase, trim } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

interface BookInsuranceDetailsInsuranceFormUpload {
  readonly id: string
  readonly name: string
  readonly rowId: any
  readonly url: string
}

interface BookInsuranceDetailsInsuranceFormData {
  _cardFront: BookInsuranceDetailsInsuranceFormUpload | File | null
  _cardBack: BookInsuranceDetailsInsuranceFormUpload | File | null
  insuranceProviderId: string
  policyHolderFirstName: string
  policyHolderLastName: string
  policyHolderBirthdateAt: Date | null
  groupId: string
  memberId: string
  policyHolderRelationship: UserInsurancePolicyHolderRelationship
  type: UserInsuranceType
}

const queryFragment = graphql`
  fragment BookInsuranceDetails_query on Query {
    currentUser {
      firstName
      lastName
      userAddresses(condition: { isPrimary: true }, first: 1) {
        nodes {
          address {
            street1
            street2
            city
            stateOrProvince
            postalCode
            country
          }
        }
      }
      userInsurances(condition: { category: PRIMARY }, first: 1) {
        nodes {
          groupId
          insuranceProviderId
          insuranceProvider {
            name
          }
          memberId
          policyHolderAddressCity
          policyHolderAddressCountry
          policyHolderAddressPostalCode
          policyHolderAddressStateOrProvince
          policyHolderAddressStreet1
          policyHolderAddressStreet2
          policyHolderBirthdateAt
          policyHolderFirstName
          policyHolderGender
          policyHolderHomePhone
          policyHolderLastName
          policyHolderMobilePhone
          policyHolderRelationship
          policyHolderSsn
          userInsuranceUploads {
            nodes {
              id
              name
              rowId
              upload {
                id
                name: filename
                rowId
                url
              }
            }
          }
          type
        }
      }
      userPaymentMethods(condition: { provider: STRIPE, isActive: true, isPrimary: true }, first: 1) {
        nodes {
          ...UserPaymentMethodDisplay_userPaymentMethod
          methodData
          rowId
        }
      }
    }
    insuranceProviders(orderBy: [NAME_ASC]) {
      nodes {
        name
        rowId
      }
    }
  }
`
const policyHolderRelationshipOptions = [
  {
    label: startCase(UserInsurancePolicyHolderRelationship.Child),
    value: UserInsurancePolicyHolderRelationship.Child
  },
  {
    label: startCase(UserInsurancePolicyHolderRelationship.Spouse),
    value: UserInsurancePolicyHolderRelationship.Spouse
  },
  {
    label: startCase(UserInsurancePolicyHolderRelationship.Other),
    value: UserInsurancePolicyHolderRelationship.Other
  }
]

interface BookInsuranceDetailsPaymentMethodData {
  paymentProvider: PaymentProviders
  paymentMethodId: string
}

interface BookInsuranceDetailsProps {
  onNext?(data: {
    userInsuranceData?: BookInsuranceDetailsInsuranceFormData
    userPaymendMethodData?: BookInsuranceDetailsPaymentMethodData
  }): Promise<void> | void
  onPrevious?(): Promise<void> | void
  query: BookInsuranceDetails_query$key
}

export const BookInsuranceDetails: FC<BookInsuranceDetailsProps> = ({ onNext, onPrevious, query }) => {
  const { t } = useTranslation('booking')
  const [isCardElementComplete, setIsCardElementComplete] = useState(false)
  const [isCreatingToken, setIsCreatingToken] = useState(false)
  const [isUserPaymentMethodChanged, setIsUserPaymentMethodChanged] = useState(false)
  const [isUserInsuranceChanged, setIsUserInsuranceChanged] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const queryData = useFragment(queryFragment, query)
  const userAddressData = queryData?.currentUser?.userAddresses?.nodes?.[0]
  const userInsuranceData = queryData?.currentUser?.userInsurances?.nodes?.[0]
  const userPaymentMethodData = queryData?.currentUser?.userPaymentMethods?.nodes?.[0]
  const userInsuranceForm = useForm<BookInsuranceDetailsInsuranceFormData>({
    initialValues: {
      _cardFront: null,
      _cardBack: null,
      insuranceProviderId: null,
      policyHolderFirstName: '',
      policyHolderLastName: '',
      policyHolderBirthdateAt: null,
      groupId: '',
      memberId: '',
      policyHolderRelationship: UserInsurancePolicyHolderRelationship.Self,
      type: UserInsuranceType.GroupHealthPlan
    },
    validate: zodResolver(
      z
        .object({
          _cardFront: zodFileOrUploadOrNull(),
          _cardBack: zodFileOrUploadOrNull(),
          insuranceProviderId: z.string().uuid(),
          policyHolderRelationship: z.nativeEnum(UserInsurancePolicyHolderRelationship),
          policyHolderFirstName: z.string().nullable(),
          policyHolderLastName: z.string().nullable(),
          policyHolderBirthdateAt: z.date().nullable(),
          groupId: z.string(),
          memberId: zodStringEmptyOrNotOnlySpaces(t('Enter a Member ID')),
          type: z.nativeEnum(UserInsuranceType)
        })
        .superRefine((values, ctx) => {
          if (values.policyHolderRelationship !== UserInsurancePolicyHolderRelationship.Self) {
            const policyHolderFirstName = trim(values.policyHolderFirstName)
            const policyHolderLastName = trim(values.policyHolderLastName)

            if (isEmpty(policyHolderFirstName)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['policyHolderFirstName'],
                message: t('Enter the policy holder first name')
              })
            } else if (policyHolderFirstName.length < 2) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['policyHolderFirstName'],
                message: t('First name should be at least 2 characters')
              })
            }

            if (isEmpty(policyHolderLastName)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['policyHolderLastName'],
                message: t('Enter the policy holder last name')
              })
            } else if (policyHolderLastName.length < 2) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['policyHolderLastName'],
                message: t('Last name should be at least 2 characters')
              })
            }

            if (isNull(values.policyHolderBirthdateAt)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['policyHolderBirthdateAt'],
                message: t('Enter the policy holder birthdate')
              })
            }
          }
        })
    ),
    validateInputOnChange: true
  })

  const hasInsuranceData = !!userInsuranceData
  const hasPaymentMethodData = !!userPaymentMethodData
  const isSelf = userInsuranceForm.values.policyHolderRelationship === UserInsurancePolicyHolderRelationship.Self
  const showInsuranceForm = !hasInsuranceData || isUserInsuranceChanged

  return (
    <Container
      p='xl'
      size='sm'
    >
      <Stack
        justify='space-between'
        spacing='md'
      >
        <Title order={3}>{t('Insurance Details')}</Title>
        {hasInsuranceData && (
          <>
            <Text weight='bold'>This is the insurance we currently have on file for you:</Text>
            <DefinitionList
              horizontalSpacing='xs'
              verticalSpacing={2}
              terms={[
                {
                  label: 'Insurance Provider',
                  value: userInsuranceData.insuranceProvider.name
                },
                {
                  label: 'Group ID',
                  value: userInsuranceData.groupId
                },
                {
                  label: 'Member ID',
                  value: userInsuranceData.memberId
                },
                {
                  label: 'Type',
                  value: humanize(userInsuranceData.type)
                },
                {
                  label: 'Policy Holder',
                  value: humanize(userInsuranceData.policyHolderRelationship)
                }
              ]}
            />
            <Group
              grow
              px='xs'
              spacing='xs'
            >
              <BigButton
                active={!isUserInsuranceChanged}
                onClick={() => {
                  userInsuranceForm.reset()
                  setIsUserInsuranceChanged(false)
                }}
              >
                {t('This is still current')}
              </BigButton>
              <BigButton
                active={isUserInsuranceChanged}
                onClick={() => setIsUserInsuranceChanged(true)}
              >
                {t('I need to update this')}
              </BigButton>
            </Group>
          </>
        )}
        {showInsuranceForm && (
          <Stack
            justify='space-between'
            px='xs'
            spacing='md'
          >
            <Text weight='bold'>Please fill out your updated insurance information</Text>
            <Group
              grow
              spacing='sm'
            >
              <SelectInput
                label={t('Provider')}
                data={connectionNodesToKeyPairs(queryData.insuranceProviders)}
                required
                searchable
                {...userInsuranceForm.getInputProps('insuranceProviderId')}
              />
              <SelectInput
                data={enumTypeToKeyPairs(UserInsuranceType)}
                label={t('Type')}
                required
                {...userInsuranceForm.getInputProps('type')}
              />
            </Group>
            <Group
              grow
              spacing='sm'
            >
              <TextInput
                label={t('Group ID')}
                required
                {...userInsuranceForm.getInputProps('groupId')}
              />
              <TextInput
                label={t('Member ID')}
                required
                {...userInsuranceForm.getInputProps('memberId')}
              />
            </Group>
            <Group
              align='flex-start'
              grow
              spacing='sm'
            >
              <DropzoneInput
                label={t('Card Front')}
                // @ts-ignore manually specifying these, since userInsuranceForm.getInputProps() only knows how to deal with html form inputs
                onChange={(file) => userInsuranceForm.setFieldValue('_cardFront', file)}
                value={userInsuranceForm.values._cardFront}
              />
              <DropzoneInput
                label={t('Card Back')}
                // @ts-ignore manually specifying these, since userInsuranceForm.getInputProps() only knows how to deal with html form inputs
                onChange={(file) => userInsuranceForm.setFieldValue('_cardBack', file)}
                value={userInsuranceForm.values._cardBack}
              />
            </Group>
            <Text size='sm'>{t('Are you the primary policy holder?')}</Text>
            <Group
              grow
              spacing='xs'
            >
              <BigButton
                active={isSelf}
                onClick={() =>
                  userInsuranceForm.setValues((values) => ({
                    ...values,
                    policyHolderBirthdateAt: null,
                    policyHolderFirstName: null,
                    policyHolderLastName: null,
                    policyHolderRelationship: UserInsurancePolicyHolderRelationship.Self
                  }))
                }
              >
                {t('Yes, I am')}
              </BigButton>
              <BigButton
                active={!isSelf}
                onClick={() =>
                  userInsuranceForm.setFieldValue(
                    'policyHolderRelationship',
                    UserInsurancePolicyHolderRelationship.Spouse
                  )
                }
              >
                {t('No, I am not')}
              </BigButton>
            </Group>
            {!isSelf && (
              <>
                <Group
                  align='flex-start'
                  grow
                  spacing='sm'
                >
                  <TextInput
                    label={t('First Name')}
                    required
                    {...userInsuranceForm.getInputProps('policyHolderFirstName')}
                  />
                  <TextInput
                    label={t('Last Name')}
                    required
                    {...userInsuranceForm.getInputProps('policyHolderLastName')}
                  />
                </Group>
                <Group
                  align='flex-start'
                  grow
                  spacing='sm'
                >
                  <DateInput
                    label={t('Birthdate')}
                    maxDate={dayjs().subtract(1, 'day').toDate()}
                    required
                    {...userInsuranceForm.getInputProps('policyHolderBirthdateAt')}
                  />
                  <SelectInput
                    data={policyHolderRelationshipOptions}
                    label={t('Relationship to Patient')}
                    required
                    {...userInsuranceForm.getInputProps('policyHolderRelationship')}
                  />
                </Group>
              </>
            )}
          </Stack>
        )}
        <Title
          mt='xl'
          order={3}
        >
          {t('Backup Payment Method')}
        </Title>
        <Text
          align='justify'
          fs='italic'
          mb='xs'
          size='sm'
        >
          {t`Nest requires a credit card to hold all appointments, regardless of insurance coverage. Your card will not be
          charged at this time and will only be billed if your insurance provider will not cover our services, or if you
          cancel or do not attend your appointment without a minimum of 24 hours notice.`}
        </Text>
        {hasPaymentMethodData && (
          <>
            <Text weight='bold'>This is the backup payment method we have on file for you:</Text>
            <UserPaymentMethodDisplay
              cardLogoWidth={60}
              p={0}
              px='xs'
              userPaymentMethod={userPaymentMethodData}
              withBorder={false}
            />
            <Group
              grow
              px='xs'
              spacing='xs'
            >
              <BigButton
                active={!isUserPaymentMethodChanged}
                onClick={() => setIsUserPaymentMethodChanged(false)}
              >
                {t('This is still current')}
              </BigButton>
              <BigButton
                active={isUserPaymentMethodChanged}
                onClick={() => setIsUserPaymentMethodChanged(true)}
              >
                {t('I need to update this')}
              </BigButton>
            </Group>
          </>
        )}
        {(isUserPaymentMethodChanged || !hasPaymentMethodData) && (
          <Stack
            justify='space-between'
            px='xs'
            spacing='md'
          >
            <Text weight='bold'>Please provide your updated backup payment method</Text>
            <Paper
              p='xs'
              w={400}
              withBorder
            >
              <CardElement
                onChange={(e) => setIsCardElementComplete(e.complete)}
                options={{
                  disableLink: true,
                  value: {
                    postalCode: split(userAddressData?.address.postalCode, '-')[0]
                  }
                }}
              />
            </Paper>
          </Stack>
        )}
        <Group
          align='center'
          mt='xl'
          position='apart'
          spacing='sm'
        >
          <Button
            leftIcon={<IconChevronLeft size={16} />}
            onClick={onPrevious}
            radius='lg'
            size='sm'
            variant='subtle'
          >
            {t('Back')}
          </Button>
          <Button
            disabled={
              ((!hasInsuranceData || isUserInsuranceChanged) && !userInsuranceForm.isValid()) ||
              ((!hasPaymentMethodData || isUserPaymentMethodChanged) && (isCreatingToken || !isCardElementComplete))
            }
            radius='lg'
            rightIcon={<IconChevronRight size={16} />}
            size='sm'
            onClick={useCallback(async () => {
              let userPaymendMethodData: BookInsuranceDetailsPaymentMethodData | undefined

              if (isUserPaymentMethodChanged || !hasPaymentMethodData) {
                setIsCreatingToken(true)

                const cardElement = elements.getElement(CardElement)
                const response = await stripe.createToken(cardElement, {
                  name: `${queryData?.currentUser?.firstName} ${queryData?.currentUser?.lastName}`,
                  address_line1: userAddressData?.address?.street1,
                  address_line2: userAddressData?.address?.street2,
                  address_city: userAddressData?.address?.city,
                  address_state: userAddressData?.address?.stateOrProvince,
                  address_zip: userAddressData?.address?.postalCode,
                  address_country: userAddressData?.address?.country
                })

                userPaymendMethodData = {
                  paymentProvider: PaymentProviders.Stripe,
                  paymentMethodId: response?.token?.id
                }

                setIsCreatingToken(false)
              }

              await onNext({
                userInsuranceData: isUserInsuranceChanged ? userInsuranceForm.values : undefined,
                userPaymendMethodData
              })
            }, [
              isUserPaymentMethodChanged,
              hasPaymentMethodData,
              onNext,
              isUserInsuranceChanged,
              userInsuranceForm.values,
              elements,
              stripe,
              queryData?.currentUser,
              userAddressData?.address
            ])}
          >
            {t('Next')}
          </Button>
        </Group>
      </Stack>
    </Container>
  )
}
