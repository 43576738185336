import { type UserForm_query$key } from '@app/__generated__/UserForm_query.graphql'
import { type UserForm_user$key } from '@app/__generated__/UserForm_user.graphql'
import { DateInput, DropzoneInput, Form, PasswordInput, TextInput, UploadProgressOverlay } from '@app/components'
import {
  type UserPatch,
  useUploader,
  zodFileOrUploadOrNull,
  zodStringEmptyOrNotOnlySpaces,
  zodStringRequiredAndNotOnlySpaces
} from '@app/lib'
import { Group, Stack } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import { IconMail } from '@tabler/icons-react'
import dayjs from 'dayjs'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const userFragment = graphql`
  fragment UserForm_user on User {
    email
    hasPassword
    firstName
    middleName
    lastName
    birthdateAt
    rowId
    userUploads(filter: { name: { equalTo: "avatar" } }) {
      nodes {
        id
        rowId
        upload {
          id
          name: filename
          rowId
          url
        }
      }
    }
  }
`

const queryFragment = graphql`
  fragment UserForm_query on Query {
    currentUser {
      rowId
    }
  }
`

export interface UserFormData {
  email: string
  avatar: {
    readonly id: string
    readonly name: string
    readonly rowId: any
    readonly url: string
  } | null
  firstName: string
  middleName: string
  lastName: string
  birthdateAt: Date | null
  password: string
  passwordConfirm: string
}

export interface UserFormProps {
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (data: UserPatch & { password?: string }) => void
  query: UserForm_query$key
  user: UserForm_user$key
}

export const UserForm: FC<UserFormProps> = ({ isSaving, onChange, onSubmit, query, user }) => {
  const userData = useFragment<UserForm_user$key>(userFragment, user)
  const queryData = useFragment<UserForm_query$key>(queryFragment, query)
  const avatarUpload = userData?.userUploads.nodes[0]
  const { t } = useTranslation('admin')
  const form = useForm<UserFormData>({
    initialValues: {
      email: userData?.email || '',
      avatar: avatarUpload?.upload || null,
      firstName: userData?.firstName || '',
      middleName: userData?.middleName || '',
      lastName: userData?.lastName || '',
      birthdateAt: userData?.birthdateAt ? dayjs(userData.birthdateAt).toDate() : null,
      password: '',
      passwordConfirm: ''
    },
    validate: zodResolver(
      z
        .object({
          email: z.string().email(),
          avatar: zodFileOrUploadOrNull(),
          firstName: zodStringRequiredAndNotOnlySpaces(t('Enter a first name')),
          middleName: zodStringEmptyOrNotOnlySpaces(t('Cannot be only spaces')),
          lastName: zodStringRequiredAndNotOnlySpaces(t('Enter a last name')),
          birthdateAt: z.date().nullable(),
          password: z.string().optional(),
          passwordConfirm: z.string().optional()
        })
        .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
          path: ['passwordConfirm'],
          message: t('Both passwords must match')
        })
    ),
    validateInputOnChange: true
  })
  const { isUploading, uploadFile, uploadProgress } = useUploader()

  useShallowEffect(() => {
    if (onChange) {
      onChange(form.isDirty())
    }
  }, [form.values])

  return (
    <>
      <UploadProgressOverlay
        progress={uploadProgress}
        visible={isUploading}
      />
      <Form
        isSaving={isSaving}
        isValid={form.isValid()}
        onSubmit={form.onSubmit(async ({ avatar, password, passwordConfirm, ...values }) => {
          const data: any = {
            ...values,
            password: password && passwordConfirm ? password : null
          }

          if (avatar instanceof File) {
            const { bucket, key } = await uploadFile(avatar, {
              data: {
                path: 'users/avatars'
              }
            })

            data.userUploads = {
              // get rid of the previous avatar upload, if present
              deleteById: avatarUpload?.id
                ? [
                    {
                      id: avatarUpload?.id
                    }
                  ]
                : null,
              // create a single new user_uploads record, with the name of "avatar"
              create: [
                {
                  name: 'avatar',
                  // and for its related upload record:
                  upload: {
                    // create a new uploads record, with the s3 object data and the user as uploader
                    create: {
                      uploaderId: queryData?.currentUser.rowId,
                      filename: avatar.name,
                      filesize: avatar.size,
                      filetype: avatar.type,
                      bucket,
                      key
                    }
                  }
                }
              ]
            }
          }

          onSubmit(data)
        })}
      >
        <Group
          align='flex-start'
          position='apart'
          spacing='xl'
          grow
        >
          <Stack>
            <TextInput
              disabled={isSaving}
              label={t('First Name')}
              required
              {...form.getInputProps('firstName')}
            />
            <TextInput
              disabled={isSaving}
              label={t('Middle Name')}
              {...form.getInputProps('middleName')}
            />
            <TextInput
              disabled={isSaving}
              label={t('Last Name')}
              required
              {...form.getInputProps('lastName')}
            />
            <TextInput
              disabled={isSaving}
              label={t('Email')}
              required
              icon={<IconMail size={18} />}
              type='email'
              {...form.getInputProps('email')}
            />
            <DateInput
              label={t('Birthdate')}
              disabled={isSaving}
              {...form.getInputProps('birthdateAt')}
            />
            <PasswordInput
              disabled={isSaving}
              label={userData?.hasPassword ? t('Reset Password') : t('Set Password')}
              {...form.getInputProps('password')}
            />
            <PasswordInput
              disabled={isSaving}
              label={t('Confirm Password')}
              {...form.getInputProps('passwordConfirm')}
            />
          </Stack>
          <Stack>
            <DropzoneInput
              disabled={isSaving}
              label={t('Avatar')}
              mb='sm'
              // @ts-ignore manually specifying these, since form.getInputProps() only knows how to deal with html form inputs
              onChange={(file) => form.setFieldValue('avatar', file)}
              value={form.values.avatar}
            />
          </Stack>
        </Group>
      </Form>
    </>
  )
}
