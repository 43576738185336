/**
 * @generated SignedSource<<55a106011e3e7903f4d646b1bb918ea1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookPersonalInfo_user$data = {
  readonly allUserAddresses: {
    readonly nodes: ReadonlyArray<{
      readonly address: {
        readonly city: string;
        readonly postalCode: string;
        readonly rowId: any;
        readonly stateOrProvince: string;
        readonly street1: string;
        readonly street2: string | null;
      } | null;
      readonly isPrimary: boolean;
      readonly rowId: any;
    }>;
  };
  readonly allUserPhones: {
    readonly nodes: ReadonlyArray<{
      readonly isPrimary: boolean;
      readonly phone: string;
      readonly rowId: any;
    }>;
  };
  readonly rowId: any;
  readonly " $fragmentSpreads": FragmentRefs<"BookPersonalInfoForm_user">;
  readonly " $fragmentType": "BookPersonalInfo_user";
};
export type BookPersonalInfo_user$key = {
  readonly " $data"?: BookPersonalInfo_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookPersonalInfo_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPrimary",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookPersonalInfo_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "allUserPhones",
      "args": null,
      "concreteType": "UserPhonesConnection",
      "kind": "LinkedField",
      "name": "userPhones",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserPhone",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phone",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "allUserAddresses",
      "args": null,
      "concreteType": "UserAddressesConnection",
      "kind": "LinkedField",
      "name": "userAddresses",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserAddress",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "address",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "city",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postalCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stateOrProvince",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "street1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "street2",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookPersonalInfoForm_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "26c2a072ab9c101a9a75666e0973eae2";

export default node;
