import { type CardLogo_userPaymentMethod$key } from '@app/__generated__/CardLogo_userPaymentMethod.graphql'
import { toLower } from 'lodash'
import Image, { type ImageProps } from 'next/image'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'

const ASPECT_RATIO = 1.5625
const userPaymentMethodFragment = graphql`
  fragment CardLogo_userPaymentMethod on UserPaymentMethod {
    methodData
  }
`

export interface CardLogoProps extends Omit<ImageProps, 'alt' | 'height' | 'src' | 'width'> {
  height?: number
  width: number
  userPaymentMethod: CardLogo_userPaymentMethod$key
}

export const CardLogo: FC<CardLogoProps> = ({ height, width = 50, userPaymentMethod, ...imageProps }) => {
  const userPaymentMethodData = useFragment<CardLogo_userPaymentMethod$key>(
    userPaymentMethodFragment,
    userPaymentMethod
  )
  const brand = useMemo(() => {
    switch (userPaymentMethodData?.methodData.brand) {
      case 'American Express':
        return 'amex'
      case 'Diners Club':
        return 'diners'
      case 'Unknown':
        return 'generic'
      default:
        return toLower(userPaymentMethodData?.methodData.brand)
    }
  }, [userPaymentMethodData?.methodData.brand])

  return (
    <Image
      width={width}
      height={height || Math.floor(width / ASPECT_RATIO)}
      {...imageProps}
      alt={userPaymentMethodData?.methodData.brand}
      src={`/cardLogos/${brand}.svg`}
    />
  )
}
