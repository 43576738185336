import { type PolicyForm_policy$key } from '@app/__generated__/PolicyForm_policy.graphql'
import { DateInput, Form, RichTextInput, SelectInput, TextareaInput, TextInput } from '@app/components'
import {
  enumTypeToKeyPairs,
  type PolicyInput,
  type PolicyPatch,
  PolicyType,
  zodStringRequiredAndNotOnlySpaces
} from '@app/lib'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import dayjs from 'dayjs'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'
import striptags from 'striptags'
import { z } from 'zod'

const policyFragment = graphql`
  fragment PolicyForm_policy on Policy {
    bodyEn
    bodyEs
    description
    name
    type
    validAt
  }
`

export type PolicyFormProps = {
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (data: PolicyInput | PolicyPatch) => void
  policy: PolicyForm_policy$key
}

export const PolicyForm: FC<PolicyFormProps> = ({ isSaving, onChange, onSubmit, policy }) => {
  const policyData = useFragment<PolicyForm_policy$key>(policyFragment, policy)
  const { t } = useTranslation('admin')
  const form = useForm({
    initialValues: {
      bodyEn: policyData?.bodyEn || '',
      bodyEs: policyData?.bodyEs || '',
      description: policyData?.description || '',
      name: policyData?.name || '',
      type: policyData?.type || PolicyType.General,
      validAt: policyData?.validAt ? dayjs(policyData.validAt).toDate() : null
    },
    validate: zodResolver(
      z.object({
        bodyEn: z
          .string()
          .trim()
          .refine(
            (str) => {
              const parsed = striptags(str)

              return parsed.length > 0
            },
            t('admin:lang_policy_content_required', { lang: t('common:english') })
          ),
        bodyEs: z
          .string()
          .trim()
          .refine(
            (str) => {
              const parsed = striptags(str)

              return parsed.length > 0
            },
            t('admin:lang_policy_content_required', { lang: t('common:spanish') })
          ),
        description: zodStringRequiredAndNotOnlySpaces(t('Enter a description')),
        name: zodStringRequiredAndNotOnlySpaces(t('Enter a name')),
        type: z.nativeEnum(PolicyType),
        validAt: z.date({ required_error: t('Choose a date') })
      })
    ),
    validateInputOnChange: true
  })

  useShallowEffect(() => {
    if (onChange) {
      onChange(form.isDirty())
    }
  }, [form.values])

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit((values) => onSubmit(values as PolicyInput | PolicyPatch))}
    >
      <SelectInput
        data={enumTypeToKeyPairs(PolicyType)}
        disabled={isSaving}
        label={t('Type')}
        required
        {...form.getInputProps('type')}
      />
      <TextInput
        disabled={isSaving}
        label={t('Name')}
        required
        {...form.getInputProps('name')}
      />
      <TextareaInput
        disabled={isSaving}
        label={t('Description')}
        required
        minRows={2}
        maxRows={2}
        {...form.getInputProps('description')}
      />
      <DateInput
        disabled={isSaving}
        label={t('Valid From')}
        required
        {...form.getInputProps('validAt')}
      />
      <RichTextInput
        disabled={isSaving}
        label={t('admin:body_lang', { lang: t('english') })}
        required
        spellCheck
        {...form.getInputProps('bodyEn')}
      />
      <RichTextInput
        disabled={isSaving}
        label={t('admin:body_lang', { lang: t('spanish') })}
        required
        spellCheck
        {...form.getInputProps('bodyEs')}
      />
    </Form>
  )
}
