/**
 * @generated SignedSource<<3f97755f258ed0f4e79a7675d34174d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserInsurancePolicyHolderRelationship = "CHILD" | "OTHER" | "SELF" | "SPOUSE" | "UNKNOWN" | "%future added value";
export type UserInsuranceType = "CHAMPVA" | "FECA_BLK_LUNG" | "GROUP_HEALTH_PLAN" | "MEDICAID" | "MEDICARE" | "OTHER" | "TRICARE_CHAMPUS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BookInsuranceDetails_query$data = {
  readonly currentUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly userAddresses: {
      readonly nodes: ReadonlyArray<{
        readonly address: {
          readonly city: string;
          readonly country: string;
          readonly postalCode: string;
          readonly stateOrProvince: string;
          readonly street1: string;
          readonly street2: string | null;
        } | null;
      }>;
    };
    readonly userInsurances: {
      readonly nodes: ReadonlyArray<{
        readonly groupId: string | null;
        readonly insuranceProvider: {
          readonly name: string;
        } | null;
        readonly insuranceProviderId: any;
        readonly memberId: string;
        readonly policyHolderAddressCity: string | null;
        readonly policyHolderAddressCountry: string | null;
        readonly policyHolderAddressPostalCode: string | null;
        readonly policyHolderAddressStateOrProvince: string | null;
        readonly policyHolderAddressStreet1: string | null;
        readonly policyHolderAddressStreet2: string | null;
        readonly policyHolderBirthdateAt: any | null;
        readonly policyHolderFirstName: string | null;
        readonly policyHolderGender: string | null;
        readonly policyHolderHomePhone: string | null;
        readonly policyHolderLastName: string | null;
        readonly policyHolderMobilePhone: string | null;
        readonly policyHolderRelationship: UserInsurancePolicyHolderRelationship;
        readonly policyHolderSsn: string | null;
        readonly type: UserInsuranceType;
        readonly userInsuranceUploads: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly rowId: any;
            readonly upload: {
              readonly id: string;
              readonly name: string;
              readonly rowId: any;
              readonly url: string;
            } | null;
          }>;
        };
      }>;
    };
    readonly userPaymentMethods: {
      readonly nodes: ReadonlyArray<{
        readonly methodData: any;
        readonly rowId: any;
        readonly " $fragmentSpreads": FragmentRefs<"UserPaymentMethodDisplay_userPaymentMethod">;
      }>;
    };
  } | null;
  readonly insuranceProviders: {
    readonly nodes: ReadonlyArray<{
      readonly name: string;
      readonly rowId: any;
    }>;
  } | null;
  readonly " $fragmentType": "BookInsuranceDetails_query";
};
export type BookInsuranceDetails_query$key = {
  readonly " $data"?: BookInsuranceDetails_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookInsuranceDetails_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookInsuranceDetails_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "isPrimary": true
              }
            },
            (v0/*: any*/)
          ],
          "concreteType": "UserAddressesConnection",
          "kind": "LinkedField",
          "name": "userAddresses",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserAddress",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Address",
                  "kind": "LinkedField",
                  "name": "address",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "street1",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "street2",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "city",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "stateOrProvince",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "postalCode",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "country",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "userAddresses(condition:{\"isPrimary\":true},first:1)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "category": "PRIMARY"
              }
            },
            (v0/*: any*/)
          ],
          "concreteType": "UserInsurancesConnection",
          "kind": "LinkedField",
          "name": "userInsurances",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserInsurance",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "groupId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "insuranceProviderId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InsuranceProvider",
                  "kind": "LinkedField",
                  "name": "insuranceProvider",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "memberId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderAddressCity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderAddressCountry",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderAddressPostalCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderAddressStateOrProvince",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderAddressStreet1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderAddressStreet2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderBirthdateAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderFirstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderGender",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderHomePhone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderLastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderMobilePhone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderRelationship",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "policyHolderSsn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserInsuranceUploadsConnection",
                  "kind": "LinkedField",
                  "name": "userInsuranceUploads",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserInsuranceUpload",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        (v1/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Upload",
                          "kind": "LinkedField",
                          "name": "upload",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": "name",
                              "args": null,
                              "kind": "ScalarField",
                              "name": "filename",
                              "storageKey": null
                            },
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "userInsurances(condition:{\"category\":\"PRIMARY\"},first:1)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "condition",
              "value": {
                "isActive": true,
                "isPrimary": true,
                "provider": "STRIPE"
              }
            },
            (v0/*: any*/)
          ],
          "concreteType": "UserPaymentMethodsConnection",
          "kind": "LinkedField",
          "name": "userPaymentMethods",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserPaymentMethod",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserPaymentMethodDisplay_userPaymentMethod"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "methodData",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "userPaymentMethods(condition:{\"isActive\":true,\"isPrimary\":true,\"provider\":\"STRIPE\"},first:1)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "NAME_ASC"
          ]
        }
      ],
      "concreteType": "InsuranceProvidersConnection",
      "kind": "LinkedField",
      "name": "insuranceProviders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InsuranceProvider",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "insuranceProviders(orderBy:[\"NAME_ASC\"])"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "b9ce7ac15c979d4ebb4c22107731d7cd";

export default node;
