import { Modal, type ModalProps, Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'

export interface HelpModalProps extends ModalProps {}

export const HelpModal: FC<HelpModalProps> = ({ children, onClose, ...modalProps }) => {
  const { t } = useTranslation('booking')

  return (
    <Modal
      centered
      onClose={onClose}
      {...modalProps}
    >
      <Modal.Header>
        <Text size='lg'>{t('Help')}</Text>
      </Modal.Header>
      <Modal.Body>
        <Text>{t('If you have any questions, please contact us at')}</Text>
        <Text>
          <a href='tel:+1 (888) 598-1554'>(888) 598-1554</a>
        </Text>
      </Modal.Body>
      {children}
    </Modal>
  )
}
