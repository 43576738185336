/**
 * @generated SignedSource<<66283df409bfffdcf264e1a6ef183218>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferralPartnerForm_query$data = {
  readonly currentUser: {
    readonly rowId: any;
  } | null;
  readonly insuranceProviders: {
    readonly nodes: ReadonlyArray<{
      readonly name: string;
      readonly rowId: any;
    }>;
  } | null;
  readonly " $fragmentType": "ReferralPartnerForm_query";
};
export type ReferralPartnerForm_query$key = {
  readonly " $data"?: ReferralPartnerForm_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferralPartnerForm_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferralPartnerForm_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "NAME_ASC"
          ]
        }
      ],
      "concreteType": "InsuranceProvidersConnection",
      "kind": "LinkedField",
      "name": "insuranceProviders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InsuranceProvider",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "insuranceProviders(orderBy:[\"NAME_ASC\"])"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2669b5dc376338d90927f912e0972e30";

export default node;
