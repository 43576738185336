import { type UpdatePolicyModal_policy$key } from '@app/__generated__/UpdatePolicyModal_policy.graphql'
import { type UpdatePolicyModalUpdatePolicyMutation } from '@app/__generated__/UpdatePolicyModalUpdatePolicyMutation.graphql'
import { PolicyForm, UnsavedChangesModal, type UnsavedChangesModalProps } from '@app/components'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useState } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'

const policyFragment = graphql`
  fragment UpdatePolicyModal_policy on Policy {
    id
    ...PolicyForm_policy
  }
`
const updatePolicyMutation = graphql`
  mutation UpdatePolicyModalUpdatePolicyMutation($input: UpdatePolicyByIdInput!) {
    updatePolicyById(input: $input) {
      policy {
        description
        name
        type
        updatedAt
        validAt
        ...PolicyForm_policy
      }
    }
  }
`

export interface UpdatePolicyModalProps extends Omit<UnsavedChangesModalProps, 'title'> {
  policy: UpdatePolicyModal_policy$key
}

export const UpdatePolicyModal: FC<UpdatePolicyModalProps> = ({ onClose, policy, ...modalProps }) => {
  const policyData = useFragment(policyFragment, policy)
  const [updatePolicy, isUpdatingPolicy] = useMutation<UpdatePolicyModalUpdatePolicyMutation>(updatePolicyMutation)
  const { t } = useTranslation('admin')
  const [isDirty, setIsDirty] = useState(false)

  return (
    <UnsavedChangesModal
      changed={isDirty}
      onClose={onClose}
      size='xl'
      title={t('Edit Policy')}
      {...modalProps}
    >
      <PolicyForm
        isSaving={isUpdatingPolicy}
        onChange={setIsDirty}
        onSubmit={useCallback(
          (patch) =>
            updatePolicy({
              variables: {
                input: {
                  id: policyData?.id,
                  patch
                }
              },
              onCompleted: onClose
            }),
          [onClose, policyData?.id, updatePolicy]
        )}
        policy={policyData}
      />
    </UnsavedChangesModal>
  )
}
