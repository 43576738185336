import { type ClinicianAvailabilityForm_clinicianAvailability$key } from '@app/__generated__/ClinicianAvailabilityForm_clinicianAvailability.graphql'
import { type ClinicianAvailabilityForm_query$key } from '@app/__generated__/ClinicianAvailabilityForm_query.graphql'
import { CheckboxInput, DateInput, Form, SelectInput, TimeInput } from '@app/components'
import { type ClinicianAvailabilityInput, type ClinicianAvailabilityPatch } from '@app/lib'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import dayjs from 'dayjs'
import { map } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const appointmentFragment = graphql`
  fragment ClinicianAvailabilityForm_clinicianAvailability on ClinicianAvailability {
    clinicianId
    startsAt
    endsAt
    isExclusive
  }
`
const dateFormatWithoutZone = 'YYYY-MM-DDTHH:mm:ss.SSS'
const queryFragment = graphql`
  fragment ClinicianAvailabilityForm_query on Query {
    clinicians(orderBy: [USER_BY_USER_ID__FIRST_NAME_ASC, USER_BY_USER_ID__LAST_NAME_ASC]) {
      nodes {
        rowId
        user {
          firstName
          lastName
        }
      }
    }
  }
`

export type ClinicianAvailabilityFormProps = {
  clinicianAvailability: ClinicianAvailabilityForm_clinicianAvailability$key
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (data: ClinicianAvailabilityInput | ClinicianAvailabilityPatch) => void
  query: ClinicianAvailabilityForm_query$key
}

export const ClinicianAvailabilityForm: FC<ClinicianAvailabilityFormProps> = ({
  clinicianAvailability,
  isSaving,
  onChange,
  onSubmit,
  query
}) => {
  const clinicianAvailabilityData = useFragment<ClinicianAvailabilityForm_clinicianAvailability$key>(
    appointmentFragment,
    clinicianAvailability
  )
  const queryData = useFragment<ClinicianAvailabilityForm_query$key>(queryFragment, query)
  const { t } = useTranslation('booking')

  const [now, startOfToday] = useMemo(() => [dayjs(), dayjs().set('hours', 0).set('minutes', 0).set('seconds', 0)], [])
  const form = useForm({
    initialValues: {
      clinicianId: clinicianAvailabilityData?.clinicianId || null,
      isExclusive: false,
      _day: clinicianAvailabilityData?.startsAt
        ? dayjs(clinicianAvailabilityData.startsAt).toDate()
        : startOfToday.toDate(),
      _startTime: clinicianAvailabilityData?.startsAt
        ? dayjs(clinicianAvailabilityData.startsAt).toDate()
        : now.set('minutes', 0).toDate(),
      _endTime: clinicianAvailabilityData?.endsAt
        ? dayjs(clinicianAvailabilityData.endsAt).toDate()
        : now.set('minutes', 0).add(1, 'hour').toDate()
    },
    validate: zodResolver(
      z
        .object({
          clinicianId: z.string().uuid(),
          _day: z.date().min(startOfToday.toDate(), { message: t('Availability must be from today onward') }),
          _startTime: z.date(),
          _endTime: z.date(),
          isExclusive: z.boolean()
        })
        .superRefine(({ _endTime, _startTime }, ctx) => {
          const result = z
            .date()
            .min(
              dayjs(_startTime).add(15, 'minutes').toDate(),
              t('End time should be at least 15 minutes after start time')
            )
            .safeParse(_endTime)

          if (result.success === false && result.error) {
            ctx.addIssue({
              ...result.error.issues[0],
              path: ['_endTime']
            })
          }
        })
    ),
    validateInputOnChange: true
  })

  useShallowEffect(() => {
    if (onChange) {
      onChange(form.isDirty())
    }
  }, [form.values])

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(({ _day, _startTime, _endTime, ...values }) => {
        const day = dayjs(_day)
        const startsAt = dayjs(_startTime)
          .set('year', day.year())
          .set('month', day.month())
          .set('date', day.date())
          .set('seconds', 0)
        const endsAt = dayjs(_endTime)
          .set('year', day.year())
          .set('month', day.month())
          .set('date', day.date())
          .set('seconds', 0)

        onSubmit({
          ...values,
          startsAt: startsAt.format(dateFormatWithoutZone),
          endsAt: endsAt.format(dateFormatWithoutZone)
        })
      })}
    >
      <SelectInput
        disabled={isSaving}
        data={useMemo(
          () =>
            map(queryData.clinicians.nodes, (clinician) => ({
              label: `${clinician.user.firstName} ${clinician.user.lastName}`,
              value: clinician.rowId
            })),
          [queryData?.clinicians?.nodes]
        )}
        label={t('Clinician')}
        required
        searchable
        {...form.getInputProps('clinicianId')}
      />

      <DateInput
        label={t('Date')}
        disabled={isSaving}
        {...form.getInputProps('_day')}
      />

      <TimeInput
        label={t('Start Time')}
        disabled={isSaving}
        {...form.getInputProps('_startTime')}
      />

      <TimeInput
        label={t('End Time')}
        disabled={isSaving}
        {...form.getInputProps('_endTime')}
      />

      <CheckboxInput
        disabled={isSaving}
        label={t('Mark as Unavailable')}
        {...form.getInputProps('isExclusive', { type: 'checkbox' })}
      />
    </Form>
  )
}
