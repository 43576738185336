import { Button, type ButtonProps, createStyles } from '@mantine/core'
import { IconCheckbox, IconSquare } from '@tabler/icons-react'
import { type FC, type MouseEventHandler } from 'react'

const useStyles = createStyles({
  bigButtonInner: {
    justifyContent: 'flex-start'
  },
  bigButtonLabel: {
    whiteSpace: 'normal'
  }
})

export interface BigButtonProps extends ButtonProps {
  active: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const BigButton: FC<BigButtonProps> = ({ active, children, ...props }) => {
  const { classes } = useStyles()

  return (
    <Button
      classNames={{
        inner: classes.bigButtonInner,
        label: classes.bigButtonLabel
      }}
      color={active ? 'brandAqua' : 'gray'}
      leftIcon={active ? <IconCheckbox size={18} /> : <IconSquare size={18} />}
      px='xs'
      radius='xl'
      size='sm'
      variant={active ? 'filled' : 'outline'}
      {...props}
    >
      {children}
    </Button>
  )
}
