import { Group, Header, type HeaderProps } from '@mantine/core'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { type FC, type ReactNode } from 'react'

import logo from './logo.png'

export interface AppHeaderProps extends Omit<HeaderProps, 'children' | 'height'> {
  baseUrl: string
  children?: ReactNode
}

export const AppHeader: FC<AppHeaderProps> = ({ baseUrl, children, ...headerProps }) => {
  const { t } = useTranslation('booking')
  const { locale } = useRouter()

  return (
    <Header
      height={80}
      {...headerProps}
    >
      <Group
        px='md'
        py='xs'
        position='apart'
      >
        <Link
          href={baseUrl}
          locale={locale}
          title={t('Home')}
        >
          <Image
            alt='logo'
            src={logo}
            priority
            height={60}
          />
        </Link>
        <Group
          position='right'
          spacing='xs'
        >
          {children}
        </Group>
      </Group>
    </Header>
  )
}
