import { type UserPaymentMethodDisplay_userPaymentMethod$key } from '@app/__generated__/UserPaymentMethodDisplay_userPaymentMethod.graphql'
import { CardLogo } from '@app/components'
import { Group, Paper, type PaperProps, Stack, Text } from '@mantine/core'
import { padStart } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'
import { graphql, useFragment } from 'react-relay'

const userPaymentMethodFragment = graphql`
  fragment UserPaymentMethodDisplay_userPaymentMethod on UserPaymentMethod {
    methodData
    ...CardLogo_userPaymentMethod
  }
`

export interface UserPaymentMethodDisplayProps extends PaperProps {
  cardLogoWidth?: number
  userPaymentMethod: UserPaymentMethodDisplay_userPaymentMethod$key
}

export const UserPaymentMethodDisplay: FC<UserPaymentMethodDisplayProps> = ({
  cardLogoWidth = 100,
  userPaymentMethod,
  ...paperProps
}) => {
  const userPaymentMethodData = useFragment<UserPaymentMethodDisplay_userPaymentMethod$key>(
    userPaymentMethodFragment,
    userPaymentMethod
  )
  const { t } = useTranslation('admin')

  return (
    <Paper
      p='xs'
      radius='md'
      withBorder
      {...paperProps}
    >
      <Group
        align='center'
        spacing='xl'
      >
        <CardLogo
          userPaymentMethod={userPaymentMethodData}
          width={cardLogoWidth}
        />
        <Stack spacing='xs'>
          <Group
            align='center'
            position='apart'
          >
            <Stack spacing={0}>
              <Text weight='bold'>{t('Name On Card')}</Text>
              <Text size='sm'>{userPaymentMethodData?.methodData.name}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text weight='bold'>{t('Last 4')}</Text>
              <Text size='sm'>{userPaymentMethodData?.methodData.last4}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text weight='bold'>{t('Expires')}</Text>
              <Text size='sm'>
                {padStart(userPaymentMethodData?.methodData.expMonth, 2, '0')}/
                {userPaymentMethodData?.methodData.expYear}
              </Text>
            </Stack>
          </Group>
        </Stack>
      </Group>
    </Paper>
  )
}
