import { Form, TextInput } from '@app/components'
import { Button, Card, Center, Divider, Flex, Space, Text, Title, createStyles, rem } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { IconSend } from '@tabler/icons-react'
import { z } from 'zod'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import { type FC } from 'react'
import Link from 'next/link'
import { useDisclosure } from '@mantine/hooks'
import { HelpModal } from '@app/components'

const schema = z.object({
  email: z.string().email()
})

interface BookLoginFormData {
  email: string
}

interface BookLoginProps {
  onSubmit: (data: BookLoginFormData) => void
  isRequestingLink: boolean
  isLinkSent: boolean
}

const useStyles = createStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 100,
    left: 0,
    right: 0,
    bottom: 0
  },
  cardDivider: { position: 'absolute', left: '50%', right: '50%', top: rem(6) },
  dividedCard: {
    width: 900,
    height: `calc(600px + ${rem(12)})`,
    margin: 'auto',
    background: `linear-gradient(90deg,${theme.white} 50%,${theme.colors.gray[2]} 50%)`,
    outline: `${rem(6)} solid ${theme.colors.gray[4]}`,
    outlineOffset: `-${rem(6)}`
  },
  submitButton: {
    outline: `${rem(6)} solid ${theme.colors.gray[4]}`,
    outlineOffset: `-${rem(6)}`,
    color: theme.black,
    borderRadius: '40px',
    paddingTop: rem(3),
    paddingBottom: rem(3)
  },
  rightPanelText: {
    marginBottom: 10,
    zIndex: 1
  }
}))

export const BookLogin: FC<BookLoginProps> = ({ onSubmit, isRequestingLink, isLinkSent }) => {
  const { t } = useTranslation('booking')
  const { classes, theme } = useStyles()
  const [showHelpModal, helpModalHandler] = useDisclosure(false)

  const form = useForm<BookLoginFormData>({
    initialValues: {
      email: ''
    },
    validate: zodResolver(schema)
  })
  return (
    <>
      <Center className={classes.root}>
        <Head>
          <title>{t('Request Link')}</title>
        </Head>
        <Card
          px='xl'
          radius='md'
          shadow='md'
          className={classes.dividedCard}
        >
          <Flex direction='row'>
            <Flex
              justify='center'
              direction='column'
              px='xl'
              mr='xl'
              h={`calc(600px - ${rem(24)})`}
              w={450}
            >
              {isLinkSent ? (
                <>
                  <Text
                    mb='lg'
                    size='sm'
                  >
                    {t(`We sent you an email with a link to sign in to your account.`)}
                  </Text>
                  <Text
                    mb='lg'
                    size='sm'
                  >
                    {t(`If this is your first time visiting us, an account has been created for you.`)}
                  </Text>
                  <Text
                    mb='lg'
                    size='sm'
                    component='button'
                    onClick={helpModalHandler.open}
                  >
                    {t(`I have not received anything yet`)}
                  </Text>
                  <Link href={'/book'}>{t(`Back to Sign Up/Login`)}</Link>
                </>
              ) : (
                <>
                  <Text
                    mb='lg'
                    weight='bolder'
                  >
                    {t('Welcome to Nest Collaborative')}
                  </Text>
                  <Text
                    mb='lg'
                    size='sm'
                  >
                    {t(
                      `Whether this is your first time with us or you're a returning patient, we'll send you a link to access your new or existing account.`
                    )}
                  </Text>
                  <Form
                    buttons={
                      <Button
                        color='gray.2'
                        className={classes.submitButton}
                        disabled={isRequestingLink}
                        fullWidth
                        mb='md'
                        leftIcon={<IconSend size={18} />}
                        type='submit'
                        px='xl'
                      >
                        {t('Submit')}
                      </Button>
                    }
                    onSubmit={form.onSubmit(onSubmit)}
                  >
                    <TextInput
                      autoComplete='email'
                      disabled={isRequestingLink}
                      icon={null}
                      mb='lg'
                      placeholder={t('Enter Your Email')}
                      required
                      type='email'
                      {...form.getInputProps('email')}
                    />
                  </Form>
                  <Text>
                    {t(`Don't have access to your email? `)}
                    <a href='#'>{t('Contact us.')}</a>
                  </Text>
                </>
              )}
            </Flex>
            <Divider
              orientation='vertical'
              h={`calc(600px + ${rem(24)})`}
              size={rem(6)}
              color={theme.colors.gray[4]}
              className={classes.cardDivider}
            />
            <Flex
              justify='center'
              direction='column'
              px='xl'
              ml='xl'
              w={450}
              h={`calc(600px - ${rem(24)})`}
            >
              <Title
                className={classes.rightPanelText}
                order={2}
              >
                {t('Virtual Breastfeeding Consults for Parents and Parents-To-Be.')}
              </Title>
              <Space h='sm' />
              <Title order={5}>{t('On demand, virtual appointments.')}</Title>
              <Text
                size='sm'
                className={classes.rightPanelText}
              >
                {t('Anytime, anywhere')}
              </Text>
              <Title
                className={classes.rightPanelText}
                order={5}
              >
                {t('Support that is accepted by most insurances.')}
              </Title>
              <Title
                className={classes.rightPanelText}
                order={5}
              >
                {t('The most diverse set of board-certified experts are just an appointment away.')}
              </Title>
              <Space h='xl' />
              <Text
                italic
                className={classes.rightPanelText}
              >
                {t('Book an appointment online by signing in with just your email.')}
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Center>
      <HelpModal
        onClose={helpModalHandler.close}
        opened={showHelpModal}
      />
    </>
  )
}

export default BookLogin
