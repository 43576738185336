import { LanguageSelectionMenu } from '@app/components'
import { Center, Container, Flex, Grid, Group, Header, type HeaderProps, Stack, Text } from '@mantine/core'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { type FC, type ReactNode } from 'react'

import logo from './logo.png'

export interface BookingHeaderProps extends Omit<HeaderProps, 'children' | 'height'> {
  baseUrl: string
  children?: ReactNode
}

export const BookingHeader: FC<BookingHeaderProps> = ({ baseUrl, children, ...headerProps }) => {
  const { t } = useTranslation('booking')
  const { locale } = useRouter()

  return (
    <Header
      height={80}
      {...headerProps}
    >
      <Grid
        px='md'
        py='sm'
      >
        <Grid.Col span={4}>
          <Link
            href={baseUrl}
            locale={locale}
            title={t('Home')}
          >
            <Image
              alt='logo'
              src={logo}
              priority
              height={60}
            />
          </Link>
        </Grid.Col>
        <Grid.Col span={4}>
          <Center>{children}</Center>
        </Grid.Col>
        <Grid.Col span={4}>
          <Stack
            sx={{
              alignItems: 'flex-end'
            }}
          >
            <Stack
              align='center'
              spacing={0}
            >
              <LanguageSelectionMenu buttonProps={{ size: 'xs' }} />
              <Text
                size='sm'
                align='center'
                sx={{
                  fontSize: 10,
                  color: '#666'
                }}
              >
                (888) 598-1554 <br />
                {t('SE HABLA ESPAÑOL')}
              </Text>
            </Stack>
          </Stack>
        </Grid.Col>
      </Grid>
    </Header>
  )
}
