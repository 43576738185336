/**
 * @generated SignedSource<<6be3d14407d944a450cf5736dbcf9c02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InsuranceProviderLayout_insuranceProvider$data = {
  readonly name: string;
  readonly slug: string | null;
  readonly " $fragmentType": "InsuranceProviderLayout_insuranceProvider";
};
export type InsuranceProviderLayout_insuranceProvider$key = {
  readonly " $data"?: InsuranceProviderLayout_insuranceProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsuranceProviderLayout_insuranceProvider">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsuranceProviderLayout_insuranceProvider",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "InsuranceProvider",
  "abstractKey": null
};

(node as any).hash = "0fc5353fa6c5fb1a4c71d5bbdd839e63";

export default node;
