import {
  type PatientsTable_query$data,
  type PatientsTable_query$key
} from '@app/__generated__/PatientsTable_query.graphql'
import { type PatientsTablePaginationQuery } from '@app/__generated__/PatientsTablePaginationQuery.graphql'
import { PaginationTable } from '@app/components'
import { type PatientFilter, PatientsOrderBy } from '@app/lib'
import { ActionIcon, Anchor } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type PatientsTablePatientEdge = ElementOf<PatientsTable_query$data['patients']['edges']>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment PatientsTable_query on Query
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "PatientFilter" }
    first: { type: "Int" }
    orderBy: { type: "[PatientsOrderBy!]", defaultValue: [USER_BY_USER_ID__LAST_NAME_ASC] }
  )
  @refetchable(queryName: "PatientsTablePaginationQuery") {
    patients(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "PatientsTable_query_patients", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          slug
          charmRecordId
          user {
            birthdateAt
            email
            firstName
            lastName
            slug
          }
        }
      }
      totalCount
    }
  }
`

export interface PatientsTableProps {
  query: PatientsTable_query$key
}

export const PatientsTable: FC<PatientsTableProps> = ({ query }) => {
  const {
    data: queryData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<PatientsTablePaginationQuery, PatientsTable_query$key>(paginationFragment, query)
  const { t } = useTranslation('admin')

  return (
    <PaginationTable<PatientsTablePatientEdge, PatientsOrderBy, PatientsTablePaginationQuery, PatientFilter>
      columns={useMemo<ColumnDef<PatientsTablePatientEdge>[]>(
        () => [
          {
            accessorKey: 'node.charmRecordId',
            id: 'charmRecordId',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Pat. #'),
            size: 100
          },
          {
            accessorKey: 'node.user.email',
            id: 'email',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Email'),
            cell: (props) => {
              const email = props.row.original.node.user.email

              return email ? <Anchor href={`mailto:${email}`}>{email}</Anchor> : '-'
            }
          },
          {
            accessorKey: 'node.user.firstName',
            id: 'firstName',
            enableSorting: true,
            enableMultiSort: false,
            header: t('First Name')
          },
          {
            accessorKey: 'node.user.lastName',
            id: 'lastName',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Last Name')
          },
          {
            accessorKey: 'node.user.birthdateAt',
            id: 'birthdateAt',
            enableSorting: true,
            enableMultiSort: false,
            header: t('Birthdate'),
            cell: (props) => {
              const birthdateAt = props.row.original.node.user.birthdateAt

              return birthdateAt ? dayjs(birthdateAt).format('MM/DD/YYYY') : '-'
            }
          },
          {
            id: 'actions',
            size: 30,
            cell: (props) => {
              const node = props.row.original.node

              return (
                <ActionIcon
                  color='blue'
                  component={Link}
                  href={`/admin/users/${node.user.slug}/patient`}
                  title={t('View Patient')}
                  variant='transparent'
                >
                  <IconChevronRight size={14} />
                </ActionIcon>
              )
            }
          }
        ],
        [t]
      )}
      data={queryData?.patients?.edges}
      getFilterFromSearch={useCallback(
        (search) =>
          !isEmpty(search)
            ? {
                or: [
                  {
                    charmRecordId: {
                      startsWithInsensitive: search
                    }
                  },
                  {
                    user: {
                      email: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    user: {
                      firstName: {
                        includesInsensitive: search
                      }
                    }
                  },
                  {
                    user: {
                      lastName: {
                        includesInsensitive: search
                      }
                    }
                  }
                ]
              }
            : null,
        []
      )}
      hasNext={hasNext}
      initialSorting={useMemo(
        () => [
          {
            id: 'lastName',
            desc: false
          }
        ],
        []
      )}
      isFilterable
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      refetch={refetch}
      sortOptions={useMemo(
        () => ({
          birthdateAt: {
            asc: PatientsOrderBy.UserByUserIdBirthdateAtAsc,
            desc: PatientsOrderBy.UserByUserIdBirthdateAtDesc
          },
          charmRecordId: {
            asc: PatientsOrderBy.CharmRecordIdAsc,
            desc: PatientsOrderBy.CharmRecordIdDesc
          },
          firstName: {
            asc: PatientsOrderBy.UserByUserIdFirstNameAsc,
            desc: PatientsOrderBy.UserByUserIdFirstNameDesc
          },
          lastName: {
            asc: PatientsOrderBy.UserByUserIdLastNameAsc,
            desc: PatientsOrderBy.UserByUserIdLastNameDesc
          }
        }),
        []
      )}
      totalCount={queryData?.patients?.totalCount}
    />
  )
}
