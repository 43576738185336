/**
 * @generated SignedSource<<528a65ac9a6c17b20df40a3bdbccc260>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InsuranceProviderLayout_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminLayout_query">;
  readonly " $fragmentType": "InsuranceProviderLayout_query";
};
export type InsuranceProviderLayout_query$key = {
  readonly " $data"?: InsuranceProviderLayout_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsuranceProviderLayout_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsuranceProviderLayout_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminLayout_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0044dd3f4b295237156665addf3121da";

export default node;
