import { type LeadForm_lead$key } from '@app/__generated__/LeadForm_lead.graphql'
import { type LeadForm_query$key } from '@app/__generated__/LeadForm_query.graphql'
import { DateInput, Form, SelectInput, TextInput } from '@app/components'
import {
  connectionNodesToKeyPairs,
  enumTypeToKeyPairs,
  type LeadInput,
  type LeadPatch,
  LeadsSource,
  LeadsStatus
} from '@app/lib'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import dayjs from 'dayjs'
import { find, map } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const queryFragment = graphql`
  fragment LeadForm_query on Query {
    referralPartners(orderBy: NAME_ASC) {
      nodes {
        rowId
        id
        name
        referralPartnerSources(filter: { isActive: { equalTo: true } }, orderBy: NAME_ASC) {
          nodes {
            rowId
            id
            name
          }
        }
      }
    }
    users(filter: { leadExists: false }, orderBy: [FIRST_NAME_ASC, LAST_NAME_ASC]) {
      nodes {
        rowId
        firstName
        lastName
      }
    }
  }
`

const leadFragment = graphql`
  fragment LeadForm_lead on Lead {
    referralPartnerId
    referralPartnerSourceId
    userId
    slug
    status
    source
    optInAt
    optInMethod
    convertedAt
  }
`

export interface LeadformData {
  referralPartnerId: string
  referralPartnerSourceId: string
  userId: string
  source: LeadsSource
  status: LeadsStatus
  optInMethod: string
  optInAt: Date
}
export interface LeadFormProps {
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (data: LeadInput | LeadPatch) => void
  lead: LeadForm_lead$key
  query: LeadForm_query$key
}

export const LeadForm: FC<LeadFormProps> = ({ isSaving, onChange, onSubmit, lead, query }) => {
  const leadData = useFragment<LeadForm_lead$key>(leadFragment, lead)
  const queryData = useFragment<LeadForm_query$key>(queryFragment, query)
  const { t } = useTranslation('admin')
  const form = useForm<LeadformData>({
    initialValues: {
      referralPartnerId: leadData?.referralPartnerId || null,
      referralPartnerSourceId: leadData?.referralPartnerSourceId || null,
      userId: leadData?.userId || null,
      source: (leadData?.source as LeadsSource) || LeadsSource.ReferralPartner,
      status: (leadData?.status as LeadsStatus) || LeadsStatus.New,
      optInMethod: leadData?.optInMethod || '',
      optInAt: leadData?.optInAt ? dayjs(leadData.optInAt).toDate() : null
    },
    validate: zodResolver(
      z.object({
        referralPartnerId: z.string().uuid().or(z.null()),
        referralPartnerSourceId: z.string().uuid().or(z.null()),
        userId: z.string().uuid(),
        source: z.nativeEnum(LeadsSource),
        status: z.nativeEnum(LeadsStatus),
        optInMethod: z.string().optional(),
        optInAt: z.date().or(z.null())
      })
    ),
    validateInputOnChange: true
  })

  const selectedReferralPartnerId = form.values.referralPartnerId

  const referralPartnerSources = selectedReferralPartnerId
    ? find(queryData?.referralPartners?.nodes, ['rowId', selectedReferralPartnerId])?.referralPartnerSources
    : null

  const shouldShowReferralPartners = form.getInputProps('source').value === LeadsSource.ReferralPartner

  const shouldShowReferralPartnerSources =
    shouldShowReferralPartners && selectedReferralPartnerId && referralPartnerSources.nodes.length > 0

  const onSourceChange = useCallback((newSource: LeadsSource) => {
    form.setFieldValue('source', newSource)
    form.setFieldValue('referralPartnerId', null)
    form.setFieldValue('referralPartnerSourceId', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onReferralPartnerChange = useCallback((newReferralPartnerId: string | null) => {
    form.setFieldValue('referralPartnerId', newReferralPartnerId)
    form.setFieldValue('referralPartnerSourceId', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useShallowEffect(() => {
    if (onChange) {
      onChange(form.isDirty())
    }
  }, [form.values])

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit((values) => onSubmit(values as LeadInput | LeadPatch))}
    >
      <SelectInput
        disabled={isSaving}
        label={t('Source')}
        data={enumTypeToKeyPairs(LeadsSource)}
        {...form.getInputProps('source')}
        onChange={onSourceChange}
      />
      {shouldShowReferralPartners && (
        <SelectInput
          disabled={isSaving}
          label={t('Referral Partner')}
          data={connectionNodesToKeyPairs(queryData?.referralPartners)}
          {...form.getInputProps('referralPartnerId')}
          onChange={onReferralPartnerChange}
        />
      )}
      {shouldShowReferralPartnerSources && (
        <SelectInput
          disabled={isSaving}
          label={t('Referral Partner Source')}
          data={connectionNodesToKeyPairs(referralPartnerSources)}
          {...form.getInputProps('referralPartnerSourceId')}
        />
      )}
      {!leadData?.userId && (
        <SelectInput
          disabled={isSaving}
          label={t('User')}
          // connectionNodesToKeyPairs expects name as default label.
          // We need a composite first name and last name to make up the label so remapping nodes to have a
          // name property made up of concatenated first and last name.
          data={connectionNodesToKeyPairs({
            nodes: map(queryData?.users.nodes, (node) => {
              return {
                ...node,
                name: `${node.firstName} ${node.lastName}`
              }
            })
          })}
          {...form.getInputProps('userId')}
        />
      )}
      <SelectInput
        disabled={isSaving}
        label={t('Status')}
        data={enumTypeToKeyPairs(LeadsStatus)}
        {...form.getInputProps('status')}
      />
      <TextInput
        disabled={isSaving}
        label={t('Opt In Method')}
        {...form.getInputProps('optInMethod')}
      />
      <DateInput
        disabled={isSaving}
        label={t('Opted In')}
        {...form.getInputProps('optInAt')}
      />
    </Form>
  )
}
