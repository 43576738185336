/**
 * @generated SignedSource<<36b4af47e62a8972ccb757207ed50189>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AppointmentsStatus = "CHECKED_IN" | "CONFIRMED" | "CONSULTED" | "LATE_CANCELLATION_EMERGENCY_MEDICAL_FIRE_ETC_" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_CIGNA_MOM" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_MEDICAID" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_RESCHEDULED" | "LATE_CANCELLATION_NO_SHOW_PLEASE_CHARGE" | "NO_SHOW_CHARGE" | "NO_SHOW_NO_CHARGE" | "UNCONFIRMED" | "_2_HR_LATE_CANCEL_CHARGE" | "_2_HR_LATE_CANCEL_NO_CHARGE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClinicianAppointmentsTable_clinician$data = {
  readonly appointments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly patient: {
          readonly user: {
            readonly firstName: string;
            readonly lastName: string;
            readonly slug: string | null;
          } | null;
        } | null;
        readonly service: {
          readonly duration: {
            readonly days: number | null;
            readonly hours: number | null;
            readonly minutes: number | null;
            readonly months: number | null;
            readonly seconds: number | null;
            readonly years: number | null;
          };
          readonly name: string;
          readonly slug: string | null;
        } | null;
        readonly startsAt: any;
        readonly status: AppointmentsStatus;
        readonly userChild: {
          readonly birthdateAt: any | null;
          readonly dueAt: any | null;
          readonly name: string;
        } | null;
      };
    }>;
    readonly totalCount: number;
  };
  readonly id: string;
  readonly " $fragmentType": "ClinicianAppointmentsTable_clinician";
};
export type ClinicianAppointmentsTable_clinician$key = {
  readonly " $data"?: ClinicianAppointmentsTable_clinician$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClinicianAppointmentsTable_clinician">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "appointments"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": [
        "STARTS_AT_DESC"
      ],
      "kind": "LocalArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ClinicianAppointmentsTablePaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ClinicianAppointmentsTable_clinician",
  "selections": [
    {
      "alias": "appointments",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "AppointmentsConnection",
      "kind": "LinkedField",
      "name": "__ClinicianAppointmentsTable_clinician_appointments_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AppointmentsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Appointment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Patient",
                  "kind": "LinkedField",
                  "name": "patient",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastName",
                          "storageKey": null
                        },
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Service",
                  "kind": "LinkedField",
                  "name": "service",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Interval",
                      "kind": "LinkedField",
                      "name": "duration",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "days",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hours",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "minutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "months",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "seconds",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "years",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startsAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserChild",
                  "kind": "LinkedField",
                  "name": "userChild",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "birthdateAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dueAt",
                      "storageKey": null
                    },
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Clinician",
  "abstractKey": null
};
})();

(node as any).hash = "b931f7bbd73425ca5ccd9862dba18c16";

export default node;
