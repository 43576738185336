/**
 * @generated SignedSource<<ca1b9acfc602e0a58b681e7863545e51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PatientAppointmentForm_patient$data = {
  readonly user: {
    readonly userChildren: {
      readonly nodes: ReadonlyArray<{
        readonly birthdateAt: any | null;
        readonly dueAt: any | null;
        readonly name: string;
        readonly rowId: any;
      }>;
    };
  } | null;
  readonly " $fragmentType": "PatientAppointmentForm_patient";
};
export type PatientAppointmentForm_patient$key = {
  readonly " $data"?: PatientAppointmentForm_patient$data;
  readonly " $fragmentSpreads": FragmentRefs<"PatientAppointmentForm_patient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PatientAppointmentForm_patient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserChildrenConnection",
          "kind": "LinkedField",
          "name": "userChildren",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserChild",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "birthdateAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Patient",
  "abstractKey": null
};

(node as any).hash = "03004b80a6407515bf912c1be4074454";

export default node;
