import { Modal, type ModalProps, Text } from '@mantine/core'
import { useModals } from '@mantine/modals'
import { IconCheck } from '@tabler/icons-react'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'

export interface UnsavedChangesModalProps extends ModalProps {
  changed?: boolean
}

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({ changed, children, onClose, ...modalProps }) => {
  const modals = useModals()
  const { t } = useTranslation('admin')

  return (
    <Modal
      centered
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={() => {
        if (changed) {
          modals.openConfirmModal({
            centered: true,
            title: t('Unsaved Changes'),
            children: <Text size='sm'>{t('You have unsaved changes, are you sure you want to cancel?')}</Text>,
            labels: {
              confirm: t("Yes, I'm Sure"),
              cancel: t('Oops, Nevermind')
            },
            confirmProps: {
              leftIcon: <IconCheck size={16} />
            },
            onConfirm: onClose,
            zIndex: 1000
          })
        } else {
          onClose()
        }
      }}
      {...modalProps}
    >
      {children}
    </Modal>
  )
}
