/**
 * @generated SignedSource<<780d03cf252184991f6a06756d5fdf53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PolicyType = "CLINICIAN" | "GENERAL" | "PATIENT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PolicyForm_policy$data = {
  readonly bodyEn: string | null;
  readonly bodyEs: string | null;
  readonly description: string | null;
  readonly name: string;
  readonly type: PolicyType;
  readonly validAt: any;
  readonly " $fragmentType": "PolicyForm_policy";
};
export type PolicyForm_policy$key = {
  readonly " $data"?: PolicyForm_policy$data;
  readonly " $fragmentSpreads": FragmentRefs<"PolicyForm_policy">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PolicyForm_policy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bodyEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bodyEs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "validAt",
      "storageKey": null
    }
  ],
  "type": "Policy",
  "abstractKey": null
};

(node as any).hash = "d799e5fc3fed2545def803baeb573beb";

export default node;
