import { Menu, type MenuProps } from '@app/components'
import { Button, type ButtonProps, Text } from '@mantine/core'
import { map, toUpper } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { type FC } from 'react'

export interface LanguageSelectionMenuProps extends MenuProps {
  buttonProps?: ButtonProps
  menuProps?: MenuProps
}

const localeMap = {
  en: {
    flag: '🇺🇸',
    label: 'english'
  },
  es: {
    flag: '🇲🇽',
    label: 'spanish'
  }
}

export const LanguageSelectionMenu: FC<LanguageSelectionMenuProps> = ({ menuProps = {}, buttonProps = {} }) => {
  const { asPath, locale: currentLocale = 'en', locales } = useRouter()
  const { t } = useTranslation('admin')

  return (
    <Menu {...menuProps}>
      <Menu.Target>
        <Button
          leftIcon={<Text size={24}>{localeMap[currentLocale].flag}</Text>}
          variant='outline'
          {...buttonProps}
        >
          {toUpper(currentLocale)}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {map(locales, (locale) => (
          <Menu.Item
            component={Link}
            href={asPath}
            icon={<Text>{localeMap[locale].flag}</Text>}
            key={locale}
            locale={locale}
          >
            {t(`common:${localeMap[locale].label}`)}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
