/**
 * @generated SignedSource<<69fdf025b074613aea8ca6d13088f3be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppointmentsStatus = "CHECKED_IN" | "CONFIRMED" | "CONSULTED" | "LATE_CANCELLATION_EMERGENCY_MEDICAL_FIRE_ETC_" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_CIGNA_MOM" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_MEDICAID" | "LATE_CANCELLATION_NO_SHOW_NO_CHARGE_RESCHEDULED" | "LATE_CANCELLATION_NO_SHOW_PLEASE_CHARGE" | "NO_SHOW_CHARGE" | "NO_SHOW_NO_CHARGE" | "UNCONFIRMED" | "_2_HR_LATE_CANCEL_CHARGE" | "_2_HR_LATE_CANCEL_NO_CHARGE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClinicianAppointmentForm_appointment$data = {
  readonly notes: string | null;
  readonly patient: {
    readonly rowId: any;
    readonly user: {
      readonly firstName: string;
      readonly lastName: string;
      readonly userChildren: {
        readonly nodes: ReadonlyArray<{
          readonly birthdateAt: any | null;
          readonly dueAt: any | null;
          readonly name: string;
          readonly rowId: any;
        }>;
      };
    } | null;
  } | null;
  readonly patientId: any;
  readonly serviceId: any;
  readonly startsAt: any;
  readonly status: AppointmentsStatus;
  readonly userChildId: any | null;
  readonly " $fragmentType": "ClinicianAppointmentForm_appointment";
};
export type ClinicianAppointmentForm_appointment$key = {
  readonly " $data"?: ClinicianAppointmentForm_appointment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClinicianAppointmentForm_appointment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianAppointmentForm_appointment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Patient",
      "kind": "LinkedField",
      "name": "patient",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UserChildrenConnection",
              "kind": "LinkedField",
              "name": "userChildren",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserChild",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "birthdateAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dueAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userChildId",
      "storageKey": null
    }
  ],
  "type": "Appointment",
  "abstractKey": null
};
})();

(node as any).hash = "6b766b162b9340f1d631f7bd2f21f13c";

export default node;
