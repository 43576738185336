import { useMantineTheme } from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons-react'
import { type FC } from 'react'

export interface IconBooleanProps {
  size?: number
  value: boolean
}

export const IconBoolean: FC<IconBooleanProps> = ({ size = 18, value }) => {
  const theme = useMantineTheme()

  return value ? (
    <IconCheck
      color={theme.colors.green[4]}
      size={size}
    />
  ) : (
    <IconX
      color={theme.colors.red[4]}
      size={size}
    />
  )
}
