import { NavLink, type NavLinkProps, ThemeIcon, type ThemeIconVariant, useMantineTheme } from '@mantine/core'
import { type Icon } from '@tabler/icons-react'
import { includes, map } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  Children,
  cloneElement,
  type FC,
  isValidElement,
  type MouseEvent,
  type MouseEventHandler,
  useMemo
} from 'react'

export type NavbarLinkProps = NavLinkProps & {
  href?: string
  IconComponent?: Icon
  iconSize?: number
  iconVariant?: ThemeIconVariant
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

export const NavbarLink: FC<NavbarLinkProps> = ({
  active,
  children,
  color,
  href,
  IconComponent,
  iconSize = 20,
  iconVariant = 'filled',
  onClick,
  ...navLinkProps
}) => {
  const router = useRouter()
  const theme = useMantineTheme()
  const colors = theme.fn.variant({ variant: 'light', color })
  const isActive = useMemo(() => {
    const childHrefs = children ? map(Children.toArray(children), 'props.href') : []
    return router.asPath === href || includes(childHrefs, router.asPath)
  }, [children, href, router.asPath])

  return (
    <NavLink
      {...navLinkProps}
      active={isActive}
      childrenOffset={0}
      component={href ? Link : undefined}
      defaultOpened={isActive}
      href={href}
      icon={
        IconComponent ? (
          <ThemeIcon
            color={color}
            variant={iconVariant}
          >
            <IconComponent size={iconSize} />
          </ThemeIcon>
        ) : undefined
      }
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation()
        if (onClick) {
          onClick(e)
        } else if (href) {
          return router.push(href)
        }
      }}
      styles={{
        root: {
          '&[data-active]': {
            backgroundColor: colors.background,
            color: colors.color,
            ...theme.fn.hover({ backgroundColor: colors.hover })
          }
        }
      }}
      variant='light'
    >
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            ...child.props,
            color,
            pl: 50
          })
        }
        return null
      })}
    </NavLink>
  )
}
