import {
  type ReferralPartnersTable_query$data,
  type ReferralPartnersTable_query$key
} from '@app/__generated__/ReferralPartnersTable_query.graphql'
import { type ReferralPartnersTablePaginationQuery } from '@app/__generated__/ReferralPartnersTablePaginationQuery.graphql'
import {
  CreateReferralPartnerModal,
  IconBoolean,
  PaginationTable,
  ReferralPartnerLogo,
  SuspenseWithLoadingOverlay
} from '@app/components'
import { humanize, type ReferralPartnerFilter, ReferralPartnersOrderBy } from '@app/lib'
import { ActionIcon, Button } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { type ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useCallback, useMemo } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'

export type ReferralPartnersTableReferralPartnerEdge = ElementOf<
  ReferralPartnersTable_query$data['referralPartners']['edges']
>

// noinspection GraphQLUnresolvedReference
const paginationFragment = graphql`
  fragment ReferralPartnersTable_query on Query
  @argumentDefinitions(
    after: { type: "Cursor" }
    filter: { type: "ReferralPartnerFilter" }
    first: { type: "Int" }
    orderBy: { type: "[ReferralPartnersOrderBy!]", defaultValue: [NAME_ASC] }
  )
  @refetchable(queryName: "ReferralPartnersTablePaginationQuery") {
    referralPartners(after: $after, filter: $filter, first: $first, orderBy: $orderBy)
      @connection(key: "ReferralPartnersTable_query_referralPartners", filters: ["filter", "orderBy"]) {
      __id
      edges {
        node {
          createdAt
          id
          isActive
          name
          type
          slug
          slugPublic
          config
          updatedAt
          ...ReferralPartnerLogo_referralPartner
        }
      }
      totalCount
    }
    ...CreateReferralPartnerModal_query
  }
`

export interface ReferralPartnersTableProps {
  onCreateModalClose: () => void
  query: ReferralPartnersTable_query$key
  showCreateModal: boolean
}

export const ReferralPartnersTable: FC<ReferralPartnersTableProps> = ({
  onCreateModalClose,
  query,
  showCreateModal
}) => {
  const {
    data: queryData,
    hasNext,
    loadNext,
    isLoadingNext,
    refetch
  } = usePaginationFragment<ReferralPartnersTablePaginationQuery, ReferralPartnersTable_query$key>(
    paginationFragment,
    query
  )
  const { t } = useTranslation('admin')

  return (
    <>
      <PaginationTable<
        ReferralPartnersTableReferralPartnerEdge,
        ReferralPartnersOrderBy,
        ReferralPartnersTablePaginationQuery,
        ReferralPartnerFilter
      >
        columns={useMemo<ColumnDef<ReferralPartnersTableReferralPartnerEdge>[]>(
          () => [
            {
              id: 'logo',
              header: t('Logo'),
              size: 100,
              cell: (props) => (
                <SuspenseWithLoadingOverlay>
                  <ReferralPartnerLogo
                    referralPartner={props?.row?.original?.node}
                    width={100}
                  />
                </SuspenseWithLoadingOverlay>
              )
            },
            {
              accessorKey: 'node.name',
              id: 'name',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Name'),
              cell: (props) => (
                <Button
                  compact
                  component={Link}
                  href={`/admin/referralPartners/${props?.row?.original?.node?.slug}`}
                  style={{
                    marginLeft: '-.5em'
                  }}
                  title={t('View Referral Partner')}
                  variant='subtle'
                >
                  {props?.row?.original?.node?.name}
                </Button>
              )
            },
            {
              accessorKey: 'node.slugPublic',
              id: 'slugPublic',
              enableSorting: false,
              enableMultiSort: false,
              header: t('URL Code')
            },
            {
              accessorKey: 'node.type',
              id: 'type',
              enableSorting: true,
              enableMultiSort: false,
              header: t('Type'),
              cell: (props) => {
                const node = props?.row?.original?.node

                return humanize(node.type)
              }
            },
            {
              id: 'isActive',
              size: 30,
              header: t('Active'),
              cell: (props) => {
                const node = props.row.original.node

                return <IconBoolean value={node.isActive} />
              }
            },
            {
              id: 'actions',
              size: 30,
              cell: (props) => (
                <ActionIcon
                  color='blue'
                  component={Link}
                  href={`/admin/referralPartners/${props?.row?.original?.node?.slug}`}
                  title={t('View Referral Partner')}
                  variant='transparent'
                >
                  <IconChevronRight size={14} />
                </ActionIcon>
              )
            }
          ],
          [t]
        )}
        data={queryData?.referralPartners?.edges}
        isFilterable
        getFilterFromSearch={useCallback(
          (search) =>
            !isEmpty(search)
              ? {
                  name: {
                    includesInsensitive: search
                  }
                }
              : null,
          []
        )}
        hasNext={hasNext}
        initialSorting={useMemo(
          () => [
            {
              id: 'name',
              desc: false
            }
          ],
          []
        )}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        refetch={refetch}
        sortOptions={useMemo(
          () => ({
            name: {
              asc: ReferralPartnersOrderBy.NameAsc,
              desc: ReferralPartnersOrderBy.NameDesc
            },
            type: {
              asc: ReferralPartnersOrderBy.TypeAsc,
              desc: ReferralPartnersOrderBy.TypeDesc
            }
          }),
          []
        )}
        totalCount={queryData?.referralPartners?.totalCount}
      />
      <CreateReferralPartnerModal
        connectionId={queryData?.referralPartners?.__id}
        opened={showCreateModal}
        onClose={onCreateModalClose}
        query={queryData}
      />
    </>
  )
}
