import { type PatientAppointmentForm_appointment$key } from '@app/__generated__/PatientAppointmentForm_appointment.graphql'
import { type PatientAppointmentForm_patient$key } from '@app/__generated__/PatientAppointmentForm_patient.graphql'
import { type PatientAppointmentForm_query$key } from '@app/__generated__/PatientAppointmentForm_query.graphql'
import { DateInput, Form, SelectInput, TextareaInput, TimeInput } from '@app/components'
import {
  type AppointmentInput,
  type AppointmentPatch,
  AppointmentsStatus,
  enumTypeToKeyPairs,
  zodStringEmptyOrNotOnlySpaces
} from '@app/lib'
import { useForm, zodResolver } from '@mantine/form'
import { useShallowEffect } from '@mantine/hooks'
import dayjs from 'dayjs'
import { find, map } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { type FC, useMemo } from 'react'
import { graphql, useFragment } from 'react-relay'
import { z } from 'zod'

const appointmentFragment = graphql`
  fragment PatientAppointmentForm_appointment on Appointment {
    clinicianId
    notes
    serviceId
    startsAt
    status
    userChildId
  }
`
const dateFormatWithoutZone = 'YYYY-MM-DDTHH:mm:ss.SSS'
const queryFragment = graphql`
  fragment PatientAppointmentForm_query on Query {
    clinicians(orderBy: [USER_BY_USER_ID__FIRST_NAME_ASC, USER_BY_USER_ID__LAST_NAME_ASC]) {
      nodes {
        rowId
        user {
          firstName
          lastName
        }
      }
    }
    services(condition: { isActive: true }) {
      nodes {
        rowId

        name
        duration {
          minutes
        }
      }
    }
  }
`

const patientFragment = graphql`
  fragment PatientAppointmentForm_patient on Patient {
    user {
      userChildren {
        nodes {
          birthdateAt
          dueAt
          name
          rowId
        }
      }
    }
  }
`

export interface PatientAppointmentFormProps {
  appointment: PatientAppointmentForm_appointment$key
  isSaving?: boolean
  onChange?: (isDirty: boolean) => void
  onSubmit: (data: AppointmentInput | AppointmentPatch) => void
  patient: PatientAppointmentForm_patient$key
  query: PatientAppointmentForm_query$key
}

export interface PatientAppointmentFormData {
  clinicianId: string
  notes: string
  serviceId: string
  status: AppointmentsStatus
  userChildId: string
  _startsAtDate: Date
  _startsAtTime: Date
}

export const PatientAppointmentForm: FC<PatientAppointmentFormProps> = ({
  appointment,
  isSaving,
  onChange,
  onSubmit,
  patient,
  query
}) => {
  const appointmentData = useFragment<PatientAppointmentForm_appointment$key>(appointmentFragment, appointment)
  const patientData = useFragment<PatientAppointmentForm_patient$key>(patientFragment, patient)
  const queryData = useFragment<PatientAppointmentForm_query$key>(queryFragment, query)
  const { t } = useTranslation('admin')

  const now = useMemo(() => dayjs(), [])
  const form = useForm<PatientAppointmentFormData>({
    initialValues: {
      clinicianId: appointmentData?.clinicianId || null,
      notes: appointmentData?.notes || '',
      serviceId: appointmentData?.serviceId || null,
      status: (appointmentData?.status as AppointmentsStatus) || AppointmentsStatus.Unconfirmed,
      userChildId: appointmentData?.userChildId || null,
      ...useMemo(() => {
        const startsAt = appointmentData?.startsAt
          ? dayjs(appointmentData.startsAt)
          : now.add(1, 'hour').minute(0).second(0).millisecond(0)

        // we need to return two distinct date objects, so that each form field doesn't corrupt the other's data
        return {
          _startsAtDate: startsAt.toDate(),
          _startsAtTime: startsAt.toDate()
        }
      }, [appointmentData?.startsAt, now])
    },
    validate: zodResolver(
      z.object({
        _startsAtDate: z.date(),
        _startsAtTime: z.date(),
        clinicianId: z.string().uuid(),
        codes: z.string().array(),
        notes: zodStringEmptyOrNotOnlySpaces(t('Cannot be only spaces')),
        serviceId: z.string().uuid(),
        status: z.nativeEnum(AppointmentsStatus),
        userChildId: z.string().uuid().or(z.null())
      })
    ),
    validateInputOnChange: true
  })

  useShallowEffect(() => {
    onChange?.(form.isDirty())
  }, [form.values, onChange])

  return (
    <Form
      isSaving={isSaving}
      isValid={form.isValid()}
      onSubmit={form.onSubmit(({ clinicianId, notes, serviceId, _startsAtDate, _startsAtTime, ...values }) => {
        const service = find(queryData?.services?.nodes, ['service.rowId', serviceId])
        const startsAtTime = dayjs(_startsAtTime)
        const startsAt = dayjs(_startsAtDate)
          .hour(startsAtTime.hour())
          .minute(startsAtTime.minute())
          .second(0)
          .millisecond(0)

        return onSubmit({
          ...values,
          clinicianId,
          endsAt: dayjs(startsAt)
            .add(service?.duration?.minutes)
            .format(dateFormatWithoutZone),
          notes: notes || null,
          serviceId,
          startsAt: startsAt.format(dateFormatWithoutZone)
        } as AppointmentInput | AppointmentPatch)
      })}
    >
      <DateInput
        disabled={isSaving}
        label={t('Date')}
        minDate={now.toDate()}
        maxDate={now.add(1, 'year').toDate()}
        required
        {...form.getInputProps('_startsAtDate')}
      />
      <TimeInput
        disabled={isSaving}
        label={t('Time')}
        required
        {...form.getInputProps('_startsAtTime')}
      />
      <SelectInput
        disabled={isSaving}
        data={useMemo(
          () =>
            map(queryData?.clinicians?.nodes, (clinician) => ({
              label: `${clinician.user.firstName} ${clinician.user.lastName}`,
              value: clinician.rowId
            })),
          [queryData?.clinicians?.nodes]
        )}
        label={t('Clinician')}
        required
        searchable
        {...form.getInputProps('clinicianId')}
      />
      <SelectInput
        clearable
        disabled={isSaving}
        data={useMemo(
          () =>
            map(patientData?.user?.userChildren?.nodes, (child) => {
              const now = dayjs()
              let age

              if (child?.birthdateAt) {
                const birthdate = dayjs(child.birthdateAt)
                const ageInYears = now.diff(birthdate, 'year')

                age =
                  ageInYears >= 1
                    ? t('admin:x_years', { x: ageInYears })
                    : t('admin:x_months', { x: now.diff(birthdate, 'month') })
              } else {
                age = t('admin:due_in_x_months', {
                  x: Math.round(dayjs(child?.dueAt).diff(now, 'month', true))
                })
              }

              return {
                label: `${child.name} (${age})`,
                value: child.rowId
              }
            }),
          [patientData?.user?.userChildren?.nodes, t]
        )}
        label={t('Child')}
        {...form.getInputProps('userChildId')}
      />
      <SelectInput
        disabled={isSaving || !form.values.clinicianId}
        data={useMemo(
          () =>
            map(queryData?.services?.nodes, (service) => ({
              label: `${service.name} (${dayjs.duration(service.duration).asMinutes()}min)`,
              value: service.rowId
            })),
          [queryData?.services?.nodes]
        )}
        label={t('Service')}
        required
        searchable
        {...form.getInputProps('serviceId')}
      />
      <TextareaInput
        disabled={isSaving}
        label={t('Notes')}
        minRows={2}
        {...form.getInputProps('notes')}
      />
      <SelectInput
        disabled={isSaving}
        data={enumTypeToKeyPairs(AppointmentsStatus)}
        label={t('Status')}
        required
        searchable
        {...form.getInputProps('status')}
      />
    </Form>
  )
}
